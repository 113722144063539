import axios from "axios";
import { Auth } from "aws-amplify";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL
    ? process.env.REACT_APP_BASE_API_URL
    : process.env.REACT_APP_DEFAULT_API_URL;

async function restGet(pathname, successCallback, failCallback) {
    const session = await Auth.currentSession();

    axios
        .create({
            headers: {
                Authorization: session.idToken.jwtToken,
            },
        })
        .get(BASE_API_URL + pathname)
        .then((res) => {
            successCallback(res.data);
        })
        .catch((err) => {
            failCallback(err);
        });
}

async function restGetSummary(
    pathname,
    params,
    successCallback,
    failCallback
) {
    const session = await Auth.currentSession();

    axios
        .create({
            headers: {
                Authorization: session.idToken.jwtToken,
            },
        })
        .get(BASE_API_URL + pathname, { params: params })
        .then((res) => {
            successCallback(res.data);
        })
        .catch((err) => {
            failCallback(err);
        });
}

async function restPost(pathname, reqBody, successCallback, failCallback) {
    const session = await Auth.currentSession();

    axios
        .create({
            headers: {
                Authorization: session.idToken.jwtToken,
            },
        })
        .post(BASE_API_URL + pathname, reqBody)
        .then((res) => {
            successCallback(res.data);
        })
        .catch((err) => {
            console.error(err);
            failCallback(err);
        });
}

async function restPut(pathname, reqBody, successCallback, failCallback) {
    const session = await Auth.currentSession();
    axios
        .create({
            headers: {
                Authorization: session.idToken.jwtToken,
            },
        })
        .put(BASE_API_URL + pathname, reqBody)
        .then((res) => {
            successCallback(res.data);
        })
        .catch((err) => {
            failCallback(err);
        });
}

async function restDelete(pathname, reqBody, successCallback, failCallback) {
    const session = await Auth.currentSession();
    axios
        .create({
            headers: {
                Authorization: session.idToken.jwtToken,
            },
        })
        .delete(BASE_API_URL + pathname, reqBody)
        .then((res) => {
            successCallback();
        })
        .catch((err) => {
            failCallback(err);
        });
}

export { restGet, restPost, restPut, restDelete, restGetSummary };

