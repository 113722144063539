import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { mainBranches } from "../private-layout/data";

export const Tab = ({ idx }) => {
    const user = useSelector((state) => state.auth.user);

    return (
        <div className="pb-5">
            <div className="pb-5 flex flex-col sm:flex-row justify-between md:items-center">
                <p className="text-primary-green font-bold text-xl">
                    {mainBranches[idx].name}
                </p>

                <div className="flex flex-col sm:items-end">
                    <p className="text-4xl text-red-500">
                        &#8226;{" "}
                        <span className="pl-1 text-xs text-gray-900">
                            = you have worked on this topic
                        </span>
                    </p>
                </div>
            </div>

            {mainBranches[idx].subBranches
                .filter((el) => el.name.toLowerCase() !== "my contacts")
                .map((sub, sIdx) => (
                    <div key={sIdx} className="pb-2">
                        <div className="flex items-center gap-x-2">
                            <p className="font-bold capitalize whitespace-nowrap">
                                {sub.name.toLowerCase()}
                            </p>
                            <div className="w-full h-1 border-b border-primary-green/60" />
                        </div>
                        <div className="py-2 pl-5 grid md:grid-cols-2">
                            {sub.topics.map((topic, tIdx) => {
                                const isWorkedTopic =
                                    user &&
                                    user.lastVisited
                                        .map((item) => item.name)
                                        .includes(topic.name);
                                const isFollowing =
                                    user &&
                                    user.following.length &&
                                    user.following.some(
                                        (item) =>
                                            item.mainBranch ===
                                                mainBranches[idx].path &&
                                            item.subBranch === sub.path &&
                                            item.topicName === topic.path
                                    );

                                return (
                                    <div
                                        key={tIdx}
                                        className="relative pb-2 text-sm text-primary-green"
                                    >
                                        {isWorkedTopic && isFollowing && (
                                            <p className="absolute -left-4 -top-3 text-4xl text-red-500">
                                                &#8226;
                                            </p>
                                        )}
                                        <Link
                                            to={`/${mainBranches[idx].path}/${sub.path}/${topic.path}`}
                                            className="hover:underline hover:underline-offset-2"
                                        >
                                            {topic.name}
                                        </Link>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ))}
        </div>
    );
};

