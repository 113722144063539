import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import FilesSharedWithMe from "./files-2-shared-with-me";
import ReportsIHaveShared from "./report-i-haved-shared";
import ReportsSharedWithMe from "./report-shared-with-me";
import PageTitle from "../../components/common/page-title";

const tabs = [
    { name: "Reports shared with me", href: "#", current: false },
    { name: "Files shared with me", href: "#", current: true },
    { name: "Reports I have Shared", href: "#", current: false },
];

export default function Sharing() {
    const [params] = useSearchParams();
    const [tab, setTab] = useState(1);

    useEffect(() => {
        if (params.get("tab") === "1") setTab(1);
        if (params.get("tab") === "2") setTab(2);
        if (params.get("tab") === "3") setTab(3);
    }, [params]);

    return (
        <>
            <PageTitle title="My Banyan" />

            <div className="flex max-w-7xl flex-col">
                <div className="py-1">
                    {/* Tabs */}
                    <div className="border-b border-gray-300">
                        <nav className="py-2 -mb-px flex space-x-8 overflow-x-auto">
                            <div className="flex">
                                {tabs.map((el, idx) => (
                                    <div key={el.name} className="relative">
                                        <button
                                            key={el.name}
                                            href={el.href}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setTab(idx + 1);
                                            }}
                                            className="mx-4 px-4 py-2 text-2xs sm:text-sm font-medium bg-primary-green hover:bg-white disabled:bg-white text-white hover:text-primary-green disabled:text-primary-green border-2 border-primary-green focus:outline-none rounded-md disabled:cursor-not-allowed transition duration-300"
                                        >
                                            {el.name}
                                        </button>
                                        {idx !== tabs.length - 1 &&
                                            idx >= 1 && (
                                                <div
                                                    className="ml-24 absolute top-1/2 right-0 h-8 w-px bg-primary-green divider"
                                                    style={{
                                                        transform:
                                                            "translateY(-50%)",
                                                    }}
                                                ></div>
                                            )}
                                    </div>
                                ))}
                            </div>
                        </nav>
                    </div>

                    {/* Description list with inline editing */}
                    {tab === 1 && <ReportsSharedWithMe />}
                    {tab === 2 && <FilesSharedWithMe />}
                    {tab === 3 && <ReportsIHaveShared />}
                </div>
            </div>
        </>
    );
}

