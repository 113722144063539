import PageTitle from "../../components/common/page-title";

import { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Auth, Hub } from "aws-amplify";
import { SubmissionAlert } from "../../components/common/submission-alert";
import { AuthLayout } from "../../components/auth/auth-layout";
import { Button, Checkbox, Text, ShowPassword } from "../../components/auth/inputs";
import { VerifyConfirmationCode } from "../../components/auth/verify-confirmation-code";
import { errorSignUpMessages } from "../../error/message";
import { updateCurrentUser } from "../../store/actions";

const subtitle = {
    text: "Start your 21-day free trial. Join numerous subscribers using My Banyan. No credit card is required. Do you already have an account?",
    linkText: "Sign in here.",
    href: "/signin",
};

const SignUp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const infoParam = queryParams.get("info");
    const user = useSelector((state) => state.auth.currentUser);

    const onAuthEvent = (payload) => {
        if (payload.event === "autoSignIn") {
            dispatch(updateCurrentUser(payload.data));
            let path = "/";
            if (location.state && location.state.pathname) path = location.state.pathname;

            navigate(path, { replace: true });
        }
    };

    Hub.listen("auth", (data) => {
        const { payload } = data;
        onAuthEvent(payload);
    });

    async function signUp(formValues) {
        try {
            await Auth.signUp({
                username: formValues.signupUsername,
                password: formValues.signupPassword,
                attributes: {
                    name: formValues.signupName,
                },
                autoSignIn: {
                    enabled: true,
                },
            });
            setReq((prevState) => ({
                ...prevState,
                user: true,
                isPending: false,
            }));
        } catch (error) {
            if (error.message === errorSignUpMessages.USER_ALREADY_EXISTS) {
                setReq((prevState) => ({
                    ...prevState,
                    error: error.message + " Please sign in to your account.",
                    isPending: false,
                }));
            } else {
                setReq((prevState) => ({
                    ...prevState,
                    error: error.message,
                    isPending: false,
                }));
            }
        }
    }

    const [formValues, setFormValues] = useState({
        signupName: "",
        signupUsername: "",
        signupPassword: "",
        showPassword: false,
    });
    const [req, setReq] = useState({
        user: false,
        isPending: false,
        error: null,
    });

    useEffect(() => {
        document.title = "Sign In and Start Organizing Your Life | mybanyanlife.com";

        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute("content", "Your first step in organizing your important documents and making them available 24/7 at any time, from anywhere.");
        } else {
            const meta = document.createElement("meta");
            meta.name = "description";
            meta.content = "Your first step in organizing your important documents and making them available 24/7 at any time, from anywhere.";
            document.head.appendChild(meta);
        }
    }, []);

    const onChangeHandler = (e) => {
        e.preventDefault();

        setFormValues((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const handleShowPassword = () => {
        setFormValues((prevState) => ({
            ...prevState,
            showPassword: !prevState.showPassword,
        }));
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();

        setReq((prevState) => ({
            ...prevState,
            isPending: true,
        }));
        signUp(formValues);
    };

    useEffect(() => {
        if (user) navigate("/");
    }, []);

    return (
        <>
            <PageTitle title="My Banyan" />

            {req.user ? (
                <VerifyConfirmationCode username={formValues.signupUsername} info={infoParam} />
            ) : (
                <AuthLayout title={"Register a new account"} subtitle={subtitle} bgImg={"signup"}>
                    {req.error && <SubmissionAlert type={"error"} message={req.error} />}
                    <p className="pb-5 text-xs text-gray-50 text-center">All fields with an asterisk * are required</p>
                    <form className="space-y-6" onSubmit={onSubmitHandler}>
                        <div className="space-y-1">
                            <Text name="signupName" type="text" value={formValues.signupName} onChange={onChangeHandler} autoComplete="new-password" required label="Your name *" />
                        </div>
                        <div className="space-y-1">
                            <Text name="signupUsername" type="email" value={formValues.signupUsername} onChange={onChangeHandler} autoComplete="new-password" required label="Email *" />
                        </div>

                        <div className="relative space-y-1">
                            <Text name="signupPassword" type={formValues.showPassword ? "text" : "password"} value={formValues.signupPassword} onChange={onChangeHandler} autoComplete="new-password" required label="Password *" />

                            <ShowPassword handleShowPassword={handleShowPassword} showPassword={formValues.showPassword} />

                            <div className="text-xs font-thin text-white">Use 8 or more chars with a mix of uppercases, lowercases, numbers & symbols</div>
                        </div>

                        <Checkbox id="remember-me" name="remember-me" required={true}>
                            I agree to the{" "}
                            <Link to="/privacy-policy-and-terms-of-service" target="_blank" className="font-light text-gray-50 hover:text-gray-700 underline">
                                terms of service
                            </Link>{" "}
                            and{" "}
                            <Link to="/privacy-policy-and-terms-of-service" target="_blank" className="font-light text-gray-50 hover:text-gray-700 underline">
                                privacy policy
                            </Link>
                        </Checkbox>

                        <div>
                            <Button type="submit" isPending={req.isPending}>
                                SIGN UP
                            </Button>
                        </div>
                    </form>
                </AuthLayout>
            )}
        </>
    );
};

export default SignUp;

