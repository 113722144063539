export const cashOnHandData = {
    formTitle: "Add or edit cash on hand",
    formSubtitle:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    pathname: "/cashOnHand",
    formFields: [
        {
            component: "input",
            label: "Location of cash",
            name: "location",
            type: "text",
            required: true,
            colObject: true,
        },
        {
            component: "value",
            label: "Current value",
            name: "value",
            type: "number",
            required: true,
            colObject: true,
        },
        {
            component: "input",
            label: "Date of estimate",
            name: "date",
            type: "date",
        },
        {
            component: "textarea",
            label: "Notes",
            name: "notes",
        },
        {
            component: "input",
            label: "Location of files / documents",
            name: "fileLocation",
            type: "text",
        },
    ],
};

