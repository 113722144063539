import { useState } from "react";

import RegJourneyModal from "./reg-journey-modal";

export default function InnerCircle({ setDialogNum }) {
  const [open, setOpen] = useState(true);
  const [innerCircleAnswer, setInnerCircleAnswer] = useState(null);

  const handleInnerCircleAnswerChange = (e) => {
    if (e.target.value.toLowerCase() === "yes") setInnerCircleAnswer(true);
    else setInnerCircleAnswer(false);
  };

  const changeDialogNum = () => {
    if (innerCircleAnswer === true) setDialogNum(2);
    else setDialogNum(3);
  };

  return (
    <RegJourneyModal open={open} setOpen={setOpen}>
      <div className="w-full h-[7vh] flex justify-center items-center text-2xl md:text-3xl text-primary-green text-center font-medium">
        <h2>WELCOME</h2>
      </div>

      <div className="h-max pl-8 pr-8 xl:pr-0 py-8 flex justify-center items-center gap-x-8 bg-alternative-green">
        <article className="xl:w-3/5 flex flex-col gap-y-8 text-xl md:text-2xl text-center font-custom2">
          <p className="text-2xl md:text-3xl text-center xl:text-left font-bold">Your Inner Circle</p>

          <p className="text-xl md:text-2xl text-justify font-medium">
            Your Inner Circle has a special role in My Banyan. These people are those whom you want to protect from financial, legal, and other burdens should something happen to you. These people can
            be from your family, close friends, and trusted advisors. In My Banyan, you decide which information and documents each Inner Circle member receives from you.
          </p>

          <p className="text-xl md:text-2xl text-justify font-medium">
            Focus for a moment on who should know the most important information and documents about your life. Do you want to identify a member now?
          </p>

          {/* MOBILE & SMALLER DESKTOP IMG */}
          <img
            src="https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fregistration-journey%2Finner-circle-welcome%2Fcircle-of-friends.jpg?alt=media&token=7ee29dd3-9eca-4fbe-8dfd-7032b16e31dc"
            alt="A group of diverse people putting their hands in the middle"
            className="xl:hidden h-[20vh] object-cover"
          />

          <section className="flex flex-col items-center sm:items-start gap-y-4 font-semibold">
            <div>
              <input
                type="radio"
                id="yesInnerCircle"
                name="regJourneyInnerCircle"
                value="yes"
                checked={innerCircleAnswer === true}
                onChange={handleInnerCircleAnswerChange}
                className="text-primary-green border-black focus:ring-primary-green focus:ring-2"
              />

              <label htmlFor="yesInnerCircle" className="ml-2">
                YES. I’ll list my first member, now.
              </label>
            </div>

            <div>
              <input
                type="radio"
                id="noInnerCircle"
                name="regJourneyInnerCircle"
                value="no"
                checked={innerCircleAnswer === false}
                onChange={handleInnerCircleAnswerChange}
                className="text-primary-green border-black focus:ring-primary-green focus:ring-2"
              />

              <label htmlFor="noInnerCircle" className="ml-2">
                NO. I’ll do that later.
              </label>
            </div>
          </section>
        </article>

        {/* LARGER DESKTOP IMG */}
        <div className="hidden xl:block w-2/5">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fregistration-journey%2Finner-circle-welcome%2Fcircle-of-friends.jpg?alt=media&token=7ee29dd3-9eca-4fbe-8dfd-7032b16e31dc"
            alt="A group of diverse people putting their hands in the middle"
          />
        </div>
      </div>

      <section className="h-[15vh] sm:h-[10vh] px-10 flex justify-between items-center bg-alternative-green">
        <div className="hidden sm:block h-20 w-20" />

        <div className="flex gap-x-2">
          <div className="h-4 sm:h-6 w-4 sm:w-6 border-2 border-primary-green rounded-full" />
          <div className="h-4 sm:h-6 w-4 sm:w-6 bg-primary-green rounded-full" />
          <div className="h-4 sm:h-6 w-4 sm:w-6 border-2 border-primary-green rounded-full" />
          <div className="h-4 sm:h-6 w-4 sm:w-6 border-2 border-primary-green rounded-full" />
          <div className="h-4 sm:h-6 w-4 sm:w-6 border-2 border-primary-green rounded-full" />
        </div>

        <button onClick={changeDialogNum}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fregistration-journey%2Fright%20arrow.svg?alt=media&token=ef3d9e22-73f8-41a8-b7d9-802f60348068"
            alt="Green right arrow"
            className="h-14 sm:h-20 w-14 sm:w-20"
          />
        </button>
      </section>
    </RegJourneyModal>
  );
}
