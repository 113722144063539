export const internetAccessData = {
  formTitle: "Add or edit internet access",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/internetAccess",
  formFields: [
    {
      component: "input",
      label: "Company providing access",
      name: "company",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "select",
      label: "Access type",
      name: "accessTech",
      type: "text",
      options: [
        { value: "Cable", text: "Cable" },
        { value: "Cellular", text: "Cellular" },
        { value: "Dedicated leased line", text: "Dedicated leased line" },
        { value: "Fiber optics broadband", text: "Fiber optics broadband" },
        { value: "Satellite", text: "Satellite" },
        { value: "Wireless", text: "Wireless" },
        { value: "Other", text: "Other" },
      ],
      colObject: true,
    },
    {
      component: "input",
      label: "Website URL",
      name: "website",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Customer serice phone number",
      name: "phone",
    },
    {
      component: "input",
      label: "Account number",
      name: "accountNumber",
      type: "text",
    },
    {
      component: "input",
      label: "Name(s) on account",
      name: "names",
      type: "text",
      description:
        "Share the name(s) of your internet service provider accounts and related access information with your loved ones.",
    },
    {
      component: "input",
      label: "Login username (or its location)",
      name: "username",
      type: "text",
    },
    {
      component: "password",
      label: "Password (or its location)",
      name: "password",
      type: "password",
    },
    {
      component: "input",
      label: "Annual / monthly fee",
      name: "fee",
      type: "text",
    },
    {
      component: "input",
      label: "Renewal date",
      name: "date",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
