export const internetServicesData = {
  formTitle: "Add or edit internet service",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/internetServices",
  formFields: [
    {
      component: "input",
      label: "Company providing service",
      name: "company",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Description of service",
      name: "description",
      type: "text",
    },
    {
      component: "input",
      label: "Provider website",
      name: "website",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Customer serice phone number",
      name: "phone",
    },
    {
      component: "input",
      label: "Account number",
      name: "accountNumber",
      type: "text",
    },
    {
      component: "input",
      label: "Name(s) on account",
      name: "names",
      type: "text",
      description:
        "Share the exact name(s) and access information for each of your website accounts with your loved ones.",
    },
    {
      component: "input",
      label: "Login username (or its location)",
      name: "username",
      type: "text",
    },
    {
      component: "password",
      label: "Password (or its location)",
      name: "password",
      type: "password",
    },
    {
      component: "input",
      label: "Annual / monthly fee",
      name: "fee",
      type: "text",
    },
    {
      component: "input",
      label: "Renewal date",
      name: "date",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
