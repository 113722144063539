export const irrevocableTrustsData = {
  formTitle: "Add or edit irrevocable trust",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/irrevocableTrusts",
  formFields: [
    {
      component: "input",
      label: "Name of trust",
      name: "name",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "select",
      label: "Type of trust",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Asset Protection Trust", text: "Asset Protection Trust" },
        { value: "By-Pass Trust", text: "By-Pass Trust" },
        { value: "Charitable Trust", text: "Charitable Trust" },
        { value: "Burial Insurance Trust", text: "Burial Insurance Trust" },
        {
          value: "Family Limited Partnership",
          text: "Family Limited Partnership",
        },
        {
          value: "Generation-Skipping Trust",
          text: "Generation-Skipping Trust",
        },
        { value: "Life Insurance Trust", text: "Life Insurance Trust" },
        { value: "QTIP Trust", text: "QTIP Trust" },
        { value: "QDOT Trust", text: "QDOT Trust" },
        { value: "Special Needs Trust", text: "Special Needs Trust" },
        { value: "Spendthrift Trust", text: "Spendthrift Trust" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Person(s) who have copies of this document",
      name: "persons",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Tangible assets in trust",
      name: "tangible",
      type: "text",
    },
    {
      component: "input",
      label: "Digital assets in trust",
      name: "digital",
      type: "text",
    },
    {
      component: "input",
      label: "Settlor(s)",
      name: "settlors",
      type: "text",
    },
    {
      component: "input",
      label: "Trustee(s)",
      name: "trustees",
      type: "text",
    },
    {
      component: "input",
      label: "Successor trustee",
      name: "successor",
      type: "text",
    },
    {
      component: "input",
      label: "Beneficiaries",
      name: "beneficiaries",
      type: "text",
    },
    {
      component: "input",
      label: "Attorney name",
      name: "attorney",
      type: "text",
    },
    {
      component: "input",
      label: "Last modification date",
      name: "firstDate",
      type: "date",
    },
    {
      component: "input",
      label: "Date notarized",
      name: "secondDate",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
