export const phoneNumbersData = {
  formTitle: 'Add or edit phone number',
  formSubtitle:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  pathname: '/phoneNumbers',
  formFields: [
    {
      component: 'select',
      label: 'Type of phone',
      name: 'type',
      type: 'text',
      required: true,
      colObject: true,
      options: [
        { value: 'Cell / mobile: Business', text: 'Cell / mobile: Business' },
        { value: 'Cell / mobile: Personal', text: 'Cell / mobile: Personal' },
        { value: 'Fax line: Business', text: 'Fax line: Business' },
        { value: 'Fax line: Personal', text: 'Fax line: Personal' },
        { value: 'Land line: Business', text: 'Land line: Business' },
        { value: 'Land line: Personal', text: 'Land line: Personal' },
        { value: 'VoIP', text: 'VoIP' },
        { value: 'Other', text: 'Other' },
      ],
    },
    {
      component: 'phoneNumber',
      label: 'Number',
      name: 'phone',
      required: true,
      colObject: true,
    },
    {
      component: 'textarea',
      label: 'Notes',
      name: 'notes',
    },
    {
      component: 'input',
      label: 'Location of files / documents',
      name: 'fileLocation',
      type: 'text',
    },
  ],
};
