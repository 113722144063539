export const communityServiceData = {
  formTitle: "Add or edit community service",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/communityService",
  formFields: [
    {
      component: "input",
      label: "Name",
      name: "name",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Service I provided",
      name: "service",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Start year",
      name: "startYear",
      type: "number",
    },
    {
      component: "input",
      label: "End year",
      name: "endYear",
      type: "number",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
