export const pageDescriptions = {
    innerCircle: [
        `This small group are the most informed and supportive of your needs and wishes. These persons can be:`,
        `Selected loved ones. They may include friends and members of your family.`,
        `People who have a critical role. They may include your executor, trustee, and powers of attorney.`,
        `Most trusted advisors. They may include your estate attorney, CPA, and financial planner.`,
    ],
    sharing: [
        `When clicking the “Reports shared with me”, you can see who shared a PDF report with you. The button “Files shared with me”, will show you who shared images or other type of files with you, like picture of a person, a passport, a house or anything else.`,
        `To see which reports YOU have shared with someone, click the “Reports I have shared” link below.`,
    ],
    contacts: [
        `Our platform is not intended to hold all your contacts. It is meant to include people who can provide timely information, records, and advice that you need to enter into My Banyan. They may include some friends, family, and professionals such as physicians, bankers, realtors, insurance brokers, veterinarians, clergy, et al.`,
    ],
    feedbackAndSupport: `Have any questions? We are happy to help. Our support team will answer you within one business day.`,
    feedbackAndSupportConfirmation: `Your submission has been received. Someone will get back to you within 24 hours`,
    privacyPolicy: [
        `SCOPE OF THIS NOTICE`,
        `Please read this privacy notice (“Notice”) carefully to understand our policies and practices
    regarding your personal data and how we will treat it. This Notice applies to individuals who
    interact with MY BANYAN, INC. MY BANYAN provides services to consumers (“you”) on
    the sites and platforms on which it appears. This Notice explains how your personal data (which
    some jurisdictions may refer to as “personal information”) is collected, used, and disclosed by
    MY BANYAN, INC. MY BANYAN, and third parties acting on our behalf (collectively
    “MYBANYAN”, “We”, “Us” “our”) on the sites and platforms on which this policy appears. It
    also tells you how you can access and update your personal data and make certain choices about
    how your personal data is used.`,
        `This Notice covers both our online and offline data collection activities where this policy is
    provided to you or is otherwise referenced. This includes personal data that We collect through
    our various channels such as websites, apps, third-party social networks, consumer affairs, points
    of sale, and events where this policy is provided to you. Please note that We might combine
    personal data from different sources (website, offline event). We also may combine data from
    third parties with data We already have.`,
        `In some instances, if you do not provide personal data to Us, We may not be able to provide you
    with certain goods and/or services. We will indicate to you when this is the case, for example, by
    stating so on our registration forms.`,
        `PERSONAL DATA THAT WE COLLECT ABOUT YOU`,
        `We collect various types of information from you, as described below.`,
        `Contact Data: Any data you provide to Us that would allow Us to contact you, such as your
    name, postal address, e-mail address, social network details, or phone number.`,
        `Account Login Data: Any data that is required to give you access to your specific account
    profile. Examples include your login ID/email address, screen name, password in unrecoverable
    form, and/or security question and answer.`,
        `Device Data: Data about the computer system or other technological device that you use to
    access one of our websites or apps, such as the Internet protocol (IP) address used to connect
    your computer or device to the Internet and other online identifiers. If you access one of the sites
    or apps subject to this policy via a mobile device such as a smartphone, the collected data will
    also include, where permitted, your phone’s unique device ID, advertising ID, and other similar
    mobile device data.`,
        `Payment Data: Any data that We need in order to fulfill an order, or that you use to make a
    purchase, such as your debit or credit card details (cardholder name, card number, expiration
    date, etc.) or other forms of payment (if such are made available).`,
        `Demographic Data: Any data that describes your demographic or behavioral characteristics.
    Examples might include your gender, geographic location (e.g., ZIP code), services, or company
    data. In some cases, this could include data that you give Us about someone else. For example, if
    you provide another individual or company’s email address for a referral. If you are a service
    provider company, We may collect data about your practice.`,
        `Third-Party Social Network Data: Any data that you share publicly on a third-party social
    network or data that is part of your profile on a third-party social network (such as Facebook,
    Twitter, Instagram, etc.) and that you allow the third-party social network to share with Us.
    Examples include your basic account data (e.g., name, email address, gender, current city, profile
    picture, user ID, list of friends, etc.) and any other additional data or activities that you permit the
    third-party social network to share. We receive your third-party social network profile data (or
    parts of it) every time you download or interact with a web application on a third-party social
    network such as Facebook, every time you use a social networking feature that is integrated
    within one of our sites (such as Facebook Connect), or every time you interact with Us through a
    third-party social network. To learn more about how your data from a third-party social network
    is obtained by Us, or to opt out of sharing such social network data, please visit the website of
    the relevant third-party social network.`,
        `Third-Party Payment Service Data: Any data that you share publicly on a third-party payment
    service or data that is part of your profile on the payment service provider (such as Paypal) and
    that you allow the third-party social network to share with Us. Examples include your basic
    account data (e.g., name, email address, credit card information, banking and account
    information, etc.) and any other additional data or activities that you permit the third-party
    payment provider to share. We do not take custody of or retain or hold any of your financial or
    banking information.`,
        `Site Usage Data: This includes information about how you use our websites and our other
    communications. For example, as you navigate through and interact with our websites/apps or
    emails, We use automatic data collection technologies to collect certain data about your device(s)
    and your actions. This includes data such as which links you click on, which pages or content
    you view and for how long, and other similar data and statistics about your interactions, such as
    content response times, download errors, and length of visits to certain pages, as well as
    operating system type and web browser type and version. We may also visually record your
    interactions with our website, including your mouse clicks, movement, scrolling, and navigation
    through our website.`,
        `Feedback Data: Any information that you share with Us about your experience of using our
    products and services. This includes any content that you create and then share with Us on thirdparty social networks or one of our websites or apps. Examples include photos, videos, personal stories, or other similar media or content. This may also include information you provide during
    your conversations with consumer affairs.`,
        `Geolocation Data: We may collect information about your location. This may include your
    precise location.`,
        `Inferences: We may draw inferences from the data We collect from and about you to create a
    profile reflecting your preferences, characteristics, and behavior.`,
        `HOW WE COLLECT PERSONAL DATA ABOUT YOU`,
        `We collect personal data directly from you, when you choose to provide it to us. For example,
    We collect data when you place an order with us. We collect data when you register on one of
    our websites or apps. We collect data when you sign up for our emails. We collect data from
    printed or digital registrations and similar forms that We collect via, for example, postal mail,
    contests, and other promotions or events. We collect data if you fill out a request or use other
    tools on your websites or apps. We also collect data if you contact Us through our websites or
    apps, via email, or through social media.`,
        `We collect data from you passively. For example, We use tracking tools like browser cookies
    and web beacons. We do this in our digital platforms and in emails that We send to you. We
    collect data about you over time when you use this website. This includes usage and browser
    data. We may have service providers or other third parties collect data this way. We also collect
    data from our mobile apps.`,
        `We get data about you from other sources. For example, our affiliates and business partners may
    give Us data about you. Social media platforms may also give Us data about you. We may get
    data about your interactions with our ads on third-party sites.`,
        `PERSONAL DATA OF CHILDREN`,
        `We do not knowingly solicit or collect personal data from children below the age of 13. If you
    are a parent or legal guardian and think that your child under 13 has given Us data, you can
    contact Us in writing or by email as provided below under the section titled CONTACT.`,
        `USES FOR YOUR PERSONAL DATA`,
        `The following paragraphs describe the various purposes for which We collect and use your
    personal data, and the different types of personal data that are collected for each purpose. Please
    note that not all of the uses below will be relevant to every individual.`,
        `Consumer service: We use your personal data for consumer service purposes. Consumer service
    activities could be responding to your inquiries or complaints.`,
        `Contests, marketing, and other promotions: We may use your personal data to provide you with
    information about goods or services (e.g., marketing communications or campaigns or
    promotions). This can be done via email, ads, SMS, phone calls, and postal mailings to the extent
    permitted by applicable laws. Some of our campaigns and promotions are run on third-party
    websites and/or social networks. For more information about our contests and other promotions,
    please see the official rules or details posted with each contest/promotion.`,
        `Third-party social networks: We use your personal data when you interact with third-party social
    networking features, such as “Like” functions, to serve you with advertisements and engage with
    you on third-party social networks. You can learn more about how these features work and the
    profile data that We obtain about you, and find out how to opt-out by reviewing the privacy
    notices of the relevant third-party social networks.`,
        `Personalization: With your consent (where required), We may use your personal data to
    personalize your experience with us. For example, we may use these categories of personal
    information: (i) to analyze your preferences and habits; (ii) to anticipate your needs based on our
    analysis of your profile; (iii) to improve and personalize your experience on our websites and
    apps; (iv) to ensure that content from our websites/apps is optimized for you and for your
    computer or device; (v) to provide you with targeted advertising and content, and (vi) to allow
    you to participate in interactive features, when you choose to do so. For example, We remember
    your login ID/email address or screen name so that you can quickly login the next time you visit
    our site. Based on this type of information, and with your consent (where required), We also
    show you specific content or promotions that are tailored to your interests.`,
        `Order fulfillment: We use your personal data to process and service your orders, inform you
    about the status of your orders, correct addresses, and conduct identity verification and other
    fraud detection activities. This involves the use of certain personal data and payment
    information.`,
        `Other general purposes (e.g. internal or market research, analytics, security): In accordance with
    applicable laws, We use your personal data for other general business purposes, such as
    maintaining your account, conducting internal or market research, and measuring the
    effectiveness of advertising campaigns. This also includes improving our websites and services.
    We also use your personal data for management and operation of our communications, IT, and
    security systems.`,
        `DISCLOSURE OF YOUR PERSONAL DATA`,
        `The following paragraphs describe how and to whom We disclose your data.`,
        `Service providers. We share data with our service providers. These are external companies that
    We use to help Us run our business (e.g., service or order fulfilment, payment processing, fraud
    detection and identity verification, website operation, market research companies, support
    services, promotions, website development, data analysis, CRC, etc.). For individuals in
    California this information may have been shared in the last 12 months.`,
        `On our website you can inquire about sales or service from various vendors. You can also submit
    an inquiry or application to a service provider or member organization. When you do that you
    authorize Us to share the information you provided on your inquiry or application form with
    those organizations. Information shared might include your contact and demographic data. If you
    do not want to share this information, you should not submit an inquiry or application. For
    individuals in California this information may have been shared in the last 12 months.`,
        `Credit reporting agencies/debt collectors. To the extent permitted by applicable law, credit
    reporting agencies and debt collectors are external companies that We use to help Us to verify
    your creditworthiness (in particular for orders with invoice) or to collect outstanding invoices.
    For individuals in California, this information may have been shared in the last 12 months.`,
        `Sharing personal data for legal reasons or due to merger/acquisition. In the event that
    MYBANYAN or its assets are acquired by, or merged with, another company including through
    bankruptcy, We will share your personal data with any of our legal successors. We will also
    disclose your personal data to third parties (i) when required by applicable law; (ii) in response to
    legal proceedings; (iii) in response to a request from a competent law enforcement agency; (iv)
    to protect our rights, privacy, safety, or property, or the public; or (v) to enforce the terms of any
    agreement or the terms of our website.`,
        `DATA PROTECTION AND RETENTION`,
        `We use standard security measures as required by applicable law. The transmission of
    information via the Internet is, unfortunately, not completely secure and despite our efforts to
    protect your personal data, We cannot guarantee the security of the data during transmission
    through our websites/apps. It is important that you also play a role in keeping your personal data
    safe and secure. When signing up for an online account, please be sure to choose an account
    password that would be difficult for others to guess and never reveal your password to anyone
    else. You are responsible for keeping this password confidential and for any use of your account.
    If you use a shared or public computer, never choose to have your login ID/email address or
    password remembered and make sure to log out of your account every time you leave the
    computer. You should also make use of any privacy settings or controls We provide you in our
    websites/apps.`,
        `Retention of personal data. We keep personal data as long as it is necessary or relevant for the
    practices described in this Notice. We also keep personal data as otherwise required by law.`,
        `YOUR CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR PERSONAL DATA`,
        `We strive to provide you with choices regarding the personal data that you provide to Us. The
    following mechanisms give you the following control over your personal data:`,
        `Advertising, marketing, and promotions. You can opt out of marketing emails by following the
    instructions provided in each such communication. Please note that even if you opt out from
    receiving marketing communications, you will still receive transactional communications from
    Us, such as order or other transaction confirmations, notifications about your account activities
    (e.g., account confirmations, password changes, confirmation of orders and submission, etc.),
    and other important non-marketing-related announcements.`,
        `Cookies, tracking tools, and targeted advertising. To learn how to manage how We and our
    vendors use cookies and other tracking tools, please read our Do Not Track Policy.`,
        `THIRD-PARTY WEBSITES AND SERVICES`,
        `We may link to third-party websites or apps, including social media platforms. This Notice does
    not apply to, and We are not responsible for, the privacy practices of these third-party websites
    or apps. Please read their privacy policies carefully.`,
        `YOUR CALIFORNIA PRIVACY RIGHTS`,
        `California residents may also take advantage of the following rights:`,
        `Access. You may request, up to two times each year, that We disclose to you the personal data
    (i.e., “personal information,” as the California Consumer Privacy Act (“CCPA”) defines the
    term) that We collect, use, and disclose about you. In response to a verified request, We will
    provide (1) the categories and specific pieces of personal data that we have collected, (2) the
    categories of sources from which that data is collected, (3) the business or commercial purpose
    for collecting it, (4) the categories of third parties with whom We shared that data, and (5) the
    business or commercial purpose for sharing that data.`,
        `Delete. You may request that We delete any personal data that We have collected from or about
    you. Note that there are some reasons we will not be able to fully address your request, such as if
    We need to complete a transaction for you, to detect and protect against fraudulent and illegal
    activity, to exercise our rights, to use the data for solely internal purposes, or to comply with a
    legal obligation.`,
        `To take advantage of any of these rights, or if you have any questions or concerns, please contact
    Us by email or our contact page on our website. For verification purposes, We may request your 
    first and last name, email address and another email address as well as devices you may have
    used when registering with Us, including your phone number or any other phone number used at
    registration and a physical mailing address. If you are submitting on someone else’s behalf we
    may ask for additional verification. This may include providing a signed letter verifying your
    right to make this request. We will not discriminate against you in response to your exercise of
    your privacy rights under the CCPA. We will respond to your access or deletion request within
    45 days of receipt of your request, after proper verification, unless we need additional time, in
    which case we will let you know.`,
        `Sale of Information. California law. We are required to tell you if We “sell” information
    including information of Californians under 16. While We do not believe We engage in
    “selling” information, it is possible that certain aspects of our sharing activity may be construed
    as a sale. You can opt out of this activity by visiting the Do Not Sell My Personal Information
    page. You can also opt out by emailing Us at info@mybanyanlife.com. We do not sell information of
    Californians under 16.`,
        `CHANGES TO THIS NOTICE`,
        `If We change the way We handle your personal data, We will update this Notice. We reserve the
    right to make changes to our practices and this Notice at any time as permitted by law. You can
    check back to see if there have been any updates or changes to our Notice.`,
        `CONTACT`,
        `To contact us about this Notice and/or our privacy practices, please contact Us at info@mybanyanlife.com.`,
        `MY BANYAN, INC.`,
        `8 The Green STE-A`,
        `Dover, DE 19901`,
        `© MY BANYAN, INC. All Rights Reserved.`,
    ],
    termsOfService: [
        `1. Introduction`,
        `Terms of Service Last updated: 08/01/2023`,
        `Our aim is to keep this Agreement as readable as possible, but in some cases for legal reasons, some of
    the language is required "legalese".`,
        `2. Your Acceptance of this Agreement`,
        `These terms of service are entered into by and between My Banyan, Inc. ("Company," "we," "our," "us"). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively "Terms of Service"), govern your access to and use of My Banyan, including any content, functionality, and services offered on or through mybanyanlife.com.`,

        `Please read the Terms of Service carefully before you start to use the Website.`,
        `By using the Website, or by clicking to accept or agree to the Terms of Service when this option is made available to you, you accept and agree to be bound and abide by these Terms of Service and our Privacy Policy, found at www.mybanyanlife.com/privacy-policy, incorporated herein by reference. If you do not want to agree to these Terms of Service, you must not access or use the Website.`,
        `BY ACCESSING AND USING THIS WEBSITE, YOU:`,
        `ACCEPT AND AGREE TO BE BOUND AND COMPLY WITH THESE TERMS OF SERVICE; YOU REPRESENT
    AND WARRANT THAT YOU ARE THE LEGAL AGE OF MAJORITY UNDER APPLICABLE LAW TO FORM A
    BINDING CONTRACT WITH US; AND YOU AGREE THAT IF YOU ACCESS THE WEBSITE FROM A
    JURISDICTION WHERE IT IS NOT PERMITTED, YOU DO SO AT YOUR OWN RISK.`,
        `3. Updates to Terms of Service`,
        `We may revise and update these Terms of Service from time to time in our sole discretion. All changes
    are effective immediately when we post them and apply to all access to and use of the Website
    thereafter.`,
        `Continuing to use the Website following the posting of revised Terms of Service means that you accept
    and agree to the changes. You acknowledge that you are to check this page each time you access the
    Website, so you are aware of any changes, as they are binding on you.`,
        `4. Your Responsibilities`,
        `You are required to ensure that all persons access the Website under any device of your control are
    aware of this Agreement and comply with it. It is a condition of your use of the Website that all the
    information you provide on the Website is correct, current, and complete.`,
        `YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR YOUR USE OF THE WEBSITE AND YOUR COMPUTER,
    INTERNET AND DATA SECURITY.`,
        `5. Prohibited Activities`,
        `You may use the Website only for lawful purposes and in accordance with these Terms of Service. You
    agree not to use the Website:`,
        `• In any way that violates any applicable federal, state, local or international law or regulation
    (including, without limitation, any laws regarding the exports of data software to and from the
    United States of America or any other country).`,
        `• For the purposes of exploiting, harming, or attempting to exploit or harm minors in any way by
    exposing them to inappropriate content, asking for personally identifiable information or
    otherwise.`,
        `• To send, knowingly receive, upload, download, use, or re-use any material that does not comply
    with the Submission Standards set out in these Terms of Service.`,
        `• To transmit, or procure the sending of, any advertising or promotional material, including any
    "junk mail," "chain letter," "spam," or any other similar solicitation.`,
        `• To impersonate or attempt to impersonate (including, without limitation, by using email
    addresses associated with any of the following): the Company, a Company employee, another
    user, or any other person, persona and/or entity.`,
        `• To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the
    Website, or which as determined by us, may harm the Company or users of the website, or
    expose them to liability.`,
        `Additionally, you agree not to:`,
        `• Use the Website in any manner that could disable, overburden, damage, or impair the Website
    or interfere with any other party's use of the Website, including their ability to engage in realtime activities through the Website.`,
        `• Use any robot, spider, or other automatic device, process, or means to access the Website for
    any purpose, including monitoring or copying any of the material on the Website.`,
        `• Use any manual process to monitor or copy any of the material on the Website, or for any other
    purpose not expressly authorized in these Terms of Service, without our prior written consent.`,
        `• Use any device, software, or routine that interferes with the proper working of the Website.`,
        `• Use any device, software, or routine that interferes with the proper working of the Website.`,
        `• Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or
    technologically harmful.`,
        `• Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the
    Website, the server on which the Website is stored, or any server, computer, or database
    connected to the Website.`,
        `• Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.`,
        `• Otherwise attempting to interfere with the proper working of the Website.`,
        `6. Intellectual Property Rights`,
        `The Website and its entire contents, features, and functionality (including, without limitation, all
    information, software, text, displays, images, video, audio, design, selection, and arrangement thereof)
    are owned by the Company, its licensors, or other providers of such material and are protected by
    United States and international copyright, trademark, patent, trade secret, and other intellectual
    property or proprietary rights laws.`,
        `These Terms of Service permit you to use the Website for your personal, non-commercial use only. You
    must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform,
    republish, download, store, or transmit any of the material on our Website, except as follows:`,
        `• Your computer may temporarily store copies of such material in RAM incidental to your
    accessing and viewing those materials.`,
        `• You may store files that are automatically cached by your Web browser for display
    enhancement purposes.`,
        `You must not:`,
        `• Modify copies of any materials from the Website;`,
        `• Download or save any of the copyright, trademark, or other proprietary rights notices from
    copies of materials from the Website; and`,
        `• Delete or alter any of the copyright, trademark, or other proprietary rights notices from copies
    of materials from the Website.`,
        `You must not access or use for any commercial purposes any part of the Website or any services or
    materials available through the Website.`,
        `If you print, copy, modify, download, or otherwise use or provide any other person with access to any
    part of the Website in breach of the Terms of Service, your right to use the Website will stop
    Immediately and you must, at our sole discretion, return or destroy any copies of the materials you have
    made. No right, title, or interest in or to the Website or any content on the Website is transferred to
    you, and all rights not expressly granted are reserved by the Company. Any use of the Website not
    expressly permitted by these Terms of Service is a breach of these Terms of Service and may violate
    copyright, trademark, and other laws.`,
        `7. Our Rights`,
        `We have the right, without provision of notice to:`,
        `• Take appropriate legal action, including, without limitation, referral to or cooperation with law
    enforcement or regulatory authorities, or notifying the harmed party of any illegal or
    unauthorized use of the Website; and`,
        `• Terminate or suspend your access to all or part of the Website for any or no reason, including,
    without limitation, any violation of these Terms of Service.`,
        `YOU WAIVE AND HOLD HARMLESS COMPANY AND ITS PARENT, SUBSIDIARIES, AFFILIATES, AND THEIR
    RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS,
    LICENSORS, LICENSEES, SUPPLIERS, AND SUCCESSORS FROM ANY AND ALL CLAIMS RESULTING FROM
    ANY ACTION TAKEN BY THE COMPANY AND ANY OF THE FOREGOING PARTIES RELATING TO ANY
    INVESTIGATIONS BY EITHER THE COMPANY OR BY LAW ENFORCEMENT AUTHORITIES.`,
        `8. Third-Party Links and Content`,
        `For your convenience, the Website may provide links or pointers to third-party sites or third-party
    content. We make no representations about any other websites or third-party content that may be
    accessed from the Website. If you choose to access any such websites, you do so at your own risk. We
    have no control over the third-party content or any such third-party sites and accept no responsibility
    for such sites or for any loss or damage that may arise from your use of them. You are subject to any
    terms and conditions of such third-party sites.`,
        `9. Online Orders`,
        `(a) All orders, purchases or transactions for the sales of goods, digital products, or information made
    using this Website are subject to the following additional terms and conditions of sale:`,
        `1. You may not order or obtain goods, digital products or information from the Website if you:
    (i) are prohibited from accessing or using the Website or any of the Website's contents,
    goods, digital products or information by applicable law or (ii) you are outside the
    geographic region where the goods, digital products or information are available for
    purchase;`,
        `2. You agree that your order is an offer to buy, under these Terms of Service, all goods, digital
    products, or information listed in your order. All orders must be accepted by us or we will
    not be obligated to sell such goods, digital products or information to you. We may choose
    not to accept orders at our sole discretion, even after we send you a confirmation email
    with your order number and details of the items you have ordered; and`,
        `3. All prices, discounts, and promotions posted on this Website are subject to change without
    notice. The price and currency charged for goods, digital products or information will be the
    price and currency advertised on the Website at the time the order is placed, subject to the
    terms of any promotions or discounts that may be applicable. The price and currency
    charged will be clearly stated in the shopping cart before you confirm your purchase. Price
    increases will only apply to orders placed after the time of the increase. Posted prices do not
    include taxes or charges for shipping and handling. All such taxes and charges will be added
    to your total pricing and will be itemized in your shopping cart and in your order
    confirmation email. We strive to display accurate price information; however, we may, on
    occasion, make inadvertent typographical errors, inaccuracies, or omissions related to
    pricing and availability. We reserve the right to correct any errors, inaccuracies, or omissions
    at any time and to cancel any orders arising from such occurrences.`,
        `(b) Where an order indicates a license is being purchased:`,
        `1. All uses on the Website of the terms "sell", "sale", "resell", "resale", "purchase", "price",
    and the like, mean the purchase or sale of a license;`,
        `2. You will comply with all terms and conditions of the applicable license Terms of Service for
    any goods, digital products or information you obtain through the Website, and you will not
    cause, induce, or permit others' non-compliance with the terms and conditions of any of
    license Terms of Services for the goods, digital products or information; and`,
        `3. Except for the limited license granted under the relevant license Terms of Service, nothing in
    these Terms of Service grants any right, title, or interest in or to (including any license
    under) any intellectual property rights in or relating to, the good, digital product or
    information, whether expressly, by implication, estoppel, or otherwise. All right, title, and
    interest in and to the good, digital product or information are and will remain with Company
    or its licensors, as applicable.`,

        `10. Payment and Fees`,
        `You may be required to purchase or pay a fee to access the Services. Company does not guarantee the
    availability of any payment method at any moment and Company may add, remove or suspend any
    payment method temporarily or permanently at Company's sole discretion. You agree to provide
    current, complete, and accurate purchase and account information for all purchases made via the
    Website and to promptly update account and payment information, including email address, payment
    method, and payment card expiration date, in order to complete your purchases and, and acknowledge
    that we may contact you as needed. Sales tax will be added to the price of purchases as deemed
    required by us. We may change prices at any time. All payments shall be in United States Dollars
    (“USD”).`,
        `You agree to pay all charges or fees at the prices then in effect for your purchases, and you authorize us
    to charge your chosen payment provider for any such amounts upon making your purchase.`,
        `We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or
    received payment. We also reserve the right to refuse any order placed through the Website.`,
        `11. Disclaimers, Liability and Indemnification`,
        `YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY GOODS,
    DIGITAL PRODUCTS, SERVICES, INFORMATION OR ITEMS FOUND OR ATTAINED THROUGH THE
    WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY GOODS, SERVICES, DIGITAL
    PRODUCTS, INFORMATION OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE ARE PROVIDED
    ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY
    KIND, EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. THE FOREGOING
    DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE
    LAW.`,
        `YOU ACKNOWLEDGE AND AGREE THAT COMPANY OR ITS RESPECTIVE DIRECTORS, OFFICERS,
    EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR
    SUCCESSORS MAKE NO WARRANTY, REPRESENTATION, OR ENDORSEMENT WITH RESPECT TO THE 
    COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY, OR AVAILABILITY OF
    THE WEBSITE OR ITS CONTENTS OR THAT: (I) ANY GOODS, SERVICES, DIGITAL PRODUCTS,
    INFORMATION OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE WILL BE ACCURATE,
    RELIABLE, ERROR-FREE, OR UNINTERRUPTED, (II) DEFECTS WILL BE CORRECTED, (III) OUR WEBSITE, OR
    THE SERVER THAT MAKES IT AVAILABLE OR ANY OF ITS CONTENT, ARE FREE OF VIRUSES OR OTHER
    HARMFUL COMPONENTS OR DESTRUCTIVE CODE.`,
        `How We Limit Our Liability to You`,
        `EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, IN NO EVENT SHALL THE COMPANY NOR
    ITS RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS,
    LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS BE LIABLE UNDER THESE TERMS OF SERVICE TO
    YOU OR ANY THIRD-PARTY FOR ANY CONSEQUENTIAL, INDIRECT, INCIDENTAL, EXEMPLARY, SPECIAL, OR
    PUNITIVE DAMAGES WHATSOEVER, INCLUDING ANY DAMAGES FOR BUSINESS INTERRUPTION, LOSS OF
    USE, DATA, REVENUE OR PROFIT, COST OF CAPITAL, LOSS OF BUSINESS OPPORTUNITY, LOSS OF
    GOODWILL, WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT (INCLUDING WITHUOT
    LIMITATION, NEGLIGENCE), ANY OTHER THEORY OF LIABILITY, OR OTHERWISE, REGARDLESS OF
    WHETHER SUCH DAMAGES WERE FORESEEABLE AND WHETHER OR NOT THE COMPANY WAS ADVISED
    OF THE POSSIBILITY OF SUCH DAMAGES.`,
        `Indemnification`,
        `To the maximum extent permitted by applicable law, you agree to defend, indemnify, and hold harmless
    Company, its parent, subsidiaries, affiliates, and their respective directors, officers, employees, agents,
    service providers, contractors, licensors, suppliers, successors, and assigns from and against any claims,
    liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys'
    fees) arising out of or relating to your breach of these Terms of Service or your use of the Website
    including, but not limited to, third-party sites and content, any use of the Website's content and services
    other than as expressly authorized in these Terms of Service or any use of any goods, digital products
    and information purchased from this Website.`,
        `12. Privacy Policy`,
        `Your provision of personal information through the Website is governed by our privacy policy located at www.mybanyanlife.com/privacy-policy.`,
        `13. Governing Law`,
        `This Terms of Service shall be governed and interpreted by the laws and courts in the State of Delaware;
    any reference to “applicable law” throughout this Terms of Service shall include within its scope the
    laws of the State of Delaware. The parties agree that the United Nations Convention on Contracts for
    the International Sale of Goods (“CISG”) will not govern these Terms of Service or the rights and
    obligations of the parties under these Terms of Service.`,
        `14. Severability`,
        `If any provision of these Terms of Service is illegal or unenforceable under applicable law, the remainder
    of the provision will be amended to achieve as closely as possible the effect of the original term and all
    other provisions of these Terms of Service will continue in full force and effect.`,
        `15. Entire Terms of Service`,
        `These Terms of Service constitute the entire and only Terms of Service between the parties in relation to
    its subject matter and replaces and extinguishes all prior or simultaneous Terms of Services,
    undertakings, arrangements, understandings or statements of any nature made by the parties or any of
    them whether oral or written (and, if written, whether or not in draft form) with respect to such subject
    matter. Each of the parties acknowledges that they are not relying on any statements, warranties or
    representations given or made by any of them in relation to the subject matter of these Terms of
    Service, save those expressly set out in these Terms of Service, and that they shall have no rights or
    remedies with respect to such subject matter otherwise than under these Terms of Service except to the
    extent that they arise out of the fraud or fraudulent misrepresentation of another party. No variation of
    these Terms of Service shall be effective unless it is in writing and signed by or on behalf of Company.`,
        `16. Waiver`,
        `No failure to exercise, and no delay in exercising, on the part of either party, any right or any power
    hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any right or power
    hereunder preclude further exercise of that or any other right hereunder.`,
        `17. Notice`,
        `We may provide any notice to you under these Terms of Service by: (i) sending a message to the email
    address you provide to us and consent to us using; or (ii) by posting such notice on the Website. Notices
    sent by email will be effective when we send the email and notices we provide by Website-posting will
    be effective upon posting. You acknowledge that it is your responsibility to keep your email address
    current and consistently monitor your email account for any such notice, including without limitation, by
    inspecting your spam or junk email folder. You further acknowledge that it is your responsibility to
    monitor the Website for any such notices posted by us.`,
        `To give us notice under these Terms of Service, you must contact us as follows: (i) by personal delivery,
    overnight courier or registered or certified mail to 8 The Green STE-A Dover, DE 19901 (“Company
    Notice Address”). We may update the Company Notice Address and/or the prescribed form of providing
    us with notice by posting a notice on this Website with such an effect. Notices provided by personal
    delivery will be effective immediately once personally received by an authorized representative of
    Company. Notices provided by overnight courier or registered or certified mail will be effective once
    received and where confirmation has been provided to evidence the receipt of the notice.`,
    ],
    endUserAgreement: [
        `Your access to and use of this My Banyan software (“MY BANYAN”), including any updates or
    support you may receive from My Banyan, Inc. a Delaware corporation (“Company”), is
    governed by the terms and conditions of this End-User License Agreement between you and
    Company (the “Agreement”). As used in this Agreement, “you” refers to you as an individual,
    and any legal entity you control, work for, or represent when you access or use MY BANYAN.
    The terms “us” and “we” refer to Company.`,
        `NOTE: THIS AGREEMENT CONTAINS A BINDING ARBITRATION PROVISION
    AND TIME LIMIT ON SUBMITTING CLAIMS THAT AFFECT YOUR RIGHTS.`,
        `PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY.`,
        `By selecting “I Accept” or otherwise installing and using MY BANYAN, you accept the terms
    and conditions of this Agreement. If you do not accept the terms and conditions of this
    Agreement, do not select “I Accept” and you shall not thereafter make any use of My Banyan.`,
        `1. Licensed Use Rights and Limits. MY BANYAN is licensed to you on a subscription basis
    for installation, access, and permitted use by you for your individual purposes on electronic
    equipment which you own, and/or control (your “System”). Your permitted use of MY
    BANYAN is subject to the limits of the subscription you selected when ordering or downloading
    MY BANYAN in terms of the duration of your subscription (the “Term”). If you accept and
    continually comply with all the terms and conditions of this Agreement, then you may use MY
    BANYAN on your System. You may not share your license with any third party (including,
    without limitation, permitting, whether directly or indirectly, third-party users of your license),
    nor may you install, access, or use MY BANYAN on equipment that you do not solely own and
    control, unless expressly authorized by MY BANYAN. If you violate the terms and conditions of
    this Agreement at any time, MY BANYAN reserves the right to immediately terminate your
    license upon written notice before the end of the Term. At the time of such termination of this
    Agreement, your license to use MY BANYAN terminates, and all copies must be removed from
    your System, including without limitation, any data obtained by you from your license.`,
        `(a) Trial License. If you acquire a “Trial License” of MY BANYAN, you will receive access to
    the same functionality as an extended Subscription License of the product, for evaluation
    purposes during a limited trial period (the “Trial License Term”). After expiration of the Trial
    License Term, unless extended by us or replaced upon your acquisition of a paid subscription of
    MY BANYAN, your license will be terminated. If you license a Trial License of MY BANYAN,
    you agree that we may periodically offer you, through in-product or stand-alone reminders or
    email, the opportunity to upgrade to an extended subscription of the product. We reserve all
    rights to modify the functionality and term of the Trial License of the product, at any time, with
    or without notice to you.`,
        `(b) Subscription License; Support. If you acquire a Subscription License of MY BANYAN,
    you will receive access to the product with full functionality, during the Term of the subscription 
    you ordered. You will also be entitled during the Term to limited customer support in the English
    language made available through our website including support forums, product documentation,
    tutorials, community pages, and product updates if and when available. If your license to the
    Subscription License expires and is not renewed, your right to use MY BANYAN, together with
    any access to customer support, will be terminated.`,
        `2. Other License Limitations. MY BANYAN is licensed, not sold. MY BANYAN is protected
    by copyright and other intellectual property laws and treaties. This Agreement only gives you
    limited rights to use MY BANYAN. We reserve all other rights. Unless applicable law gives you
    more rights despite this limitation, you may use MY BANYAN only as expressly permitted in
    this Agreement. In doing so, you must comply with technical limitations in MY BANYAN that
    only allow you to use it in certain ways. You may not:`,
        `• work around the technical limitations in MY BANYAN;`,
        `• reverse engineer, decompile or disassemble MY BANYAN, except and only to the extent
    that applicable law expressly permits, despite this limitation;`,
        `• install on unauthorized computers (including without limitation, any other such electronic
    device) or use MY BANYAN beyond the limits specified in this Agreement;`,
        `• use MY BANYAN to develop or disclose the results of any benchmarking of
    performance;`,
        `• obtain a MY BANYAN license in order to engage in, assist with, or in otherwise any
    manner contribute to the development of a competitive product or endeavor;`,
        `• publish MY BANYAN for others to copy;`,
        `• distribute MY BANYAN to any third party;`,
        `• rent, lease or lend MY BANYAN; or`,
        `• transfer MY BANYAN or this Agreement to any third party.`,
        `3. Privacy. Information collected in relation to your use of MY BANYAN will be handled in
    accordance with MY BANYAN’s privacy policy. Prior to agreeing to these terms and
    conditions, please refer to our privacy policy, available at INSERT PRIVACY POLICY WEB
    LINK, for a more detailed explanation of how your information is collected, stored and used by
    us and any third-party service providers.`,
        `4. Your Feedback. If you provide us with feedback on the operation of MY BANYAN, you also
    thereby give us, without charge, the right to use that feedback for any purpose. You shall not
    provide us with feedback if such feedback is subject to a third-party license that requires us to
    license our software or documentation to third parties. All such rights pursuant to this Section 4
    shall survive the termination of this Agreement.`,
        `5. Customer Service. If you purchase a Subscription License, we will use commercially
    reasonable efforts during our normal business hours to provide to you, or make available to you,
    limited customer support services upon request related to MY BANYAN during the Term. Any
    supplemental software code, updates or materials provided to you under this Customer Service
    provision shall be considered part of MY BANYAN and subject to the terms and conditions of
    this Agreement.`,
        `6. U.S. Government Restricted Rights. MY BANYAN and its accompanying documentation
    are deemed to be commercial computer software as defined in FAR 12.212 and subject to
    restricted rights as defined in FAR Section 52.227-19 “Commercial Computer Software –
    Restricted Rights” and DFARS 227.7202, “Rights in Commercial Computer Software or
    Commercial Computer Software Documentation,” as applicable, and any successor regulations.
    Any use, modification, reproduction release, performance, display or disclosure of MY
    BANYAN by the U.S. Government shall be solely in accordance with the terms of this
    Agreement.`,
        `7. Export Restrictions. MY BANYAN is subject to applicable U.S. export laws and regulations.
    You must comply with all domestic and international export laws and regulations that apply to
    MY BANYAN. These laws include restrictions on destinations, end users and end use. You
    agree not to export MY BANYAN to any prohibited country, entity, or person for which an
    export license or other governmental approval is required.`,
        `8. DISCLAIMER OF WARRANTIES. MY BANYAN IS LICENSED “AS-IS.” TO THE
    FULL EXTENT PERMITTED BY APPLICABLE LAW, WE EXPRESSLY DISCLAIM ALL
    WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT
    LIMITATION ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A
    PARTICULAR PURPOSE OR NONINFRINGEMENT OF INTELLECTUAL PROPERTY
    RIGHTS, AND WE MAKE NO WARRANTIES THAT: (I) OUR PRODUCTS, SERVICES,
    AND/OR WEBSITE WILL MEET YOUR REQUIREMENTS; (II) OUR PRODUCTS,
    SERVICES, AND/OR WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
    ERROR-FREE; (III) THE RESULTS THAT MAY BE OBTAINED FROM YOUR USE OF
    THE PRODUCTS, SERVICES, AND/OR USE OF THE WEBSITE WILL BE ACCURATE OR
    RELIABLE; (IV) THE QUALITY OF ANY PRODUCTS AND/OR SERVICES PURCHASED
    OR INFORMATION OBTAINED BY YOU THROUGH THE PRODUCTS, SERVICES,
    AND/OR WEBSITE WILL MEET YOUR EXPECTATIONS; OR (V) ANY ERRORS IN THE
    PRODUCTS, SERVICES, AND/OR WEBSITE WILL BE CORRECTED.`,
        `MY BANYAN IS NOT DESIGNED, INTENDED OR LICENSED FOR USE IN
    HAZARDOUS ENVIRONMENTS WHERE A SOFTWARE MALFUNCTION COULD
    CAUSE PROPERTY DAMAGE OR PERSONAL INJURY, AND WE SPECIFICALLY
    DISCLAIM ANY EXPRESS OR IMPLIED WARRANTY OF FITNESS FOR SUCH
    PURPOSES.`,
        `WE DO NOT WARRANT, IN ANY WAY THIRD-PARTY PRODUCTS, INCLUDING, BUT
    NOT LIMITED TO, “INTEGRATIONS” OR ANY “ADD-ONS” YOU MAY DEVELOP
    YOURSELF OR OBTAIN FROM ANY THIRD PARTIES THROUGH OUR WEBSITE OR
    ELSEWHERE. YOU ASSUME ALL RISKS IN USING THIRD-PARTY PRODUCTS OR
    SERVICES WITH MY BANYAN.`,
        `9. LIMITATION ON AND EXCLUSION OF DAMAGES. OUR LIABILITY UNDER THIS
    AGREEMENT IS LIMITED. YOU CAN RECOVER ONLY DIRECT DAMAGES UP TO THE
    AMOUNT THAT YOU PAID FOR MY BANYAN. YOU CANNOT RECOVER ANY OTHER
    DAMAGES, INCLUDING CONSEQUENTIAL, LOST PROFITS, SPECIAL, INDIRECT OR 
    INCIDENTAL DAMAGES. THIS LIMITATION APPLIES TO ALL CLAIMS RELATED TO
    MY BANYAN, INCLUDING WITHOUT LIMITATION CLAIMS FOR BREACH OF
    CONTRACT, BREACH OF WARRANTY, GUARANTEE OR CONDITION, STRICT
    LIABILITY, NEGLIGENCE, OR ANY OTHER TORT TO THE EXTENT PERMITTED BY
    APPLICABLE LAW. IT ALSO APPLIES EVEN IF WE KNEW OR SHOULD HAVE
    KNOWN ABOUT THE POSSIBILITY OF ANY SUCH DAMAGES.`,
        `10. Dispute Resolution and Binding Arbitration Provision.`,
        `(a) Definitions. As used in this Arbitration Provision, “Claims” means all claims, disputes, or
    controversies between you and us of any nature or kind, whether pre-existing, present, or future,
    that arise from or relate only to your use of MY BANYAN or any of our products or services.
    This includes, without limitation, disagreements about the validity, enforceability, or scope of
    this Arbitration Provision.`,
        `(b) Informal Efforts to Resolve Dispute. If a dispute arises between you and us, you should
    first attempt to resolve it by contacting us with written notice (in accordance with Section 10(i)
    of this Agreement, citing the details of your complaint, including your contact information. We
    shall attempt in good faith to resolve all Claims submitted this way within fifteen (15) days of
    receipt, if possible, under the circumstances.`,
        `(c) Agreement to Arbitrate; Right to Opt-Out. If informal efforts to resolve Claims fail or are
    not used, you agree that any and all Claims will be resolved exclusively by binding arbitration as
    described herein, except that: (i) you may assert Claims in a small claims court in the United
    States if your Claims meet the court’s jurisdictional requirements; and (ii) either party may
    pursue Claims and relief in a court of competent jurisdiction regarding the validity and/or
    infringement of a party’s intellectual property rights.`,
        `THERE IS NO JUDGE OR JURY IN ARBITRATION; COURT REVIEW OF AN
    ARBITRATION AWARD IS VERY LIMITED. HOWEVER, AN ARBITRATOR CAN
    AWARD TO YOU, ON AN INDIVIDUAL-BASIS, THE SAME DAMAGES AND FORMS
    OF RELIEF AS A COURT COULD (INCLUDING INJUNCTIVE AND DECLARATORY
    RELIEF AS WELL AS STATUTORY DAMAGES) AND MUST FOLLOW THE LAW
    AND TERMS OF THIS AGREEMENT AS A COURT WOULD.`,
        `ANY ARBITRATION UNDER THIS AGREEMENT WILL TAKE PLACE ON AN
    INDIVIDUAL BASIS; CLASS ARBITRATIONS AND CLASS ACTIONS AND PRIVATE
    ATTORNEY GENERAL ACTIONS INITIATED BY YOU ARE NOT PERMITTED.`,
        `IF YOU DO NOT WISH TO BE BOUND BY THIS ARBITRATION PROVISION, YOU
    MUST NOTIFY US IN WRITING WITHIN THIRTY (30) DAYS FROM THE DATE
    THAT YOU FIRST ACCEPT OR HAVE ACCESS TO THIS AGREEMENT BY
    MAILING AN OPT-OUT REQUEST TO OUR CUSTOMER SERVICE CENTER
    LISTED BELOW. YOUR WRITTEN NOTIFICATION MUST INCLUDE YOUR NAME,
    ADDRESS, THE EMAIL ADDRESS YOU USED TO REGISTER WITH US, AND A
    CLEAR STATEMENT THAT YOU DO NOT WISH TO RESOLVE DISPUTES WITH 
    US THROUGH ARBITRATION. YOUR DECISION TO OPT OUT OF THIS
    ARBITRATION PROVISION WILL HAVE NO ADVERSE EFFECT ON YOUR
    RELATIONSHIP WITH US OR THE DELIVERY OF SERVICES TO YOU BY US.`,
        `(d) Arbitration Fees. The allocation and payment of all filing, administration and arbitrator fees
    will be governed by the rules of the American Arbitration Association (“AAA”) which limit the
    amount a consumer is required to pay.`,
        `(e) Arbitration Rules. If you are a resident of the United States of America, the arbitration will
    be conducted by the American Arbitration Association (“AAA”) in Wilmington Delaware under
    its rules. If you are a resident of a country other than the United States, the arbitration will be
    conducted by the AAA’s International Centre for Dispute Resolution in Dover, Delaware, under
    its rules for international arbitration, and you agree to submit to the personal jurisdiction of the
    U.S. federal court in, Wilmington, Delaware, in order to compel arbitration, to stay proceedings
    pending arbitration, or to confirm, modify, vacate or enter judgment on the award entered by the
    arbitrator. In the event of a conflict or inconsistency between the applicable arbitration rules and
    this Arbitration Provision, this Arbitration Provision shall govern and control.`,
        `(f) Arbitration Process. Since appearing in-person for arbitration can be unduly burdensome in
    the circumstances, arbitration under this Arbitration Provision shall not require any personal
    appearance by the parties or witnesses unless mutually agreed. Either or both parties may
    participate by written submissions, telephone calls, or other means of remote communication as
    allowed by the arbitrator. The arbitration proceedings will be conducted in the English language
    at a location designated by the AAA in the County of Kent, Delaware.`,
        `The arbitration can only decide Claim(s) between you and us, and may not consolidate or join
    the claims of other persons that may have similar claims. There shall be no pre-arbitration
    discovery except as provided for in the applicable AAA rules. The arbitrator will honor claims of
    privilege recognized by law and will take reasonable steps to protect customer account
    information and other confidential or proprietary information.`,
        `In conducting the arbitration proceeding, the arbitrator will apply the laws of the State of
    Delaware (without regard to its conflicts of law provisions) and U.S. federal law for matters
    covered by federal law (for example, the Federal Arbitration Act). At the request of any party,
    the arbitrator shall provide a brief written explanation of the basis for the decision and award.
    Judgment upon the award rendered by the arbitrator may be entered in any court having
    jurisdiction. The arbitrator’s decision will be final and binding on the parties except for any right
    to appeal under the AAA rules or the Federal Arbitration Act.`,
        `(g) Confidentiality. You shall keep confidential any information exchanged during the
    arbitration as well as the decision of the arbitrator made with respect to any Claim(s) arbitrated
    under this Arbitration Provision. With the exception of disclosure to your attorneys, accountants,
    auditors, and other legal or financial advisors, you shall not disclose such information or decision
    to any other person unless you are required to do so by law.`,
        `(h) Continuing Obligation to Arbitrate; Severability. This Arbitration Provision shall survive
    termination of your access to, or use of, any services pursuant to this Agreement. If any portion
    of this Arbitration Provision is deemed invalid or unenforceable at law, such invalid or
    unenforceable provision will be interpreted, construed or reformed to the extent required to make
    it valid and enforceable, and this shall not invalidate the remaining portions of this Arbitration
    Provision.`,
        `(i) Notice Address:`,
        `MY BANYAN, Inc.`,
        `8 The Green Ste-A`,
        `Dover, Delaware 19901`,
        `info@mybanyanlife.com`,
        `11. Applicable Law; Forum. Delaware state law governs the interpretation of this Agreement
    and applies to claims for breach of it, regardless of any conflict of laws principles. Without
    limiting the parties’ rights and obligations under the Arbitration Provision of this Agreement,
    you agree that any lawsuit filed to resolve any Claim you have with us arising out of or relating
    to this Agreement will be brought exclusively in a state or federal court located in or for the State
    of Delaware, and you agree to submit to the personal jurisdiction of such courts for the purpose
    of such Claims.`,
        `12. Entire Agreement; Severability. This Agreement as well as any ordering documents
    prepared or signed by MY BANYAN specifying the Term of the subscription and applicable
    limitations (such as the maximum number of daily Actions) constitutes a complete and exclusive
    statement of the terms of the Agreement between the parties with respect to its subject matter.
    This Agreement may not be amended, supplemented or otherwise modified except by a written
    Agreement executed by an authorized representative of both parties. If for any reason any
    provision of this Agreement is held to be invalid or unenforceable to any extent, then (a) such
    provision will be interpreted, construed or reformed to the extent reasonably required to render
    the same valid, enforceable and consistent with the original intent underlying such provision; (b)
    such provision will remain in effect to the extent that it is not invalid or unenforceable; and (c)
    such invalidity or unenforceability will not affect any other provision of this Agreement.`,
        `© 2023 MY BANYAN. All Rights Reserved.`,
    ],
};

