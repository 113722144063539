import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { restGet, restPost } from "../../store/api";
import { Fieldset, Button } from "./inputs";
import { Toastr } from "../common/toastr";
import { getUserDetails } from "../../store/actions";

export const ChangeCard = ({ setOpen }) => {
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState();
    const [paymentMethods, setPaymentMethods] = useState([]);

    useEffect(() => {
        async function fetchPaymentMethods() {
            await restGet(
                "/paymentMethods",
                (data) => setPaymentMethods(data),
                (err) => console.error(err)
            );
        }
        fetchPaymentMethods();
    }, []);

    const onChangeHandler = (e) => {
        e.preventDefault();

        setFormValues((prevState) => ({
            ...prevState,
            [e.target.name]: JSON.parse(e.target.value),
        }));
    };

    const successCallback = (user) => {
        dispatch(getUserDetails(user));
        Toastr({
            type: "success",
            message: "Successful.",
        });
    };

    const failCallback = (err) => {
        console.error(err);
        Toastr({ type: "error", message: "Error system." });
    };

    const onSubmit = async () => {
        const reqBody = {
            customerId: user.paymentCustomerId,
            paymentMethod: formValues.paymentMethodId,
        };
        await restPost(
            "/paymentMethods/default",
            reqBody,
            successCallback,
            failCallback
        );
    };

    return (
        <Fieldset
            title={"Change your payment method"}
            subtitle={
                "Select a saved payment method or add a new one in subscription tab."
            }
        >
            <div className="flex flex-col space-y-2">
                <label className="text-sm font-medium text-gray-500">
                    Select a saved payment method
                </label>
                <select
                    name="paymentMethodId"
                    className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={onChangeHandler}
                >
                    <option value="">-- Select --</option>
                    {paymentMethods.map((pm) => (
                        <option key={pm.id} value={JSON.stringify(pm)}>
                            **** **** **** {pm.card.last4} ({pm.card.brand}{" "}
                            {pm.card.exp_month}/{pm.card.exp_year})
                        </option>
                    ))}
                </select>
            </div>

            <div className="mt-4">
                <Button text="Submit" onClick={onSubmit} />
            </div>
        </Fieldset>
    );
};

