export const organizationOfDigitalFilesData = {
  formTitle: "Add or edit organization of digital file",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/organizationOfDigitalFiles",
  formFields: [
    {
      component: "input",
      label: "Where my files are located",
      name: "location",
      type: "text",
      colObject: true,
    },
    {
      component: "mc",
      label: "How my files are currently organized",
      name: "current",
      required: true,
      values: [
        "All important digital files are in folders and subfolders",
        "Some important files are in folders",
        "Some important files are not in folders",
        "I can easily locate all of my important files",
        "I can easily locate some of my important files",
        "My files are organized in a way that is easy to understand",
        "I have documented the organization of my files so someone can locate them",
        "I have no clear organization of my files",
        "Other",
      ],
    },
    {
      component: "mc",
      label: "How I want my files organized in the future",
      name: "future",
      required: true,
      values: [
        "All important digital files are in folders and subfolders",
        "Some important files are in folders",
        "Some important files are not in folders",
        "I can easily locate all of my important files",
        "I can easily locate some of my important files",
        "My files are organized in a way that is easy to understand",
        "I have documented the organization of my files so someone can locate them",
        "I have no clear organization of my files",
        "Other",
      ],
    },
    {
      component: "input",
      label: "I have a file describing my organization",
      name: "description",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
