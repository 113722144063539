import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { restGet, restPut } from "../../../store/api";
import { ContactList } from "./contact-list";
import { SharedList } from "./shared-list";
import { Toastr } from "../../../components/common/toastr";
import { useSelector } from "react-redux";

import WhiteBtn from "../../buttons/whiteButton";
import GreenBtn from "../../buttons/greenBtn";

export default function FileShare({
    selectedFiles,
    goBack,
}) {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [contactList, setContactList] = useState([]);
    const userEmail = useSelector(
        (state) => state.auth.currentUser.attributes.email
    );
    const userName = useSelector(
        (state) => state.auth.currentUser.attributes.name
    );
    const [name, setName] = useState([]);
    const [sharedList, setSharedList] = useState({
        list: [],
        selected: null,
    });

    const failCallback = (err) => {
        console.error(err);
    };

    const addSelectedToSharedList = (contact) => {
        if (sharedList.list.length > 0) {
            const result = sharedList.list.find(
                (el) => el.email === contact.email
            );
            if (result) return undefined;
        }
        setSharedList((prevState) => ({
            ...prevState,
            list: [...prevState.list, contact],
            selected: contact,
        }));
    };

    const removeItemOfSharedList = (email) => {
        setSharedList((prevState) => ({
            ...prevState,
            list: prevState.list.filter((el) => el.email !== email),
        }));
    };

    const getDataToEdit = () => {
        restGet(
            "/myContacts/index",
            (data) => setContactList(data),
            failCallback
        );

        if (selectedFiles.length === 1) {
            restGet(
                "/fileSharing/show/" + selectedFiles[0],
                (data) => {
                    setSharedList((prevState) => ({
                        ...prevState,
                        list: [...prevState.list, ...data.sharedList],
                    }));
                },
                failCallback
            );

            restGet(
                "/fileUploads/" + selectedFiles[0],
                (data) => {
                    setName([data.fileName]);
                },
                failCallback
            );
        } else {
            for (const i in selectedFiles) {
                restGet(
                    "/fileSharing/show/" + selectedFiles[i],
                    (data) => {
                        setSharedList((prevState) => ({
                            ...prevState,
                            list: [...prevState.list, ...data.sharedList],
                        }));
                    },
                    failCallback
                );

                restGet(
                    "/fileUploads/" + selectedFiles[i],
                    (data) => {
                        setName((preNames) => [...preNames, data.fileName]);
                    },
                    failCallback
                );
            }
        }
    };

    const onSubmit = async (values) => {
        if (name.length > 1) {
            for (const [i, n] of name.entries()) {
                let reqBody = {
                    sendName: userName,
                    fileIds: [selectedFiles[i]],
                    type: "report",
                    name: n,
                    sendEmail: userEmail,
                };

                setIsSubmitting(true);

                if (values && values.length > 0) {
                    reqBody.sharedList = values;
                    reqBody.receiveName = contactList;
                } else {
                    reqBody.sharedList = [];
                }

                await restPut(
                    "/fileSharing/report/",
                    reqBody,
                    () => {
                        goBack();

                        Toastr({
                            type: "success",
                            message: "Successful",
                        });
                    },
                    failCallback
                );
            }
        }

        if (selectedFiles.length === 1) {
            let reqBody = {
                sendName: userName,
                fileIds: selectedFiles,
                type: "report",
                name: name[0],
                sendEmail: userEmail,
            };

            setIsSubmitting(true);

            if (values && values.length > 0) {
                reqBody.sharedList = values;
                reqBody.receiveName = contactList;
            } else {
                reqBody.sharedList = [];
            }

            await restPut(
                "/fileSharing/report/",
                reqBody,
                () => {
                    goBack();

                    Toastr({
                        type: "success",
                        message: "Successful",
                    });
                },
                failCallback
            );
        }
    };

    useEffect(() => {
        getDataToEdit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="flex flex-col sm:flex-row w-full sm:justify-between sm:mt-4 mb-4">
            {contactList && contactList.length > 0 ? (
                <div className="mt-6 sm:mt-1 sm:w-1/2 sm:mr-4 md:mr-8 lg:mr-12">
                    <ContactList
                        selected={contactList[0]}
                        setSelected={addSelectedToSharedList}
                        list={contactList}
                    />
                </div>
            ) : (
                <div className="mt-6 sm:mt-1 sm:w-1/2 sm:mr-4 md:mr-8 lg:mr-12 text-sm">
                    There are no Inner Circle contacts to be shared. Please
                    create one{" "}
                    <Link
                        to={"/inner-circle"}
                        alt=""
                        className="text-primary-green hover:text-custom-dark-green hover:underline"
                    >
                        here
                    </Link>
                </div>
            )}

            <div className="space-y-3 divide-y divide-gray-300 sm:w-1/2 sm:ml-4">
                <div className="mt-6 sm:mt-0">
                    {sharedList.list.length > 0 ? (
                        <SharedList
                            people={sharedList.list}
                            removeItem={removeItemOfSharedList}
                        />
                    ) : (
                        <span className="text-sm text-gray-600">
                            Shared list is empty
                        </span>
                    )}
                </div>
                <div className="pt-5">
                    <div className="flex justify-end">
                        <WhiteBtn
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                goBack();
                            }}
                        >
                            Cancel
                        </WhiteBtn>

                        <GreenBtn
                            type="button"
                            disabled={
                                isSubmitting ||
                                !contactList ||
                                contactList.length === 0 ||
                                sharedList.list.length < 1
                            }
                            onClick={(e) => {
                                e.preventDefault();
                                onSubmit(sharedList.list);
                            }}
                        >
                            {isSubmitting ? (
                                <>
                                    <svg
                                        className="animate-spin h-5 w-5 mr-3 text-white"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        />

                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        />
                                    </svg>
                                    Processing...
                                </>
                            ) : (
                                "Share"
                            )}
                        </GreenBtn>
                    </div>
                </div>
            </div>
        </div>
    );
}

