export const taxReturnsData = {
  formTitle: "Add or edit my income source",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/taxReturns",
  formFields: [
    {
      component: "select",
      label: "Location of tax authority",
      name: "location",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Federal", text: "Federal" },
        { value: "State", text: "State" },
        { value: "Country outside of US", text: "Country outside of US" },
      ],
    },
    {
      component: "input",
      label: "Name of country or state",
      name: "name",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Year of last return was filed",
      name: "year",
      type: "number",
    },
    {
      component: "input",
      label: "Next filing due date",
      name: "date",
      type: "date",
    },
    {
      component: "select",
      label: "Would you like a reminder sent to you",
      name: "reminder",
      type: "text",
      options: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" },
      ],
    },
    {
      component: "input",
      label: "Name of tax preparer / filer",
      name: "taxPreparer",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Phone number of tax preparer",
      name: "phone",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
