export const birthInfoData = {
  formTitle: "Add or edit my birth info",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/birthInfo",
  formFields: [
    {
      component: "input",
      label: "Month/Day/Year",
      name: "date",
      type: "date",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Location / hospital",
      name: "location",
      type: "text",
    },
    {
      component: "input",
      label: "City, state, country",
      name: "region",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};

