export const employmentStatusData = {
  formTitle: "Add or edit marital agreement",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/employmentStatus",
  formFields: [
    {
      component: "select",
      label: "Current status",
      name: "status",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Full-time employee", text: "Full-time employee" },
        { value: "Part-time employee", text: "Part-time employee" },
        { value: "Self-employed", text: "Self-employed" },
        { value: "Not employed", text: "Not employed" },
        { value: "Seeking employment", text: "Seeking employment" },
        { value: "Providing pro bono / volunteer services", text: "Providing pro bono / volunteer services" },
        { value: "Fully retired", text: "Fully retired" },
        { value: "Semi-retired", text: "Semi-retired" },
        { value: "May retire in 5 years or less", text: "May retire in 5 years or less" },
        { value: "May retire in 10 years or more", text: "May retire in 10 years or more" },
        { value: "Not even thinking about retirement", text: "Not even thinking about retirement" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Start Date",
      type: "date",
      name: "startDate",
      required: true,
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
