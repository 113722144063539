import { Auth } from "aws-amplify";
import { Toastr } from "../common/toastr";

export const confirmSignup = async (
    username,
    code,
    type,
    callback,
    setIsPending
) => {
    try {
        await Auth.confirmSignUp(username, code);
        callback(type);
    } catch (error) {
        console.error(error);
        setIsPending(false);
        Toastr({
            type: "error",
            message: "Please enter the verification code correctly",
        });
    }
};

