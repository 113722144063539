import { useState } from "react";
import { PaymentWrapper } from "./payment";
import { useSelector } from "react-redux";
import { memberships } from "./memberships";

const Subscription = () => {
  const currentUser = useSelector((state) => state.auth.user);

  const currentType = currentUser?.type;
  const [access, setAccess] = useState(false);
  const [price, setPrice] = useState("-1");
  const [key, setKey] = useState("0");

  const countTimesSubscription = currentUser?.countTimesSubscription || 0;
  const fullMembers = memberships[0].full_member;
  const discountSpecial = memberships[1].discount_special;
  const discountPer = memberships[2].discount_per;
  const referrals = memberships[3].referrals;

  const radioHandler = (e) => {
    setPrice(e.target.value);
    setKey(e.target.id);
  };

  return (
    <>
      {access ? (
        <PaymentWrapper value={[key, price]} />
      ) : (
        <>
          <div className="relative mx-auto max-w-7xl px-6 lg:px-8 pt-12">
            <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none">
              <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                <div className="flex justify-center items-center">
                  <h3 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight">Select Your Subscription.</h3>
                </div>

                <section className="my-8 px-5 flex justify-start">
                  <div>
                    <h4 className="text-primary-green font-bold uppercase">TO BECOME A PAID MEMBER</h4>

                    <p>1. Select your level of subscription</p>

                    <p>2. Click “Apply”</p>
                  </div>
                </section>

                <fieldset className="mt-4 pl-5">
                  <legend className="sr-only">Subscription</legend>

                  <div className="space-y-4" onChange={radioHandler}>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      {/* STANDARD SUBSCRIPTION */}
                      <div>
                        <p className="text-lg font-medium text-primary-green">Annual Subscription </p>

                        <div className="pl-3 flex items-center">
                          <input
                            id={12 >= countTimesSubscription ? fullMembers[0].year.key : fullMembers[1].year.key}
                            value={12 >= countTimesSubscription ? fullMembers[0].year.value : fullMembers[1].year.value}
                            name="notification-method"
                            type="radio"
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 disabled:cursor-not-allowed"
                            disabled={currentType === "normal"}
                          />

                          <label htmlFor="full" className="ml-3 block text-sm font-medium text-gray-700">
                            <span className="font-bold">${fullMembers[0].year.value}</span>
                            {" for the first year. "}
                            <span className="font-bold">${fullMembers[1].year.value}</span>
                            {" for annual renewals."}
                          </label>
                        </div>
                      </div>

                      {/* FIRST RESPONDERS AND VETERANS SUBSCRIPTION */}
                      <div>
                        <p className="text-lg font-medium text-primary-green">First Responders and Veterans Subscription </p>

                        <div className="pl-3 flex items-center">
                          <input
                            id={12 >= countTimesSubscription ? discountPer[0].year.key : discountPer[1].year.key}
                            value={12 >= countTimesSubscription ? discountPer[0].year.value : discountPer[1].year.value}
                            name="notification-method"
                            type="radio"
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 disabled:cursor-not-allowed"
                            disabled={currentType === "normal"}
                          />

                          <label htmlFor="full" className="ml-3 block text-sm font-medium text-gray-700">
                            <span className="font-bold">${discountPer[0].year.value}</span>
                            {" for the first year. "}
                            <span className="font-bold">${discountPer[1].year.value}</span>
                            {" for annual renewals."}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      {/* THREE YEAR SUBSCRIPTION */}
                      <div>
                        <p className="text-lg font-medium text-primary-green">3-Year Subscription </p>

                        <div className="pl-3 flex items-center">
                          <input
                            id={discountSpecial[4].key}
                            value={discountSpecial[4].value}
                            name="notification-method"
                            type="radio"
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 disabled:cursor-not-allowed"
                            disabled={currentType === "normal"}
                          />

                          <label htmlFor="full" className="ml-3 block text-sm font-medium text-gray-700">
                            <span className="font-bold">${discountSpecial[4].value}</span>
                            {" for three years."} <b>$75</b> {"for annual renewals after that."}
                          </label>
                        </div>
                      </div>

                      {/* INNER CIRCLE MEMBER SUBSCRIPTION */}
                      <div>
                        <p className="text-lg font-medium text-primary-green">Inner Circle Member Subscription. </p>

                        <div className="pl-3 flex items-center">
                          <input
                            id={12 >= countTimesSubscription ? referrals[0].year.key : referrals[1].year.key}
                            value={12 >= countTimesSubscription ? referrals[0].year.value : referrals[1].year.value}
                            name="notification-method"
                            type="radio"
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 disabled:cursor-not-allowed"
                            disabled={currentType === "normal"}
                          />

                          <label htmlFor="full" className="ml-3 block text-sm font-medium text-gray-700">
                            <span className="font-bold">${referrals[0].year.value}</span> for the first year. <b>${referrals[1].year.value}</b> for annual renewals.
                          </label>
                        </div>
                      </div>

                      <div>
                        {/* 5-Year SUBSCRIPTION */}
                        <p className="text-lg font-medium text-primary-green">5-Year Subscription </p>

                        <div className="pl-3 flex items-center">
                          <input
                            id={discountSpecial[0].key}
                            value={discountSpecial[0].value}
                            name="notification-method"
                            type="radio"
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 disabled:cursor-not-allowed"
                            disabled={currentType === "normal"}
                          />

                          <label htmlFor="full" className="ml-3 block text-sm font-medium text-gray-700">
                            <span className="font-bold">${discountSpecial[0].value}</span>
                            {" for five years."} <b>$75</b> {"for annual renewals after that."}
                          </label>
                        </div>

                        {/* LIFETIME SUBSCRIPTION */}
                        <p className="mt-5 text-lg font-medium text-primary-green">Lifetime Subscription </p>

                        <div className="pl-3 flex items-center">
                          <input
                            id={discountSpecial[1].key}
                            value={discountSpecial[1].value}
                            name="notification-method"
                            type="radio"
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 disabled:cursor-not-allowed"
                            disabled={currentType === "normal"}
                          />

                          <label htmlFor="full" className="ml-3 block text-sm font-medium text-gray-700">
                            <span className="font-bold">${discountSpecial[1].value}</span>
                            {" one-time charge."}
                          </label>
                        </div>
                      </div>

                      <div>
                        {/* IF YOU HAVE A LICENSE CODE */}
                        <div className="mt-6 pl-5 text-md">
                          <h4 className="pt-5 text-primary-green font-bold uppercase">IF YOU HAVE A LICENSE CODE</h4>

                          <ol className="ml-5 list-[lower-number]">
                            <li>Select the duration of your code.</li>

                            {/* <li>
                              <p>Click the box below.</p>

                              <div onChange={radioHandler} className="my-4 pl-3 flex items-center">
                                <input
                                  id="21"
                                  value="0"
                                  name="notification-method"
                                  type="radio"
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 disabled:cursor-not-allowed"
                                  disabled={currentType === "normal"}
                                />

                                <label htmlFor="full" className="ml-3 block text-sm font-medium text-gray-700">
                                  YES. I have a special license code
                                </label>
                              </div>
                            </li> */}

                            <li>Click "Apply"</li>
                            <li>Enter the licence code on the next screen in the “Promotion code” field and click “Apply. Then enter your credit card information and follow instructions.</li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <div className="flex justify-end">
                  <div className="pt-10 pr-14">
                    <div className="rounded-md shadow">
                      <button
                        to="#"
                        disabled={Number(price) < 0}
                        onClick={() => setAccess(true)}
                        className="w-full rounded-md px-10 py-3 text-base font-medium bg-blue-800 hover:bg-gray-800 disabled:bg-white text-white disabled:text-blue-800 border-2 disabled:border-blue-800 disabled:cursor-not-allowed transition duration-300"
                      >
                        APPLY
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Subscription;

