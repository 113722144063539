import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { PaperClipIcon } from "@heroicons/react/solid";
import { restGet } from "../../store/api";
import { updateTopicById } from "../../store/actions";

import OneRecordPDFReport from "../../components/pdf-report/one";

const apiPath = "/myContacts";
const method = "/show/";

const recordDetailNames = {
    contactType: "contact type",
    firstName: "first name",
    lastName: "last name",
    email: "email",
    phone: "phone number",
    notes: "notes",
};

export default function InnerCircleView({ files }) {
    const [params] = useSearchParams();
    const pathname = apiPath + method + params.get("id");

    const reportTitle = "innerCircle";

    const dispatch = useDispatch();
    const data = useSelector((state) => state.topic.byId);
    const userEmail = useSelector(
        (state) => state.auth.currentUser.attributes.email
    );

    const successCallback = (data) => {
        dispatch(updateTopicById(data));
    };

    const failCallback = (err) => {
        console.error(err);
    };

    const getDataToShow = () => {
        restGet(pathname, successCallback, failCallback);
    };

    useEffect(() => {
        getDataToShow();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {data && (
                <div className="mt-8 flex flex-col">
                    <div>
                        <div className="flex items-center justify-between">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">
                                Record details
                            </h3>
                            <OneRecordPDFReport
                                fileName={`${reportTitle.replace(
                                    /([a-z])([A-Z])/g,
                                    "$1_$2"
                                )}_Report_${data._id}`}
                                record={data}
                                title={reportTitle}
                                user={userEmail}
                                files={files}
                            />
                        </div>
                        <div className="mt-5 border-t border-gray-300">
                            <dl className="sm:divide-y sm:divide-gray-300">
                                {data &&
                                    Object.keys(data)
                                        .filter(
                                            (el) =>
                                                el !== "sharing" &&
                                                el !== "_id" &&
                                                el !== "uid" &&
                                                el !== "__v" &&
                                                el !== "createdAt" &&
                                                el !== "innerCircle"
                                        )
                                        .map((el) => (
                                            <div
                                                key={uuidv4()}
                                                className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
                                            >
                                                <dt className="text-sm font-medium text-gray-500">
                                                    {recordDetailNames[el]}
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                    {data[el]}
                                                </dd>
                                            </div>
                                        ))}
                                {files && (
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Attachments
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            <ul className="divide-y divide-gray-300 rounded-md border border-gray-300">
                                                {files.map((file) => (
                                                    <li
                                                        key={file._id}
                                                        className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                                                    >
                                                        <div className="flex w-0 flex-1 items-center">
                                                            <PaperClipIcon
                                                                className="h-5 w-5 flex-shrink-0 text-gray-400"
                                                                aria-hidden="true"
                                                            />
                                                            <span className="ml-2 w-0 flex-1 truncate">
                                                                {file.fileName}
                                                            </span>
                                                        </div>
                                                        <div className="ml-4 flex-shrink-0">
                                                            <a
                                                                href={file.url}
                                                                className="font-medium text-indigo-600 hover:text-indigo-500"
                                                            >
                                                                Download
                                                            </a>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </dd>
                                    </div>
                                )}
                            </dl>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

