import { QuestionMarkCircleIcon } from "@heroicons/react/solid";

export default function Description({ data }) {
  return (
    <span className="relative group">
      <QuestionMarkCircleIcon
        className="ml-4 h-5 w-5 text-gray-black inline hover:text-primary-green transition duration-300"
        aria-hidden="true"
      />
      <div className="z-10 hidden group-hover:block hover:block bg-white shadow-lg rounded p-2 w-80 lg:w-96 absolute translate-y-1">
        <p className="text-xs">{data}</p>
      </div>
    </span>
  );
}

