export const businessFormationAgreementData = {
  formTitle: "Add or edit business formation agreements",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/businessFormationAgreement",
  formFields: [
    {
      component: "input",
      label: "Company name",
      name: "company",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Registration in what state",
      name: "state",
      type: "text",
      colObject: true,
    },
    {
      component: "select",
      label: "Corporation type",
      name: "type",
      type: "text",
      options: [
        { value: "C Corp", text: "C Corp" },
        { value: "LLC", text: "LLC" },
        { value: "Nonprofit corporation", text: "Nonprofit corporation" },
        { value: "S Corp", text: "S Corp" },
        { value: "Sole proprietorship", text: "Sole proprietorship" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "select",
      label: "Type of agreement",
      name: "agreementType",
      type: "text",
      options: [
        { value: "Arbitration Agreement", text: "Arbitration Agreement" },
        {
          value: "Articles of Incorporation",
          text: "Articles of Incorporation",
        },
        { value: "Indemnity Agreement", text: "Indemnity Agreement" },
        { value: "Operating Agreement", text: "Operating Agreement" },
        {
          value: "Non-Disclosure Agreements",
          text: "Non-Disclosure Agreements",
        },
        { value: "Partnership Agreement", text: "Partnership Agreement" },
        { value: "Shareholder Agreement", text: "Shareholder Agreement" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Attorney name",
      name: "attorney",
      type: "text",
    },
    {
      component: "input",
      label: "Participants",
      name: "participants",
      type: "text",
    },
    {
      component: "input",
      label: "Date of origination",
      name: "originationDate",
      type: "date",
    },
    {
      component: "input",
      label: "Latest revision date",
      name: "latestDate",
      type: "date",
    },
    {
      component: "input",
      label: "Next revision date",
      name: "nextDate",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};

