export const additionalSecurityIncomeData = {
  formTitle: "Add or edit additional security income",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/supplementalSecurity(SSI)",
  formFields: [
    {
      component: "input",
      label: "Local SSA address",
      name: "address",
      type: "text",
      colObject: true,
    },
    {
      component: "phoneNumber",
      label: "Local SSA phone number",
      name: "phone",
      colObject: true,
    },
    {
      component: "input",
      label: "Application sent date",
      name: "sentDate",
      type: "date",
    },
    {
      component: "input",
      label: "Application approved date",
      name: "approvedDate",
      type: "date",
    },
    {
      component: "input",
      label: "Coverage",
      name: "coverage",
      type: "text",
    },
    {
      component: "input",
      label: "Coverage limits",
      name: "coverageLimits",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
