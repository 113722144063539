import { Fragment } from "react";
import { useSelector } from "react-redux";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
import { classNames } from "../../lib/classnames";


export const TodoHeader = ({ setOpen, sort, setSort }) => {
    const user = useSelector((state) => state.auth.user);
    const isSubscriptionDisabled = user && user.type === "readonly";

    const buttonClasses = `
    group ml-6 rounded-md py-2 px-4 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 bg-primary-green hover:bg-white disabled:bg-white text-white hover:text-primary-green disabled:text-primary-green border-2 border-primary-green disabled:cursor-not-allowed transition duration-300
  `;

    return (
        <header className="flex flex-col md:flex-row flex-none items-center justify-between border-b border-gray-300 py-4 px-6">
            <h2 className="text-gray-500 text-sm">
                This list shows details on all your to-do taks that you have
                entered. To add a new task to the TO DO LIST topic, simply name
                it, include the date and time for completion, and add any
                relevant comments. Once submitted, the task will automatically
                appear in your calendar, and you'll receive notifications in the
                upper right-hand corner of My Banyan to keep you on track.
                <br />
                <br />
                Click on the checkbox in the first column if you have completed
                the task.
            </h2>

            <div className="flex items-center">
                <div className="hidden md:flex md:items-center w-max">
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            if (!isSubscriptionDisabled) {
                                setOpen(true);
                            }
                        }}
                        type="button"
                        className={`${buttonClasses} flex items-center gap-2`}
                        disabled={isSubscriptionDisabled}
                    >
                        <img
                            src="https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/09-to-do-list-icon-white.svg"
                            alt="TO-DO icon"
                            className="w-8 mr-2 rounded-xl group-hover:bg-primary-green transition duration-300"
                        />
                        Add a TO-DO task
                    </button>
                </div>

                <Menu as="div" className="relative ml-6 md:hidden">
                    <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Open menu</span>
                        <span className="mr-2">Menu</span>
                        <ChevronDownIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                        />
                    </Menu.Button>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                                <Menu.Item>
                                    {({ active }) => (
                                        <a
                                            href="/"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSort("createdAt");
                                            }}
                                            className={`${buttonClasses} flex items-center gap-2`}
                                        >
                                            <img
                                                src="https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/09-to-do-list-icon-white.svg"
                                                alt="TO-DO icon"
                                                className="w-8 mr-2 rounded-xl group-hover:bg-primary-green transition duration-300"
                                            />
                                            Add a TO-DO task
                                        </a>
                                    )}
                                </Menu.Item>
                            </div>
                            <div className="py-1">
                                <Menu.Item>
                                    {({ active }) => (
                                        <a
                                            href="/"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSort("createdAt");
                                            }}
                                            className={classNames(
                                                active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700",
                                                "block px-4 py-2 text-sm"
                                            )}
                                        >
                                            Created at
                                        </a>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <a
                                            href="/"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSort("deadline");
                                            }}
                                            className={classNames(
                                                active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700",
                                                "block px-4 py-2 text-sm"
                                            )}
                                        >
                                            Deadline
                                        </a>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <a
                                            href="/"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSort("completed");
                                            }}
                                            className={classNames(
                                                active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700",
                                                "block px-4 py-2 text-sm"
                                            )}
                                        >
                                            Completed
                                        </a>
                                    )}
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </header>
    );
};

