import { Fragment, useState, useEffect, useRef } from "react";
import { Field } from "react-final-form";
import { ActionPanel } from "../../components/action-panel";
import { UploadPhoto } from "./upload-photo";
import { AddContactForm } from "../../components/add-contact/form";
import { ShowPassword } from "../../components/auth/inputs";

export const Text = ({ name, children, ...props }) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-300 sm:pt-5">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {children}
      </label>

      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <Field
          {...props}
          name={name}
          component="input"
          maxLength={65}
          className="block w-full max-w-lg rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
    </div>
  );
};


const NumberValueTextInput = ({ input, meta, inputRef, required }) => {
  useEffect(() => {
    if (meta.error && meta.touched && inputRef.current) {
      inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [meta.error, meta.touched, inputRef]);

  return (
    <div>
      <div className="relative">
        <span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          $
        </span>
        <input
          {...input}
          ref={inputRef}
          required={required}
          type="text"
          maxLength={65}
          className="block w-full max-w-lg pl-7 pr-12 rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
    </div>
  );
};


export const NumberValueText = ({ name, children, ...props }) => {
  const inputRef = useRef(null);

  const formatValue = (value) => {
    if (value) {
      const floatValue = parseFloat(value);
      if (!isNaN(floatValue)) {
        return floatValue.toFixed(2);
      }
    }
    return value;
  };

  const validatePeriods = (value) => {
    const splitValue = value.split(".");

    return splitValue.length - 1 <= 1 ? value : false;
  };

  const validateAgainstSymbols = (value) => {
    const validPattern = /^[0-9,.\s]*$/;

    return validPattern.test(value) ? value : false;
  };

  const validateDecimalPlaces = (value) => {
    const decimalPart = (value?.toString().split(".")[1] || "").length;
    return decimalPart <= 2 ? value : false;
  };

  const validate = (value) => {
    if (value) {
      const valueWithoutPeriods = validatePeriods(value);
      if (valueWithoutPeriods === false) {
        return "Maximum of 1 period";
      }

      const valueWithoutSymbols = validateAgainstSymbols(valueWithoutPeriods);
      if (valueWithoutSymbols === false) {
        return "Can only have numbers, commas, and a period";
      }

      const valueWithDecimals = validateDecimalPlaces(valueWithoutSymbols);
      if (valueWithDecimals === false) {
        return "Maximum of 2 decimal places";
      }
    }
  };

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-300 sm:pt-5">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {children}
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <Field
          name={name}
          validate={validate}
          normalize={formatValue}
        >
          {({ input, meta }) => (
            <NumberValueTextInput
              input={input}
              meta={meta}
              inputRef={inputRef}
              required={props.required}
            />
          )}
        </Field>
      </div>
    </div>
  );
};


export const DateText = ({ name, children, ...props }) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-300 sm:pt-5">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {children}
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <Field
          {...props}
          name={name}
          component="input"
          maxLength={4}
          max="9999-12-31"
          className="block w-full max-w-lg rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
    </div>
  );
};

export const NumberText = ({ name, children, ...props }) => {
  const range = props["range"];
  const mustBeNumber = (value) =>
    value && isNaN(value) ? "Must be a number" : undefined;
  const minValue = (min) => (value) =>
    isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;
  const maxValue = (max) => (value) =>
    isNaN(value) || value <= max ? undefined : `Should be less than ${max}`;
  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-300 sm:pt-5">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {children}
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <div className="w-full max-w-lg">
          <Field
            {...props}
            name={name}
            component="input"
            validate={composeValidators(
              mustBeNumber,
              minValue(range[0]),
              maxValue(range[1])
            )}
            type="number"
            className="block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-2"
            maxLength={40}
          >
            {({ input, meta }) => (
              <div className="input-container">
                <input
                  className="block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-2"
                  {...input}
                />
                {meta.error && meta.touched && (
                  <span className="text-xs text-red-500">{meta.error}</span>
                )}
              </div>
            )}
          </Field>
        </div>
      </div>
    </div>
  );
};

export const Password = ({ name, children, ...props }) => {
  const [formValues, setFormValues] = useState({});

  const handleShowPassword = () => {
    setFormValues((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  return (
    <div className="relative space-y-1">
      <Text
        name="password"
        type={formValues.showPassword ? "text" : "password"}
      >
        {children}
      </Text>

      <ShowPassword
        handleShowPassword={handleShowPassword}
        showPassword={formValues.showPassword}
      />
    </div>
  );
};

export const PhoneNumber = ({ name, children, ...props }) => {
  const formatPhoneNumber = (value) => {
    if (!value) return value;

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  };

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-300 sm:pt-5">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {children}
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <Field
          name={name}
          render={({ input }) => (
            <input
              {...props}
              {...input}
              type="text"
              pattern="\([0-9]{3}\) [0-9]{3}-[0-9]{4}"
              placeholder="(XXX) XXX-XXXX"
              className="block w-full max-w-lg rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              onChange={(e) =>
                input.onChange(formatPhoneNumber(e.target.value))
              }
              value={formatPhoneNumber(input.value)}
            />
          )}
          maxLength={10}
        />
      </div>
    </div>
  );
};

export const Textarea = ({ name, children, ...props }) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-300 sm:pt-5">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {children}
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <Field
          {...props}
          name={name}
          component="textarea"
          rows={5}
          maxLength={600}
          className="block w-full max-w-lg rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
        <p className="mt-2 text-sm text-gray-500">Notes about this record.</p>
      </div>
    </div>
  );
};

export const Select = ({ label, required, name, children, ...props }) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-300 sm:pt-5">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
        {required && <span className="text-red-500"> *</span>}
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <Field
          name={name}
          {...props}
          component="select"
          className="block w-full max-w-lg rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
        >
          {children}
        </Field>
      </div>
    </div>
  );
};

export const SelectWithState = ({
  label,
  required,
  name,
  children,
  value,
  ...props
}) => {

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-300 sm:pt-5">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
        <span className="text-red-500">{required && " *"}</span>
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0 relative">
        <select
          name={name}
          required={required}
          value={value || ""}
          {...props}
          component="select"
          className="block w-full max-w-lg rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
        >
          {children}
        </select>
      </div>
    </div>
  );
};

export const MultipleChoice = ({
  label,
  name,
  values,
  onChange,
  editValues,
}) => {
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherInputValue, setOtherInputValue] = useState("");
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [otherTypeValues, setOtherTypeValues] = useState([]);
  const [typeMc, setTypeMc] = useState([]);

  const hasInvalidValues = editValues?.some((value) => !values.includes(value));

  const handleCheckboxChange = (event, value, name) => {
    const checked = event.target.checked;
    setTypeMc(name);

    if (value === "Other") {
      setShowOtherInput(checked);
      if (!checked) {
        setOtherInputValue("");
        setCheckboxValues((prevValues) => {
          return prevValues;
        });
      } else {
        if (editValues && editValues.length > 0) {
          if (value === "Other") {
            setCheckboxValues(editValues);
          } else {
            setCheckboxValues([value, ...editValues]);
          }
        }
      }
    } else {
      if (checked) {
        if (checkboxValues && checkboxValues.length > 0) {
          setCheckboxValues((prevValues) => [value, ...prevValues]);
        } else if (editValues && editValues.length > 0) {
          setCheckboxValues([value, ...editValues]);
        } else {
          setCheckboxValues([value]);
        }
      } else {
        if (checkboxValues && checkboxValues.length > 0) {
          setCheckboxValues((prevValues) =>
            prevValues.filter((item) => item !== value)
          );
        } else if (editValues && editValues.length > 0) {
          const afterEditValues = editValues.filter((item) => item !== value);
          setCheckboxValues(afterEditValues);
        }
      }
    }
  };

  useEffect(() => {
    if (hasInvalidValues) {
      const differentValues = editValues.filter(
        (value) => !values.includes(value)
      );
      if (differentValues.length > 0) {
        setOtherInputValue(differentValues[0]);
      }
    }
  }, [hasInvalidValues]);

  useEffect(() => {
    onChange(null, checkboxValues, name);
  }, [checkboxValues]);

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-300 sm:pt-5">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
      </label>
      <div className="-mt-3 sm:col-span-2">
        <fieldset className="space-y-5 sm:grid sm:grid-cols-2">
          <legend className="sr-only">Notifications</legend>
          {values &&
            values.map((value, index) => (
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <Field
                    component={"input"}
                    name={name}
                    type="checkbox"
                    value={value}
                    checked={
                      value === "Other"
                        ? showOtherInput
                        : editValues
                        ? editValues.includes(value)
                        : checkboxValues?.includes(value)
                    }
                    onChange={(e) => {
                      handleCheckboxChange(e, value, name);
                      onChange(e, checkboxValues, name);
                    }}
                    className="h-4 w-4 rounded border-2 border-gray-700 text-indigo-600 focus:ring-indigo-500"
                    id={`checkbox_${value}`}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor={`checkbox_${value}`}
                    className="text-gray-700"
                  >
                    {value}
                  </label>
                </div>
              </div>
            ))}
          {showOtherInput && (
            <div className="relative flex items-start space-x-4">
              <div className="ml-5 text-sm items-center flex-row">
                <label htmlFor="comments" className="text-gray-700">
                  Other
                </label>
              </div>
              <div className="flex h-5 items-center">
                <input
                  type="text"
                  name={typeMc}
                  placeholder="Enter information data here"
                  className="h-8 px-2 border border-gray-300 rounded-md"
                  value={otherInputValue}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setOtherInputValue(inputValue);
                    if (otherTypeValues.length > 0) {
                      otherTypeValues.pop();
                      otherTypeValues.push(inputValue);
                      checkboxValues.pop();
                    } else {
                      otherTypeValues.push(inputValue);
                    }
                    checkboxValues.push(otherTypeValues[0]);
                    onChange(e, checkboxValues, typeMc);
                  }}
                />
              </div>
            </div>
          )}
        </fieldset>
      </div>
    </div>
  );
};

export const Contact = ({ label, required, name, children, ...props }) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      {open && (
        <ActionPanel open={open} setOpen={setOpen}>
          <AddContactForm setOpen={setOpen} />
        </ActionPanel>
      )}
      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-300 sm:pt-5">
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          {label}
          <span className="text-red-500">{required && "*"}</span>
        </label>
        <div className="mt-1 sm:col-span-1 sm:mt-0">
          <Field
            name={name}
            {...props}
            component="select"
            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
          >
            {children}
          </Field>
        </div>
        <div>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setOpen(true);
            }}
            className="ml-0 mt-3 sm:mt-0 sm:ml-3 inline-flex justify-center rounded-md border border-transparent bg-primary-green py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-custom-dark-green focus:outline-none focus:ring-2 focus:ring-primary-green focus:ring-offset-2"
          >
            Add Contact
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export const ContactCheckbox = ({ firstName, lastName, email, ...props }) => {
  return (
    <div className="relative flex items-start py-4">
      <div className="min-w-0 flex-1 text-sm">
        <label htmlFor="contacts" className="font-medium text-gray-700">
          {firstName} {lastName}
        </label>
        <p id="comments-description" className="text-gray-500">
          {email}
        </p>
      </div>
      <div className="ml-3 flex h-5 items-center">
        <Field
          {...props}
          component="input"
          type="checkbox"
          className="h-4 w-4 rounded border-2 border-gray-700 text-indigo-600 focus:ring-indigo-500"
        />
      </div>
    </div>
  );
};

export const Checkbox = ({ label, required, title, name, ...props }) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4 sm:border-t sm:border-gray-300 sm:pt-6">
      <div>
        <div className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700">
          {label}
          <span className="text-red-500">{required && "*"}</span>
        </div>
      </div>
      <div className="mt-4 sm:col-span-2 sm:mt-0">
        <div className="max-w-lg space-y-4">
          <div className="relative flex items-start">
            <div className="flex h-5 items-center">
              <Field
                {...props}
                name={name}
                component="input"
                type="checkbox"
                className="h-4 w-4 rounded border-2 border-gray-700 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={name} className="text-gray-700">
                {title}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Fieldset = ({ title, subtitle, children, type }) => {
  let displayTitle = title;
  let displaySubtitle = subtitle;

  const typesToClearDisplay = [
    "/militaryStatus",
    "/familyTreeDocuments",
    "/oralHistories",
    "/importantStoriesTraditions",
    "/iAmMostGratefulFor",
    "/whatIHaveLearned",
    "/communityService",
    "/intellectualProperty",
    "/organizationOfDigitalFiles",
    "/dataSecurityMethods",
    "/cashOnHand",
    "/emergencyTreatment",
    "/socialSecurityDisability",
    "/bankruptcyFilings",
    "/homeSafes",
    "/extraPropertyKeys",
    "/estatePlans",
    "/todDeeds",
    "/howISeeMySeniorLife",
    "/selfFundingHealthCare",
    "/supplementalSecurity(SSI)",
    "/otherFinanceSources",
    "/valuesThatIHopeToPassOn",
    "/recordedMemories",
    "/dispositionOfMyBodies",
    "/ceremonialPreferences",
    "/graveMarkerInscriptions",
    "/obituaryContents",
    "/obituaryFuneralNotices",
    "/prePayments",
    "/estimatedExpenses",
    "/ceremonyLocations",
    "/serviceInvitationLists",
    "/music",
    "/transportationServices"
  ];

  if (typesToClearDisplay.includes(type)) {
      displayTitle = "";
      displaySubtitle = "";
  }

  return (
    <div className="space-y-6 pt-6 sm:space-y-5 sm:pt-8">
      <div>
        {displayTitle && (
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {displayTitle}
          </h3>
        )}

        {displaySubtitle.toLowerCase() === "topic form" ? (
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            All fields with an asterisk <span className="text-red-500">*</span>{" "}
            must be entered in order to save the record.
          </p>
        ) : (
          displaySubtitle && (
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {displaySubtitle}
            </p>
          )
        )}
      </div>

      <div className="space-y-6 sm:space-y-5">{children}</div>
    </div>
  );
};

export const AvatarUpload = ({ id }) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <ActionPanel open={open} setOpen={setOpen}>
        <UploadPhoto id={id} />
      </ActionPanel>
      <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-300 sm:pt-5">
        <label
          htmlFor="photo"
          className="block text-sm font-medium text-gray-700"
        >
          Photo
        </label>
        <div className="mt-1 sm:col-span-2 sm:mt-0">
          <div className="flex items-center">
            <span className="h-12 w-12 overflow-hidden rounded-full bg-gray-100">
              <svg
                className="h-full w-full text-gray-300"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setOpen(true);
              }}
              className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Change
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
