import moment from "moment";

import { Fragment } from "react";
import {
  Document,
  Page,
  Text,
  View,
  Font,
  Image,
  Link,
} from "@react-pdf/renderer";

import logo from "../../../assets/image/logo.jpg";
import tree from "../../../assets/image/Tree.png";
import copyright from "../../../assets/image/copyright.jpg";

Font.register({
  family: "Prompt",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Cy_g4bmkvc5Q9dw.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Cr_s4bmkvc5Q9dw.ttf",
      fontWeight: "thin",
    },
    {
      src: "https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Ck_k4bmkvc5Q9dw.ttf",
      fontWeight: "bold",
    },
  ],
});

export default function PDFLayout({ records, title, name, email }) {
  const updateRecords = (records) => {
    const recordsData = [...records];

    const results = recordsData.map((item) => {
      delete item._id;
      delete item.uid;
      delete item.createdAt;
      delete item.__v;
      delete item.files;
      delete item.contacts;
      return item;
    });

    return results;
  };

  const date = moment().format("ll");
  const year = moment().format("YYYY");
  const newRecords = updateRecords(records);
  const newTitle = title.split(/(?=[A-Z])/).join(" ");

  const Br = () => "\n";

  return (
    <Document style={{ backgroundColor: "white" }}>
      {/* FIRST PAGE */}
      {newRecords.slice(0, 1).map((info) => (
        <Page
          size="LETTER"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: 30,
            fontFamily: "Prompt",
          }}
        >
          {/* TOP HALF */}
          <View style={{ display: "flex", flexDirection: "column" }}>
            {/* HEADER */}
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  paddingRight: 20,
                }}
              >
                <Image src={logo} style={{ width: 250, marginBottom: 20 }} />

                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Text
                    style={{
                      marginBottom: 15,
                      textTransform: "uppercase",
                      letterSpacing: 1,
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    {newTitle === "last Will Testaments"
                      ? "LAST WILL & TESTAMENTS"
                      : newTitle}
                  </Text>

                  <Text
                    style={{
                      color: "rgb(107 114 128)",
                      textTransform: "uppercase",
                      letterSpacing: 5,
                      fontSize: 11,
                    }}
                  >
                    report
                  </Text>

                  <Text
                    style={{
                      color: "rgb(107 114 128)",
                      letterSpacing: 1,
                      fontSize: 12,
                    }}
                  >
                    Created by:{" "}
                    <Text
                      style={{
                        color: "rgb(107 114 128)",
                        textTransform: "capitalize",
                        letterSpacing: 1,
                        fontSize: 12,
                      }}
                    >
                      {name}
                    </Text>
                  </Text>

                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text
                      style={{
                        marginRight: 15,
                        color: "rgb(107 114 128)",
                        textTransform: "lowercase",
                        letterSpacing: 1,
                        fontSize: 12,
                      }}
                    >
                      {email}
                    </Text>

                    <Text
                      style={{
                        color: "rgb(107 114 128)",
                        letterSpacing: 1,
                        fontSize: 12,
                      }}
                    >
                      {date}
                    </Text>
                  </View>
                </View>
              </View>

              <Image src={tree} style={{ width: 230 }} />
            </View>

            {/* DISCLAIMER */}
            <Text style={{ marginBottom: 4, fontSize: 8 }}>
              The information contained in this document is confidential,
              privileged and only for the use of the intended recipient. It may
              not be published or redistributed without the consent of the My
              Banyan account holder. My Banyan, Inc. gives no warranties of
              accuracy or completeness of any information, facts and/or opinions
              contained therein.
            </Text>

            {/* INFO */}
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  marginRight: 15,
                  color: "#7a935a",
                  fontSize: 35,
                  fontWeight: "bold",
                }}
              >
                1
              </Text>

              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 25,
                  fontSize: 12,
                  borderTop: 2,
                  borderTopColor: "#7a935a",
                }}
              >
                {Object.keys(info).map((key, index) => (
                  <View>
                    {key !== "createdAt" && key !== "id" && (
                      <View
                        key={index}
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: 4,
                          borderBottom: 1,
                          borderBottomColor: "#909090",
                          borderBottomStyle: "dashed",
                        }}
                      >
                        {/* LEFT COLUMN KEYS */}
                        <Text
                          style={{
                            flex: 2,
                            fontWeight: "bold",
                            textAlign: "left",
                            textTransform: "capitalize",
                          }}
                        >
                          {Array.isArray(info[key]) && info[key].length < 1
                            ? null
                            : key.split(/(?=[A-Z])/).join(" ")}
                          :
                        </Text>

                        {/* RIGHT COLUMN VALUES */}
                        <Text style={{ flex: 5, textAlign: "left" }}>
                          {Array.isArray(info[key]) ? (
                            info[key].map((i, idx) => (
                              <Fragment key={idx}>
                                {newTitle === "last Will Testaments" &&
                                key === "contents" ? (
                                  <Fragment>
                                    {i}
                                    <Br />
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    {key.toLowerCase().includes("file") ? (
                                      <Link
                                        src={`${i}`}
                                        style={{ color: "blue" }}
                                      >
                                        {i}
                                      </Link>
                                    ) : (
                                      i
                                    )}
                                    {info[key].length - 1 !== idx ? ", " : ""}
                                  </Fragment>
                                )}
                              </Fragment>
                            ))
                          ) : key.toLowerCase().includes("file") ? (
                            <Link
                              src={`${info[key]}`}
                              style={{ color: "blue" }}
                            >
                              {info[key]}
                            </Link>
                          ) : (
                            info[key].toString()
                          )}
                        </Text>
                      </View>
                    )}
                  </View>
                ))}
              </View>
            </View>
          </View>

          {/* FOOTER */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 10,
              }}
            >
              <Text>
                <Image src={copyright} style={{ width: 15 }} />
                {year} My Banyan, Inc
              </Text>
            </View>

            <Text
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              style={{ flex: 1, fontSize: 12, textAlign: "center" }}
            />

            <Text style={{ flex: 1, fontSize: 10, textAlign: "center" }}>
              www.mybanyanlife.com
            </Text>
          </View>
        </Page>
      ))}

      {/* SUBSEQUENT PAGES */}
      {newRecords.slice(1).map((info, index) => (
        <Page
          key={index}
          size="LETTER"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: 30,
            fontFamily: "Prompt",
          }}
        >
          {/* INFO */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <Text
              style={{
                marginRight: 15,
                color: "#7a935a",
                fontSize: 35,
                fontWeight: "bold",
              }}
            >
              {index + 2}
            </Text>

            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                marginTop: 25,
                fontSize: 12,
                borderTop: 2,
                borderTopColor: "#7a935a",
              }}
            >
              {Object.keys(info).map((key, index) => (
                <View>
                  {key !== "createdAt" && key !== "id" && (
                    <View
                      key={index}
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: 4,
                        borderBottom: 1,
                        borderBottomColor: "#595959",
                        borderBottomStyle: "dashed",
                      }}
                    >
                      {/* LEFT COLUMN KEYS */}
                      <Text
                        style={{
                          flex: 2,
                          fontWeight: "bold",
                          textAlign: "left",
                          textTransform: "capitalize",
                        }}
                      >
                        {Array.isArray(info[key]) && info[key].length < 1
                          ? null
                          : key.split(/(?=[A-Z])/).join(" ")}
                        :
                      </Text>

                      {/* RIGHT COLUMN VALUES */}
                      <Text style={{ flex: 5, textAlign: "left" }}>
                        {Array.isArray(info[key]) ? (
                          info[key].map((i, idx) => (
                            <Fragment key={idx}>
                              {newTitle === "last Will Testaments" &&
                              key === "contents" ? (
                                <Fragment>
                                  {i}
                                  <Br />
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {key.toLowerCase().includes("file") ? (
                                    <Link
                                      src={`${i}`}
                                      style={{ color: "blue" }}
                                    >
                                      {i}
                                    </Link>
                                  ) : (
                                    i
                                  )}
                                  {info[key].length - 1 !== idx ? ", " : ""}
                                </Fragment>
                              )}
                            </Fragment>
                          ))
                        ) : key.toLowerCase().includes("file") ? (
                          <Link src={`${info[key]}`} style={{ color: "blue" }}>
                            {info[key]}
                          </Link>
                        ) : (
                          info[key].toString()
                        )}
                      </Text>
                    </View>
                  )}
                </View>
              ))}
            </View>
          </View>

          {/* FOOTER */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 10,
              }}
            >
              <Text>
                <Image src={copyright} style={{ width: 15 }} />
                {year} My Banyan, Inc
              </Text>
            </View>

            <Text
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              style={{ flex: 1, fontSize: 12, textAlign: "center" }}
            />

            <Text style={{ flex: 1, fontSize: 10, textAlign: "center" }}>
              www.mybanyanlife.com
            </Text>
          </View>
        </Page>
      ))}
    </Document>
  );
}
