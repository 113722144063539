import { Fragment } from "react";

export const RenderFavorites = ({ user, setQuickStart }) => {
  return (
    <Fragment>
      {user.favorites && user.favorites.length > 0 && user.type !== "readonly" ? (
        <div className="mx-auto w-full">
          <ul className="grid grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 gap-y-4 gap-x-8 sm:gap-x-4 md:gap-x-8 lg:gap-x-2 2xl:gap-x-10">
            {user.favorites.map((el) => (
              <li key={el.path} className="relative">
                <a
                  className="block group mx-auto overflow-hidden rounded-lg"
                  style={{ position: 'relative', cursor: 'pointer' }}
                  href={el.path}
                >
                  <img
                    src={el.favoriteIcon}
                    alt={`Go to ${el.name}`}
                    className="object-cover group-hover:opacity-75 transition duration-300 w-full h-auto"
                  />
                  <p className="mt-2 block text-3xs sm:text-sm lg:text-3xs xl:text-2xs 2xl:text-xs font-medium text-gray-900 text-center uppercase">
                    {el.name.slice(0, -7)}
                  </p>
                </a>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="w-full h-full flex justify-center">
          <p className="text-2xs 2xl:text-sm text-primary-green leading-6">
            Welcome to the READ ONLY version of My Banyan. When someone shares a report, topic, or file with you, it will appear in the SHARING screen, which is accessible through the active SHARING link on the left navigation bar. You also have access to the Profile in the upper right-hand corner, where you can convert your READ ONLY account to a paid version with a 21 day free trial at any time.
          </p>
        </div>
      )}
    </Fragment>
  );
};
