/* eslint-disable react/jsx-no-target-blank */
import OneRecordPDFReport from "../../components/pdf-report/one";
import GreenBtn from "../../components/buttons/greenBtn";

import { Fragment, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { PaperClipIcon } from "@heroicons/react/solid";
import { restGet } from "../../store/api";
import { updateTopicById } from "../../store/actions";
import { scrollTop } from "../../components/common/scroll-top";

export default function TopicView({ path, files }) {
    const method = "/show/";
    const [params] = useSearchParams();

    const pathname = path + method + params.get("id");
    const reportTitle = path.split("/")[1];

    const location = useLocation();
    const pathName = location.pathname;
    const name = pathName.split("/")[3];

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const data = useSelector((state) => state.topic.byId);
    const userEmail = useSelector(
        (state) => state.auth.currentUser.attributes.email
    );
    const userName = useSelector(
        (state) => state.auth.currentUser.attributes.name
    );

    const getPath = (pathname) => {
        const arr = pathname.split("/");
        let path = "/";

        for (let i = 1; i < 4; i++) {
            path = path + arr[i];

            if (i !== 3) path = path + "/";
        }

        return path;
    };

    const prevPath = getPath(location.pathname);

    const successCallback = (data) => {
        dispatch(updateTopicById(data));
    };

    const failCallback = (err) => {
        console.error(err);
    };

    const getDataToShow = () => {
        restGet(pathname, successCallback, failCallback);
    };

    const generateFormattedData = (data) => {
        const filteredKeys = Object.keys(data).filter(
            (el) =>
                el !== "sharing" &&
                el !== "_id" &&
                el !== "uid" &&
                el !== "__v" &&
                el !== "createdAt" &&
                el !== "contacts" &&
                el !== "files"
        );

        const formattedData = filteredKeys.map((el) => {
            let formattedValue;
            if (Array.isArray(data[el])) {
                formattedValue = data[el].join(", ");
            } else {
                formattedValue = data[el];
            }

            return formattedValue;
        });

        return formattedData[0];
    };

    useEffect(() => {
        getDataToShow();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {data && (
                <div className="mt-8 flex flex-col">
                    <div>
                        <div className="flex items-center justify-between">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">
                                Record details
                            </h3>

                            <div>
                                <OneRecordPDFReport
                                    fileName={`${reportTitle.replace(
                                        /([a-z])([A-Z])/g,
                                        "$1_$2"
                                    )}_${generateFormattedData(data)}_Report_${
                                        data._id
                                    }`}
                                    record={data}
                                    title={reportTitle}
                                    name={userName}
                                    email={userEmail}
                                    linkName={name}
                                    files={files}
                                />
                            </div>
                        </div>

                        <div className="mt-5 border-t border-gray-300">
                            <dl className="sm:divide-y sm:divide-gray-300">
                                {data &&
                                    Object.keys(data)
                                        .filter(
                                            (el) =>
                                                el !== "sharing" &&
                                                el !== "_id" &&
                                                el !== "uid" &&
                                                el !== "__v" &&
                                                el !== "createdAt" &&
                                                el !== "contacts" &&
                                                el !== "files"
                                        )
                                        .map((el) => (
                                            <div
                                                key={uuidv4()}
                                                className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
                                            >
                                                <dt className="text-sm font-bold text-gray-900">
                                                    {el
                                                        .replace(
                                                            /([a-z])([A-Z])/g,
                                                            "$1 $2"
                                                        )
                                                        .replace(
                                                            /^./,
                                                            function (str) {
                                                                return str.toUpperCase();
                                                            }
                                                        )}
                                                </dt>

                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                    {Array.isArray(data[el]) ? (
                                                        data[el].map((item, idx) => (
                                                            <span key={`array_${idx}`}>
                                                                {el === "since"
                                                                    ? item.split("T")[0]
                                                                    : item}
                                                                {data[el].length - 1 !== idx && ", "}
                                                            </span>
                                                        ))
                                                    ) : el === "since" ? (
                                                        data[el].split("T")[0]
                                                    ) : (
                                                        data[el]
                                                    )}
                                                </dd>
                                            </div>
                                        ))}

                                {files && (
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                        <dt className="text-sm font-bold text-gray-900">
                                            Attachments
                                        </dt>

                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            <ul className="divide-y divide-gray-300 rounded-md border border-gray-300">
                                                {files.length > 0 ? (
                                                    <Fragment>
                                                        {" "}
                                                        {files.map((file) => (
                                                            <li
                                                                key={file._id}
                                                                className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                                                            >
                                                                <div className="flex w-0 flex-1 items-center">
                                                                    <PaperClipIcon
                                                                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                                                                        aria-hidden="true"
                                                                    />
                                                                    <span className="ml-2 w-0 flex-1 truncate">
                                                                        <a
                                                                            href={
                                                                                file.url
                                                                            }
                                                                            target="_blank"
                                                                            className="text-indigo-600 hover:text-indigo-500"
                                                                        >
                                                                            {
                                                                                file.fileName
                                                                            }
                                                                        </a>
                                                                    </span>
                                                                </div>

                                                                <div className="ml-4 flex-shrink-0">
                                                                    <a
                                                                        href={
                                                                            file.url
                                                                        }
                                                                        className="font-medium text-indigo-600 hover:text-indigo-500"
                                                                    >
                                                                        Download
                                                                    </a>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </Fragment>
                                                ) : (
                                                    <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                                        <div className="flex w-0 flex-1 items-center">
                                                            <span className="ml-2 w-0 flex-1 truncate">
                                                                No file attached
                                                            </span>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        </dd>
                                    </div>
                                )}
                            </dl>
                        </div>

                        <div className="text-right">
                            <GreenBtn
                                onClick={() => {
                                    navigate(prevPath);
                                    scrollTop();
                                }}
                            >
                                Return to all records
                            </GreenBtn>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

