import moment from "moment";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { TodoHeader } from "./todo-header";
import { FormSubmit } from "./forms/form-submit";
import { ActionPanel } from "../common/action-panel";
import { restGet, restPut } from "../../store/api";
import { todoSetToggle, updateTodoByAll } from "../../store/actions";

import PageTitle from "../common/page-title";

const Todo = () => {
    const apiPath = "todoEvents";

    const dispatch = useDispatch();
    const events = useSelector((state) => state.todo.byAll);
    const toggle = useSelector((state) => state.todo.toggle);
    const [open, setOpen] = useState(false);
    const [initialValues, setInitialValues] = useState(null);
    const [sort, setSort] = useState("deadline");

    const successCallback = (data) => {
        dispatch(updateTodoByAll(data));
    };

    const failCallback = (err) => {
        console.error(err);
    };

    const getEvents = () => {
        restGet(
            "/" + apiPath + "/index?sort=" + sort,
            successCallback,
            failCallback
        );
    };

    const updateHandler = (event) => {
        setInitialValues(event);
        setOpen(true);
    };

    const updateSuccess = () => {
        dispatch(todoSetToggle());
    };

    const updateFail = () => {
        console.error("error");
    };

    const handleOnChange = (index) => {
        const reqBody = events[index];
        reqBody.completed = !reqBody.completed;
        restPut(
            "/" + apiPath + "/update/" + events[index]._id,
            reqBody,
            updateSuccess,
            updateFail
        );
    };

    useEffect(() => {
        getEvents();
        if (open === false) {
            setInitialValues(null);
        }
    }, [toggle, sort, open]);

    return (
        <>
            <PageTitle title="My Banyan" />

            {open && (
                <ActionPanel open={open} setOpen={setOpen}>
                    <FormSubmit
                        apiPath={apiPath}
                        setOpen={setOpen}
                        initialValues={initialValues}
                    />
                </ActionPanel>
            )}

            <TodoHeader setOpen={setOpen} sort={sort} setSort={setSort} />
            <div className="mt-6 mb-4 flex flex-col">
                <div className="overflow-x-auto">
                    <div className="inline-block min-w-full py-2 align-middle md:px-0">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                        >
                                            Completed
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Task
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Due Date
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                                        >
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {events &&
                                        events.length > 0 &&
                                        events.map((event, index) => (
                                            <tr
                                                key={event._id}
                                                className={
                                                    index % 2 === 0
                                                        ? undefined
                                                        : "bg-gray-50"
                                                }
                                            >
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    <div className="flex h-5 items-center">
                                                        <input
                                                            id="comments"
                                                            aria-describedby="comments-description"
                                                            name="comments"
                                                            checked={
                                                                event.completed
                                                            }
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                handleOnChange(
                                                                    index
                                                                );
                                                            }}
                                                            className="h-4 w-4 rounded border-2 border-gray-700 text-indigo-600 focus:ring-0 focus:ring-offset-0"
                                                        />
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    <div className="truncate flex items-center">
                                                        <div className="ml-2 flex text-sm">
                                                            <p
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    updateHandler(
                                                                        event
                                                                    );
                                                                }}
                                                                className="truncate font-medium text-indigo-600 cursor-pointer w-64"
                                                            >
                                                                {event.title}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    <div className="truncate mt-4 flex-shrink-0 sm:mt-0">
                                                        <div className="text-sm text-gray-500">
                                                        <label
                                                            htmlFor="when"
                                                            className="font-medium text-gray-700"
                                                        >
                                                            {event.when
                                                                ? event.completed
                                                                    ? "Finished on "
                                                                    : ""
                                                                : event.completed
                                                                ? "Finished"
                                                                : "No date or time"}
                                                        </label>
                                                        <span
                                                            id="when-description"
                                                            className="text-gray-500 "
                                                        >
                                                            {event.completed ? (
                                                                event.completedAt ? (
                                                                    <>
                                                                        <span>
                                                                            {moment(event.completedAt).format("LL")}{" "}
                                                                            {event.atTime ? moment(event.atTime, "HH:mm").format("LT") : ""}
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    "No completion date"
                                                                )
                                                            ) : event.when ? (
                                                                event.atTime ? (
                                                                    <>
                                                                        <span>
                                                                            {moment(event.when).format("ll")}{" "}
                                                                            {moment(event.atTime, "HH:mm").format("LT")}
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    moment(event.when).format("LL")
                                                                )
                                                            ) : (
                                                                ""
                                                            )}
                                                        </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right float-right">
                                                    {event.completed ? (
                                                        <CheckCircleIcon
                                                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                                            aria-hidden="true"
                                                        />
                                                    ) : (
                                                        <div className="flex overflow-hidden text-sm">
                                                            Not Completed
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Todo;

