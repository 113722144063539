export const recentHealthExamsData = {
  formTitle: "Add or edit recent health exam",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/recentHealthExams",
  formFields: [
    {
      component: "select",
      label: "Type of exam",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        {
          value: "Age-related macular degeneration (AMD)",
          text: "Age-related macular degeneration (AMD)",
        },
        { value: "Blood pressure test", text: "Blood pressure test" },
        { value: "Blood sugar", text: "Blood sugar" },
        { value: "Calcium test", text: "Calcium test" },
        { value: "Cataracts exam", text: "Cataracts exam" },
        { value: "Colonoscopy", text: "Colonoscopy" },
        {
          value: "Complete blood count (CBC)",
          text: "Complete blood count (CBC)",
        },
        { value: "COVID test", text: "COVID test" },
        { value: "Dental exam", text: "Dental exam" },
        { value: "ECG / EKG test", text: "ECG / EKG test" },
        { value: "Glaucoma exam", text: "Glaucoma exam" },
        { value: "Hearing test", text: "Hearing test" },
        { value: "Kidney function test", text: "Kidney function test" },
        { value: "Pap smear exam", text: "Pap smear exam" },
        { value: "Lipid profile", text: "Lipid profile" },
        { value: "Liver function test", text: "Liver function test" },
        { value: "Mammogram exam", text: "Mammogram exam" },
        { value: "Prostate cancer test", text: "Prostate cancer test" },
        { value: "STI screening", text: "STI screening" },
        { value: "Stool occult test", text: "Stool occult test" },
        { value: "Thyroid function test", text: "Thyroid function test" },
        { value: "Uric acid test", text: "Uric acid test" },
        { value: "Urine analysis", text: "Urine analysis" },
        { value: "Visual acuity exam", text: "Visual acuity exam" },
        {
          value: "Vitamin deficiency test",
          text: "Vitamin deficiency test",
        },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Physician name",
      name: "physician",
      type: "text",
    },
    {
      component: "input",
      label: "Purpose of exam",
      name: "purpose",
      type: "text",
    },
    {
      component: "input",
      label: "Date",
      name: "date",
      type: "date",
    },
    {
      component: "input",
      label: "Findings",
      name: "findings",
      type: "text",
    },
    {
      component: "input",
      label: "Next exam date",
      name: "nextDate",
      type: "date",
    },
    {
      component: "input",
      label: "Person who has copies",
      name: "personWhoHasCopies",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
