import PageTitle from "../../components/common/page-title";
import { CabinetList } from "../../components/cabinet/list";

export default function Cabinet({ page }) {
    return (
        <>
            <PageTitle title="My Banyan" />

            <CabinetList />
        </>
    );
}

