export const bankruptcyFilingsData = {
  formTitle: "Add or edit bankruptcy filing",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/bankruptcyFilings",
  formFields: [
    {
      component: "input",
      label: "Chapter 7 bankruptcy worksheet location",
      name: "c7Location",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Chapter 13 bankruptcy worksheet location",
      name: "c13Location",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Attorney name",
      name: "attorney",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Possible next date",
      name: "date",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
