export const privateHealthInsuranceData = {
  formTitle: "Add or edit private health insurance",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/privateHealthInsurance",
  formFields: [
    {
      component: "select",
      label: "Coverage type",
      name: "coverageType",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Dental Plan", text: "Dental Plan" },
        { value: "Disability Plan", text: "Disability Plan" },
        { value: "Health Care Plan: HMO", text: "Health Care Plan: HMO" },
        { value: "Health Care Plan: PPO", text: "Health Care Plan: PPO" },
        {
          value: "Health Care Plan: Supplemental",
          text: "Health Care Plan: Supplemental",
        },
        {
          value: "Health Savings Account (HSA)",
          text: "Health Savings Account (HSA)",
        },
        { value: "Hearing Plan", text: "Hearing Plan" },
        { value: "Long-Term Care Plan", text: "Long-Term Care Plan" },
        { value: "Prescription Plan", text: "Prescription Plan" },
        { value: "Vision Plan", text: "Vision Plan" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Insurance company",
      name: "company",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Broker name",
      name: "broker",
      type: "text",
    },
    {
      component: "input",
      label: "Name of insured",
      name: "insured",
      type: "text",
      description: "The person that the insurance policy covers.",
    },
    {
      component: "input",
      label: "Name of person who receives copy of bills if I am disabled",
      name: "copyPerson",
      type: "text",
      description:
        "This ensures that any required policy premiums are paid on time in the event of death/disability.",
    },
    {
      component: "input",
      label: "Policy owner",
      name: "policyOwner",
      type: "text",
      description:
        "The insured person or a representative of the insured (such as a spouse or trustee) should the insured become disabled.",
    },
    {
      component: "input",
      label: "Beneficiary",
      name: "beneficiary",
      type: "text",
      description:
        "The person, group of persons, or entity that receives benefits upon your death. A beneficiary can be a person, a trust you’ve established, a charity, or your estate.",
    },
    {
      component: "input",
      label: "Person(s) who have copies of this document",
      name: "persons",
      type: "text",
    },
    {
      component: "input",
      label: "Account number",
      name: "accountNumber",
      type: "text",
    },
    {
      component: "input",
      label: "Login username (or its location)",
      name: "username",
      type: "text",
    },
    {
      component: "password",
      label: "Account password (or its location)",
      name: "password",
      type: "password",
    },
    {
      component: "input",
      label: "PIN number (or its location)",
      name: "pin",
      type: "text",
    },
    {
      component: "input",
      label: "Coverage limits",
      name: "coverageLimits",
      type: "text",
      description:
        "The maximum amount of money an insurance company will pay for a covered claim.",
    },
    {
      component: "input",
      label: "Annual premium",
      name: "annualPremium",
      type: "text",
    },
    {
      component: "input",
      label: "Date of most recent review of this policy",
      name: "recentReviewDate",
      type: "date",
    },
    {
      component: "input",
      label: "Date of next review",
      name: "nextReviewDate",
      type: "date",
    },
    {
      component: "input",
      label: "Expiration date",
      name: "expirationDate",
      type: "date",
    },
    {
      component: "input",
      label: "Policy paid by employer",
      name: "paidPolicy",
      type: "text",
    },
    {
      component: "input",
      label: "Employer contact name",
      name: "employer",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Employer contact phone number",
      name: "employerPhone",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
