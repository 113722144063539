import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const middlewares = [thunk];

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  return <Provider store={store}>{props.children}</Provider>;
};
