import { useEffect } from "react";

export default function PrivacyPolicyAndTermsOfService() {
    // Changes the document's title and meta description
    useEffect(() => {
        document.title = "My Banyan Terms of Use and Privacy Policy";

        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute("content", "Read My Banyan Life's privacy policy and terms of service to understand how we protect your data and ensure a safe user experience.");
        } else {
            const meta = document.createElement("meta");
            meta.name = "description";
            meta.content = "Read My Banyan Life's privacy policy and terms of service to understand how we protect your data and ensure a safe user experience.";
            document.head.appendChild(meta);
        }
    }, []);

    return (
        <main className="text-sm md:text-base lg:text-lg overflow-x-clip">
            <section className="relative w-full h-full lg:h-[100vh] bg-primary-green">
                <img
                    src="https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fprivacy-policy%2Fprivacy-policy.jpg?alt=media&token=18158126-8add-4cd0-9ade-36332162f74c"
                    alt="Laptop with a lock on the keyboard"
                    className="w-full h-full object-cover bg-primary-green"
                />

                {/* DESKTOP TITLE */}
                <div className="hidden absolute z-10 top-0 left-[10%] w-1/3 h-full px-5 lg:px-10 bg-primary-green md:flex justify-center items-center">
                    <h1 className="text-lg lg:text-2xl xl:text-4xl font-bold text-white">
                        Privacy Policy & <br /> Terms of Service
                    </h1>
                </div>

                {/* MOBILE TITLE */}
                <div className="relative md:hidden p-10 bg-primary-green text-white">
                    <h2 className="text-2xl font-bold text-center">
                        Privacy Policy & <br /> Terms of Service
                    </h2>
                </div>
            </section>

            <article className="p-6 sm:p-10 space-y-12 text-justify">
                <h2 className="font-bold text-lg md:text-xl lg:text-2xl">TERMS OF USE & PRIVACY POLICY</h2>

                <section className="space-y-8">
                    <h3 className="font-bold">SCOPE OF THIS NOTICE</h3>

                    <p>
                        Please read this privacy notice (“Notice”) carefully to understand our policies and practices regarding your personal data and how we will treat it. This Notice applies to
                        individuals who interact with MY BANYAN, INC. MY BANYAN provides services to consumers (“you”) on the sites and platforms on which it appears. This Notice explains how your
                        personal data (which some jurisdictions may refer to as “personal information”) is collected, used, and disclosed by MY BANYAN, INC. MY BANYAN, and third parties acting on our
                        behalf (collectively “MYBANYAN”, “We”, “Us” “our”) on the sites and platforms on which this policy appears. It also tells you how you can access and update your personal data
                        and make certain choices about how your personal data is used.
                    </p>

                    <p>
                        This Notice covers both our online and offline data collection activities where this policy is provided to you or is otherwise referenced. This includes personal data that We
                        collect through our various channels such as websites, apps, third-party social networks, consumer affairs, points of sale, and events where this policy is provided to you.
                        Please note that We might combine personal data from different sources (website, offline event). We also may combine data from third parties with data We already have.
                    </p>

                    <p>
                        In some instances, if you do not provide personal data to Us, We may not be able to provide you with certain goods and/or services. We will indicate to you when this is the
                        case, for example, by stating so on our registration forms.
                    </p>
                </section>

                <section className="space-y-8">
                    <h3 className="font-bold">PERSONAL DATA THAT WE COLLECT ABOUT YOU</h3>

                    <p>We collect various types of information from you, as described below.</p>

                    <ul className="ml-5 md:ml-10 list-disc space-y-4">
                        <li>
                            <p>
                                <span className="font-semibold">Contact Data:</span> Any data you provide to Us that would allow Us to contact you, such as your name, postal address, e-mail address,
                                social network details, or phone number.
                            </p>
                        </li>

                        <li>
                            <p>
                                <span className="font-semibold">Account Login Data:</span> Any data that is required to give you access to your specific account profile. Examples include your login
                                ID/email address, screen name, password in unrecoverable form, and/or security question and answer.
                            </p>
                        </li>

                        <li>
                            <p>
                                <span className="font-semibold">Device Data:</span> Data about the computer system or other technological device that you use to access one of our websites or apps,
                                such as the Internet protocol (IP) address used to connect your computer or device to the Internet and other online identifiers. If you access one of the sites or apps
                                subject to this policy via a mobile device such as a smartphone, the collected data will also include, where permitted, your phone’s unique device ID, advertising ID,
                                and other similar mobile device data.
                            </p>
                        </li>

                        <li>
                            <p>
                                <span className="font-semibold">Payment Data:</span> Any data that We need in order to fulfill an order, or that you use to make a purchase, such as your debit or
                                credit card details (cardholder name, card number, expiration date, etc.) or other forms of payment (if such are made available).
                            </p>
                        </li>

                        <li>
                            <p>
                                <span className="font-semibold">Demographic Data:</span> Any data that describes your demographic or behavioral characteristics. Examples might include your gender,
                                geographic location (e.g., ZIP code), services, or company data. In some cases, this could include data that you give Us about someone else. For example, if you provide
                                another individual or company’s email address for a referral. If you are a service provider company, We may collect data about your practice.
                            </p>
                        </li>

                        <li>
                            <p>
                                <span className="font-semibold">Third-Party Social Network Data:</span> Any data that you share publicly on a third-party social network or data that is part of your
                                profile on a third-party social network (such as Facebook, Twitter, Instagram, etc.) and that you allow the third-party social network to share with Us. Examples
                                include your basic account data (e.g., name, email address, gender, current city, profile picture, user ID, list of friends, etc.) and any other additional data or
                                activities that you permit the third-party social network to share. We receive your third-party social network profile data (or parts of it) every time you download or
                                interact with a web application on a third-party social network such as Facebook, every time you use a social networking feature that is integrated within one of our
                                sites (such as Facebook Connect), or every time you interact with Us through a third-party social network. To learn more about how your data from a third-party social
                                network is obtained by Us, or to opt out of sharing such social network data, please visit the website of the relevant third-party social network.
                            </p>
                        </li>

                        <li>
                            <p>
                                <span className="font-semibold">Third-Party Payment Service Data:</span> Any data that you share publicly on a third-party payment service or data that is part of your
                                profile on the payment service provider (such as Paypal) and that you allow the third-party social network to share with Us. Examples include your basic account data
                                (e.g., name, email address, credit card information, banking and account information, etc.) and any other additional data or activities that you permit the third-party
                                payment provider to share. We do not take custody of or retain or hold any of your financial or banking information.
                            </p>
                        </li>

                        <li>
                            <p>
                                <span className="font-semibold">Site Usage Data:</span> This includes information about how you use our websites and our other communications. For example, as you
                                navigate through and interact with our websites/apps or emails, We use automatic data collection technologies to collect certain data about your device(s) and your
                                actions. This includes data such as which links you click on, which pages or content you view and for how long, and other similar data and statistics about your
                                interactions, such as content response times, download errors, and length of visits to certain pages, as well as operating system type and web browser type and version.
                                We may also visually record your interactions with our website, including your mouse clicks, movement, scrolling, and navigation through our website.
                            </p>
                        </li>

                        <li>
                            <p>
                                <span className="font-semibold">Feedback Data:</span> Any information that you share with Us about your experience of using our products and services. This includes any
                                content that you create and then share with Us on third party social networks or one of our websites or apps. Examples include photos, videos, personal stories, or
                                other similar media or content. This may also include information you provide during your conversations with consumer affairs.
                            </p>
                        </li>

                        <li>
                            <p>
                                <span className="font-semibold">Geolocation Data:</span> We may collect information about your location. This may include your precise location.
                            </p>
                        </li>

                        <li>
                            <p>
                                <span className="font-semibold">Inferences:</span> We may draw inferences from the data We collect from and about you to create a profile reflecting your preferences,
                                characteristics, and behavior.
                            </p>
                        </li>
                    </ul>
                </section>

                <section className="space-y-8">
                    <h3 className="font-bold">HOW WE COLLECT PERSONAL DATA ABOUT YOU</h3>

                    <p>
                        We collect personal data directly from you, when you choose to provide it to us. For example, We collect data when you place an order with us. We collect data when you register
                        on one of our websites or apps. We collect data when you sign up for our emails. We collect data from printed or digital registrations and similar forms that We collect via,
                        for example, postal mail, contests, and other promotions or events. We collect data if you fill out a request or use other tools on your websites or apps. We also collect data
                        if you contact Us through our websites or apps, via email, or through social media.
                    </p>

                    <p>
                        We collect data from you passively. For example, We use tracking tools like browser cookies and web beacons. We do this on our digital platforms and in emails that We send to
                        you. We collect data about you over time when you use this website. This includes usage and browser data. We may have service providers or other third parties collect data this
                        way. We also collect data from our mobile apps.
                    </p>

                    <p>
                        We get data about you from other sources. For example, our affiliates and business partners may give Us data about you. Social media platforms may also give Us data about you.
                        We may get data about your interactions with our ads on third-party sites.
                    </p>
                </section>

                <section className="space-y-8">
                    <h3 className="font-bold">PERSONAL DATA OF CHILDREN</h3>

                    <p>
                        We do not knowingly solicit or collect personal data from children below the age of 13. If you are a parent or legal guardian and think that your child under 13 has given Us
                        data, you can contact Us in writing or by email as provided below under the section titled CONTACT.
                    </p>
                </section>

                <section className="space-y-8">
                    <h3 className="font-bold">USES FOR YOUR PERSONAL DATA</h3>

                    <p>
                        The following paragraphs describe the various purposes for which We collect and use your personal data, and the different types of personal data that are collected for each
                        purpose. Please note that not all of the uses below will be relevant to every individual.
                    </p>

                    <ul className="ml-5 md:ml-10 list-disc space-y-4">
                        <li>
                            <p>
                                <span className="font-semibold">Consumer service:</span> We use your personal data for consumer service purposes. Consumer service activities could be responding to
                                your inquiries or complaints.
                            </p>
                        </li>

                        <li>
                            <p>
                                <span className="font-semibold">Contests, marketing, and other promotions:</span> We may use your personal data to provide you with information about goods or services
                                (e.g., marketing communications or campaigns or promotions). This can be done via email, ads, SMS, phone calls, and postal mailings to the extent permitted by
                                applicable laws. Some of our campaigns and promotions are run on third-party websites and/or social networks. For more information about our contests and other
                                promotions, please see the official rules or details posted with each contest/promotion.
                            </p>
                        </li>

                        <li>
                            <p>
                                <span className="font-semibold">Third-party social networks:</span> We use your personal data when you interact with third-party social networking features, such as
                                “Like” functions, to serve you with advertisements and engage with you on third-party social networks. You can learn more about how these features work and the profile
                                data that We obtain about you, and find out how to opt-out by reviewing the privacy notices of the relevant third-party social networks.
                            </p>
                        </li>

                        <li>
                            <p>
                                <span className="font-semibold">Personalization:</span> With your consent (where required), We may use your personal data to personalize your experience with us. For
                                example, we may use these categories of personal information: (i) to analyze your preferences and habits; (ii) to anticipate your needs based on our analysis of your
                                profile; (iii) to improve and personalize your experience on our websites and apps; (iv) to ensure that content from our websites/apps is optimized for you and for your
                                computer or device; (v) to provide you with targeted advertising and content, and (vi) to allow you to participate in interactive features, when you choose to do so.
                                For example, We remember your login ID/email address or screen name so that you can quickly login the next time you visit our site. Based on this type of information,
                                and with your consent (where required), We also show you specific content or promotions that are tailored to your interests.
                            </p>
                        </li>

                        <li>
                            <p>
                                <span className="font-semibold">Order fulfillment:</span> We use your personal data to process and service your orders, inform you about the status of your orders,
                                correct addresses, and conduct identity verification and other fraud detection activities. This involves the use of certain personal data and payment information.
                            </p>
                        </li>
                    </ul>

                    <p>
                        Other general purposes (e.g. internal or market research, analytics, security): In accordance with applicable laws, We use your personal data for other general business
                        purposes, such as maintaining your account, conducting internal or market research, and measuring the effectiveness of advertising campaigns. This also includes improving our
                        websites and services. We also use your personal data for management and operation of our communications, IT, and security systems.
                    </p>
                </section>

                <section className="space-y-8">
                    <h3 className="font-bold">DISCLOSURE OF YOUR PERSONAL DATA</h3>

                    <p>The following paragraphs describe how and to whom We disclose your data.</p>

                    <p>
                        Service providers. We share data with our service providers. These are external companies that We use to help Us run our business (e.g., service or order fulfillment, payment
                        processing, fraud detection and identity verification, website operation, market research companies, support services, promotions, website development, data analysis, CRC,
                        etc.). For individuals in California this information may have been shared in the last 12 months.
                    </p>

                    <p>
                        On our website you can inquire about sales or service from various vendors. You can also submit an inquiry or application to a service provider or member organization. When you
                        do that you authorize Us to share the information you provided on your inquiry or application form with those organizations. Information shared might include your contact and
                        demographic data. If you do not want to share this information, you should not submit an inquiry or application. For individuals in California this information may have been
                        shared in the last 12 months.
                    </p>

                    <p>
                        Credit reporting agencies/debt collectors. To the extent permitted by applicable law, credit reporting agencies and debt collectors are external companies that We use to help
                        Us to verify your creditworthiness (in particular for orders with invoice) or to collect outstanding invoices. For individuals in California, this information may have been
                        shared in the last 12 months.
                    </p>

                    <p>
                        Sharing personal data for legal reasons or due to merger/acquisition. In the event that MYBANYAN or its assets are acquired by, or merged with, another company including
                        through bankruptcy, We will share your personal data with any of our legal successors. We will also disclose your personal data to third parties (i) when required by applicable
                        law; (ii) in response to legal proceedings; (iii) in response to a request from a competent law enforcement agency; (iv) to protect our rights, privacy, safety, or property, or
                        the public; or (v) to enforce the terms of any agreement or the terms of our website.
                    </p>
                </section>

                <section className="space-y-8">
                    <h3 className="font-bold">DATA PROTECTION AND RETENTION</h3>

                    <p>
                        We use standard security measures as required by applicable law. The transmission of information via the Internet is, unfortunately, not completely secure and despite our
                        efforts to protect your personal data, We cannot guarantee the security of the data during transmission through our websites/apps. It is important that you also play a role in
                        keeping your personal data safe and secure. When signing up for an online account, please be sure to choose an account password that would be difficult for others to guess and
                        never reveal your password to anyone else. You are responsible for keeping this password confidential and for any use of your account. If you use a shared or public computer,
                        never choose to have your login ID/email address or password remembered and make sure to log out of your account every time you leave the computer. You should also make use of
                        any privacy settings or controls We provide you in our websites/apps.
                    </p>

                    <p>
                        Retention of personal data. We keep personal data as long as it is necessary or relevant for the practices described in this Notice. We also keep personal data as otherwise
                        required by law.
                    </p>
                </section>

                <section className="space-y-8">
                    <h3 className="font-bold">YOUR CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR PERSONAL DATA</h3>

                    <p>We strive to provide you with choices regarding the personal data that you provide to Us. The following mechanisms give you the following control over your personal data:</p>

                    <p>
                        Advertising, marketing, and promotions. You can opt out of marketing emails by following the instructions provided in each such communication. Please note that even if you opt
                        out from receiving marketing communications, you will still receive transactional communications from Us, such as order or other transaction confirmations, notifications about
                        your account activities (e.g., account confirmations, password changes, confirmation of orders and submission, etc.), and other important non-marketing-related announcements.
                    </p>

                    <p>Cookies, tracking tools, and targeted advertising. To learn how to manage how We and our vendors use cookies and other tracking tools, please read our Do Not Track Policy.</p>
                </section>

                <section className="space-y-8">
                    <h3 className="font-bold">THIRD-PARTY WEBSITES AND SERVICES</h3>

                    <p>
                        We may link to third-party websites or apps, including social media platforms. This Notice does not apply to, and We are not responsible for, the privacy practices of these
                        third-party websites or apps. Please read their privacy policies carefully.
                    </p>
                </section>

                <section className="space-y-8">
                    <h3 className="font-bold">YOUR CALIFORNIA PRIVACY RIGHTS</h3>

                    <p>California residents may also take advantage of the following rights:</p>

                    <ul className="ml-5 md:ml-10 list-disc space-y-4">
                        <li>
                            <p>
                                <span className="font-semibold">Access:</span> You may request, up to two times each year, that We disclose to you the personal data (i.e., “personal information,” as
                                the California Consumer Privacy Act (“CCPA”) defines the term) that We collect, use, and disclose about you. In response to a verified request, We will provide (1) the
                                categories and specific pieces of personal data that we have collected, (2) the categories of sources from which that data is collected, (3) the business or commercial
                                purpose for collecting it, (4) the categories of third parties with whom We shared that data, and (5) the business or commercial purpose for sharing that data.
                            </p>
                        </li>

                        <li>
                            <p>
                                <span className="font-semibold">Delete:</span> You may request that We delete any personal data that We have collected from or about you. Note that there are some
                                reasons we will not be able to fully address your request, such as if We need to complete a transaction for you, to detect and protect against fraudulent and illegal
                                activity, to exercise our rights, to use the data for solely internal purposes, or to comply with a legal obligation.
                            </p>
                        </li>
                    </ul>

                    <p>
                        To take advantage of any of these rights, or if you have any questions or concerns, please contact Us by email or our contact page on our website. For verification purposes, We
                        may request your first and last name, email address and another email address as well as devices you may have used when registering with Us, including your phone number or any
                        other phone number used at registration and a physical mailing address. If you are submitting on someone else’s behalf we may ask for additional verification. This may include
                        providing a signed letter verifying your right to make this request. We will not discriminate against you in response to your exercise of your privacy rights under the CCPA. We
                        will respond to your access or deletion request within 45 days of receipt of your request, after proper verification, unless we need additional time, in which case we will let
                        you know.
                    </p>

                    <p>
                        <span className="font-semibold">Sale of Information.</span> California law. We are required to tell you if We “sell” information including information of Californians under 16.
                        While We do not believe We engage in “selling” information, it is possible that certain aspects of our sharing activity may be construed as a sale. You can opt out of this
                        activity by visiting the Do Not Sell My Personal Information page. You can also opt out by emailing Us at info@mybanyanlife.com. We do not sell information about Californians
                        under 16.
                    </p>
                </section>

                <section className="space-y-8">
                    <h3 className="font-bold">CHANGES TO THIS NOTICE</h3>

                    <p>
                        If We change the way We handle your personal data, We will update this Notice. We reserve the right to make changes to our practices and this Notice at any time as permitted by
                        law. You can check back to see if there have been any updates or changes to our Notice.
                    </p>
                </section>

                <section className="space-y-8">
                    <h3 className="font-bold">CONTACT</h3>

                    <p>To contact us about this Notice and/or our privacy practices, please contact Us at info@mybanyanlife.com.</p>

                    <div>
                        <p>MY BANYAN, INC.</p>

                        <p>8 The Green STE-A</p>

                        <p>Dover, DE 19901</p>
                    </div>

                    <p>© MY BANYAN, INC. All Rights Reserved.</p>
                </section>
            </article>
        </main>
    );
}
