import { useState } from "react";

import PageTitle from "../../components/common/page-title";
import MyAccount from "../../components/my-profile/my-account";
import Subscription from "../../components/my-profile/subscription";
import BillingPlan from "../../components/my-profile/billing-information";
import { classNames } from "../../lib/classnames";

const tabs = [
    { name: "Subscription", href: "#", current: false },
    { name: "Billing & Plan Info", href: "#", current: false },
    { name: "My Profile", href: "#", current: true },
];

export default function MyProfile() {
    const [tab, setTab] = useState(1);

    return (
        <>
            <PageTitle title="My Banyan" />

            <div className="flex max-w-7xl flex-col">
                <div className="py-1">
                    {/* Tabs */}
                    <div className="border-b border-gray-300">
                        <nav className="-mb-px flex space-x-8 overflow-y-auto">
                            {tabs.map((el, idx) => (
                                <a
                                    key={el.name}
                                    href={el.href}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setTab(idx + 1);
                                    }}
                                    className={classNames(
                                        idx + 1 === tab
                                            ? "border-primary-green text-primary-green"
                                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                        "whitespace-nowrap py-4 px-1 border-b-2 font-bold text-sm"
                                    )}
                                >
                                    {el.name}
                                </a>
                            ))}
                        </nav>
                    </div>

                    {/* Description list with inline editing */}
                    {tab === 1 && <Subscription />}
                    {tab === 2 && <BillingPlan />}
                    {tab === 3 && <MyAccount />}
                </div>
            </div>
        </>
    );
}

