export const birthCertificatesData = {
  formTitle: "Add or edit birth certificate",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/birthCertificates",
  formFields: [
    {
      component: "input",
      label: "Name on certificate",
      name: "name",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "City",
      name: "city",
      type: "text",
    },
    {
      component: "input",
      label: "Date",
      name: "birthDate",
      type: "date",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Location of certificate",
      name: "certificateLocation",
      type: "text",
    },
    {
      component: "input",
      label: "Person(s) who have copies of this document",
      name: "persons",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
