export const dataSecurityMethodsData = {
  formTitle: "Add or edit my data security method",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/dataSecurityMethods",
  formFields: [
    {
      component: "mc",
      label: "Which data am I most worried about losing?",
      name: "worried",
      colObject: true,
      values: [
        "Bank accounts",
        "Bank ATM / debit cards",
        "Business records",
        "Contact information on friends",
        "Credit accounts",
        "Credit card number",
        "Credit information",
        "Email accounts",
        "Emails",
        "Financial records",
        "Investment accounts",
        "Legal documents",
        "Medical records",
        "Passwords, PIN numbers",
        "Personal identification",
        "Photos and videos",
        "Social media accounts",
        "Other",
      ],
    },
    {
      component: "input",
      label: "Most recent security occurrences",
      name: "occurrences",
      type: "text",
    },
    {
      component: "input",
      label: "Significant losses",
      name: "losses",
      type: "text",
    },
    {
      component: "mc",
      label: "These methods I need to use in the future to protect my data",
      name: "utilize",
      values: [
        "Encrypted cell phone",
        "Encrypted cloud service",
        "Encrypted hard drive",
        "Routine data backup",
        "Spam filter on email accounts",
        "Strong passwords",
        "Software: Identity theft protection",
        "Software: Malware protection",
        "Software: Password manager",
        "Software: VPN (Virtual Private Network)",
        "Two factor authentication (2FA)",
        "Up-to-date computer operating systems",
        "Other",
      ],
    },
    {
      component: "input",
      label: "Target date to implement new methods",
      name: "date",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};

