export const advancedDirectivesData = {
  formTitle: "Add or edit advanced directive",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/advancedDirectives",
  formFields: [
    {
      component: "select",
      label: "Type of health care directive",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        {
          value: "DNR form (Do Not Resuscitate)",
          text: "DNR form (Do Not Resuscitate)",
        },
        {
          value: "Durable Power of Attorney for Health Care",
          text: "Durable Power of Attorney for Health Care",
        },
        {
          value: "Healthcare / Medical POA",
          text: "Healthcare / Medical POA",
        },
        { value: "Healthcare Proxy", text: "Healthcare Proxy" },
        { value: "HIPAA authorization form", text: "HIPAA authorization form" },
        { value: "Living Will", text: "Living Will" },
        {
          value: "Organ donation registration",
          text: "Organ donation registration",
        },
        {
          value: "POLST form (Physicians Orders for Life Sustaining Treatment)",
          text: "POLST form (Physicians Orders for Life Sustaining Treatment)",
        },
      ],
    },
    {
      component: "mc",
      label: "If directive is a Living Will, check contents",
      name: "will",
      values: [
        "Acceptable treatments to prolong life",
        "Unacceptable treatments to prolong life",
        "End-of-life wishes",
        "Food and water wishes",
        "Pain management wishes",
        "People who can make medical decisions",
        "Wishes about disposition of my body",
        "Other",
      ],
    },
    {
      component: "input",
      label: "Authorization / registration date",
      name: "registrationDate",
      type: "date",
    },
    {
      component: "input",
      label: "Person named in document",
      name: "person",
      type: "text",
    },
    {
      component: "input",
      label: "Person who has copies",
      name: "personCopy",
      type: "text",
    },
    {
      component: "input",
      label: "Location of posted document",
      name: "location",
      type: "text",
    },
    {
      component: "input",
      label: "Next review date",
      name: "reviewDate",
      type: "date",
    },
    {
      component: "input",
      label: "Health care professsional, if needed",
      name: "professsional",
      type: "text",
    },
    {
      component: "input",
      label: "Attorney, if needed",
      name: "attorney",
      type: "text",
    },
    {
      component: "input",
      label: "Agent / executor, if needed",
      name: "agent",
      type: "text",
    },
    {
      component: "input",
      label: "Witnesses, if needed",
      name: "witnesses",
      type: "text",
    },
    {
      component: "input",
      label: "Donation agency, if needed",
      name: "donation",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};

