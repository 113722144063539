import PageTitle from "../../components/common/page-title";

import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { ShowPassword, Text } from "../../components/auth/inputs";
import { AuthLayout } from "../../components/auth/auth-layout";
import { Button } from "../../components/auth/inputs";
import { SubmissionAlert } from "../../components/common/submission-alert";
import { VerifyConfirmationCode } from "../../components/auth/verify-confirmation-code";
import { getUserDetails, updateCurrentUser } from "../../store/actions";
import { restGet, restPut } from "../../store/api";
import { errorSignInMessages } from "../../error/message";

const SignInChallengeName = {
    NewPasswordRequired: "NEW_PASSWORD_REQUIRED",
    SmsMfa: "SMS_MFA",
    SoftwareTokenMfa: "SOFTWARE_TOKEN_MFA",
};

export default function SignIn() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.currentUser);
    const userCheck = useSelector((state) => state.auth.user);

    const type = userCheck?.type;

    const [formValues, setFormValues] = useState({
        username: "",
        password: "",
        showPassword: false,
    });

    const [req, setReq] = useState({
        user: false,
        isPending: false,
        error: null,
    });

    // Redirects from "/"" route to "/signin" route
    useEffect(() => {
        if (location.pathname === "/") navigate("/signin");
    }, []);

    // Changes the document's title and meta description
    useEffect(() => {
        document.title = "Sign In to Your Life Online | mybanyanlife.com";

        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute("content", "Continue to organize your important documents online, because you never know what tomorrow will bring.");
        } else {
            const meta = document.createElement("meta");
            meta.name = "description";
            meta.content = "Continue to organize your important documents online, because you never know what tomorrow will bring.";
            document.head.appendChild(meta);
        }
    }, []);

    const onChangeHandler = (e) => {
        e.preventDefault();

        setFormValues((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const handleShowPassword = () => {
        setFormValues((prevState) => ({
            ...prevState,
            showPassword: !prevState.showPassword,
        }));
    };

    const onSubmitHandler = async () => {
        try {
            const user = await Auth.signIn({
                username: formValues.username,
                password: formValues.password,
            });
            switch (user.challengeName) {
                case SignInChallengeName.NewPasswordRequired:
                    break;
                case SignInChallengeName.SmsMfa:
                    break;
                case SignInChallengeName.SoftwareTokenMfa:
                    dispatch(updateCurrentUser(user));
                    navigate("/two-factor", { replace: true });
                    break;
                default:
                    dispatch(updateCurrentUser(user));
                    break;
            }
        } catch (error) {
            if (error.message === errorSignInMessages.USER_NOT_COMFIRM) {
                setReq((prevState) => ({
                    ...prevState,
                    user: true,
                    isPending: false,
                }));
            } else if (error.message === errorSignInMessages.PASSWORD_ATTEMPT_EXCEED) {
                setReq((prevState) => ({
                    ...prevState,
                    error: "You have entered incorrectly 5 times, please try again in 5 minutes.",
                    isPending: false,
                }));
            } else {
                setReq((prevState) => ({
                    ...prevState,
                    error: error.message,
                    isPending: false,
                }));
            }
        }
    };

    const setShowPopup = () => {
        const reqBody = { showingPopup: true };
        restPut("/users/update", reqBody, (data) => dispatch(getUserDetails(data), (err) => console.error(err)));
    };

    const handleClick = () => {
        if (!location.state === null) {
            navigate("/signup", {
                state: {
                    pathname: location.state.pathname,
                },
            });
        } else {
            navigate("/signup");
        }
    };

    useEffect(() => {
        if (userCheck) {
            let path = "/";
            if (type === "readonly") {
                path = "/sharing";
            }
            if (location.state && location.state.pathname) {
                path = location.state.pathname;
            }
            setShowPopup();
            navigate(path, { replace: true });
        }
    }, [userCheck]);

    useEffect(() => {
        if (user) {
            restGet(
                "/users/show",
                (data) => dispatch(getUserDetails(data)),
                (err) => console.error(err)
            );
        }
    }, [user]);

    return (
        <>
            <PageTitle title="Sign in to My Banyan and Start Organizing Your Life" />

            {req.user ? (
                <VerifyConfirmationCode username={formValues.username} subtitle={{ text: "go back to sign in", href: "/signin" }} />
            ) : (
                <AuthLayout title={"Sign in to your account"} state={location.state ? location.state : null} bgImg={"signin"}>
                    {req.error && <SubmissionAlert type={"error"} message={req.error} />}
                    <form
                        className="space-y-6"
                        onSubmit={(e) => {
                            e.preventDefault();
                            setReq((prevState) => ({
                                ...prevState,
                                isPending: true,
                            }));
                            onSubmitHandler();
                        }}
                    >
                        <div className="space-y-1">
                            <Text name="username" type="email" value={formValues.username} onChange={onChangeHandler} required label="Email *" autoComplete="new-password" />
                        </div>

                        <div className="relative space-y-1">
                            <Text name="password" type={formValues.showPassword ? "text" : "password"} value={formValues.password} onChange={onChangeHandler} required label="Password *" autoComplete="new-password" />

                            <ShowPassword handleShowPassword={handleShowPassword} showPassword={formValues.showPassword} />
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="text-sm">
                                <Link to="/reset-password" className="font-light text-gray-50 hover:text-gray-700 underline">
                                    Forgot your password?
                                </Link>
                            </div>
                        </div>

                        <div>
                            <Button type="submit" isPending={req.isPending}>
                                {req.isPending ? (
                                    <>
                                        <div className="spinner-border animate-spin inline-block w-4 h-4 text-white rounded-full mr-1" role="status" />
                                        Processing...
                                    </>
                                ) : (
                                    <p className="uppercase">Sign in</p>
                                )}
                            </Button>
                        </div>
                    </form>

                    <div className="mt-8 flex flex-col items-center">
                        <p className="font-light text-sm text-gray-50 text-center">Haven't got an account yet?!</p>
                        <div className="w-full mt-5">
                            <Button onClick={handleClick} className="w-full flex justify-center py-2 px-4 border-2 disabled:opacity-75 font-medium border-white hover:bg-indigo-500 rounded-md lg:shadow-md text-sm text-white bg-secondary-green focus:outline-none">
                                Register a new account
                            </Button>
                        </div>
                    </div>
                </AuthLayout>
            )}
        </>
    );
}

