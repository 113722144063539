export const accomplishmentsData = {
  formTitle: "Add or edit accomplishment",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/accomplishments",
  formFields: [
    {
      component: "select",
      label: "Type",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        {
          value: "Artist: painting, sculpture",
          text: "Artist: painting, sculpture",
        },
        { value: "Awards", text: "Awards" },
        {
          value: "Author: books, plays, articles",
          text: "Author: books, plays, articles",
        },
        { value: "Business success", text: "Business success" },
        { value: "Community service", text: "Community service" },
        { value: "Education: degrees, research, publications", text: "Education: degrees, research, publications" },
        {
          value: "Financial: wealth, security",
          text: "Financial: wealth, security",
        },
        { value: "Handicaps: physical, mental", text: "Handicaps: physical, mental" },
        { value: "Inventions / patents", text: "Inventions / patents" },
        { value: "Military honors", text: "Military honors" },
        { value: "Movies / videos written, produced", text: "Movies / videos written, produced" },
        {
          value: "Music: singer, musician, composer",
          text: "Music: singer, musician, composer",
        },
        { value: "Philanthropic", text: "Philanthropic" },
        {
          value: "Positions: appointed, elected",
          text: "Positions: appointed, elected",
        },
        {
          value: "Relationships: personal, family, social",
          text: "Relationships: personal, family, social",
        },
        { value: "Professional successes", text: "Professional successes" },
        { value: "Sports / recreation", text: "Sports / recreation" },
        { value: "Teaching / mentoring", text: "Teaching / mentoring" },
        { value: "Travel / adventure", text: "Travel / adventure" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Description",
      name: "description",
      type: "text",
    },
    {
      component: "input",
      label: "Dates",
      name: "dates",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
