export const intellectualPropertyData = {
  formTitle: "Add or edit intellectual property",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/intellectualProperty",
  formFields: [
    {
      component: "select",
      label: "Type of intellectual property",
      name: "type",
      type: "text",
      colObject: true,
      options: [
        { value: "Copyright", text: "Copyright" },
        { value: "Industrial Design", text: "Industrial Design" },
        { value: "Patent", text: "Patent" },
        { value: "Trade Dress", text: "Trade Dress" },
        { value: "Trade Secret", text: "Trade Secret" },
        { value: "Trademark", text: "Trademark" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Name of intellectual property",
      name: "name",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Reference number",
      name: "referenceNumber",
      type: "text",
    },
    {
      component: "input",
      label: "Description",
      name: "description",
      type: "text",
    },
    {
      component: "input",
      label: "Owner(s)",
      name: "owners",
      type: "text",
    },
    {
      component: "input",
      label: "Date of origination",
      name: "originationDate",
      type: "date",
    },
    {
      component: "input",
      label: "Expiration date",
      name: "expirationDate",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
