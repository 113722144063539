import { useNavigate } from "react-router-dom";

const bgImages = {
    signin: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/auth/sign-in-bg2.jpeg",
    signup: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/auth/sign-up-bg.jpeg",
    forgotpassword:
        "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/auth/forgot-password-bg.jpeg",
    default:
        "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/auth/AdobeStock_157470283.jpeg",
};

export const AuthLayout = ({ title, subtitle, state, children, bgImg }) => {
    const navigate = useNavigate();

    const pathname = window.location.pathname;

    const redirect = () => {
        navigate(subtitle.href, { state });
    };

    return (
        <div className="flex min-h-screen">
            <div className='flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 lg:bg-none lg:bg-primary-green bg-[url("https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/auth/mobile-screen-2.jpeg")]'>
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <div className="flex flex-col">
                            <div className="mb-3">
                                <img
                                    className="mx-auto h-10 w-auto"
                                    src="https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/auth/whitebanyan_icon.svg"
                                    alt="Workflow"
                                />
                            </div>
                            <div>
                                <img
                                    className="mx-auto h-3 w-auto"
                                    src="https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/auth/mybanyan_whtltext.svg"
                                    alt="Workflow"
                                />
                            </div>
                        </div>

                        <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-50 text-center">
                            {title}
                        </h2>
                        {subtitle && (
                            <p className="mt-2 text-sm text-gray-50 text-center">
                                {subtitle.text}{" "}
                                <a
                                    href={subtitle.href}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirect();
                                    }}
                                    className="font-light text-gray-50 hover:text-gray-700 underline"
                                >
                                    {subtitle.linkText}
                                </a>
                            </p>
                        )}
                    </div>

                    <div className="mt-8">
                        <div className="mt-6">{children}</div>
                    </div>
                </div>
            </div>
            <div className="relative hidden w-0 flex-1 lg:block">
                <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src={`${
                        bgImages[bgImg] ? bgImages[bgImg] : bgImages.default
                    }`}
                    alt=""
                />
                {(pathname === "/signin" || pathname === "/") && (
                    <p className="absolute top-[35%] w-full text-2xl xl:text-4xl 2xl:text-5xl text-center text-white font-bold">
                        Do more than organize.
                        <br />
                        Banyanize!
                    </p>
                )}
            </div>
        </div>
    );
};

