export const checklistPageData = {
  myUnexpected: {
    description: [
      "These five downloadable PDF articles and checklists are invaluable resources that cover a range of important life situations. By printing out these checklists and sharing them with family members or trusted advisors, or simply placing them on your refrigerator as a reminder, you can have peace of mind knowing you are well-prepared for emergencies, identity theft, end-of-life-planning, and farewells.",
    ],
    pdfs: [
      {
        img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/how-to-manage-id-theft.png",
        imgAlt: "How to Manage Identity Theft Article",
        link: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/HOW+TO+MANAGE+IDENTITY+THEFT.pdf",
      },
      {
        img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/planning-medical-emergency.png",
        imgAlt: "Planning for my Medical Emergency Article",
        link: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/PLANNING+FOR+MY+MEDICAL+EMERGENCY.pdf",
      },
      {
        img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/what-to-do-after-I-die.png",
        imgAlt: "What To Do After I Die Checklist",
        link: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/WHAT+TO+DO+AFTER+I+DIE.pdf",
      },
      {
        img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/disasters-adn-emergency.png",
        imgAlt: "Disasters and Emergency Checklist",
        link: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/DISASTERS+AND+EMERGENCY+CHECKLIST.pdf",
      },
      {
        img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/coordinating-farewell.png",
        imgAlt: "Coordinating a Farewell Ceremony Checklist",
        link: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/COORDINATING+A+FAREWELL+CEREMONY+.pdf",
      },
    ],
  },
  medicalEmergency: {
    description: [
      "A medical emergency can strike unexpectedly and be life changing. In the downloadable PDF below you will find a helpful list to help you plan for some of these medical events that you could anticipate.",
    ],
    pdfs: [
      {
        img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/planning-medical-emergency.png",
        imgAlt: "Planning for my Medical Emergency Article",
        link: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/PLANNING+FOR+MY+MEDICAL+EMERGENCY.pdf",
      },
    ],
  },
  estatePlanning101: {
    description: [
      `Introducing our downloadable PDF article, "Estate Planning 101." This informative guide delves into the vital process of estate planning, which involves crafting documents to ensure your assets are distributed as per your desires in the future. Discover a simple three-step approach to kickstart your estate planning journey, demystifying the process and helping you navigate potential complexities. Learn about the significance of probate and gain insights into the seamless transfer of your legacy. Begin securing your loved ones' financial future by downloading the article now.`,
    ],
    boldDescription: [`Read this article online or download.`],
    pdfs: [
      {
        img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/estate-planning-101.png",
        imgAlt: "Estate Planning 101 Article",
        link: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/Estate+Planning+101.pdf",
      },
    ],
  },
  relocation: {
    description: [
      "If you have decided to relocate to a different residence within 7-10 days or 3-5 years, use the different checklists below for guidance on important areas where you need to make decisions and take action.",
    ],
    pdfs: [
      {
        img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/relocation-list-7-10.png",
        imgAlt: "Relocation Checklist 7 to 10 Days",
        link: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/RELOCATION+CHECKLIST-+7-10+DAYS.pdf",
      },
      {
        img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/relocation-1-2.png",
        imgAlt: "Relocation Checklist 1 to 2 Months",
        link: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/RELOCATION+CHECKLIST-+1-2+MONTHS.pdf",
      },
      {
        img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/relocation-list-9-12.png",
        imgAlt: "Relocation Checklist 9 to 12 Months",
        link: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/RELOCATION+CHECKLIST-+9-12+MONTHS.pdf",
      },
    ],
  },
  retirement: {
    description: [
      "This downloadable PDF is your essential guide if retirement is on the horizon within the next three to five years. This comprehensive checklist serves as a roadmap, offering invaluable guidance on crucial decisions and considerations. This indispensable resource equips you with the tools and insights needed to approach your impending retirement with confidence and meticulous planning.",
    ],
    pdfs: [
      {
        img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/retirement-checklist.png",
        imgAlt: "Retirement Checklist 3 to 5 Years",
        link: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/RETIREMENT+CHECKLIST-+3-5+YEARS+BEFORE.pdf",
      },
    ],
  },
  legalDocs: {
    description: [
      "Below, you will find the legal documents for your review, including the Privacy Policy, Terms of Service, and the End User Agreement. Your careful examination of these documents is greatly appreciated.",
    ],
    pdfs: [
      {
        img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/Privacy-policy.png",
        imgAlt: "Privacy Policy Checklist",
        link: "legal-docs/privacy",
      },
      {
        img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/Terms-of-Service.png",
        imgAlt: "Terms of Service Checklist",
        link: "legal-docs/terms-of-service",
      },
      {
        img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/end-user-agreement.png",
        imgAlt: "End User Agreement Checklist",
        link: "legal-docs/end-user-agreement",
      },
    ],
  },
};
