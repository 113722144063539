import { Auth } from "aws-amplify";
import { TYPES } from "./types";

// Auth
export const updateCurrentUser = (user) => {
    return { type: TYPES.UPDATE_CURRENT_USER, payload: user };
};
export const signOut = () => async (dispatch) => {
    try {
        await Auth.signOut();
        dispatch(updateCurrentUser(null));
    } catch (error) {
        console.error(error);
    }
};
export const getUserDetails = (user) => {
    return { type: TYPES.GET_USER_DETAILS, payload: user };
};

// Topic
export const updateTopicByAll = (topics) => {
    return { type: TYPES.UPDATE_TOPIC_BY_ALL, payload: topics };
};
export const updateTopicById = (topic) => {
    return { type: TYPES.UPDATE_TOPIC_BY_ID, payload: topic };
};
export const setTopicToggle = () => {
    return { type: TYPES.SET_TOPIC_TOGGLE };
};

// Calendar
export const updateCalendarByAll = (events) => {
    return { type: TYPES.UPDATE_CALENDAR_BY_ALL, payload: events };
};
export const updateCalendarById = (event) => {
    return { type: TYPES.UPDATE_CALENDAR_BY_ID, payload: event };
};
export const calendarSetToggle = () => {
    return { type: TYPES.CALENDAR_SET_TOGGLE };
};

// Todo
export const updateTodoByAll = (events) => {
    return { type: TYPES.UPDATE_TODO_BY_ALL, payload: events };
};
export const updateTodoById = (event) => {
    return { type: TYPES.UPDATE_TODO_BY_ID, payload: event };
};
export const todoSetToggle = () => {
    return { type: TYPES.TODO_SET_TOGGLE };
};

// File Upload
export const updateFileUploadByAll = (files) => {
    return { type: TYPES.UPDATE_FILE_UPLOAD_BY_ALL, payload: files };
};
export const updateFileUploadById = (file) => {
    return { type: TYPES.UPDATE_FILE_UPLOAD_BY_ID, payload: file };
};

// Sharing
export const updateSharingByAll = (shares) => {
    return { type: TYPES.UPDATE_SHARING_BY_ALL, payload: shares };
};
export const updateSharingById = (share) => {
    return { type: TYPES.UPDATE_SHARING_BY_ID, payload: share };
};

// Inner Circle
export const updateInnerCircleByAll = (contacts) => {
    return { type: TYPES.UPDATE_INNER_CIRCLE_BY_ALL, payload: contacts };
};
export const updateInnerCircleById = (contact) => {
    return { type: TYPES.UPDATE_INNER_CIRCLE_BY_ID, payload: contact };
};

// Contact
export const updateContactByAll = (contacts) => {
    return { type: TYPES.UPDATE_CONTACT_BY_ALL, payload: contacts };
};
export const updateContactById = (contact) => {
    return { type: TYPES.UPDATE_CONTACT_BY_ID, payload: contact };
};

// Notification
export const updateNotificationByAll = (notifications) => {
    return { type: TYPES.UPDATE_NOTIFICATION_BY_ALL, payload: notifications };
};

export const updateNotificationById = (notification) => {
    return { type: TYPES.UPDATE_NOTIFICATION_BY_ID, payload: notification };
};

// Multiple checkbox
export const updateMultipleCheckboxByAll = (multipleCheckboxs) => {
    return {
        type: TYPES.UPDATE_MULTIPLE_CHECKBOX_BY_ALL,
        payload: multipleCheckboxs,
    };
};

export const updateMultipleCheckboxById = (multipleCheckbox) => {
    return {
        type: TYPES.UPDATE_MULTIPLE_CHECKBOX_BY_ID,
        payload: multipleCheckbox,
    };
};

// Type
export const updateTypeByAll = (types) => {
    return { type: TYPES.UPDATE_TYPE_BY_ALL, payload: types };
};

