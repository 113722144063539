export function SharedList({ people, removeItem }) {
  return (
    <div>
      <div className='my-6 flow-root'>
        <ul className='-my-5 divide-y divide-gray-300'>
          {people.map((person) => (
            <li key={person.email} className='py-4'>
              <div className='flex items-center space-x-4'>
                <div className='flex-shrink-0'>
                  <img
                    className='h-8 w-8 rounded-full'
                    src={person.defaultFile}
                    alt=''
                  />
                </div>
                <div className='min-w-0 flex-1'>
                  <p className='truncate text-sm font-medium text-gray-900'>
                    {person.firstName} {person.lastName}
                  </p>
                  <p className='truncate text-sm text-gray-500'>
                    {person.email}
                  </p>
                </div>
                <div>
                  <a
                    href='/'
                    onClick={(e) => {
                      e.preventDefault();
                      removeItem(person.email);
                    }}
                    className='inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50'
                  >
                    Remove
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
