import { useLocation } from "react-router-dom";

import PageTitle from "../../components/common/page-title";
import { InnerCircleList } from "../../components/inner-circle/list";
import { InnerCircleForm } from "../../components/inner-circle/form";
import InnerCircleView from "../../components/inner-circle/view";
import { Share } from "../../lib/share";

export default function InnerCircle({ page }) {
    const location = useLocation();

    const pathnames = location.pathname.split("/");
    const pathname = "/" + pathnames[2];

    return (
        <>
            <PageTitle title="My Banyan" />

            {page === "list" && <InnerCircleList />}
            {page === "new" && <InnerCircleForm />}
            {page === "share" && <Share pathname={pathname} />}
            {page === "edit" && <InnerCircleForm />}
            {page === "view" && <InnerCircleView />}
        </>
    );
}

