import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { restPost, restPut } from "../../store/api";
import { Toastr } from "../common/toastr";

import WhiteBtn from "../buttons/whiteButton";
import GreenBtn from "../buttons/greenBtn";

export default function Submit({
    pathname,
    reqBody,
    initialValues,
    innerCircle,
    children,
}) {
    const navigate = useNavigate();

    const user = useSelector((state) => state.auth.user);
    const currentUser = useSelector((state) => state.auth.currentUser);

    const nameSend = currentUser.attributes.name;

    const successCallback = (message, data) => {
        const id = data._id;
        navigate(`/contacts/edit?id=${id}&tab=2`);
        Toastr({
            type: "success",
            message,
        });
    };

    const failCallback = (err) => {
        console.error(err);
        Toastr({ type: "error", message: "Error with contact." });
    };

    const onSubmit = async (values) => {
        const result = reqBody(values);
        result.innerCircle = innerCircle;
        result.nameSend = nameSend;

        if (initialValues) {
            await restPut(
                pathname,
                initialValues,
                (data) => successCallback("Contact successfully edited.", data),
                failCallback
            );
        } else {
            await restPost(
                pathname,
                result,
                (data) => {
                    successCallback("Contact successfully created.", data);
                },
                failCallback
            );
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form
                    onSubmit={handleSubmit}
                    className="space-y-8 divide-y divide-gray-300 mb-6 -mt-2"
                >
                    <div className="space-y-8 divide-y divide-gray-300 sm:space-y-5">
                        {children}
                    </div>

                    <div className="pt-5">
                        <div className="flex justify-end">
                            <WhiteBtn
                                type="button"
                                disabled={submitting}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/contacts");
                                }}
                            >
                                Cancel
                            </WhiteBtn>

                            <GreenBtn
                                type="submit"
                                disabled={
                                    user === null ||
                                    user.type === "readonly" ||
                                    submitting
                                }
                            >
                                Save
                            </GreenBtn>
                        </div>
                    </div>
                </form>
            )}
        />
    );
}

