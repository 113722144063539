export const reflectionsOnMyAdultLifeData = {
  formTitle: "Add or edit influential people in my life",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/reflectionsOnMyAdultLife",
  formFields: [
    {
      component: "select",
      label: "Moments that I reflect on",
      name: "moments",
      type: "text",
      required: true,
      colObject: true,
      options: [
        {
          value: "Greatest challenges I have faced",
          text: "Greatest challenges I have faced",
        },
        { value: "Happiest times", text: "Happiest times" },
        {
          value: "How fear blocks me from taking action",
          text: "How fear blocks me from taking action",
        },
        {
          value: "How I feel about expressing affection to others",
          text: "How I feel about expressing affection to others",
        },
        { value: "How I gained wisdom", text: "How I gained wisdom" },
        {
          value: "How I have given back to my community",
          text: "How I have given back to my community",
        },
        {
          value: "How I keep my life balanced",
          text: "How I keep my life balanced",
        },
        {
          value: "How I resolve conflicts with others",
          text: "How I resolve conflicts with others",
        },
        {
          value: "How I set goals in my life",
          text: "How I set goals in my life",
        },
        {
          value: "Relationships that have mattered to me",
          text: "Relationships that have mattered to me",
        },
        {
          value: "Spiritual beliefs that guide me",
          text: "Spiritual beliefs that guide me",
        },
        { value: "Values that I practice", text: "Values that I practice" },
        {
          value: "What I am most curious about",
          text: "What I am most curious about",
        },
        {
          value: "How I show love to my partner/spouse",
          text: "How I show love to my partner/spouse",
        },
        {
          value: "What is on my bucket list",
          text: "What is on my bucket list",
        },
        {
          value: "What it is/was like to be married",
          text: "What it is/was like to be married",
        },
        {
          value: "What it is/was like to be a parent",
          text: "What it is/was like to be a parent",
        },
        {
          value: "What matters most in my life",
          text: "What matters most in my life",
        },
        {
          value: "What retirement feels like",
          text: "What retirement feels like",
        },
        {
          value: "What worries me most about the future",
          text: "What worries me most about the future",
        },
        {
          value: "What I need to do more of to be happier",
          text: "What I need to do more of to be happier",
        },
        { value: "When I have felt alone", text: "When I have felt alone" },
        { value: "Other", text: "Other" }
      ],
    },
    {
      component: "input",
      label: "Description",
      name: "description",
      type: "text",
    },
    {
      component: "input",
      label: "My age",
      name: "age",
      type: "number",
    },
    {
      component: "input",
      label: "Who was there",
      name: "who",
      type: "text",
    },
    {
      component: "input",
      label: "Where and when",
      name: "whereWhen",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
  ],
};
