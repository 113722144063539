import { Field } from "react-final-form";

export const Button = ({ text, ...props }) => {
  return (
    <button
      {...props}
      type="button"
      className="px-2.5 py-0.5 rounded-md bg-secondary-green font-medium text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
    >
      {text}
    </button>
  );
};

export const FormButton = ({ text, ...props }) => {
  return (
    <div className="sm:col-span-6">
      <button
        {...props}
        className="group relative flex w-full justify-center rounded-md border border-transparent bg-secondary-green py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        {text}
      </button>
    </div>
  );
};

export const Text = ({ label, size, ...props }) => {
  return (
    <div className={size}>
      <label
        htmlFor="search"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="relative mt-1 flex items-center">
        <Field
          {...props}
          component="input"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
    </div>
  );
};

export const Checkbox = ({ label, size, ...props }) => {
  return (
    <div className={`${size} flex`}>
      <div className="pr-3 flex h-5 items-center">
        <Field
          {...props}
          component="input"
          type="checkbox"
          className="h-4 w-4 rounded border-2 border-gray-700 text-indigo-600 focus:ring-indigo-500"
        />
      </div>
      <label
        htmlFor="checkbox"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
    </div>
  );
};

export const Textarea = ({ label, size, ...props }) => {
  return (
    <div className={size}>
      <label
        htmlFor="search"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="relative mt-1 flex items-center">
        <Field
          {...props}
          component="textarea"
          rows="5"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
    </div>
  );
};

export const Fieldset = ({ title, subtitle, children }) => {
  return (
    <div className="px-4 py-5 sm:p-6">
      <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
      <div className="mt-2 max-w-xl text-sm text-gray-500">
        <p>{subtitle}</p>
      </div>
      {children}
    </div>
  );
};

