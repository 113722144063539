function RedBtn({ type, disabled, onClick, children }) {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className="mx-1 px-4 py-2 text-sm font-medium bg-red-500 hover:bg-white disabled:bg-white text-white hover:text-red-500 disabled:text-red-500 border-2 border-red-500 focus:outline-none rounded-md disabled:cursor-not-allowed transition duration-300"
    >
      {children}
    </button>
  );
}

export default RedBtn;
