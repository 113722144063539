import { toast } from "react-toastify";

export const Toastr = ({ type, message }) => {
  const toastProps = {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored'
  };

  switch (type) {
    case 'success':
      toast.success(message, toastProps);
      break;
    case 'info':
      toast.info(message, toastProps);
      break;
    case 'warning':
      toast.warning(message, toastProps);
      break;
    case 'error':
      toast.error(message, toastProps);
      break;
    default:
      toast(message, toastProps);
      break;
  }

  return null;
};
