/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { updateTopicByAll } from "../../store/actions";

import { restGet, restDelete } from "../../store/api";
import { restPut } from "../../store/api";
import AllRecordsPDFReport from "../../components/pdf-report/all";
import { Toastr } from "../../components/common/toastr";
import { getUserDetails } from "../../store/actions";
import DeleteModal from "../../components/topics-overview/delete-modal";
import GreenBtn from "../../components/buttons/greenBtn";
import RedBtn from "../../components/buttons/redBtn";

const listException = [
    "/eventual/estate-plans/estatePlans",
    "/about-me/my-essentials/medicareCard",
    "/about-me/my-essentials/maritalStatus",
    "/eventual/estate-plans/lastWillTestaments",
    "/eventual/estate-plans/personalLetterOfIntents",
    "/eventual/estate-plans/revocableLivingTrust",
    "/eventual/estate-plans/durablePoa",
    "/eventual/estate-plans/generalPoa",
    "/eventual/senior-life-plans/additionalSecurityIncome",
    "/eventual/legacy-wishes/valuesThatIHopeToPassOn",
    "/eventual/farewell-plans/dispositionOfMyBodies",
    "/eventual/farewell-plans",
    "/everyday/digital-world/dataSecurityMethods",
    "/everyday/insurance/socialSecurityDisability",
    "/everyday/health/bloodType",
    "/everyday/health/emergencyTreatment",
    "/everyday/digital-world/dataSecurityMethods",
    "/about-me/my-essentials/birthInfo",
    "/about-me/my-essentials/employmentStatus",
    "/about-me/my-essentials/birthCertificates",
    "/about-me/my-essentials/naturalizationCertificates",
    "/about-me/my-essentials/naturalizationCertificates",
    "/about-me/my-essentials/socialSecurityCard",
    "/eventual/farewell-plans/obituaryContents",
    "/eventual/farewell-plans/insuranceBenefits",
    "/eventual/farewell-plans/funeralHomeDirectors",
    "/eventual/farewell-plans/ceremonialPreferences",
    "/eventual/farewell-plans/burialPlotLocations",
    "/eventual/farewell-plans/graveMarkerInscriptions",
    "/eventual/farewell-plans/funeralHomeDirectors",
    "/eventual/farewell-plans/estimatedExpenses",
    "/eventual/farewell-plans/ceremonyLocations",
    "/eventual/farewell-plans/ceremonyLeaders",
    "/eventual/farewell-plans/serviceInvitationLists",
    "/eventual/farewell-plans/florists",
];

export default function TopicList({ colObject, pathname }) {
    const location = useLocation();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.topic.byAll);
    const firstData = useSelector((state) => state.topic.byId);
    const [currentData, setCurrentData] = useState({});
    const navigate = useNavigate();

    const [selectedRows, setSelectedRows] = useState({});

    const [idDelete, setIdDelete] = useState([]);
    const [openSingleDeletePopup, setOpenSingleDeletePopup] = useState(false);
    const [singleIdDelete, setSingleIdDelete] = useState("");
    const [openMultipleDeletePopup, setOpenMultipleDeletePopup] =
        useState(false);
    const [selectAllIsChecked, setSelectAllIsChecked] = useState(false);
    const [dataDownload, setDataDownload] = useState(null);

    const reportTitle = pathname.split("/index")[0].split("/")[1];
    const userEmail = useSelector(
        (state) => state.auth.currentUser.attributes.email
    );
    const userName = useSelector(
        (state) => state.auth.currentUser.attributes.name
    );
    const user = useSelector((state) => state.auth.user);

    const locationPathName = location.pathname.split("/");
    const mainBranch = locationPathName[1];
    const subBranch = locationPathName[2];
    const topicName = locationPathName[3];

    const trueKeys = Object.keys(selectedRows)
        .filter((key) => selectedRows[key] === true)
        .map((key) => parseInt(key));

    const filteredRecords = trueKeys.map((key) => data[key]);
    const updatedFilteredRecords = filteredRecords.map((record, index) => {
        const matchingDownload = dataDownload?.find(
            (download) => download?.id === record?.id
        );

        const { files, ...restOfRecord } = record;

        if (matchingDownload) {
            const fileFields = {};
            fileFields[`file ${index + 1}`] = matchingDownload.url;
            return {
                ...restOfRecord,
                ...fileFields,
            };
        }

        return restOfRecord;
    });

    const handleCheckboxClick = (event, index, id) => {
        setSelectedRows({
            ...selectedRows,
            [index]: event.target.checked,
        });

        if (idDelete.includes(id)) {
            const newIds = idDelete.filter((checkedId) => checkedId !== id);
            setIdDelete(newIds);
        } else setIdDelete((prevIds) => [...prevIds, id]);
    };

    const successCallback = (dt) => {
        if (dt.length !== currentData.length) {
            if (dt.length !== null) {
                dt.forEach((item, index) => {
                    item.id = item._id;
                });
                setCurrentData(dt);
            }

            dispatch(updateTopicByAll(dt));
        }
    };

    const successDeleteCallback = (dt) => {
        const newData = data.filter((row, index) => dt !== index);
        setCurrentData(newData);
        dispatch(updateTopicByAll(newData));

        Toastr({
            type: "success",
            message: "Data deleted successfully.",
        });

        // ! Janky way of getting data after an item is deleted
        setTimeout(function () {
            window.location.reload();
        }, 4000);
    };

    const failCallback = (err) => {
        console.error(err);
    };

    const getDataToShow = () => {
        if (pathname === "/supplementalSecurity(SSI)/index") {
            pathname = "/supplementalSecurity/index";
        }
        restGet(pathname, successCallback, failCallback);
    };

    const generateFileName = (topic) => {
        if (topic !== undefined) {
            const firstColumnValue = Array.isArray(
                topic[Object.keys(colObject)[0]]
            )
                ? topic[Object.keys(colObject)[0]][0]
                : topic[Object.keys(colObject)[0]];

            return `${reportTitle.replace(
                /([a-z])([A-Z])/g,
                "$1_$2"
            )}_${firstColumnValue}_Report`;
        }
        return "";
    };

    useEffect(() => {
        getDataToShow();
        const fetchData = () => {
            restGet(
                "/fileUploads/all",
                (data) => {
                    setDataDownload(data);
                },
                (error) => console.error(error)
            );
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSelectedRows(selectedRows);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);

    useEffect(() => {
        if (firstData && firstData.length === 0) {
            navigate(location.pathname + "/new");
        }
    });

    const deleteSingleItem = async (index) => {
        const id = index;

        if (pathname === "/supplementalSecurity(SSI)/index")
            pathname = "/supplementalSecurity/index";
        const updatedPathname = pathname.replace("/index", "/delete");

        const following = user.following;
        const filteredData = following.filter((item) => {
            return !(
                item.mainBranch === mainBranch &&
                item.subBranch === subBranch &&
                item.topicName === topicName
            );
        });

        if (data.length === 1) {
            restPut(
                "/users/update",
                {
                    following: filteredData,
                },
                (data) => dispatch(getUserDetails(data)),
                (err) => console.error(err)
            );
        }

        await restDelete(
            `${updatedPathname}/${id}`,
            {},
            () => successDeleteCallback(index),
            failCallback
        );

        setOpenSingleDeletePopup(false);
    };

    const handleSelectAllClick = (event) => {
        setSelectedRows(
            data.reduce(
                (acc, row, index) => ({
                    ...acc,
                    [index]: event.target.checked,
                }),
                {}
            )
        );

        const ids = data.map((value) => value.id);

        if (idDelete.length === ids.length) setIdDelete([]);
        else setIdDelete(ids);
    };

    const handleDeleteClick = async () => {
        const selectedIds = idDelete;

        try {
            if (pathname === "/supplementalSecurity(SSI)/index")
                pathname = "/supplementalSecurity/index";
            const updatedPathname = pathname.replace("/index", "/delete");

            await restDelete(
                `${updatedPathname}/${selectedIds.join(",")}`,
                {},
                (data) => {},
                failCallback
            );

            const newData = data.filter((row) => !selectedIds[row._id]);

            dispatch(updateTopicByAll(newData));

            setSelectedRows({});

            const following = user.following;
            const filteredData = following.filter((item) => {
                return !(
                    item.mainBranch === mainBranch &&
                    item.subBranch === subBranch &&
                    item.topicName === topicName
                );
            });

            if (data.length === 0) {
                restPut(
                    "/users/update",
                    {
                        following: filteredData,
                    },
                    (data) => dispatch(getUserDetails(data)),
                    (err) => console.error(err)
                );
            }

            Toastr({
                type: "success",
                message: "Data deleted successfully.",
            });

            setIdDelete([]);
            setSelectAllIsChecked(false);
            setOpenMultipleDeletePopup(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <div className="mt-6 mb-4 flex flex-col">
                <div className="overflow-x-auto">
                    <div className="flex justify-end">
                        <Link to={location.pathname + "/new"}>
                            <GreenBtn
                                disabled={
                                    user.type === "readonly" ||
                                    (listException.includes(
                                        location.pathname
                                    ) &&
                                        data &&
                                        data.length > 0)
                                }
                            >
                                Add a new record
                            </GreenBtn>
                        </Link>

                        {
                            <AllRecordsPDFReport
                                fileName={generateFileName(filteredRecords[0])}
                                records={updatedFilteredRecords}
                                title={reportTitle}
                                name={userName}
                                email={userEmail}
                                selectedRows={selectedRows}
                                trueKeys={trueKeys}
                            />
                        }
                    </div>

                    <div className="inline-block min-w-full py-2 align-middle md:px-0">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="w-min py-2 pl-2 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                        >
                                            <div className="w-max flex items-center gap-4">
                                                {!listException.includes(
                                                    location.pathname
                                                ) &&
                                                    currentData.length > 0 && (
                                                        <input
                                                            type="checkbox"
                                                            checked={
                                                                selectAllIsChecked
                                                            }
                                                            className="rounded border-gray-700 text-primary-green shadow-sm focus:border-primary-green focus:ring focus:ring-primary-green focus:ring-opacity-50"
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                setSelectAllIsChecked(
                                                                    !selectAllIsChecked
                                                                );
                                                                handleSelectAllClick(
                                                                    event
                                                                );
                                                            }}
                                                        />
                                                    )}

                                                <span className="relative group">
                                                    <div className="z-10 hidden group-hover:block hover:block bg-white text-black border-4 border-primary-green shadow-lg rounded p-2 w-max absolute translate-y-4">
                                                        <p className="text-sm">
                                                            Select All Records
                                                        </p>
                                                    </div>
                                                </span>
                                            </div>
                                        </th>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                        >
                                            {
                                                colObject[
                                                    Object.keys(colObject)[0]
                                                ]
                                            }
                                        </th>
                                        {Object.keys(colObject)
                                            .filter((el, index) => index > 0)
                                            .map((el) => (
                                                <th
                                                    key={uuidv4()}
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    {colObject[el]}
                                                </th>
                                            ))}
                                        <th
                                            scope="col"
                                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                        >
                                            <span className="sr-only">
                                                Edit
                                            </span>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody className="bg-white">
                                    {data &&
                                        data.length > 0 &&
                                        data.map((topic, topicIdx) => {
                                            return (
                                                <tr
                                                    key={uuidv4()}
                                                    className={
                                                        topicIdx % 2 === 0
                                                            ? undefined
                                                            : "bg-gray-50"
                                                    }
                                                >
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        <input
                                                            type="checkbox"
                                                            className="rounded border-gray-700 text-primary-green shadow-sm focus:border-primary-green focus:ring focus:ring-primary-green focus:ring-opacity-50"
                                                            checked={
                                                                selectedRows[
                                                                    topicIdx
                                                                ] || false
                                                            }
                                                            onChange={(event) =>
                                                                handleCheckboxClick(
                                                                    event,
                                                                    topicIdx,
                                                                    topic.id
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        {Array.isArray(
                                                            topic[
                                                                Object.keys(
                                                                    colObject
                                                                )[0]
                                                            ]
                                                        )
                                                            ? topic[
                                                                  Object.keys(
                                                                      colObject
                                                                  )[0]
                                                              ].map(
                                                                  (
                                                                      item,
                                                                      idx
                                                                  ) => (
                                                                      <span
                                                                          key={`array_${idx}`}
                                                                          style={{
                                                                              display:
                                                                                  "block",
                                                                          }}
                                                                      >
                                                                          {item}
                                                                          {pathname.includes(
                                                                              [
                                                                                  "/estatePlans",
                                                                              ]
                                                                          ) &&
                                                                              topic[
                                                                                  Object.keys(
                                                                                      colObject
                                                                                  )[0]
                                                                              ]
                                                                                  .length -
                                                                                  1 !==
                                                                                  idx && (
                                                                                  <br />
                                                                              )}
                                                                      </span>
                                                                  )
                                                              )
                                                            : topic[
                                                                  Object.keys(
                                                                      colObject
                                                                  )[0]
                                                              ]}
                                                    </td>
                                                    {Object.keys(colObject)
                                                        .filter(
                                                            (el, index) =>
                                                                index > 0
                                                        )
                                                        .map((el) => (
                                                            <td
                                                                key={uuidv4()}
                                                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                                            >
                                                                {Array.isArray(
                                                                    topic[el]
                                                                )
                                                                    ? topic[
                                                                          el
                                                                      ].map(
                                                                          (
                                                                              item,
                                                                              idx
                                                                          ) => (
                                                                              <span
                                                                                  key={`array_${idx}`}
                                                                              >
                                                                                  {
                                                                                      item
                                                                                  }
                                                                                  {topic[
                                                                                      el
                                                                                  ]
                                                                                      .length -
                                                                                      1 !==
                                                                                      idx &&
                                                                                      ", "}
                                                                              </span>
                                                                          )
                                                                      )
                                                                    : topic[el]}
                                                            </td>
                                                        ))}

                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <Link
                                                            to={
                                                                location.pathname +
                                                                "/view?id=" +
                                                                topic.id
                                                            }
                                                            className="text-gray-900 hover:text-[#005ac1]"
                                                        >
                                                            View
                                                            <span className="sr-only">
                                                                ,{" "}
                                                                {
                                                                    topic[
                                                                        Object.keys(
                                                                            colObject
                                                                        )[0]
                                                                    ]
                                                                }
                                                            </span>
                                                        </Link>

                                                        <span className="mx-2">
                                                            |
                                                        </span>
                                                        <Link
                                                            to={
                                                                location.pathname +
                                                                "/edit?id=" +
                                                                topic.id
                                                            }
                                                            className="text-gray-900 hover:text-[#005ac1]"
                                                        >
                                                            Edit
                                                            <span className="sr-only">
                                                                ,{" "}
                                                                {
                                                                    topic[
                                                                        Object.keys(
                                                                            colObject
                                                                        )[0]
                                                                    ]
                                                                }
                                                            </span>
                                                        </Link>
                                                        <span className="mx-2">
                                                            |
                                                        </span>
                                                        <a
                                                            onClick={() => {
                                                                setOpenSingleDeletePopup(
                                                                    true
                                                                );
                                                                setSingleIdDelete(
                                                                    topic.id
                                                                );
                                                            }}
                                                            className="text-gray-900 cursor-pointer hover:text-[#005ac1]"
                                                        >
                                                            Delete
                                                            <div
                                                                className="sr-only"
                                                                id={
                                                                    `tpId-` +
                                                                    topicIdx
                                                                }
                                                            >
                                                                {topic._id}
                                                            </div>
                                                        </a>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* POPUP TO DELETE A SINGLE ITEM */}
            <DeleteModal
                open={openSingleDeletePopup}
                setOpen={setOpenSingleDeletePopup}
            >
                <div className="text-lg space-y-6">
                    <h2>Do you really want to delete this record?</h2>

                    <div className="flex justify-evenly">
                        <GreenBtn
                            type="button"
                            onClick={() => {
                                deleteSingleItem(singleIdDelete);
                            }}
                        >
                            Yes
                        </GreenBtn>

                        <RedBtn
                            type="button"
                            onClick={() => setOpenSingleDeletePopup(false)}
                        >
                            No
                        </RedBtn>
                    </div>
                </div>
            </DeleteModal>

            {/* POPUP TO DELETE MULTIPLE ITEMS */}
            <DeleteModal
                open={openMultipleDeletePopup}
                setOpen={setOpenMultipleDeletePopup}
            >
                <div className="text-lg space-y-6">
                    <h2>Do you really want to delete these records?</h2>

                    <div className="flex justify-evenly">
                        <GreenBtn
                            type="button"
                            onClick={() => handleDeleteClick()}
                        >
                            Yes
                        </GreenBtn>

                        <RedBtn
                            type="button"
                            onClick={() => setOpenMultipleDeletePopup(false)}
                        >
                            No
                        </RedBtn>
                    </div>
                </div>
            </DeleteModal>
        </>
    );
}

