import { useState } from "react";

import RegJourneyModal from "./reg-journey-modal";

export default function Welcome({ setDialogNum }) {
    const [open, setOpen] = useState(true);

    return (
        <RegJourneyModal open={open} setOpen={setOpen}>
            <article className="h-max lg:h-[72vh] p-8 flex justify-center items-center bg-alternative-green">
                <div className="max-w-4xl flex flex-col gap-y-8 lg:gap-y-12 items-center font-custom2">
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fregistration-journey%2Fwelcome%2Fwelcome.png?alt=media&token=3d8d56f0-4fd9-424c-9db9-20117eddf8e9"
                        alt="Welcome in a fancy font"
                    />

                    <p className="text-2xl md:text-3xl text-center font-bold">
                        Thank You for Joining My Banyan.
                    </p>

                    <p className="text-xl md:text-2xl text-justify">
                        On the next page, you can begin your My Banyan
                        experience. First, you will focus on your trusted Inner
                        Circle. They will be essential to your success with My
                        Banyan. Then you will view the broad scope of My
                        Banyan's areas where you can enter important information
                        and documents. If you have any questions or comments, we
                        invite you to reach out to our Customer Support team at
                        support@mybanyanlife.com.
                    </p>

                    <div className="flex flex-col items-center gap-y-2 text-lg md:text-xl font-medium">
                        <div className="flex gap-x-10">
                            <div className="flex flex-col items-center">
                                <img
                                    src="https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/dashboard/registration-journey/welcome/P-Signature-01.svg"
                                    alt="Peter's signature"
                                    className="h-10 md:h-12"
                                />

                                <p>Peter Lewis</p>
                            </div>

                            <div className="flex flex-col items-center">
                                <img
                                    src="https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/dashboard/registration-journey/welcome/B-Signature-01.svg"
                                    alt="Brian's signature"
                                    className="h-10 md:h-12"
                                />

                                <p>Brian Harris</p>
                            </div>
                        </div>

                        <p className="md:text-lg">Co-founders of My Banyan</p>
                    </div>
                </div>
            </article>

            <section className="h-[15vh] sm:h-[10vh] px-10 flex justify-between items-center bg-alternative-green">
                <div className="hidden sm:block h-20 w-20" />

                <div className="flex gap-x-2">
                    <div className="h-4 sm:h-6 w-4 sm:w-6 bg-primary-green rounded-full" />
                    <div className="h-4 sm:h-6 w-4 sm:w-6 border-2 border-primary-green rounded-full" />
                    <div className="h-4 sm:h-6 w-4 sm:w-6 border-2 border-primary-green rounded-full" />
                    <div className="h-4 sm:h-6 w-4 sm:w-6 border-2 border-primary-green rounded-full" />
                    <div className="h-4 sm:h-6 w-4 sm:w-6 border-2 border-primary-green rounded-full" />
                </div>

                <button onClick={() => setDialogNum(1)}>
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fregistration-journey%2Fright%20arrow.svg?alt=media&token=ef3d9e22-73f8-41a8-b7d9-802f60348068"
                        alt="Green right arrow"
                        className="h-14 sm:h-20 w-14 sm:w-20"
                    />
                </button>
            </section>
        </RegJourneyModal>
    );
}
