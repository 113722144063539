import { useEffect, useMemo, useState } from "react";

import ExcelJS from "exceljs";
import moment from "moment";

import PageTitle from "../common/page-title";
import { mainBranches } from "../private-layout/data";
import { restGetSummary } from "../../store/api";

export function NetWorthSummary({ name, title }) {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [assetTotal, setAssetTotal] = useState(0);

    const handleExportExcel = async () => {
        const saveAs = require("file-saver");

        const date = moment().format("L");

        const header = `Net worth Summary Report as of ${date}`;
        const columns = [
            "Estimated net worth",
            "",
        ];

        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Net Worth Summary Report");

        // Add header row
        worksheet.mergeCells("A1:B1");
        const headerRow = worksheet.getCell("A1");
        headerRow.value = header;
        headerRow.font = { bold: true, size: 14, color: { argb: "FFFFFFFF" } };
        headerRow.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF6C8954" },
        };
        headerRow.alignment = { vertical: "middle", horizontal: "center" };

        // Add column headers
        worksheet.addRow([]);
        const columnRow = worksheet.addRow(columns);
        columnRow.eachCell((cell) => {
            cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FF808080" },
            };
            cell.alignment = { vertical: "middle", horizontal: "center" };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
        });

        // Add assets total row
        const assetTotalRow = worksheet.addRow([
            "Assets",
            `${assetTotal}`,
        ]);
        assetTotalRow.eachCell((cell, colNumber) => {
            cell.font = { bold: true, color: { argb: "000000" } };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFFFF" },
            };
            cell.alignment = { vertical: "middle", horizontal: "right" };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
        });

        // Add liabilites total row
        const liabilitiesTotalRow = worksheet.addRow([
            "Liabilities",
            `${total}`,
        ]);
        liabilitiesTotalRow.eachCell((cell, colNumber) => {
            cell.font = { bold: true, color: { argb: "000000" } };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFFFF" },
            };
            cell.alignment = { vertical: "middle", horizontal: "right" };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
        });

        const networthTotal = getTotal(assetTotal, total)

        // Add net worth total row
        const networthTotalRow = worksheet.addRow([
            "Net Worth",
            `$${networthTotal}`,
        ]);
        networthTotalRow.eachCell((cell, colNumber) => {
            cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FF6C8954" },
            };
            cell.alignment = { vertical: "middle", horizontal: "right" };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
        });

        worksheet.columns = [
            { width: 20 }, // Branch
            { width: 30 }, // Blank
        ];

        // Generate the Excel file
        const buffer = await workbook.xlsx.writeBuffer();

        // Create a Blob from the ArrayBuffer
        const excelBlob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Trigger file download
        saveAs(excelBlob, `Liability_Summary_Report_${date}.xlsx`);
    };

    const liabilityTopics = useMemo(() => {
        const branch = mainBranches.find(
            (branch) => branch.path === "everyday"
        );
        const subBranch = branch?.subBranches.find(
            (subBranch) => subBranch.path === "finances"
        );
        const topics = subBranch?.topics || [];
        const filteredTopics = topics.filter(topic => topic.name.includes("LIABILITIES"));

        return filteredTopics;
    }, []);

    const formattedTotal = (total) => {
        let totalWithoutCommas = total;
        if (typeof total === "string") {
            totalWithoutCommas = total.replace(/,/g, "");
        }

        if (isNaN(parseFloat(totalWithoutCommas))) {
            return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(0);
        }

        const convertTotal = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(totalWithoutCommas);

        return convertTotal;
    };

    const calculateTotal = (data) => {
        let total = 0;

        data.forEach((item) => {
            if (item.estimatedValue) {
                let estimatedValueStr = item.estimatedValue
                if (typeof estimatedValueStr === "string") {
                    estimatedValueStr = estimatedValueStr.replace(/\$|,/g, "");
                }
    
                let estimatedValue = parseFloat(estimatedValueStr);

                if (!isNaN(estimatedValue)) {
                    total += estimatedValue;
                }
            }
        });

        setTotal(formattedTotal(total));
    }

    const modelLiabilityTopics = liabilityTopics
        .map((item) => item.path)
        .concat();

    const financesTopics = useMemo(() => {
        const branch = mainBranches.find(
            (branch) => branch.path === "everyday"
        );
        const subBranch = branch?.subBranches.find(
            (subBranch) => subBranch.path === "finances"
        );
        return subBranch?.topics || [];
    }, []);

    const filteredTopics = financesTopics.filter((topic) =>
        topic.name.startsWith("ASSETS")
    );
    const modelFinancesTopics = filteredTopics
        .map((item) => item.path)
        .concat("personalInsurance");

    const addLifePersonalInsurance = (data) => {
        const hasWholeLife = data.some(
            (item) =>
                item.topic.toLowerCase() === "personalinsurance" &&
                item.type &&
                item.type.toLowerCase() === "whole life"
        );

        if (!hasWholeLife) {
            data.push({
                asset: "",
                branch: "finances",
                estimateValue: "",
                id: "",
                topic: "PersonalInsurance",
                type: "Whole Life",
            });
        }

        const hasTermLife = data.some(
            (item) =>
                item.topic.toLowerCase() === "personalinsurance" &&
                item.type &&
                item.type.toLowerCase() === "term life"
        );

        if (!hasTermLife) {
            data.push({
                asset: "",
                branch: "finances",
                estimateValue: "",
                id: "",
                topic: "PersonalInsurance",
                type: "Term Life",
            });
        }

        return data;
    };

    const removeNonLifePersonalInsurance = (data) => {
        const dataWithoutNonLifePersonalInsurance = data.filter((item) => {
            return (
                item.topic.toLowerCase() !== "personalinsurance" ||
                (item.type && item.type.toLowerCase() === "whole life") ||
                (item.type && item.type.toLowerCase()) === "term life"
            );
        });

        return dataWithoutNonLifePersonalInsurance;
    };

    const sortLifePersonalInsurance = (data) => {
        return data.sort((a, b) => {
            if (
                a.topic.toLowerCase() === "personalinsurance" &&
                b.topic.toLowerCase() === "personalinsurance"
            ) {
                if (
                    a.type.toLowerCase() === "whole life" &&
                    b.type.toLowerCase() === "term life"
                ) {
                    return 1;
                }
                if (
                    a.type.toLowerCase() === "term life" &&
                    b.type.toLowerCase() === "whole life"
                ) {
                    return -1;
                }
            }
            return 0;
        });
    };

    const formatTypes = (data) => {
        const typesWithLifePersonalInsurance = addLifePersonalInsurance(data);

        const typesWithoutNonLifePersonalInsurance =
            removeNonLifePersonalInsurance(typesWithLifePersonalInsurance);

        const typesSortedByLifePersonalInsurance = sortLifePersonalInsurance(
            typesWithoutNonLifePersonalInsurance
        );

        return typesSortedByLifePersonalInsurance;
    };

    const calculateAssetTotal = (data) => {
        const filteredData = data.filter(item => 
            item.topic !== "PersonalInsurance" &&
            item.topic !== "Lease" &&
            item.topic !== "LoanReceivable"
        );
    
        let total = 0;
    
        filteredData.forEach((item) => {
            if (item.estimateValue) {
                let estimateValue = parseFloat(item.estimateValue.replace(/,/g, ""));
                
                if (!isNaN(estimateValue)) {
                    total += estimateValue;
                }
            }
        });
    
        setAssetTotal(formattedTotal(total));
    }

    const getDataAssetSummary = () => {
        restGetSummary(
            "/liability-summary",
            modelLiabilityTopics,
            (data) => {
                setData(data);
                calculateTotal(data);
            },
            (error) => console.error(error)
        );

        restGetSummary(
            "/asset-summary",
            modelFinancesTopics,
            (data) => {
                const dataFormattedForTopics = formatTypes(data);

                calculateAssetTotal(dataFormattedForTopics);
            },
            (error) => console.error(error)
        );
    };

    const getTotal = (assetTotal, total) => {
        if (!assetTotal || !total) {
            return "";
        }
        let assetTotalNum = parseFloat(assetTotal.replace(/[$,]/g, ''));
        let totalNum = parseFloat(total.replace(/[$,]/g, ''));

        let sum = assetTotalNum - totalNum;

        return sum.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }

    useEffect(() => {
        getDataAssetSummary();
    }, []);

    return (
        <>
            <PageTitle title="My Banyan" />

            <div className="mb-8">
                <div className="mx-auto flex max-w-7xl flex-col pt-4 md:pt-6 lg:pt-8">
                    <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-8 py-4 md:px-8">
                            <div className="flex flex-col md:flex-row items-center justify-between">
                                <div className="md:w-1/4 text-2xl uppercase text-primary-green font-bold">
                                    {name}
                                </div>

                                <div className="md:w-3/4 pt-2 md:pt-0 text-sm">
                                    <p>
                                        The Net Worth Summary in My Banyan provides a clear snapshot of 
                                        your financial standing by calculating what remains after your liabilities are 
                                        subtracted from your assets. This easy-to-understand report automatically 
                                        updates whenever you add new information to the My Liabilities Report or 
                                        the My Assets Summary Report, ensuring you always have an up-to-date view of your net worth.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="px-8 py-4 md:px-8">
                            <hr className="border-t-2 border-lime-600" />
                        </div>

                        <div className="flex px-8 py-4 md:px-8 justify-center md:justify-end">
                            <button
                                className="rounded-full border border-transparent bg-secondary-green py-2 px-12 text-sm font-medium text-black hover:bg-emerald-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 transition duration-300"
                                onClick={handleExportExcel}
                            >
                                Download
                            </button>
                        </div>

                        <div className="px-8 py-4 md:px-8 overflow-y-auto">
                            <table className="w-full border-collapse border border-gray-300">
                                <thead className="px-8 py-4">
                                    <tr className="uppercase font-thin text-white bg-[#7A935A] text-base">
                                        <th className="w-1/2 border border-slate-600 px-4 py-2">
                                            Estimated net worth
                                        </th>
                                        <th className="w-1/2 border border-slate-600 px-4 py-2">
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr
                                        className="text-thin font-medium"
                                    >
                                        {/* Estimated net worth */}
                                        <td className="border border-slate-700 px-4 py-2 underline text-blue-400">
                                            <a
                                                href="/summary-reports/assets-summary"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <p>Assets</p>
                                            </a>
                                        </td>

                                        {/* Blank */}
                                        <td className="border border-slate-700 px-4 py-2 text-right">
                                            {assetTotal ? assetTotal : "N/A"}
                                        </td>
                                    </tr>

                                    <tr
                                        className="text-thin font-medium"
                                    >
                                        {/* Estimated net worth */}
                                        <td className="border border-slate-700 px-4 py-2 underline text-blue-400">
                                            <a
                                                href="/summary-reports/liabilities-summary"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <p>Liabilities</p>
                                            </a>
                                        </td>

                                        {/* Blank */}
                                        <td className="border border-slate-700 px-4 py-2 text-right">
                                            {total ? total : "N/A"}
                                        </td>
                                    </tr>

                                    <tr className="text-white">
                                        <td className="bg-[#7A935A] border border-slate-700 font-bold px-4 py-2">
                                            NET WORTH
                                        </td>
                                        <td className="bg-[#7A935A] border border-slate-700 text-white font-bold px-4 py-2 text-right">
                                            ${getTotal(assetTotal, total)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

