/* eslint-disable react/jsx-no-target-blank */
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { restGet } from "../../store/api";

export default function ReportsSharedWithMe() {
    const [data, setData] = useState([]);
    const user = useSelector((state) => state.auth.currentUser);
    const email = user?.attributes.email;

    const successCallback = (data) => {
        setData(data);
    };

    const failCallback = (err) => {
        console.error(err);
    };

    const getDataToShow = () => {
        const url = "/fileSharing/shared/" + email;
        restGet(url, successCallback, failCallback);
    };

    const getUrl = (data) => {
        let name = data.name;
        if (name) {
            let parts = name && name.split("_");
            const topicId = parts && parts[parts.length - 1];
            parts.pop();

            const newName = parts
                .map((item) => {
                    if (item.includes(" ")) {
                        return item.replace(/ /g, "+");
                    } else {
                        return item;
                    }
                })
                .join("_");
            const url =
                "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/" +
                data.uid +
                "/" +
                "report" +
                "/" +
                topicId +
                "/" +
                newName +
                ".pdf";

            return url;
        }
    };

    useEffect(() => {
        getDataToShow();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {data && (
                <div>
                    <div className="mt-6 mb-4 flex flex-col">
                        <div className="overflow-x-auto">
                            <div className="inline-block min-w-full py-2 align-middle md:px-0">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                >
                                                    Shared from
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Report name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Actions you can take
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                            {data.map((el, elIdx) => (
                                                <tr
                                                    key={uuidv4()}
                                                    className={
                                                        elIdx % 2 === 0
                                                            ? undefined
                                                            : "bg-gray-50"
                                                    }
                                                >
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        {el.email}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <p className="truncate-ellipsis">
                                                            {el.name &&
                                                                (el.name
                                                                    .length > 40
                                                                    ? `${el.name
                                                                          .replace(
                                                                              /_/g,
                                                                              " "
                                                                          )
                                                                          .substring(
                                                                              0,
                                                                              40
                                                                          )}...`
                                                                    : el.name.replace(
                                                                          /_/g,
                                                                          " "
                                                                      ))}
                                                        </p>
                                                    </td>
                                                    <td className="float-left relative flex whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6">
                                                        <a
                                                            target="_blank"
                                                            href={getUrl(el)}
                                                            className="text-primary-green hover:text-indigo-900 hover:underline"
                                                            download
                                                        >
                                                            Download
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
}

