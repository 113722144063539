import moment from "moment";

import { Fragment, useState, useContext } from "react";
import { Transition } from "@headlessui/react";
import { InboxIcon } from "@heroicons/react/outline";

import { restPut, restGet } from "../../store/api";
import { NotificationContext } from "../../contexts/notificationContext";

export default function Notification({ event, idx, close, dismissHandler }) {
    const [show, setShow] = useState(true);
    const { setNoti } = useContext(NotificationContext);

    const readHandler = () => {
        restPut(
            "/calendarEvents/update/" + event._id,
            { read: true },
            (data) => updateNoti(),
            (err) => console.error(err)
        );
    };

    const updateNoti = () => {
        restGet(
            "/calendarEvents/notification",
            (data) => setNoti(data),
            (err) => console.error(err)
        );
    };

    return (
        <>
            {/* Global notification live region, render this permanently at the end of the document */}
            <div
                aria-live="assertive"
                className="pointer-events-none z-50 inset-0 flex items-end px-4 py-2 sm:items-start sm:p-2 space-y-2"
            >
                <div className="flex w-screen flex-col space-y-4">
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    <Transition
                        show={show}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="pointer-events-auto w-full max-screen overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        <InboxIcon
                                            className="h-6 w-6 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </div>

                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-gray-900">
                                            {event.title}
                                        </p>

                                        <p className="mt-1 text-sm text-gray-500">
                                            {moment(event.when).format(
                                                "YYYY-MM-DD"
                                            )}
                                        </p>

                                        <div className="mt-3 flex space-x-7">
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setShow(false);
                                                    readHandler();
                                                    dismissHandler();
                                                }}
                                                className="rounded-md bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Dismiss
                                            </button>
                                        </div>
                                    </div>

                                    <div className="ml-4 flex flex-shrink-0">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={() => {
                                                setShow(false);
                                            }}
                                        >
                                            <span className="sr-only">
                                                Close
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    );
}

