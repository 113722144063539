import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { updateCurrentUser } from "../../store/actions";
import { Fieldset, Text } from "./inputs";

export const ChangeName = ({ setOpen }) => {
    const user = useSelector((state) => state.auth.currentUser);
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState({
        displayName: user && user.attributes.name ? user.attributes.name : "",
    });

    const onChangeHandler = (e) => {
        e.preventDefault();

        setFormValues((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const onSubmit = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, {
                name: formValues.displayName,
            });
            const updatedUser = await Auth.currentAuthenticatedUser();

            dispatch(updateCurrentUser(updatedUser));
            setOpen(false);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Fieldset
            title={"Change your name"}
            subtitle={"Your initials will be display in your photo icon."}
        >
            <Text
                type="text"
                label="Name"
                name="displayName"
                autoComplete="new-password"
                required
                onChange={onChangeHandler}
                placeholder=""
                value={formValues.displayName}
            />

            <button
                type="submit"
                onClick={(e) => {
                    e.preventDefault();
                    onSubmit();
                }}
                className="w-full mx-1 px-4 py-2 text-sm font-medium bg-primary-green hover:bg-white disabled:bg-white text-white hover:text-primary-green disabled:text-primary-green border-2 border-primary-green focus:outline-none rounded-md disabled:cursor-not-allowed transition duration-300"
            >
                Submit
            </button>
        </Fieldset>
    );
};

