export const generalPoaData = {
  formTitle: "Add or edit general POA",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/generalPoa",
  formFields: [
    {
      component: "input",
      label: "Name of agent",
      name: "name",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Scope of powers",
      name: "scope",
      type: "text",
    },
    {
      component: "input",
      label: "Attorney",
      name: "attorney",
      type: "text",
    },
    {
      component: "input",
      label: "Date notarized",
      name: "date",
      type: "date",
    },
    {
      component: "input",
      label: "Witnesses",
      name: "witnesses",
      type: "text",
    },
    {
      component: "input",
      label: "Person who has copies",
      name: "personWhoHasCopies",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
