export const genealogyWebsitesData = {
  formTitle: "Add or edit my genealogy website",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/genealogyWebsites",
  formFields: [
    {
      component: "select",
      label: "Name of source",
      name: "source",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "23andMe", text: "23andMe" },
        { value: "Ancestry.com", text: "Ancestry.com" },
        { value: "Archives", text: "Archives" },
        { value: "Cyndi’s List", text: "Cyndi’s List" },
        { value: "EllisIslland", text: "EllisIslland" },
        { value: "FamilySearch", text: "FamilySearch" },
        { value: "Find My Past", text: "Find My Past" },
        { value: "Findagrave", text: "Findagrave" },
        { value: "Genes Reunite", text: "Genes Reunite" },
        { value: "GenWeb", text: "GenWeb" },
        { value: "JewiaGen", text: "JewiaGen" },
        { value: "Legacy Tree", text: "Legacy Tree" },
        { value: "MyHeritage", text: "MyHeritage" },
        { value: "One Great Family", text: "One Great Family" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Website",
      name: "website",
      type: "text",
    },
    {
      component: "input",
      label: "Annual fee",
      name: "fee",
      type: "text",
    },
    {
      component: "input",
      label: "Login username (or its location)",
      name: "username",
      type: "text",
    },
    {
      component: "password",
      label: "Password (or its location)",
      name: "password",
      type: "password",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
