export const burialPlotLocationsData = {
  formTitle: "Add or edit burial plot location",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/burialPlotLocations",
  formFields: [
    {
      component: "input",
      label: "Name of cemetery",
      name: "cemetery",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Address of cemetery",
      name: "address",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Contact phone number",
      name: "phone",
    },
    {
      component: "input",
      label: "Number of plot",
      name: "plotNumber",
      type: "number",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
