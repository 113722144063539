import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router";
import { Fieldset, Text } from "./inputs";
import { Toastr } from "../common/toastr";

export const ChangePassword = ({ setOpen }) => {
    const navigate = useNavigate();

    const [formValues, setFormValues] = useState({
        currentPassword: "",
        newPassword: "",
    });

    const signOut = async () => {
        try {
            await Auth.signOut();
            navigate("/signin", { replace: true });
        } catch (error) {
            console.error(error);
        }
    };

    const onChangeHandler = (e) => {
        e.preventDefault();

        setFormValues((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const onSubmit = () => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                return Auth.changePassword(
                    user,
                    formValues.currentPassword,
                    formValues.newPassword
                );
            })
            .then((data) => signOut())
            .catch((err) => {
                if (err.code === "LimitExceededException") {
                    Toastr({
                        type: "error",
                        message:
                            "Attempt limit exceeded, please try after some time.",
                    });
                }
                if (err.code === "InvalidPasswordException") {
                    Toastr({
                        type: "error",
                        message:
                            "Password did not conform with policy: Password not long enough.",
                    });
                }
            });
    };

    return (
        <Fieldset
            title={"Change your password"}
            subtitle={
                "To be able to change your password, you need to provide the current one"
            }
        >
            <Text
                name="currentPassword"
                type="password"
                autoComplete="new-password"
                required
                label="Current Password *"
                onChange={onChangeHandler}
                placeholder=""
            />
            <Text
                name="newPassword"
                type="password"
                autoComplete="new-password"
                required
                label="New Password *"
                onChange={onChangeHandler}
                placeholder=""
            />
            <button
                type="submit"
                onClick={(e) => {
                    e.preventDefault();
                    onSubmit();
                }}
                className="w-full mx-1 px-4 py-2 text-sm font-medium bg-primary-green hover:bg-white disabled:bg-white text-white hover:text-primary-green disabled:text-primary-green border-2 border-primary-green focus:outline-none rounded-md disabled:cursor-not-allowed transition duration-300"
            >
                Submit
            </button>
        </Fieldset>
    );
};

