export const propertySecurityServiceData = {
  formTitle: "Add or edit property security service",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/propertySecurityService",
  formFields: [
    {
      component: "input",
      label: "Property location (street, city, state, zip code, country)",
      name: "location",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Security company",
      name: "company",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Account number",
      name: "accountNumber",
      type: "text",
    },
    {
      component: "input",
      label: "Name(s) on account",
      name: "names",
      type: "text",
    },
    {
      component: "input",
      label: "Login username (or its location)",
      name: "username",
      type: "text",
    },
    {
      component: "password",
      label: "Password (or its location)",
      name: "password",
      type: "password",
    },
    {
      component: "input",
      label: "Code / key (or its location)",
      name: "code",
      type: "text",
    },
    {
      component: "input",
      label: "Fees",
      name: "fees",
      type: "number",
    },
    {
      component: "input",
      label: "Renewal date",
      name: "date",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
