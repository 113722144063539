export const maritalStatusData = {
  formTitle: "Add or edit marital status",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/maritalStatus",
  formFields: [
    {
      component: "select",
      label: "Status",
      name: "status",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Common law marriage", text: "Common law marriage" },
        { value: "Domestic partnership", text: "Domestic partnership" },
        { value: "Engaged to be married", text: "Engaged to be married" },
        { value: "Married", text: "Married" },
        { value: "Separated", text: "Separated" },
        { value: "Single (divorced)", text: "Single (divorced)" },
        { value: "Single (never married)", text: "Single (never married)" },
        { value: "Widowed", text: "Widowed" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Since",
      name: "since",
      type: "date",
      required: true,
    }
  ],
};
