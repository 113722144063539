import Submit from "./submit";
import Description from "../../components/description";

import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Switch } from "@headlessui/react";
import {
  Fieldset,
  Text,
  PhoneNumber,
  Select,
  Textarea,
} from "../../lib/form/inputs";
import { FileUpload } from "../../lib/form/file-upload";
import { restGet } from "../../store/api";
import { updateInnerCircleById } from "../../store/actions";
import { myContactsData } from "../../pages/topics/my-contacts/data";
import { classNames } from "../../lib/classnames";

const tabs = [
  { name: "Record", href: "#", current: true },
  { name: "Attachments", href: "#", current: false },
];

const InnerCircleSwitch = ({ enabled, setEnabled }) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-300 sm:pt-5">
      <label
        htmlFor={"innerCircle"}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        Inner Circle
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={classNames(
            enabled ? "bg-primary-green" : "bg-gray-300",
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-green focus:ring-offset-2"
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          />
        </Switch>
      </div>
    </div>
  );
};

export function AddContactForm({ setOpen }) {
  const [tab, setTab] = useState(1);
  const [enabled, setEnabled] = useState(false);
  const [params] = useSearchParams();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.innerCircle.byId);

  const reqBody = (values) => {
    let body = {};
    myContactsData.formFields.forEach(
      (el) => (body[el.name] = values[el.name])
    );

    return body;
  };

  const getDataToEdit = () => {
    restGet(
      myContactsData.pathname + "/show/" + params.get("id"),
      (data) => dispatch(updateInnerCircleById(data)),
      (error) => console.error(error)
    );
  };

  useEffect(() => {
    if (params.get("id")) getDataToEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <div className="flex max-w-7xl flex-col">
        <div className="py-1">
          {/* Tabs */}

          <div className="border-b border-gray-300">
            <nav className="-mb-px flex space-x-8">
              {data ? (
                <>
                  {tabs.map((el, idx) => (
                    <a
                      key={el.name}
                      href={el.href}
                      onClick={(e) => {
                        e.preventDefault();
                        setTab(idx + 1);
                      }}
                      className={classNames(
                        idx + 1 === tab
                          ? "border-primary-green text-primary-green"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap py-4 px-1 border-b-2 font-bold text-sm"
                      )}
                    >
                      {el.name}
                    </a>
                  ))}
                </>
              ) : (
                <>
                  {tabs.map((el, idx) => (
                    <a
                      key={el.name}
                      href={el.href}
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      className={classNames(
                        idx + 1 === tab
                          ? "border-primary-green text-primary-green"
                          : "border-transparent text-gray-300 cursor-default",
                        "whitespace-nowrap py-4 px-1 border-b-2 font-bold text-sm"
                      )}
                    >
                      {el.name}
                    </a>
                  ))}
                </>
              )}
            </nav>
          </div>

          {/* Description list with inline editing */}
          {tab === 1 && (
            <Submit
              pathname={
                params.get("id")
                  ? myContactsData.pathname + "/update/" + params.get("id")
                  : myContactsData.pathname + "/new"
              }
              reqBody={reqBody}
              initialValues={params.get("id") ? data : null}
              innerCircle={enabled}
              setOpen={setOpen}
            >
              <Fieldset
                title={params.get("id") ? "Edit a record" : ""}
                subtitle={
                  "All required fields need to be filled out in order to save the record."
                }
              >
                <InnerCircleSwitch enabled={enabled} setEnabled={setEnabled} />
                {myContactsData.formFields.map((el) => (
                  <Fragment key={el.name}>
                    {el.component === "input" && (
                      <Text
                        name={el.name}
                        type={el.type}
                        autoComplete={el.autoComplete}
                        required={el.type === "email" ? enabled : el.required}
                        placeholder={el.placeholder}
                      >
                        {`${el.label} ${
                          (el.type === "email" ? enabled : el.required)
                            ? "*"
                            : ""
                        }`}
                        {el.description ? (
                          <Description data={el.description} />
                        ) : null}
                      </Text>
                    )}
                    {el.component === "phoneNumber" && (
                      <PhoneNumber
                        name={el.name}
                        autoComplete={el.autoComplete}
                        required={el.required}
                        placeholder={el.placeholder}
                      >
                        {`${el.label} ${el.required ? "*" : ""}`}
                        {el.description ? (
                          <Description data={el.description} />
                        ) : null}
                      </PhoneNumber>
                    )}
                    {el.component === "textarea" && (
                      <Textarea
                        name={el.name}
                        autoComplete={el.autoComplete}
                        required={el.required}
                        placeholder={el.placeholder}
                      >{`${el.label} ${el.required ? "*" : ""}`}</Textarea>
                    )}
                    {el.component === "select" && (
                      <Select
                        label={`${el.label} ${el.required ? "*" : ""}`}
                        name={el.name}
                        type={el.type}
                        autoComplete={el.autoComplete}
                        required={el.required}
                        placeholder={el.placeholder}
                      >
                        <option value={""}>Select...</option>
                        {el.options.map((option) => (
                          <option key={option.text} value={option.value}>
                            {option.text}
                          </option>
                        ))}
                      </Select>
                    )}
                  </Fragment>
                ))}
              </Fieldset>
            </Submit>
          )}
          {tab === 2 && data && (
            <div className="space-y-8 divide-y divide-gray-300 mb-6 -mt-2">
              <div className="space-y-8 divide-y divide-gray-300 sm:space-y-5">
                <Fieldset
                  title={"Attach files to the record"}
                  subtitle={"Maximum files to be attached are 10."}
                >
                  <FileUpload pathname={myContactsData.pathname} data={data} />
                </Fieldset>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

