/* eslint-disable array-callback-return */
import { Link, useLocation } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/outline";

const scrollTop = (shouldScroll = true) => {
  if (shouldScroll) {
    window.scrollTo(0, 0);
  }
};

const exceptions = ["about-me", "everyday", "eventual"];

export const Breadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/");

  const createPath = (path) => {
    let array = location.pathname.split(path);
    return array[0] + path;
  };

  return (
    <nav className="bg-white flex shadow" aria-label="Breadcrumb">
      <ol className="max-w-7xl w-full px-4 flex space-x-4 overflow-hidden">
        <li className="flex">
          <div className="flex items-center">
            <Link
              to="/"
              onClick={scrollTop(false)}
              className={`text-gray-900 hover:text-custom-brown transition duration-300 ${
                pathnames.length === 2 ? "mr-4" : null
              }`}
            >
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>

            <svg
              className="flex-shrink-0 w-6 h-full text-gray-900"
              viewBox="0 0 24 44"
              preserveAspectRatio="none"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
          </div>
        </li>
        {pathnames
          .filter((item, index) => index > 1)
          .map((path, index) => {
            if (!(exceptions.includes(path) && pathnames.length === 3)) {
              return (
                <li key={path} className="flex">
                  <div className="flex items-center">
                    <Link
                      to={createPath(path)}
                      onClick={scrollTop(false)}
                      className="text-sm font-medium text-gray-900 hover:text-custom-brown transition duration-300 capitalize"
                    >
                      {path === "dnaInfo"
                        ? "DNA Info"
                        : path
                            .replaceAll("-", " ")
                            .replace(/([A-Z])([A-Z])/g, "$1 $2")
                            .replace(/([a-z])([A-Z])/g, "$1 $2")}
                    </Link>
                    <svg
                      className="flex-shrink-0 w-6 h-full text-gray-900"
                      viewBox="0 0 24 44"
                      preserveAspectRatio="none"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                  </div>
                </li>
              );
            }
          })}
      </ol>
    </nav>
  );
};

