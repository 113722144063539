export const selfFundingHealthCareData = {
  formTitle: "Add or edit self-funding health care",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/selfFundingHealthCare",
  formFields: [
    {
      component: "input",
      label: "Date of current financial plan",
      name: "currentDate",
      type: "date",
      colObject: true,
    },
    {
      component: "input",
      label: "Name of financial planner",
      name: "financialPlanner",
      type: "text",
      colObject: true,
    },
    {
      component: "select",
      label: "Major revenue sources in current plan",
      name: "revenueSources",
      type: "text",
      options: [
        { value: "Annuities", text: "Annuities" },
        { value: "Commission", text: "Commission" },
        { value: "Consulting fees", text: "Consulting fees" },
        { value: "Deferred compensation", text: "Deferred compensation" },
        { value: "Dividends / interest", text: "Dividends / interest" },
        { value: "Fees for service", text: "Fees for service" },
        { value: "Hourly wages", text: "Hourly wages" },
        { value: "Military benefits", text: "Military benefits" },
        { value: "Pension", text: "Pension" },
        { value: "Profit sharing", text: "Profit sharing" },
        { value: "Rent", text: "Rent" },
        { value: "Required minimum distribution (RMD)", text: "Required minimum distribution (RMD)" },
        { value: "Retainer", text: "Retainer" },
        { value: "Salary", text: "Salary" },
        { value: "Securities", text: "Securities" },
        { value: "Social Security", text: "Social Security" },
        { value: "Stock options", text: "Stock options" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "select",
      label: "Long-term living options included in plan",
      name: "options",
      type: "text",
      options: [
        {
          value: "Assisted living / nursing faciity",
          text: "Assisted living / nursing faciity",
        },
        {
          value: "Independent living community",
          text: "Independent living community",
        },
        { value: "Live at another location", text: "Live at another location" },
        { value: "Live at current home", text: "Live at current home" },
        { value: "Nursing home", text: "Nursing home" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Major risks for current plan",
      name: "majorRisks",
      type: "text",
    },
    {
      component: "input",
      label: "Steps to mitigate risks",
      name: "mitigateRisks",
      type: "text",
    },
    {
      component: "input",
      label: "Date for next financial plan",
      name: "nextDate",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
