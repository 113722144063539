import { useEffect, useMemo, useState } from "react";

import ExcelJS from "exceljs";
import moment from "moment";

import PageTitle from "../common/page-title";
import { mainBranches } from "../private-layout/data";
import { restGetSummary } from "../../store/api";

const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const insertSpacesBeforeCapital = (text) => {
    return text.replace(/([a-z])([A-Z])/g, "$1 $2");
};

const removeValue = (text) => {
    const textArr = text.split(" ");

    const textArrWithoutValuable = textArr.filter(
        (word) => word.toLowerCase() !== "valuable"
    );

    const textArrWithoutValue = textArrWithoutValuable.filter(
        (word) => word.toLowerCase() !== "value"
    );

    return textArrWithoutValue.join(" ");
};

const pluralizeText = (text) => {
    const words = ["investment", "personal vehicle", "collectible"];
    const lowercaseText = text.toLowerCase();

    if (words.indexOf(lowercaseText) !== -1) {
        return `${text}s`;
    } else if (text.toLowerCase() === "lease") {
        return "Leases Receivable";
    } else if (text.toLowerCase() === "loan receivable") {
        return "Loans Receivable";
    } else {
        return text;
    }
};

const formatTopic = (text) => {
    const textWithSpaces = insertSpacesBeforeCapital(text);

    const textWithoutValue = removeValue(textWithSpaces);

    return pluralizeText(textWithoutValue);
};

export function AssetsSummary({ name, title }) {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);

    const handleExportExcel = async () => {
        const saveAs = require("file-saver");

        const date = moment().format("L");

        const totalEstimateValue = data.reduce((total, item) => {
            const totalAsInteger = total * 100;
            const valueAsInteger = item.estimateValue
                ? parseFloat(item.estimateValue.replace(/,/g, "")) * 100
                : 0;

            const sumTotalAsInteger = totalAsInteger + valueAsInteger;

            return sumTotalAsInteger / 100;
        }, 0);

        const header = `Asset Summary Report as of ${date}`;
        const columns = ["Branch", "Topic", "Type", "Asset", "Estimated Value", "Location of Document", "Ownership", "Holdings"];

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Assets Summary Report");

        // Add header row
        worksheet.mergeCells("A1:H1");
        const headerRow = worksheet.getCell("A1");
        headerRow.value = header;
        headerRow.font = { bold: true, size: 14, color: { argb: "FFFFFFFF" } };
        headerRow.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF6C8954" },
        };
        headerRow.alignment = { vertical: "middle", horizontal: "center" };

        // Add column headers
        worksheet.addRow([]);
        const columnRow = worksheet.addRow(columns);
        columnRow.eachCell((cell) => {
            cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FF808080" },
            };
            cell.alignment = { vertical: "middle", horizontal: "center" };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
        });

        // Add data rows
        data.forEach((item) => {
            const row = worksheet.addRow([
                item.topic === "PersonalInsurance"
                    ? "Insurance"
                    : capitalizeFirstLetter(item.branch),
                formatTopic(item.topic),
                item.type || "N/A",
                item.asset || "N/A",
                item.estimateValue
                    ? formattedTotal(item.estimateValue.replace(/,/g, ""))
                    : "$0.00",
                item.localtionOfDocument || "N/A",
                item.ownership || "N/A",
                item.holdings || "N/A",
            ]);

            row.eachCell((cell) => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
            });
        });

        // Add total row
        const totalRow = worksheet.addRow([
            "",
            "",
            "",
            "Estimated total",
            formattedTotal(totalEstimateValue),
        ]);
        totalRow.eachCell((cell, colNumber) => {
            if (colNumber >= 4) {
                cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FF6C8954" },
                };
                cell.alignment = { vertical: "middle", horizontal: "right" };
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
            }
        });

        // Adjust column widths
        worksheet.columns = [
            { width: 20 },
            { width: 30 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
        ];

        // Generate the Excel file
        const buffer = await workbook.xlsx.writeBuffer();

        // Create a Blob from the ArrayBuffer
        const excelBlob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Trigger file download
        saveAs(excelBlob, `Assets_Summary_Report_${date}.xlsx`);
    };

    const financesTopics = useMemo(() => {
        const branch = mainBranches.find(
            (branch) => branch.path === "everyday"
        );
        const subBranch = branch?.subBranches.find(
            (subBranch) => subBranch.path === "finances"
        );
        return subBranch?.topics || [];
    }, []);

    const filteredTopics = financesTopics.filter((topic) =>
        topic.name.startsWith("ASSETS")
    );
    const modelFinancesTopics = filteredTopics
        .map((item) => item.path)
        .concat("personalInsurance");

    // Sorts Personal Insurance topics to be Term Life then Whole Life.
    const sortLifePersonalInsurance = (data) => {
        return data.sort((a, b) => {
            if (
                a.topic.toLowerCase() === "personalinsurance" &&
                b.topic.toLowerCase() === "personalinsurance"
            ) {
                if (
                    a.type.toLowerCase() === "whole life" &&
                    b.type.toLowerCase() === "term life"
                ) {
                    return 1;
                }
                if (
                    a.type.toLowerCase() === "term life" &&
                    b.type.toLowerCase() === "whole life"
                ) {
                    return -1;
                }
            }
            return 0;
        });
    };

    // Adds an empty Whole and/or Term Life Personal Insurance row if it wasn't present in the data.
    const addLifePersonalInsurance = (data) => {
        const hasWholeLife = data.some(
            (item) =>
                item.topic.toLowerCase() === "personalinsurance" &&
                item.type &&
                item.type.toLowerCase() === "whole life"
        );

        if (!hasWholeLife) {
            data.push({
                asset: "",
                branch: "finances",
                estimateValue: "",
                id: "",
                topic: "PersonalInsurance",
                type: "Whole Life",
            });
        }

        const hasTermLife = data.some(
            (item) =>
                item.topic.toLowerCase() === "personalinsurance" &&
                item.type &&
                item.type.toLowerCase() === "term life"
        );

        if (!hasTermLife) {
            data.push({
                asset: "",
                branch: "finances",
                estimateValue: "",
                id: "",
                topic: "PersonalInsurance",
                type: "Term Life",
            });
        }

        return data;
    };

    // Removes all non-Whole and Term Life Personal Insurance rows from the data.
    const removeNonLifePersonalInsurance = (data) => {
        const dataWithoutNonLifePersonalInsurance = data.filter((item) => {
            return (
                item.topic.toLowerCase() !== "personalinsurance" ||
                (item.type && item.type.toLowerCase() === "whole life") ||
                (item.type && item.type.toLowerCase()) === "term life"
            );
        });

        return dataWithoutNonLifePersonalInsurance;
    };

    const formatTypes = (data) => {
        const typesWithLifePersonalInsurance = addLifePersonalInsurance(data);

        const typesWithoutNonLifePersonalInsurance =
            removeNonLifePersonalInsurance(typesWithLifePersonalInsurance);

        const typesSortedByLifePersonalInsurance = sortLifePersonalInsurance(
            typesWithoutNonLifePersonalInsurance
        );

        return typesSortedByLifePersonalInsurance;
    };

    const getDataAssetSummary = () => {
        restGetSummary(
            "/asset-summary",
            modelFinancesTopics,
            (data) => {
                const dataFormattedForTopics = formatTypes(data);

                setData(dataFormattedForTopics);

                calculateTotal(dataFormattedForTopics);
            },
            (error) => console.error(error)
        );
    };

    // Formats the total into a string with a $
    const formattedTotal = (total) => {
        let totalWithoutCommas = total;
        if (typeof total === "string") {
            totalWithoutCommas = total.replace(/,/g, "");
        }

        if (isNaN(parseFloat(totalWithoutCommas))) {
            return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(0);
        }

        const convertTotal = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(totalWithoutCommas);

        return convertTotal;
    };

    // Creates a sum total using all the numbers in the data
    const calculateTotal = (data) => {
        let total = 0;

        data.forEach((item) => {
            if (item.estimateValue) {
                let estimateValue = parseFloat(
                    item.estimateValue.replace(/,/g, "")
                );

                if (!isNaN(estimateValue)) {
                    total += estimateValue;
                }
            }
        });

        setTotal(formattedTotal(total));
    };

    useEffect(() => {
        getDataAssetSummary();
    }, []);

    const topicReplacements = {
        BankAccountBalance: "bankAccountsBalances",
        Investment: "investments",
        PersonalVehicleValue: "personalVehiclesValue",
        CollectibleValuable: "collectibleValuables",
        LoanReceivable: "loansReceivable",
        Lease: "leases",
    };

    return (
        <>
            <PageTitle title="My Banyan" />

            <div className="mb-8">
                <div className="mx-auto flex max-w-7xl flex-col pt-4 md:pt-6 lg:pt-8">
                    <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-8 py-4 md:px-8">
                            <div className="flex flex-col md:flex-row items-center justify-between">
                                <div className="md:w-1/4 text-2xl uppercase text-primary-green font-bold">
                                    {name}
                                </div>

                                <div className="md:w-3/4 pt-2 md:pt-0 text-sm">
                                    <p>
                                        This report shows the value of each
                                        asset you have listed in your My Banyan
                                        program. To update any record, click on
                                        the cell in the {title} column. You will
                                        be taken to that record where you can
                                        enter new data.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="px-8 py-4 md:px-8">
                            <hr className="border-t-2 border-lime-600" />
                        </div>

                        <div className="flex px-8 py-4 md:px-8 justify-center md:justify-end">
                            <button
                                className="rounded-full border border-transparent bg-secondary-green py-2 px-12 text-sm font-medium text-black hover:bg-emerald-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 transition duration-300"
                                onClick={handleExportExcel}
                            >
                                Download
                            </button>
                        </div>

                        <div className="px-8 py-4 md:px-8 overflow-y-auto">
                            <table className="w-full border-collapse border border-gray-300">
                                <thead className="px-8 py-4">
                                    <tr className="uppercase font-thin text-white bg-[#7A935A] text-base">
                                        <th className="w-1/8 border border-slate-600 px-4 py-2">
                                            Branch
                                        </th>
                                        <th className="w-1/8 border border-slate-600 px-4 py-2">
                                            Topic
                                        </th>
                                        <th className="w-1/8 border border-slate-600 px-4 py-2">
                                            Asset
                                        </th>
                                        <th className="w-1/8 border border-slate-600 px-4 py-2">
                                            Type
                                        </th>
                                        <th className="w-1/8 border border-slate-600 px-4 py-2">
                                            Estimated Value
                                        </th>
                                        <th className="w-1/8 border border-slate-600 px-4 py-2">
                                            Location of Document
                                        </th>
                                        <th className="w-1/8 border border-slate-600 px-4 py-2">
                                            Ownership
                                        </th>
                                        <th className="w-1/8 border border-slate-600 px-4 py-2">
                                            Holdings
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {data?.map((item, index) => (
                                        <tr
                                            key={index}
                                            className="text-thin font-medium"
                                        >
                                            {/* BRANCH */}
                                            <td className="border border-slate-700 text-center px-4 py-2">
                                                {item.topic ===
                                                "PersonalInsurance"
                                                    ? "Insurance"
                                                    : capitalizeFirstLetter(
                                                          item.branch
                                                      )}
                                            </td>

                                            {/* TOPIC */}
                                            <td className="border border-slate-700 px-4 py-2">
                                                <a
                                                    href={`/everyday/${
                                                        item.topic ===
                                                        "PersonalInsurance"
                                                            ? "insurance"
                                                            : "finances"
                                                    }/${
                                                        topicReplacements[
                                                            item.topic
                                                        ] || item.topic
                                                    }`}
                                                    target="_blank"
                                                    className="text-blue-600 underline"
                                                    rel="noreferrer"
                                                >
                                                    {formatTopic(item.topic)}
                                                </a>
                                            </td>

                                            {/* ASSET */}
                                            <td className="border border-slate-700 px-4 py-2 italic">
                                                {item.estimateValue ? (
                                                    <a
                                                        href={`/everyday/${
                                                            item.topic ===
                                                            "PersonalInsurance"
                                                                ? "insurance"
                                                                : "finances"
                                                        }/${
                                                            topicReplacements[
                                                                item.topic
                                                            ] || item.topic
                                                        }/edit?id=${item.id}`}
                                                        target="_blank"
                                                        className="text-blue-600 underline"
                                                        rel="noreferrer"
                                                    >
                                                        {item.asset
                                                            ? item.asset
                                                                  .length > 25
                                                                ? `${item.asset.substring(
                                                                      0,
                                                                      25
                                                                  )}...`
                                                                : item.asset
                                                            : "N/A"}
                                                    </a>
                                                ) : (
                                                    <p className="text-blue-500">
                                                        N/A
                                                    </p>
                                                )}
                                            </td>

                                            {/* TYPE */}
                                            <td className="border border-slate-700 px-4 py-2 italic">
                                                {item.type && (
                                                    <p>{item.type}</p>
                                                )}
                                            </td>

                                            {/* ESTIMATED VALUE */}
                                            <td
                                                className={`border border-slate-700 px-4 py-2 text-right italic ${
                                                    item.topic === "Lease" ? "text-red-600" : ""
                                                }`}
                                            >
                                                {item.estimateValue ? (
                                                    <p>
                                                        {formattedTotal(item.estimateValue)}
                                                    </p>
                                                ) : (
                                                    <p>$0.00</p>
                                                )}
                                            </td>

                                            {/* LOCATION OF DOCUMENT */}
                                            <td className={`border border-slate-700 px-4 py-2 text-right italic ${
                                                item.topic === "Investment" ? "text-red-600" : ""
                                            }`}>
                                                { item.localtionOfDocument }
                                            </td>

                                            {/* OWNERSHIP */}
                                            <td className="border border-slate-700 px-4 py-2 text-right italic">
                                                {item.topic === "Investment" ? (
                                                    <p>{ item.ownership }</p>
                                                ) : (
                                                    <p></p>
                                                )}
                                            </td>

                                            {/* HOLDINGS */}
                                            <td className="border border-slate-700 px-4 py-2 text-right italic">
                                                {item.topic === "Investment" ? (
                                                    <p>{ item.holdings }</p>
                                                ) : (
                                                    <p></p>
                                                )}
                                            </td>
                                        </tr>
                                    ))}

                                    <tr className="bg-[#7A935A] text-white">
                                        <td className=""></td>
                                        <td className=""></td>
                                        <td className=""></td>
                                        <td className="border border-slate-700 font-bold px-4 py-2 uppercase">
                                            Estimated total
                                        </td>
                                        <td className="bg-white border border-slate-700 text-black font-bold px-4 py-2 text-right">
                                            {total}
                                        </td>
                                        <td className=""></td>
                                        <td className=""></td>
                                        <td className=""></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

