export const deedsOnMyPropertiesData = {
  formTitle: "Add or edit deed on my property",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/deedsOnMyProperties",
  formFields: [
    {
      component: "input",
      label: "Property location (street, city, state, zip code, country)",
      name: "address",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "select",
      label: "Type of ownership",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Corporate", text: "Corporate" },
        { value: "Joint tenancy", text: "Joint tenancy" },
        { value: "Partnership", text: "Partnership" },
        { value: "Sole ownership", text: "Sole ownership" },
        { value: "Tenancy in common", text: "Tenancy in common" },
        { value: "Tenants by entirety", text: "Tenants by entirety" },
        { value: "Trust", text: "Trust" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Date of purchase",
      name: "date",
      type: "date",
    },
    {
      component: "input",
      label: "Owners on title",
      name: "owners",
      type: "text",
      description:
        "The names of the owners of your real estate property legally recorded on the deed. These may be names of persons, a trust, or another entity such as a business you own. It’s important to have exact names on the deed.",
    },
    {
      component: "input",
      label: "Mortgage holder",
      name: "mortgageHolder",
      type: "text",
    },
    {
      component: "input",
      label: "Estimated annual rental profit",
      name: "rentalProfit",
      type: "text",
    },
    {
      component: "input",
      label: "Estimated current value",
      name: "currentValue",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
