export const stockLedgersData = {
  formTitle: 'Add or edit stock ledger',
  formSubtitle:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  pathname: '/stockLedgers',
  formFields: [
    {
      component: 'input',
      label: 'Company',
      name: 'company',
      type: 'text',
      required: true,
      colObject: true,
    },
    {
      component: 'input',
      label: 'Number of shares',
      name: 'shares',
      type: 'text',
    },
    {
      component: 'input',
      label: 'Date of origination',
      name: 'date',
      type: 'date',
    },
    {
      component: 'textarea',
      label: 'Notes',
      name: 'notes',
    },
    {
      component: 'input',
      label: 'Location of files / documents',
      name: 'fileLocation',
      type: 'text',
    },
  ],
};
