import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { restPost, restPut } from "../../store/api";
import { setTopicToggle } from "../../store/actions";
import { Toastr } from "../common/toastr";

import WhiteBtn from "../buttons/whiteButton";
import GreenBtn from "../buttons/greenBtn";

export default function Submit({
  pathname,
  reqBody,
  initialValues,
  innerCircle,
  children,
  setOpen,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.currentUser);

  const nameSend = currentUser.attributes.name;
  const successCallback = () => {
    setOpen(false);
    dispatch(setTopicToggle());
    Toastr({
      type: "success",
      message: "Add or update contact successful.",
    });
  };

  const failCallback = (err) => {
    Toastr({ type: "error", message: "Error system." });
  };

  const onSubmit = async (values) => {
    const result = reqBody(values);
    result.innerCircle = innerCircle;
    result.nameSend = nameSend;

    if (initialValues) {
      await restPut(pathname, result, successCallback, failCallback);
    } else {
      await restPost(pathname, result, successCallback, failCallback);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form
          onSubmit={handleSubmit}
          className="space-y-8 divide-y divide-gray-300 mb-6 -mt-2"
        >
          <div className="space-y-8 divide-y divide-gray-300 sm:space-y-5">
            {children}
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <WhiteBtn
                type="button"
                disabled={submitting}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/inner-circle");
                }}
              >
                Cancel
              </WhiteBtn>

              <GreenBtn type="submit" disabled={submitting}>
                Save
              </GreenBtn>
            </div>
          </div>
        </form>
      )}
    />
  );
}

