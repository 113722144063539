export const giftingToIndividualsData = {
    formTitle: "Add or edit gifting to individual",
    formSubtitle:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    pathname: "/giftingToIndividuals",
    formFields: [
        {
            component: "select",
            label: "Gifting structure",
            name: "giftingStructure",
            type: "text",
            required: true,
            colObject: true,
            options: [
                { value: "529 account", text: "529 account" },
                {
                    value: "Annual exclusion gifts",
                    text: "Annual exclusion gifts",
                },
                { value: "Charitable Trust", text: "Charitable Trust" },
                { value: "Crummey Trust", text: "Crummey Trust" },
                { value: "Family Trust", text: "Family Trust" },
                { value: "Gift In Trust", text: "Gift In Trust" },
                { value: "Outright direct gift", text: "Outright direct gift" },
                { value: "Personal account", text: "Personal account" },
                { value: "UTMA account", text: "UTMA account" },
                { value: "Other", text: "Other" },
            ],
        },
        {
            component: "input",
            label: "Recipients",
            name: "recipients",
            type: "text",
            required: true,
            colObject: true,
        },
        {
            component: "input",
            label: "Person(s) who have copies of this document",
            name: "personsCopy",
            type: "text",
            required: true,
            colObject: true,
        },
        {
            component: "mc",
            label: "Asset",
            name: "asset",
            values: [
                "Business",
                "Cash",
                "Personal property",
                "Real Estate",
                "Stocks / Bonds",
                "Other",
            ],
        },
        {
            component: "select",
            label: "Frequency",
            name: "frequency",
            type: "text",
            options: [
                { value: "Annnual", text: "Annnual" },
                { value: "One-time", text: "One-time" },
                { value: "Variable", text: "Variable" },
                { value: "Other", text: "Other" },
            ],
        },
        {
            component: "input",
            label: "Gift value estimate",
            name: "giftValueEstimate",
            type: "text",
        },
        {
            component: "input",
            label: "Attorney",
            name: "attorney",
            type: "text",
        },
        {
            component: "input",
            label: "Financial advisor",
            name: "financialAdvisor",
            type: "text",
        },
        {
            component: "input",
            label: "Date of last gifting",
            name: "firstDate",
            type: "date",
        },
        {
            component: "input",
            label: "Date of next gifting",
            name: "secondDate",
            type: "date",
        },
        {
            component: "textarea",
            label: "Notes",
            name: "notes",
        },
        {
            component: "input",
            label: "Location of files / documents",
            name: "fileLocation",
            type: "text",
        },
        {
            component: "input",
            label: "Person(s) who know about this plan",
            name: "persons",
            type: "text",
        },
    ],
};

