import TopicView from "./topic-view";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { updateFileUploadByAll } from "../../store/actions";
import { restGet } from "../../store/api";

export function View({ pathname }) {
    const dispatch = useDispatch();
    const files = useSelector((state) => state.fileUpload.byAll);
    const cabinetFiles =
        files && files.filter((item) => item.type === "cabinet");
    const [params] = useSearchParams();
    const apiPath = "/fileUploads/topic?id=" + params.get("id");

    useEffect(() => {
        restGet(
            apiPath,
            (data) => dispatch(updateFileUploadByAll(data)),
            (err) => console.error(err)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <TopicView path={pathname} files={cabinetFiles} />;
}

