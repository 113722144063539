import WhiteBtn from "../../components/buttons/whiteButton";
import GreenBtn from "../../components/buttons/greenBtn";

import { Form } from "react-final-form";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { restPost, restPut } from "../../store/api";
import { Toastr } from "../../components/common/toastr";
import { getUserDetails } from "../../store/actions";

const scrollTop = (shouldScroll = true) => {
    if (shouldScroll) {
        window.scrollTo(0, 0);
    }
};

const getPath = (pathname) => {
    const arr = pathname.split("/");
    let path = "/";
    for (let i = 1; i < 5; i++) {
        path = path + arr[i];
        if (i !== 4) {
            path = path + "/";
        }
    }

    return path;
};

export default function TopicForm({
    pathname,
    reqBody,
    initialValues,
    children,
    setTab,
    setSelectedOptionType,
}) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);

    const locationPathName = location.pathname.split("/");
    const mainBranch = locationPathName[1];
    const subBranch = locationPathName[2];
    const topicName = locationPathName[3];

    const path = getPath(location.pathname);

    const newSuccessCallback = (data) => {
        if (path.includes("/new")) {
            navigate(path.split("/new")[0] + "/edit?id=" + data._id + "&tab=2");
        } else {
            navigate(path + "/edit?id=" + data._id + "&tab=2");
        }
        Toastr({ type: "success", message: "Data successfully saved" });
        scrollTop();
    };

    const editSuccessCallback = (data) => {
        if (path.includes("/edit")) {
            setTab(2);
            navigate(
                path.split("/edit")[0] + "/edit?id=" + data._id + "&tab=2",
                {
                    replace: true,
                }
            );
        } else {
            navigate(path, { replace: true });
        }
        Toastr({ type: "success", message: "Data successfully edited." });
        scrollTop();
    };

    const failCallback = (err) => {
        Toastr({ type: "error", message: "Error with data." });
    };

    const updateFollowing = (mainBranch, subBranch, topicName) => {
        const updateFollowingArray = (following) => {
            let arr = [...following];
            arr.push({
                mainBranch: mainBranch,
                subBranch: subBranch,
                topicName: topicName,
            });

            return arr;
        };

        const exists = user.following.some(
            (item) =>
                item.mainBranch === mainBranch &&
                item.subBranch === subBranch &&
                item.topicName === topicName
        );

        if (!exists || !user.following) {
            restPut(
                "/users/update",
                {
                    following: updateFollowingArray(user.following),
                },
                (data) => dispatch(getUserDetails(data)),
                (err) => console.error(err)
            );
        }
    };

    const onSubmit = async (values) => {
        const result = reqBody(values);
        const resultValues = Object.values(result);

        if (
            values.hasOwnProperty("firstDate") &&
            values.hasOwnProperty("secondDate")
        ) {
            if (values["secondDate"] < values["firstDate"]) {
                Toastr({
                    type: "error",
                    message: "The time information entered is incorrect",
                });
                return;
            }
        }

        if (
            resultValues.every(
                (el) => el === undefined || el === "" || el.length < 1
            )
        ) {
            Toastr({
                type: "error",
                message: "At least one field must be filled.",
            });
            return;
        }

        if (initialValues) {
            if (pathname === "/supplementalSecurity(SSI)/edit") {
                pathname = "/supplementalSecurity/edit";
            }

            await restPut(pathname, result, editSuccessCallback, failCallback);
        } else {
            if (pathname === "/supplementalSecurity(SSI)/new") {
                pathname = "/supplementalSecurity/new";
            }
            
            await restPost(pathname, result, newSuccessCallback, failCallback);

            updateFollowing(mainBranch, subBranch, topicName);
            scrollTop(false);
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form
                    onSubmit={handleSubmit}
                    className="space-y-8 divide-y divide-gray-300 mb-6 -mt-2"
                >
                    <div className="space-y-8 divide-y divide-gray-300 sm:space-y-5">
                        {children}
                    </div>

                    <div className="pt-5">
                        <div className="flex justify-end">
                            <div className="mr-6 mx-1 py-2 text-sm font-medium italic text-primary-green">
                                <p>To import a file for this record,</p>
                                <p>
                                    you can do so on the next screen after
                                    clicking SAVE.
                                </p>
                            </div>
                            <WhiteBtn
                                type="button"
                                disabled={submitting}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(
                                        path
                                            .replace("/new", "")
                                            .replace("/edit", "")
                                    );
                                    scrollTop();
                                }}
                            >
                                Cancel
                            </WhiteBtn>

                            <GreenBtn
                                type="submit"
                                disabled={
                                    user === null ||
                                    user.type === "readonly" ||
                                    submitting
                                }
                            >
                                Save
                            </GreenBtn>
                        </div>
                    </div>
                </form>
            )}
        />
    );
}

