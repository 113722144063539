import { useState } from "react";

import { PlusIcon, MinusIcon } from "@heroicons/react/solid";

import { faqData } from "./data";
import PageTitle from "../common/page-title";

export default function FAQ() {
    const [show, setShow] = useState({
        open: true,
        idx: 0,
    });

    const onClickHandler = (index) => {
        setShow((prevState) => ({
            ...prevState,
            open: show.idx === index ? !prevState.open : true,
            idx: index,
        }));
    };

    return (
        <>
            <PageTitle title="My Banyan" />

            <div className="max-w-3xl mx-auto py-5 px-2 sm:px-10 2xl:px-0">
                {faqData.map((item, index) => (
                    <div
                        key={index}
                        className={`${index === 0 ? null : "pt-5"}`}
                    >
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                onClickHandler(index);
                            }}
                            className={`${
                                show.open && show.idx === index
                                    ? "bg-primary-green text-white"
                                    : "bg-primary-green/[0.15] text-primary-green"
                            } py-3 px-4 hover:bg-primary-green flex items-center justify-between hover:text-white cursor-pointer`}
                        >
                            <p className="text-xl font-semibold">
                                {item.title} FAQs
                            </p>
                            {show.open && show.idx === index ? (
                                <MinusIcon className="w-6" />
                            ) : (
                                <PlusIcon className="w-6" />
                            )}
                        </div>
                        {show.open && show.idx === index && (
                            <div className="pb-5">
                                {item.faqs.map((f, fIdx) => (
                                    <div key={fIdx} className="pt-5 md:pt-10">
                                        <p className="pb-3 md:text- font-semibold text-black">
                                            {f.q}
                                        </p>
                                        <p className="md:text-base">{f.a}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </>
    );
}

