export const realEstateHoldingsData = {
    formTitle: "Add or edit real estate holding",
    formSubtitle:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    pathname: "/realEstateHoldings",
    formFields: [
        {
            component: "input",
            label: "Property name, if any",
            name: "property",
            type: "text",
            required: true,
            colObject: true,
        },
        {
            component: "textarea",
            label: "Street, City, State, Zip Code",
            name: "address",
            type: "text",
            required: true,
            colObject: true,
        },
        {
            component: "input",
            label: "Country",
            name: "country",
            type: "text",
            required: true,
            colObject: true,
        },
        {
            component: "input",
            label: "Rent amount",
            name: "rentAmount",
            type: "text",
            required: true,
            colObject: true,
        },
        {
            component: "select",
            label: "Type of property",
            name: "typeOfProperty",
            type: "text",
            options: [
                { value: "My primary residence", text: "My primary residence" },
                {
                    value: "My secondary residence",
                    text: "My secondary residence",
                },
                {
                    value: "My vacation residence",
                    text: "My vacation residence",
                },
                { value: "Agriculture", text: "Agriculture" },
                { value: "Commercial", text: "Commercial" },
                {
                    value: "Cooperative apartment building",
                    text: "Cooperative apartment building",
                },
                { value: "Industrial", text: "Industrial" },
                { value: "Mixed use", text: "Mixed use" },
                {
                    value: "Residential rental: short-term",
                    text: "Residential rental: short-term",
                },
                {
                    value: "Residential rental: long-term",
                    text: "Residential rental: long-term",
                },
                { value: "Time share", text: "Time share" },
                { value: "Trust-owned", text: "Trust-owned" },
                { value: "Vacant land", text: "Vacant land" },
                {
                    value: "Water (lakes, streams)",
                    text: "Water (lakes, streams)",
                },
                { value: "Other", text: "Other" },
            ],
        },
        {
            component: "mc",
            label: "My goal for this property",
            name: "goal",
            values: [
                "Develop and sell",
                "Enjoy",
                "Income generation",
                "Pass on to my heirs",
                "Sell",
                "Uncertain about my goal",
                "Other",
            ],
        },
        {
            component: "input",
            label: "Name of person(s) on deed",
            name: "persons",
            type: "text",
        },
        {
            component: "input",
            label: "Name of entity on deed",
            name: "entity",
            type: "text",
        },
        {
            component: "input",
            label: "Name of property manager, if any",
            name: "propertyManager",
            type: "text",
        },
        {
            component: "phoneNumber",
            label: "Phone number of property manager",
            name: "phone",
        },
        {
            component: "input",
            label: "Recent renovations / upgrades to the property",
            name: "renovations",
            type: "text",
        },
        {
            component: "input",
            label: "Square feet",
            name: "squareFeet",
            type: "text",
        },
        {
            component: "input",
            label: "Purchase date",
            name: "purchaseDate",
            type: "date",
        },
        {
            component: "input",
            label: "Most recent estimated value",
            name: "estimatedValue",
            type: "text",
        },
        {
            component: "input",
            label: "Date of estimated value",
            name: "estimatedValueDate",
            type: "date",
        },
        {
            component: "input",
            label: "Next date for estimated value",
            name: "nextDate",
            type: "date",
        },
        {
            component: "input",
            label: "Annual property tax",
            name: "tax",
            type: "text",
        },
        {
            component: "input",
            label: "Payment frequency",
            name: "paymentFreq",
            type: "text",
        },
        {
            component: "textarea",
            label: "Notes",
            name: "notes",
        },
        {
            component: "input",
            label: "Location of files / documents",
            name: "fileLocation",
            type: "text",
        },
    ],
};

