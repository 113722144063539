export const careProvidersFriendsFamilyData = {
  formTitle: "Add or edit care providers: friend / family",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/careProvidersFriendsFamily",
  formFields: [
    {
      component: "input",
      label: "Name",
      name: "name",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Reason for selecting this person",
      name: "reason",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
