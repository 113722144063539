import PageTitle from "../../components/common/page-title";
import { ReportList } from "../../components/report/list";

export default function Report({ page }) {
    return (
        <>
            <PageTitle title="My Banyan" />

            <ReportList />
        </>
    );
}

