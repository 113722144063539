export const militaryStatusData = {
  formTitle: "Add or edit military status",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/militaryStatus",
  formFields: [
    {
      component: "input",
      label: "Date of enlistment",
      name: "enlistmentDate",
      type: "date",
      colObject: true,
    },
    {
      component: "input",
      label: "Positions and branches of service",
      name: "positions",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Honors",
      name: "honors",
      type: "text",
    },
    {
      component: "input",
      label: "Date of discharge",
      name: "dischargeDate",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
