export const incomeSourcesData = {
  formTitle: "Add or edit my income source",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/incomeSources",
  formFields: [
    {
      component: "select",
      label: "Type of income",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Annuities", text: "Annuities" },
        { value: "Commission", text: "Commission" },
        { value: "Consulting fees", text: "Consulting fees" },
        { value: "Deferred compensation", text: "Deferred compensation" },
        { value: "Dividends / interest", text: "Dividends / interest" },
        { value: "Fees for service", text: "Fees for service" },
        { value: "Hourly wages", text: "Hourly wages" },
        { value: "Military benefits", text: "Military benefits" },
        { value: "Pension", text: "Pension" },
        { value: "Profit sharing", text: "Profit sharing" },
        { value: "Rent", text: "Rent" },
        {
          value: "Required minimum distribution (RMD)",
          text: "Required minimum distribution (RMD)",
        },
        { value: "Retainer", text: "Retainer" },
        { value: "Salary", text: "Salary" },
        { value: "Securities", text: "Securities" },
        { value: "Social Security", text: "Social Security" },
        { value: "Stock options", text: "Stock options" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Source",
      name: "source",
      type: "text",
    },
    {
      component: "input",
      label: "Annual payout",
      name: "payout",
      type: "text",
      description:
        "Your estimated total income from all sources over the period of one year. Your most recent tax return and your cash flow plan are the most accurate sources of this information.",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
