export const permitsData = {
  formTitle: 'Add or edit permit',
  formSubtitle:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  pathname: '/permits',
  formFields: [
    {
      component: 'input',
      label: 'Company',
      name: 'company',
      type: 'text',
      required: true,
      colObject: true,
    },
    {
      component: 'input',
      label: 'Parties',
      name: 'parties',
      type: 'text',
    },
    {
      component: 'input',
      label: 'Description',
      name: 'description',
      type: 'text',
    },
    {
      component: 'input',
      label: 'Date of origination',
      name: 'originationDate',
      type: 'date',
    },
    {
      component: 'input',
      label: 'Next review date',
      name: 'nextReviewDate',
      type: 'date',
    },
    {
      component: 'input',
      label: 'Attorney name',
      name: 'attorney',
      type: 'text',
    },
    {
      component: 'input',
      label: 'Value',
      name: 'value',
      type: 'text',
    },
    {
      component: 'textarea',
      label: 'Notes',
      name: 'notes',
    },
    {
      component: 'input',
      label: 'Location of files / documents',
      name: 'fileLocation',
      type: 'text',
    },
  ],
};
