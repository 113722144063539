export const howISeeMySeniorLifeData = {
  formTitle: "Add or edit how I see my senior life",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/howISeeMySeniorLife",
  formFields: [
    {
      component: "input",
      label: "What I look forward to",
      name: "lookForward",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "What I am most concerned about",
      name: "concerned",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "People I might consult about my senior life",
      name: "consultPeople",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
  ],
};
