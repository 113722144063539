export const estimatedExpensesData = {
  formTitle: "Add or edit estimated expense",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/estimatedExpenses",
  formFields: [
    {
      component: "input",
      label: "Funeral services",
      name: "funeral",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Ceremony leaders",
      name: "leaders",
      type: "text",
    },
    {
      component: "input",
      label: "Ceremony venue rental",
      name: "venue",
      type: "text",
    },
    {
      component: "input",
      label: "Cremation",
      name: "cremation",
      type: "text",
    },
    {
      component: "input",
      label: "Urn",
      name: "urn",
      type: "text",
    },
    {
      component: "input",
      label: "Casket",
      name: "casket",
      type: "text",
    },
    {
      component: "input",
      label: "Flowers",
      name: "flowers",
      type: "text",
    },
    {
      component: "input",
      label: "Entertainment",
      name: "entertainment",
      type: "text",
    },
    {
      component: "input",
      label: "Printing",
      name: "printing",
      type: "text",
    },
    {
      component: "input",
      label: "Decorations",
      name: "decorations",
      type: "text",
    },
    {
      component: "input",
      label: "Transportation",
      name: "transportation",
      type: "text",
    },
    {
      component: "input",
      label: "Gravesite acquisition",
      name: "acquisition",
      type: "text",
    },
    {
      component: "input",
      label: "Gravesite burial",
      name: "burial",
      type: "text",
    },
    {
      component: "input",
      label: "Gravesite marker and inscription",
      name: "markerInscription",
      type: "text",
    },
    {
      component: "input",
      label: "Post-ceremony reception",
      name: "reception",
      type: "text",
    },
    {
      component: "input",
      label: "Other",
      name: "other",
      type: "text",
    },
    {
      component: "input",
      label: "Estimated total",
      name: "estimatedTotal",
      type: "number",
      colObject: true,
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
