export const businessAssetsData = {
  formTitle: "Add or edit business asset",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/businessAssets",
  formFields: [
    {
      component: "input",
      label: "Company name",
      name: "company",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "select",
      label: "Type of asset",
      name: "asset",
      type: "text",
      colObject: true,
      options: [
        { value: "Accounts receivable", text: "Accounts receivable" },
        { value: "Cash", text: "Cash" },
        { value: "Equipment", text: "Equipment" },
        { value: "Good will", text: "Good will" },
        { value: "Intellectual property", text: "Intellectual property" },
        { value: "Inventory", text: "Inventory" },
        { value: "Marketable securities", text: "Marketable securities" },
        {
          value: "Plants, manufacturing facilities",
          text: "Plants, manufacturing facilities",
        },
        { value: "Property", text: "Property" },
        { value: "Raw materials", text: "Raw materials" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Value",
      name: "value",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
