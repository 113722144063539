import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

import { restPost, restPut, restDelete } from "../../../store/api";
import { Fieldset, Text, Textarea, Checkbox } from "./inputs";
import {
    todoSetToggle,
    updateCalendarByAll,
    updateNotificationByAll,
} from "../../../store/actions";
import { useEffect, useState } from "react";
import { Toastr } from "../../common/toastr";

const today = new Date().toISOString().split("T")[0];

export function FormSubmit({ apiPath, setOpen, initialValues }) {
    const dispatch = useDispatch();

    const auth = useSelector((state) => state.auth.currentUser);
    const [noDate, setNoDate] = useState(false);
    const [allDay, setAllDay] = useState(false);
    const [range, setRange] = useState(false);

    const successCallback = (data) => {};

    const successPutCallback = (data) => {
        setOpen(false);
        dispatch(todoSetToggle());
        dispatch(updateCalendarByAll([data]));
        dispatch(updateNotificationByAll(data));
        Toastr({
            type: "success",
            message: "Task successfully edited.",
        });
    };

    const successPostCallback = (data) => {
        setOpen(false);
        dispatch(todoSetToggle());
        dispatch(updateCalendarByAll([data]));
        dispatch(updateNotificationByAll(data));
        Toastr({
            type: "success",
            message: "Task successfully created.",
        });
    };

    const successDeleteCallback = (data) => {
        setOpen(false);
        dispatch(todoSetToggle());
        dispatch(updateNotificationByAll(data));
        Toastr({
            type: "success",
            message: "Task successfully deleted.",
        });
    };

    const failCallback = (err) => {
        console.error("error: ", err);
    };

    const validate = (values) => {
        const errors = {};
        if (!values.title) {
            errors.title = "Task is required";
        }

        return errors;
    };

    const onSubmit = async (values) => {
        if (values.allDay && values.when === undefined) {
            Toastr({ type: "error", message: "Date is required" });
            return;
        }

        const content = {
            title: "Expiration time of to-do " + values["title"],
            allDay: true,
            when: values["when"],
            notes: "Expiration time of to-do " + values["title"],
        };
        if (initialValues) {
            if (values.noDate) {
                values["isTodo"] = true;
            }
            if (values.allDay) {
                values["starts"] = null;
                values["ends"] = null;
                values["isTodo"] = false;
                values["atTime"] = null;
            }

            if (!values.noDate && !values.allDay) {
                values["isTodo"] = false;
            }
            await restPut(
                "/" + apiPath + "/update/" + initialValues._id,
                values,
                successPutCallback,
                failCallback
            );

            if (
                values["isTodo"] === false &&
                values.when &&
                values.when !== null
            ) {
                const timezone =
                    Intl.DateTimeFormat().resolvedOptions().timeZone;
                content["email"] = auth.attributes.email;
                content["topic"] = values["title"];
                content["name"] = auth.attributes.name;
                content["timezone"] = timezone;
                await restPost(
                    "/" + apiPath + "/renewal",
                    content,
                    successCallback,
                    failCallback
                );
            }
        } else {
            if (values.allDay) {
                values["starts"] = null;
                values["ends"] = null;
            }
            await restPost(
                "/" + apiPath + "/new",
                values,
                successPostCallback,
                failCallback
            );
            if (values.when && values.when !== null) {
                const timezone =
                    Intl.DateTimeFormat().resolvedOptions().timeZone;
                content["email"] = auth.attributes.email;
                content["topic"] = values["title"];
                content["name"] = auth.attributes.name;
                content["timezone"] = timezone;
                await restPost(
                    "/" + apiPath + "/renewal",
                    content,
                    successCallback,
                    failCallback
                );
            }
        }
    };

    const deleteHandler = () => {
        restDelete(
            "/" + apiPath + "/delete/" + initialValues._id,
            {},
            successDeleteCallback,
            failCallback
        );
    };

    useEffect(() => {
        setNoDate(initialValues?.isTodo);
        if (
            initialValues?.isTodo === false &&
            initialValues?.atTime === undefined &&
            initialValues?.starts === null
        ) {
            setAllDay(true);
        }
        if (initialValues?.ends) {
            setRange(true);
        }
    }, [initialValues?.isTodo]);

    return (
        <Fieldset title="Add a new task" subtitle={"Task needs to have title"}>
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues ? initialValues : null}
                validate={validate}
                render={({
                    handleSubmit,
                    form,
                    submitting,
                    pristine,
                    values,
                }) => (
                    <form onSubmit={handleSubmit} className="mt-2 space-y-6">
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <Text
                                name="title"
                                type="text"
                                autoComplete="new-password"
                                required
                                label="Task *"
                                placeholder="New task"
                                size={"sm:col-span-6"}
                            />
                            <Text
                                name="location"
                                type="text"
                                autoComplete="new-password"
                                label="Location"
                                placeholder="Where"
                                size={"sm:col-span-6"}
                            />
                            <Checkbox
                                name="noDate"
                                autoComplete="new-password"
                                label="No date and time"
                                size={"sm:col-span-3"}
                                checked={noDate}
                                onClick={() => {
                                    setNoDate(!noDate);
                                    setAllDay(false);
                                    setRange(false);
                                }}
                            />
                            <Checkbox
                                name="allDay"
                                autoComplete="new-password"
                                label="All day"
                                size={"sm:col-span-3"}
                                checked={allDay}
                                onClick={() => {
                                    setAllDay(!allDay);
                                    setNoDate(false);
                                    setRange(false);
                                }}
                            />
                            <Checkbox
                                name="range"
                                autoComplete="new-password"
                                label="Range"
                                size={"sm:col-span-3"}
                                checked={range}
                                onClick={() => {
                                    setRange(!range);
                                    setNoDate(false);
                                }}
                            />
                            <div></div>
                            {noDate || range ? null : (
                                <>
                                    <Text
                                        name="when"
                                        type="date"
                                        autoComplete="new-password"
                                        min={today}
                                        label="Date"
                                        size={"sm:col-span-3"}
                                    />
                                    {allDay ? null : (
                                        <Text
                                            name="atTime"
                                            type="time"
                                            autoComplete="new-password"
                                            label="Time"
                                            size={"sm:col-span-3"}
                                        />
                                    )}
                                </>
                            )}
                            {!range ? null : (
                                <>
                                    <Text
                                        name="starts"
                                        type="date"
                                        autoComplete="new-password"
                                        label="Start Date"
                                        size={"sm:col-span-3"}
                                    />
                                    <Text
                                        name="ends"
                                        type="date"
                                        autoComplete="new-password"
                                        label="End Date"
                                        size={"sm:col-span-3"}
                                    />
                                </>
                            )}
                            <Textarea
                                name="notes"
                                autoComplete="new-password"
                                label={"Notes"}
                                size={"sm:col-span-6"}
                            />
                            <button
                                type="submit"
                                className="sm:col-span-6 px-4 py-2 text-sm font-medium bg-primary-green hover:bg-white disabled:bg-white text-white hover:text-primary-green disabled:text-primary-green border-2 border-primary-green focus:outline-none rounded-md disabled:cursor-not-allowed transition duration-300"
                            >
                                Save
                            </button>

                            {initialValues && initialValues._id && (
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        deleteHandler();
                                    }}
                                    className="sm:col-span-6 px-4 py-2 text-sm font-medium bg-red-500 hover:bg-white disabled:bg-white text-white hover:text-red-500 disabled:text-red-500 border-2 border-red-500 focus:outline-none rounded-md disabled:cursor-not-allowed transition duration-300"
                                >
                                    Delete
                                </button>
                            )}
                        </div>
                    </form>
                )}
            />
        </Fieldset>
    );
}

