import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { restGet, restPost } from "../../store/api";
import { getUserDetails } from "../../store/actions";
import PageTitle from "../../components/common/page-title";
import Welcome from "./registration-journey/welcome";
import InnerCircle from "./registration-journey/inner-circle";
import SelectInnerCircle from "./registration-journey/select-inner-circle";
import SelectBranches from "./registration-journey/select-branches";
import Code from "./registration-journey/code";
import ChoosePriorities from "../choose-priorities";
import Home from "./home";

const DIALOG_WELCOME = 0;
const DIALOG_INNER_CIRCLE = 1;
const DIALOG_SELECT_INNER_CIRCLE = 2;
const DIALOG_SELECT_BRANCHES = 3;
const DIALOG_CODE = 4;

export default function Dashboard() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const currentUser = useSelector((state) => state.auth.currentUser);

  const [dialogNum, setDialogNum] = useState(0);
  const [emailSent, setEmailSent] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [showHome, setShowHome] = useState(true);

  const name = currentUser?.attributes.name;
  const email = currentUser?.attributes.email;

  useEffect(() => {
    const getData = () => {
      restGet(
        "/users/show",
        (data) => {
          dispatch(getUserDetails(data));
        },
        (err) => console.error(err)
      );
    };

    getData();
  }, []);

  useEffect(() => {
    const thankyou = user?.thankyou;

    if (!emailSent && thankyou === false && currentUser) {
      if (currentUser) {
        const reqBody = {
          name: name,
          email: email,
          uid: user.uid,
        };

        restPost(
          "/email/thankyou",
          reqBody,
          () => {
            setEmailSent(true);
          },
          (err) => console.error(err)
        );
      }
    }
  }, [currentUser, email, emailSent, name, user]);

  return (
    <>
      <PageTitle title="My Banyan" />

      {user && (
        <>
          {/* REGISTRATION JOURNEY */}
          <>
            {user?.quickstart && dialogNum === DIALOG_WELCOME && <Welcome setDialogNum={setDialogNum} />}

            {user?.quickstart && dialogNum === DIALOG_INNER_CIRCLE && <InnerCircle setDialogNum={setDialogNum} />}

            {user?.quickstart && dialogNum === DIALOG_SELECT_INNER_CIRCLE && <SelectInnerCircle setDialogNum={setDialogNum} />}

            {user?.quickstart && dialogNum === DIALOG_SELECT_BRANCHES && <SelectBranches setDialogNum={setDialogNum} />}

            {user?.quickstart === false && dialogNum === DIALOG_CODE && <Code setDialogNum={setDialogNum} />}
          </>

          {showReset && <ChoosePriorities setShowReset={setShowReset} setShowHome={setShowHome} />}

          {showHome && <Home user={user} setShowReset={setShowReset} setShowHome={setShowHome} />}
        </>
      )}
    </>
  );
}

