function GreenBtn({ type, disabled, onClick, children }) {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className="mx-1 px-4 py-2 text-sm font-medium bg-primary-green hover:bg-white disabled:bg-white text-white hover:text-primary-green disabled:text-primary-green border-2 border-primary-green focus:outline-none rounded-md disabled:cursor-not-allowed transition duration-300"
    >
      {children}
    </button>
  );
}

export default GreenBtn;
