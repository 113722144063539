export const passwordManagementSoftwareData = {
    formTitle: "Add or edit password management software",
    formSubtitle:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    pathname: "/passwordManagementSoftware",
    formFields: [
        {
            component: "select",
            label: "Name of password software / file",
            name: "software",
            type: "text",
            required: true,
            colObject: true,
            options: [
                { value: "1Password", text: "1Password" },
                { value: "BitWarden", text: "BitWarden" },
                { value: "Blur", text: "Blur" },
                { value: "Dashland", text: "Dashland" },
                { value: "Enpass", text: "Enpass" },
                { value: "Excel spreadsheet", text: "Excel spreadsheet" },
                { value: "KeePass", text: "KeePass" },
                { value: "Keeper", text: "Keeper" },
                { value: "LastPass", text: "LastPass" },
                { value: "Roboform", text: "Roboform" },
                { value: "True Key", text: "True Key" },
                {
                    value: "Web browser (Firefox, Chrome, Safari, et al)",
                    text: "Web browser (Firefox, Chrome, Safari, et al)",
                },
                {
                    value: "Word / Excel document",
                    text: "Word / Excel document",
                },
                { value: "Zoho Vault", text: "Zoho Vault" },
                { value: "Other", text: "Other" },
            ],
        },
        {
            component: "input",
            label: "Location of software / file",
            name: "softwareLocation",
            type: "text",
        },
        {
            component: "input",
            label: "Person(s) who have access to this program",
            name: "access",
            type: "text",
        },
        {
            component: "input",
            label: "Website URL",
            name: "website",
            type: "text",
        },
        {
            component: "input",
            label: "Serial number",
            name: "serialNo",
            type: "text",
        },
        {
            component: "input",
            label: "Name(s) on account",
            name: "names",
            type: "text",
            description:
                "Share the name(s) and access information on these accounts with your loved ones.",
        },
        {
            component: "input",
            label: "Login username (or its location)",
            name: "username",
            type: "text",
        },
        {
            component: "password",
            label: "Password (or its location)",
            name: "password",
            type: "password",
        },
        {
            component: "input",
            label: "Renewal date",
            name: "date",
            type: "date",
        },
        {
            component: "input",
            label: "Renewal fee",
            name: "fee",
            type: "text",
        },
        {
            component: "textarea",
            label: "Notes",
            name: "notes",
        },
        {
            component: "input",
            label: "Location of files / documents",
            name: "fileLocation",
            type: "text",
        },
    ],
};

