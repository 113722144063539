export const bloodTypeData = {
  formTitle: 'Add or edit blood type',
  formSubtitle:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  pathname: '/bloodType',
  formFields: [
    {
      component: 'select',
      label: 'My type',
      name: 'bloodType',
      type: 'text',
      required: true,
      colObject: true,
      options: [
        {
          value: 'O+  (38% of US population)',
          text: 'O+  (38% of US population)',
        },
        { value: 'O-  (7%)', text: 'O-  (7%)' },
        { value: 'A+  (36%)', text: 'A+  (36%)' },
        { value: 'A-  (6%)', text: 'A-  (6%)' },
        { value: 'B+  (9%)', text: 'B+  (9%)' },
        { value: 'B-  (2%)', text: 'B-  (2%)' },
        { value: 'AB+  (3%)', text: 'AB+  (3%)' },
        { value: 'AB-  (0.6%)', text: 'AB-  (0.6%)' },
      ],
    },
    {
      component: 'select',
      label: 'I can receive these blood types',
      name: 'receive',
      type: 'text',
      options: [
        {
          value: 'O+ can receive only O+, O- blood',
          text: 'O+ can receive only O+, O- blood',
          id: 1,
        },
        {
          value: 'O- can receive only O- blood',
          text: 'O- can receive only O- blood',
          id: 2,
        },
        {
          value: ' A+ can receive only A+, A-. O+, O- blood',
          text: ' A+ can receive only A+, A-. O+, O- blood',
          id: 3,
        },
        {
          value: 'A- can receive only A-, O- blood',
          text: 'A- can receive only A-, O- blood',
          id: 4,
        },
        {
          value: 'B+ can receive only B+, B-, O+, O- blood',
          text: 'B+ can receive only B+, B-, O+, O- blood',
          id: 5,
        },
        {
          value: 'B- can receive only B-, O- blood',
          text: 'B- can receive only B-, O- blood',
          id: 6,
        },
        {
          value: 'AB+ can receive all blood types (universal recipient)',
          text: 'AB+ can receive all blood types (universal recipient)',
          id: 7,
        },
        {
          value: 'AB- can receive only AB-, A-, B-, O- blood',
          text: 'AB- can receive only AB-, A-, B-, O- blood',
          id: 8
        },
      ],
    },
    {
      component: 'select',
      label: 'I can donate to these blood types',
      name: 'donate',
      type: 'text',
      options: [
        {
          value: 'O+ can donate blood only to O+, A+, B+, AB+',
          text: 'O+ can donate blood only to O+, A+, B+, AB+',
          id: 1,
        },
        {
          value: 'O- can donate blood to all types (universal donor)',
          text: 'O- can donate blood to all types (universal donor)',
          id: 2,
        },
        {
          value: 'A+ can donate blood only to A+, AB+',
          text: 'A+ can donate blood only to A+, AB+',
          id: 3,
        },
        {
          value: 'A- can donate blood only to A+, A-, AB+, AB-',
          text: 'A- can donate blood only to A+, A-, AB+, AB-',
          id: 4
        },
        {
          value: 'B+ can donate blood only to B+, AB+',
          text: 'B+ can donate blood only to B+, AB+',
          id: 5,
        },
        {
          value: 'B- can donate blood only to B+, B-, AB+, AB-',
          text: 'B- can donate blood only to B+, B-, AB+, AB-',
          id: 6,
        },
        {
          value:
            'AB+ can donate blood only to AB+, AB-. It is also a universal plasma donor',
          text: 'AB+ can donate blood only to AB+, AB-. It is also a universal plasma donor',
          id: 7,
        },
        {
          value:
            'AB- can donate blood only to AB+, AB-. It is also a universal plasma donor',
          text: 'AB- can donate blood only to AB+, AB-. It is also a universal plasma donor',
          id: 8
        },
      ],
    },
    {
      component: "input",
      label: "Person who has copies",
      name: "personWhoHasCopies",
      type: "text",
    },
    {
      component: 'textarea',
      label: 'Notes',
      name: 'notes',
    },
    {
      component: 'input',
      label: 'Location of files / documents',
      name: 'fileLocation',
      type: 'text',
    },
  ],
};
