export const tfaqData = {
  squares: [
    {
      img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/tutorials-%26-faqs/AdobeStock_104008900.jpg",
      imgAlt: "#",
      title: "tutorials",
      link: "tutorials",
    },
    {
      img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/tutorials-%26-faqs/AdobeStock_145881320.jpg",
      imgAlt: "#",
      title: "faq",
      link: "faq",
    },
  ],
};

export const faqData = [
  {
    title: "About Us",
    faqs: [
      {
        q: "Why is it called My Banyan?",
        a: "Have you ever seen a banyan tree? Its size is extraordinary. A single tree spreads a broad protective canopy of branches that can grow up to 80 feet high and span an entire city block.  For centuries people have gathered under banyan trees to conduct business and hold private conversations. The banyan tree is our symbol for the extraordinary place we’ve created for you to organize, store, and shelter your most important plans and information. Under its protective canopy, you have a safe place to share your plans with your Inner Circle.",
      },
      {
        q: "Who is behind My Banyan?",
        a: "My Banyan is the brainchild of Peter Lewis and Brian Harris. Brian has been a successful entrepreneur and business consultant for more than 35 years. He is internationally recognized as a pioneer and thought leader in consumer marketing. He developed and successfully brought to market a number of consumer marketing methods and tools used around the world today. Peter has many years of experience as a strategic planning consultant to senior executives. He is a renowned trainer, meeting facilitator, and public speaker. He has had over 300 U.S. and international clients, including Fortune 25 companies and large governmental agencies.",
      },
    ],
  },
  {
    title: "Usage",
    faqs: [
      {
        q: "I am currently using one of your competitors, why should I switch?",
        a: "As you go through your life, you need to manage more information. My Banyan lets you gather, organize and store all of it in one protected place. It can easily replace up to 10 different software packages from passwords and banking to Investments and property. My Banyan also lets you securely share this information with loved ones and/or trusted advisors. You control who sees what.",
      },
      {
        q: "Can I transfer all my data from a competitor to you?",
        a: "If you can export the information from a competitor, you may be able to upload it to My Banyan in a future version of My Banyan.",
      },
      {
        q: "Can I use my existing personal affairs organization system on My Banyan?",
        a: "My Banyan is already an extremely organized well of repositories and digital drawers. We believe you will be happy to see how you can Banyanize your data with our intuitive, comprehensive list of life planning categories and tools.",
      },
      {
        q: "Can I use My Banyan outside of the United States?",
        a: "Yes. You can use My Banyan anywhere is the world.",
      },
      {
        q: "Can I use My Banyan if I don’t live in the U.S.?",
        a: "Yes. My Banyan lives in the cloud, so as long as you have an internet connection you can use My Banyan.",
      },
      {
        q: "What is the difference between My Banyan and free storage cloud services such as Dropbox, Google Drive, Microsoft One Drive, iDrive, and others?",
        a: "Services such as Dropbox are static containers and cloud storage systems for sharing files. They’re not designed to remind you about keeping your information current, and offer no reporting. And security is a concern with such platforms.",
      },
      {
        q: "Can I use My Banyan for my business?",
        a: "Yes. My Banyan includes a business section where you can store and share all kinds of information from agreements and stocks to business insurance.",
      },
    ],
  },
  {
    title: "Account",
    faqs: [
      {
        q: "Do you have a free trial?",
        a: "Yes. You can try My Banyan for 21 days for free.",
      },
      {
        q: "Does My Banyan have a monthly or annual subscription?",
        a: "Due to the amount of information My Banyan contains and its flexibility, we are only offering an annual subscription, which allows for the most efficient use of the site.",
      },
      {
        q: "How does billing work?",
        a: "Billing is done through STRIPE when you are securely logged in to My Banyan. STRIPE is a certified PCI Service Provider Level 1, the most stringent level of certification available in the payments industry. STRIPE uses best-in-class security tools and practices to maintain this security level. No information about your credit card is kept at My Banyan.",
      },
      {
        q: "Does My Banyan offer a discount for family members, first Responders, military personnel, or teachers?",
        a: "Yes. We offer a discounted membership of $125 per year for first responders, military service members and veterans.",
      },
      {
        q: "If I die or become incapacitated, who can access my account?",
        a: "Only your trusted advisors or family members can access your account, and then only if you have given them access as a member of your Inner Circle.",
      },
      {
        q: "If I do not renew my subscription what happens to the information I have entered?",
        a: "Your information is securely stored for 60 days. If you do not renew your subscription before the 60 days are over, your files will be permanently deleted.",
      },
      {
        q: "Can I pause the account and decided to renew later?",
        a: "There is no “pause” feature, but you can cancel or deny renewal of your annual subscription at any time.",
      },
      {
        q: "How do I cancel my account?",
        a: "You can cancel the account online in your account settings.",
      },
    ],
  },
  {
    title: "Security",
    faqs: [
      {
        q: "Is My Banyan secure?",
        a: "You can trust My Banyan with your most confidential information. We take your trust very seriously, and the protection of your privacy and data is our first priority. The only people who see your information are those whom you designate. My Banyan uses the industry’s most sophisticated encrypted servers and operates under the same security and privacy frameworks as major financial institutions and healthcare companies.",
      },
      {
        q: "What is HIPAA and SOC 2 Compliance?",
        a: "Data privacy a significant concern. HIPAA (Health Insurance Portability and Accountability Act) and SOC2 (System and Organizations Controls) are federal standards for protecting and securing PHI (Protected Health Information).",
      },
      {
        q: "What is two-step verification, and do I have to use it?",
        a: "Two-step verification helps make sure your personal information stays private, safe, and secure when you sign into My Banyan. In addition to your password, two-step verification adds a quick second check to verify that it's you. This extra verification is a proven way to prevent widespread cyberattacks.",
      },
      {
        q: "Where is my personal information and data stored?",
        a: "All your data is stored on a secure AWS platform. AWS pioneered cloud computing in 2006, creating cloud infrastructure that allows sites to securely build and innovate faster. AWS allows users to control where data is stored and who can access it.",
      },
      {
        q: "Do you share my personal information?",
        a: "By clicking on Do Not Sell or Share when you sign up for a My Banyan account, you are indicating your choice to opt out of the sale or sharing of your personal information and your activity on this website, which certain third-party advertisers may capture via the use of cookies or pixels.",
      },
      {
        q: "Who has access to my personal information stored in My Banyan?",
        a: "Your personal information is encrypted on our servers, so even if we were able to locate your information, there is no way for us to read or access it.",
      },
      {
        q: "How do I change my password or email?",
        a: "You can change your password or email at any time in your account preferences when logged in.",
      },
    ],
  },
  {
    title: "Feature",
    faqs: [
      {
        q: "What is the difference between My Inner Circle and My Contacts?",
        a: "A contact is anyone whose contact information you enter in My Banyan. Your Inner Circle is an exclusive group of contacts with whom you’ll share and discuss your most sensitive information and plans. Your Inner Circle are the most trusted people in your life, such as loved ones and trusted advisors.",
      },
      {
        q: "Is there a how-to section for all My Banyan planning categories?",
        a: "Yes. We have tutorials, descriptions, and quick-start tips for all sections of My Banyan.",
      },
      {
        q: "Can I customize the application?",
        a: "You can customize your favorite categories and subjects during the sign-up process.",
      },
      {
        q: "Does My Banyan have a smartphone app?",
        a: "We are working on both iPhone and Android apps, expected to launch in 2024.",
      },
    ],
  },
  {
    title: "Sharing",
    faqs: [
      {
        q: "What My Banyan information can I share?",
        a: "You can share any subject, file, or information that you enter in My Banyan with your Inner Circle.",
      },
      {
        q: "Does My Banyan allow file sharing?",
        a: "Yes. We allow sharing with and from your Inner Circle.",
      },
    ],
  },
  {
    title: "Productivity",
    faqs: [
      {
        q: "Does My Banyan have progress tracking tools?",
        a: "Yes. My Banyan’s dashboard will show you up to five of the last topics and categories you’ve worked on.",
      },
      {
        q: "Does My Banyan have personal reminders?",
        a: "Yes. My Banyan will remind you of important dates and events via the calendar tool.",
      },
      {
        q: "Does My Banyan have to-do lists?",
        a: "Yes. To-do lists are integrated in our calendar tool.",
      },
      {
        q: "Does My Banyan have checklists?",
        a: "Yes. My Banyan has custom checklists for some of the most important and unforeseen events in your life.",
      },
    ],
  },
  {
    title: "Support",
    faqs: [
      {
        q: "If I have a question about My Banyan, who can help?",
        a: "You can reach My Banyan support at support@mybanyanlife.com",
      },
      {
        q: "How can I report a technical problem or error?",
        a: "You can report it directly to our support team at support@mybanyanlife.com",
      },
      {
        q: "I signed up but haven’t received my confirmation email or verification code.",
        a: "Please check your spam folder first. You can also request it to be resent to your email. If you still do not receive confirmation/verification, please contact support@mybanyanlife.com",
      },
      {
        q: "How do I make suggestions for a feature or functionality?",
        a: "Please contact us via support@mybanyanlife.com",
      },
      {
        q: "I accidentally deleted a file, contact or topic. How can I restore it?",
        a: "You cannot delete files, contacts, or topics in My Banyan, but you can archive them.",
      },
    ],
  },
  {
    title: "Technical",
    faqs: [
      {
        q: "Is there a limit to how many documents I can upload?",
        a: "There is no limit on how many documents you can upload to My Banyan.",
      },
      {
        q: "Can I upload any file type?",
        a: "Yes. You can upload Word documents, Excel sheets, PDFs, and jpgs, as well as movie and audio files.",
      },
      {
        q: "Are there any file size limits?",
        a: "We recommend that you don’t upload files larger than 10MB, as that will tax your own internet – the larger the file, the longer it takes to upload.",
      },
      {
        q: "How does My Banyan handle feature updates?",
        a: "We inform all subscribers via email of any upcoming feature additions or enhancements.",
      },
      {
        q: "Can I use My Banyan on my phone?",
        a: "Yes, though you may find it easier to use your desktop or laptop when uploading files or entering large quantities of information.",
      },
      {
        q: "Can I print from My Banyan?",
        a: "Yes. You can print reports from My Banyan.",
      },
    ],
  },
];

export const tutorialsData = {
  videos: [
    {
      img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/tutorials-%26-faqs/Welcome.jpg",
      imgAlt: "Welcome to My Banyan Video",
      video:
        "https://customer-dt3dzeh2frr4vgqu.cloudflarestream.com/0168c7c23c32e3048975ac0e2bebd0f6/iframe?preload=true&poster=https%3A%2F%2Fcustomer-dt3dzeh2frr4vgqu.cloudflarestream.com%2F0168c7c23c32e3048975ac0e2bebd0f6%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D5s%26height%3D600",
      title: "Tutorial 1 - 02:02 minutes",
      body: "Welcome to My Banyan!",
    },
    {
      img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/tutorials-%26-faqs/QuickStart.jpg",
      imgAlt: "Quick Start Guide Video",
      // video:
      //   "https://customer-dt3dzeh2frr4vgqu.cloudflarestream.com/64df510079d38c70ee8b4588bafed6ad/iframe?preload=true&poster=https%3A%2F%2Fcustomer-dt3dzeh2frr4vgqu.cloudflarestream.com%2F64df510079d38c70ee8b4588bafed6ad%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D5s%26height%3D600",
      video: "https://customer-dt3dzeh2frr4vgqu.cloudflarestream.com/c25afb18a30ee8e9cc8dd7b554f21fe2/iframe?poster=https%3A%2F%2Fcustomer-dt3dzeh2frr4vgqu.cloudflarestream.com%2Fc25afb18a30ee8e9cc8dd7b554f21fe2%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600",
      title: "Tutorial 2 - 04:37 minutes",
      body: "Master My Banyan: Connect, Enter, Share!",
    },
    {
      img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/tutorials-%26-faqs/Navigation.jpg",
      imgAlt: "Navigation and Your Dashboard Video",
      video:
        "https://customer-dt3dzeh2frr4vgqu.cloudflarestream.com/bb632db0b8dfe3733aed4a471e272d9a/iframe?preload=true&poster=https%3A%2F%2Fcustomer-dt3dzeh2frr4vgqu.cloudflarestream.com%2Fbb632db0b8dfe3733aed4a471e272d9a%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D8s%26height%3D600",
      title: "Tutorial 3 - 03:29 minutes",
      body: "Effortless My Banyan Dashboard Navigation!",
    },
    {
      img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/tutorials-%26-faqs/contacts.jpg",
      imgAlt: "How to Add Contacts & Inner Circle Members Video",
      video:
        "https://customer-dt3dzeh2frr4vgqu.cloudflarestream.com/c1ec7511b2b82854e4091db10ad1f39b/iframe?preload=true&poster=https%3A%2F%2Fcustomer-dt3dzeh2frr4vgqu.cloudflarestream.com%2Fc1ec7511b2b82854e4091db10ad1f39b%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D5s%26height%3D600",
      title: "Tutorial 4 - 02:26 minutes",
      body: "Seamless Inner Circle and Contact Management!",
    },
    {
      img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/tutorials-%26-faqs/Branches.jpg",
      imgAlt: "How to Select Branches & Topics Video",
      // video:
      //   "https://customer-dt3dzeh2frr4vgqu.cloudflarestream.com/c08dc73596748ddb651612a4b4d2b44c/iframe?preload=true&poster=https%3A%2F%2Fcustomer-dt3dzeh2frr4vgqu.cloudflarestream.com%2Fc08dc73596748ddb651612a4b4d2b44c%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D6s%26height%3D600",
      video: "https://customer-dt3dzeh2frr4vgqu.cloudflarestream.com/b7916313361b84159a711262f546f8b0/iframe?poster=https%3A%2F%2Fcustomer-dt3dzeh2frr4vgqu.cloudflarestream.com%2Fb7916313361b84159a711262f546f8b0%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600",
      title: "Tutorial 5 - 03:19 minutes",
      body: "Master Branches & Topics in My Banyan!",
    },
    {
      img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/tutorials-%26-faqs/share-a-report.jpg",
      imgAlt: "How to Share a Report Video",
      // video:
      //   "https://customer-dt3dzeh2frr4vgqu.cloudflarestream.com/5ff7afb1f7172b65453b949750240993/iframe?preload=true&poster=https%3A%2F%2Fcustomer-dt3dzeh2frr4vgqu.cloudflarestream.com%2F5ff7afb1f7172b65453b949750240993%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D5s%26height%3D600",
      video: "https://customer-dt3dzeh2frr4vgqu.cloudflarestream.com/bda0279b7d0d203a5e1e28dab160db34/iframe?poster=https%3A%2F%2Fcustomer-dt3dzeh2frr4vgqu.cloudflarestream.com%2Fbda0279b7d0d203a5e1e28dab160db34%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600",
      title: "Tutorial 6 - 03:43 minutes",
      body: "Securely Share with Inner Circle in My Banyan!",
    },
    {
      img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/tutorials-%26-faqs/to-do-list.jpg",
      imgAlt: "The To Do List and Calendar Overview",
      video:
        "https://customer-dt3dzeh2frr4vgqu.cloudflarestream.com/318a563fd0fee9b2cb4899e3b1a68d13/iframe?preload=true&poster=https%3A%2F%2Fcustomer-dt3dzeh2frr4vgqu.cloudflarestream.com%2F318a563fd0fee9b2cb4899e3b1a68d13%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D4s%26height%3D600",
      title: "Tutorial 7 - 03:56 minutes",
      body: "Stay Organized: To-Do List & Calendar Tips!",
    },
  ],
};

