export const softwareData = {
  formTitle: "Add or edit my software",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/software",
  formFields: [
    {
      component: "input",
      label: "Name of application",
      name: "name",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "select",
      label: "Location of application",
      name: "appLocation",
      type: "text",
      options: [
        { value: "Cell phone", text: "Cell phone" },
        { value: "Local computer", text: "Local computer" },
        { value: "Web-based", text: "Web-based" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "select",
      label: "Type of software",
      name: "type",
      type: "text",
      options: [
        { value: "Business management", text: "Business management" },
        { value: "Calendar", text: "Calendar" },
        { value: "Communications", text: "Communications" },
        {
          value: "Customer relations management (CRM)",
          text: "Customer relations management (CRM)",
        },
        { value: "Database", text: "Database" },
        { value: "Email", text: "Email" },
        { value: "Games", text: "Games" },
        { value: "Graphics", text: "Graphics" },
        { value: "Multimedia", text: "Multimedia" },
        {
          value: "Photo processing / management",
          text: "Photo processing / management",
        },
        { value: "Presentation", text: "Presentation" },
        { value: "Project management", text: "Project management" },
        { value: "Sales management", text: "Sales management" },
        { value: "Spreadsheet", text: "Spreadsheet" },
        { value: "Video conferencing", text: "Video conferencing" },
        {
          value: "Video processing / management",
          text: "Video processing / management",
        },
        { value: "Web browser", text: "Web browser" },
        { value: "Word processing", text: "Word processing" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "select",
      label: "Operating system",
      name: "system",
      type: "text",
      options: [
        { value: "Apple iOS", text: "Apple iOS" },
        { value: "Apple Mac OS", text: "Apple Mac OS" },
        { value: "Blackberry OS", text: "Blackberry OS" },
        { value: "Google Android OS", text: "Google Android OS" },
        { value: "Microsoft Windows OS", text: "Microsoft Windows OS" },
        { value: "Oracle Linux OS", text: "Oracle Linux OS" },
        { value: "Web-based", text: "Web-based" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Website",
      name: "website",
      type: "text",
    },
    {
      component: "input",
      label: "Serial number",
      name: "serialNumber",
      type: "text",
    },
    {
      component: "input",
      label: "Name(s) on account",
      name: "names",
      type: "text",
      description:
        "Share the exact name(s) and related access information on important software accounts with your loved ones.",
    },
    {
      component: "input",
      label: "Login username (or its location)",
      name: "username",
      type: "text",
    },
    {
      component: "password",
      label: "Password (or its location)",
      name: "password",
      type: "password",
    },
    {
      component: "input",
      label: "Fee",
      name: "fee",
      type: "text",
    },
    {
      component: "input",
      label: "Renewal date",
      name: "date",
      type: "date",
    },
    {
      component: "input",
      label: "Name of personal tech advisor",
      name: "techAdvisor",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
