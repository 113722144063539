export const todAccountsData = {
  formTitle: "Add or edit ToD account",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/todAccounts",
  formFields: [
    {
      component: "input",
      label: "Institution holding asset",
      name: "asset",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Asset name",
      name: "name",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Person(s) who have copies of this document",
      name: "persons",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Beneficiaries",
      name: "beneficiaries",
      type: "text",
    },
    {
      component: "input",
      label: "Date notarized",
      name: "date",
      type: "date",
    },
    {
      component: "input",
      label: "Witnesses",
      name: "witnesses",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};

