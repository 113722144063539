import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { List } from "./list";
import { Form } from "./form";
import { Share } from "./share";
import { View } from "./view";
import PageTitle from "../components/common/page-title";
import { getUserDetails } from "../store/actions";
import { restGet } from "../store/api";

export const TopicPage = ({ page, data, name }) => {
    const { formFields, formSubtitle, formTitle, pathname } = data;

    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        restGet(
            "/users/show",
            (data) => dispatch(getUserDetails(data)),
            (err) => console.error(err)
        );
    }, []);

    return (
        <>
            <PageTitle title="My Banyan" />

            {user && page === "list" && (
                <List formFields={formFields} pathname={pathname} name={name} />
            )}
            {page === "new" && (
                <Form
                    formFields={formFields}
                    pathname={pathname}
                    formTitle={formTitle}
                    formSubtitle={formSubtitle}
                    initialValues={user}
                />
            )}
            {page === "edit" && (
                <Form
                    formFields={formFields}
                    pathname={pathname}
                    formTitle={formTitle}
                    formSubtitle={formSubtitle}
                    initialValues={user}
                />
            )}
            {page === "share" && <Share pathname={pathname} />}
            {page === "view" && <View pathname={pathname} />}
        </>
    );
};

