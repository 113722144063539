export const myFavoritesInMyYouthData = {
  formTitle: "Add or edit influential people in my life",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/myFavoritesInMyYouth",
  formFields: [
    {
      component: "select",
      label: "My favorite. . .",
      name: "favorite",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Activities", text: "Activities" },
        { value: "Family traditions", text: "Family traditions" },
        { value: "Books / authors", text: "Books / authors" },
        { value: "Clothes", text: "Clothes" },
        { value: "Drinks", text: "Drinks" },
        { value: "Foods", text: "Foods" },
        { value: "Friends", text: "Friends" },
        { value: "Games, indoor", text: "Games, indoor" },
        { value: "Games, outdoor", text: "Games, outdoor" },
        { value: "Heroes", text: "Heroes" },
        { value: "Holidays", text: "Holidays" },
        { value: "Hobbies", text: "Hobbies" },
        {
          value: "Ice creams / candies / snacks",
          text: "Ice creams / candies / snacks",
        },
        { value: "Movies", text: "Movies" },
        { value: "Musical instruments", text: "Musical instruments" },
        { value: "Pets", text: "Pets" },
        { value: "Places to hang out", text: "Places to hang out" },
        { value: "Places to explore", text: "Places to explore" },
        { value: "Places to hide", text: "Places to hide" },
        { value: "School subjects", text: "School subjects" },
        { value: "School teachers", text: "School teachers" },
        { value: "Songs / singers / groups", text: "Songs / singers / groups" },
        { value: "Sports", text: "Sports" },
        { value: "TV shows", text: "TV shows" },
        { value: "Vacations", text: "Vacations" },
      ],
    },
    {
      component: "input",
      label: "Description",
      name: "description",
      type: "text",
    },
    {
      component: "number",
      label: "My age",
      name: "age",
      type: "text",
      range: [9, 15]
    },
    {
      component: "input",
      label: "Who was there",
      name: "who",
      type: "text",
    },
    {
      component: "input",
      label: "Where and when",
      name: "whereWhen",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
  ],
};
