export const namesData = {
  formTitle: "Add or edit name",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/names",
  formFields: [
    {
      component: "input",
      label: "First name",
      name: "firstName",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Middle name",
      name: "middleName",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Last name",
      name: "lastName",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Maiden name",
      name: "maidenName",
      type: "text",
    },
    {
      component: "input",
      label: "Birth name",
      name: "birthName",
      type: "text",
    },
    {
      component: "textarea",
      label: "Previous legal names",
      name: "prevLegalNames",
      type: "text",
    },
    {
      component: "textarea",
      label: "Professional names",
      name: "proNames",
      type: "text",
    },
    {
      component: "input",
      label: "Nicknames",
      name: "nicknames",
      type: "text",
    },
    {
      component: "input",
      label: "AKAs",
      name: "akaNames",
      type: "text",
    },
    {
      component: "input",
      label: "Pen names",
      name: "penNames",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
  ],
};
