export const businessInsuranceData = {
  formTitle: "Add or edit business insurance",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/businessInsurance",
  formFields: [
    {
      component: "select",
      label: "Coverage type",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Business interruption", text: "Business interruption" },
        { value: "Business income", text: "Business income" },
        { value: "Commercial auto", text: "Commercial auto" },
        { value: "Commercial property", text: "Commercial property" },
        { value: "Commercial umbrella", text: "Commercial umbrella" },
        { value: "Cyber / data breach", text: "Cyber / data breach" },
        { value: "Directors / officers", text: "Directors / officers" },
        { value: "General liability", text: "General liability" },
        { value: "Health", text: "Health" },
        { value: "Product liability", text: "Product liability" },
        { value: "Professional liability", text: "Professional liability" },
        { value: "Workers compansation", text: "Workers compansation" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Insurance company",
      name: "company",
      type: "text",
      colObject: true,
    },
    {
      component: "autocomplete",
      label: "Broker name",
      name: "broker",
    },
    {
      component: "input",
      label: "Name of insured",
      name: "insured",
      type: "text",
      description: "The person that the insurance policy covers.",
    },
    {
      component: "input",
      label: "Name of person who receives copy of bills if I am disabled",
      name: "copy",
      type: "text",
      description:
        "This ensures that any required policy premiums are paid on time in the event of death/disability.",
    },
    {
      component: "input",
      label: "Policy owner",
      name: "policyOwner",
      type: "text",
      description:
        "The insured person or a representative of the insured (such as a spouse or trustee) should the insured become disabled.",
    },
    {
      component: "input",
      label: "Account number",
      name: "accountNumber",
      type: "text",
    },
    {
      component: "input",
      label: "Login username (or its location)",
      name: "username",
      type: "text",
    },
    {
      component: "password",
      label: "Account password (or its location)",
      name: "password",
      type: "password",
    },
    {
      component: "input",
      label: "PIN number (or its location)",
      name: "pin",
      type: "text",
    },
    {
      component: "input",
      label: "Coverage limits",
      name: "coverageLimits",
      type: "text",
      description:
        "The maximum amount of money an insurance company will pay for a covered claim.",
    },
    {
      component: "input",
      label: "Annual premium",
      name: "annualPremium",
      type: "text",
    },
    {
      component: "input",
      label: "Date of most recent review of this policy",
      name: "recentPolicyReviewDate",
      type: "date",
    },
    {
      component: "input",
      label: "Date of next review",
      name: "nextReviewDate",
      type: "date",
    },
    {
      component: "input",
      label: "Expiration date",
      name: "expirationDate",
      type: "date",
    },
    {
      component: "input",
      label: "Beneficiary contact name",
      name: "beneficiaryName",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
