export const myContactsData = {
  formTitle: "Add or edit passport",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/myContacts",
  formFields: [
    {
      component: "select",
      label: "Contact Type",
      name: "contactType",
      type: "text",
      required: true,
      options: [
        { value: "immediate-family", text: "Immediate Family" },
        { value: "extended-family", text: "Extended Family" },
        { value: "Friends", text: "Friends" },
        { value: "Acquaintances", text: "Acquaintances" },
        { value: "Legal contacts", text: "Legal contacts" },
        { value: "Financial contacts", text: "Financial contacts" },
        { value: "Health contacts", text: "Health contacts" },
        { value: "Business contacts", text: "Business contacts" },
        {
          value: "Other contacts",
          text: "Other contacts",
        },
      ],
    },
    {
      component: "input",
      label: "First Name",
      name: "firstName",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Last Name",
      name: "lastName",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Email",
      name: "email",
      type: "email",
      required: true,
      colObject: true,
    },
    {
      component: "phoneNumber",
      label: "Phone number",
      name: "phone",
      autoComplete: "new-password",
    },
    {
      component: "phoneNumber",
      label: "Another Phone",
      name: "anotherPhone",
    },
    {
      component: "avatar",
      name: "avatar",
    },
    {
      component: "checkbox",
      label: "Inner Circle",
      name: "innerCircle",
      title: "Add the contact to your Inner Circle",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
  ],
};

