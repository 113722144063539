export const accountsPayableData = {
  formTitle: "Add or edit account payable",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/accountsPayable",
  formFields: [
    {
      component: "input",
      label: "Creditor",
      name: "creditor",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "phoneNumber",
      label: "Creditor phone",
      name: "phone",
      colObject: true,
    },
    {
      component: "value",
      label: "Current amount owed",
      name: "amountOwed",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
