import moment from "moment";

import { useContext } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { restPost, restPut, restDelete } from "../../../store/api";
import { Fieldset, Text, Textarea } from "./inputs";
import { calendarSetToggle } from "../../../store/actions";
import { endsIsSmallerThanStarts } from "./validators";
import { Toastr } from "../../common/toastr";
import { NotificationContext } from "../../../contexts/notificationContext";
import { restGet } from "../../../store/api";

export function FormSubmit({ apiPath, setOpen, initialValues }) {
    const user = useSelector((state) => state.auth.user);

    const isSubscriptionDisabled = user && user.type === "readonly";

    const currDate = Date.now();
    const dt = new Date(currDate);
    const todayWithTime = dt.toISOString().slice(0, 16);

    const { setNoti } = useContext(NotificationContext);
    const dispatch = useDispatch();

    const successCallback = (message) => {
        dispatch(calendarSetToggle());
        setOpen(false);
        Toastr({
            type: "success",
            message,
        });

        restGet(
            "/calendarEvents/notification",
            (data) => setNoti(data),
            (err) => console.error(err)
        );
    };

    const failCallback = (err) => {
        console.error(err);
        Toastr({ type: "error", message: "Error with event/task." });
    };

    const onSubmit = async (values) => {
        let reqBody = { ...values };

        if (values.alldayDate && values.allday) {
            reqBody.starts = moment(values.alldayDate).format(
                "YYYY-MM-DDT00:00"
            );
            reqBody.ends = moment(values.alldayDate).format("YYYY-MM-DDT00:00");
            reqBody.when = moment(values.alldayDate).format("YYYY-MM-DD");
            delete reqBody.alldayDate;
        }

        // reset to all day format if starts === ends
        if (!values.allday && values.starts === values.ends) {
            let s = moment(values.starts).format("YYYY-MM-DDT00:00");
            reqBody.starts = s;
            reqBody.ends = s;
        }

        if (initialValues) {
            await restPut(
                "/" + apiPath + "/update/" + initialValues._id,
                reqBody,
                () => successCallback("Event/Task successfully edited."),
                failCallback
            );
        } else {
            await restPost(
                "/" + apiPath + "/new",
                reqBody,
                () => successCallback("Event successfully created."),
                failCallback
            );
        }
    };

    const deleteHandler = () => {
        restDelete(
            "/" + apiPath + "/delete/" + initialValues._id,
            {},
            () => successCallback("Event/Task successfully deleted."),
            failCallback
        );
    };

    return (
        <Fieldset
            title={`${
                initialValues && initialValues._id
                    ? "Review / edit an event"
                    : "Add a new event"
            }`}
            subtitle={"Event needs to have title, date, and time"}
        >
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues ? initialValues : null}
                validate={(values) => {
                    const errors = {};

                    if (
                        values.ends &&
                        values.starts &&
                        endsIsSmallerThanStarts(values.starts, values.ends)
                    ) {
                        errors.ends = "Ends must be later than starts";
                    }

                    return errors;
                }}
                render={({
                    handleSubmit,
                    form,
                    submitting,
                    pristine,
                    values,
                }) => (
                    <form onSubmit={handleSubmit} className="mt-2 space-y-6">
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <Text
                                name="title"
                                type="text"
                                autoComplete="new-password"
                                required
                                label="Title *"
                                placeholder="New Event"
                                size={"sm:col-span-6"}
                            />
                            <Text
                                name="location"
                                type="text"
                                autoComplete="new-password"
                                label="Location"
                                placeholder="Location or Virtual Room"
                                size={"sm:col-span-6"}
                            />
                            <div className="sm:col-span-6 flex items-start">
                                <div className="flex h-5 items-center">
                                    <Field
                                        component={"input"}
                                        name="allday"
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-700 text-indigo-600 focus:ring-indigo-500"
                                    />
                                </div>
                                <div className="ml-3 text-sm">
                                    <label
                                        htmlFor="allday"
                                        className="font-medium text-gray-900"
                                    >
                                        All-day event
                                    </label>
                                </div>
                            </div>

                            {values.allday && (
                                <Field
                                    name="alldayDate"
                                    defaultValue={
                                        initialValues
                                            ? moment(
                                                  initialValues.starts
                                              ).format("YYYY-MM-DD")
                                            : ""
                                    }
                                >
                                    {({ input, meta }) => (
                                        <div className="sm:col-span-6">
                                            <label
                                                htmlFor="alldayDate"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Date *
                                            </label>
                                            <div className="relative mt-1 flex items-center">
                                                <input
                                                    {...input}
                                                    type="date"
                                                    required
                                                    placeholder=""
                                                    autoComplete="new-password"
                                                    min={todayWithTime}
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Field>
                            )}

                            {!values.allday && (
                                <>
                                    {" "}
                                    <Field name="starts">
                                        {({ input, meta }) => (
                                            <div className="sm:col-span-3">
                                                <label
                                                    htmlFor="starts"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Starts *
                                                </label>
                                                <div className="relative mt-1 flex items-center">
                                                    <input
                                                        {...input}
                                                        type={"datetime-local"}
                                                        required={
                                                            values.allday
                                                                ? false
                                                                : true
                                                        }
                                                        placeholder=""
                                                        autoComplete="new-password"
                                                        min={todayWithTime}
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="ends">
                                        {({ input, meta }) => (
                                            <div className="sm:col-span-3">
                                                <label
                                                    htmlFor="ends"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Ends *
                                                </label>
                                                <div className="relative mt-1 flex items-center">
                                                    <input
                                                        {...input}
                                                        type={"datetime-local"}
                                                        required={
                                                            values.allday
                                                                ? false
                                                                : true
                                                        }
                                                        placeholder=""
                                                        autoComplete="new-password"
                                                        min={todayWithTime}
                                                        className="block w-full disabled:opacity-50 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                    />
                                                </div>
                                                {meta.error && meta.touched && (
                                                    <span className="text-xs text-red-500">
                                                        {meta.error}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                </>
                            )}

                            <Textarea
                                name="notes"
                                autoComplete="new-password"
                                label={"Notes"}
                                placeholder=""
                                size={"sm:col-span-6"}
                            />

                            <div className="sm:col-span-6">
                                <button
                                    type="submit"
                                    disabled={submitting || pristine}
                                    className="w-full mx-1 px-4 py-2 text-sm font-medium bg-primary-green hover:bg-white disabled:bg-white text-white hover:text-primary-green disabled:text-primary-green border-2 border-primary-green focus:outline-none rounded-md disabled:cursor-not-allowed transition duration-300"
                                >
                                    Save
                                </button>
                            </div>

                            {initialValues && initialValues._id && (
                                <div className="sm:col-span-6">
                                    <button
                                        type="button"
                                        disabled={isSubscriptionDisabled}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            deleteHandler();
                                        }}
                                        className="w-full mx-1 px-4 py-2 text-sm font-medium bg-red-500 hover:bg-white disabled:bg-white text-white hover:text-red-500 disabled:text-red-500 border-2 border-red-500 focus:outline-none rounded-md disabled:cursor-not-allowed transition duration-300"
                                    >
                                        Delete
                                    </button>
                                </div>
                            )}
                        </div>
                    </form>
                )}
            />
        </Fieldset>
    );
}

