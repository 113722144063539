export const otherFinanceSourcesData = {
  formTitle: "Add or edit other finance source",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/otherFinanceSources",
  formFields: [
    {
      component: "input",
      label: "Source",
      name: "source",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Reference information",
      name: "info",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Value",
      name: "value",
      type: "number",
      colObject: true,
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
