import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import { Text, Button, ShowPassword } from "./inputs";
import { SubmissionAlert } from "../common/submission-alert";

export default function VerifyResetPassword({ username }) {
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    resetCode: "",
    resetPassword: "",
    showPassword: false,
  });
  const [req, setReq] = useState({
    error: null,
    isPending: false,
  });

  const dispatchCallback = () => {
    navigate("/signin", { replace: true });
  };

  const onChangeHandler = (e) => {
    e.preventDefault();

    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleShowPassword = () => {
    setFormValues((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  const onSubmitHandler = async () => {
    try {
      await Auth.forgotPasswordSubmit(
        username,
        formValues.resetCode.trim(),
        formValues.resetPassword.trim()
      );
      dispatchCallback();
    } catch (error) {
      setReq((prevState) => ({
        ...prevState,
        error: error.message,
        isPending: false,
      }));
    }
  };

  return (
    <>
      {req.error && <SubmissionAlert type={"error"} message={req.error} />}
      <form
        className="space-y-6"
        onSubmit={(e) => {
          e.preventDefault();
          setReq((prevState) => ({
            ...prevState,
            isPending: true,
          }));
          onSubmitHandler();
        }}
      >
        <div className="text-sm text-white font-light">
          <p className="text-center">
            We have sent a 6-digit verification code to the email you provided
            to create a new password
          </p>
          <p className="text-center py-3">
            If you don't receive the email within a couple minutes, please check
            the spam folder in your email program. The subject line of the email
            is "Your Verification Code."
          </p>
        </div>
        <div className="space-y-1">
          <Text
            name="resetCode"
            type="text"
            value={formValues.resetCode}
            onChange={onChangeHandler}
            autoComplete="new-password"
            required
            label="Your verification code *"
          />
        </div>

        <div className="relative space-y-1">
          <Text
            name="resetPassword"
            type={formValues.showPassword ? "text" : "password"}
            value={formValues.resetPassword}
            onChange={onChangeHandler}
            autoComplete="new-password"
            required
            label="New password *"
          />

          <ShowPassword
            handleShowPassword={handleShowPassword}
            showPassword={formValues.showPassword}
          />
        </div>

        <div className="pt-3">
          <Button type="submit" isPending={req.isPending}>
            RESET PASSWORD
          </Button>
        </div>
      </form>
    </>
  );
}
