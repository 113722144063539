import { useEffect, useMemo, useState } from "react";

import ExcelJS from "exceljs";
import moment from "moment";

import PageTitle from "../common/page-title";
import { mainBranches } from "../private-layout/data";
import { restGetSummary } from "../../store/api";

const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const insertSpacesBeforeCapital = (text) => {
    return text.replace(/([a-z])([A-Z])/g, "$1 $2");
};

const removeValue = (text) => {
    const textArr = text.split(" ");

    return textArr.join(" ");
};

const formatTopic = (text) => {
    const textWithSpaces = insertSpacesBeforeCapital(text);

    const textWithoutValue = removeValue(textWithSpaces);

    return textWithoutValue;
};

export function HealthSummary({ name, title }) {
    const [data, setData] = useState([]);

    const handleExportExcel = async () => {
        const saveAs = require("file-saver");

        const date = moment().format("L");

        const header = `Health Summary Report as of ${date}`;
        const columns = [
            "Branch",
            "Topic",
            "Item",
            "Details",
            "Effective Date",
            "Person With Copies",
        ];

        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Health Summary Report");

        // Add header row
        worksheet.mergeCells("A1:E1");
        const headerRow = worksheet.getCell("A1");
        headerRow.value = header;
        headerRow.font = { bold: true, size: 14, color: { argb: "FFFFFFFF" } };
        headerRow.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF6C8954" },
        };
        headerRow.alignment = { vertical: "middle", horizontal: "center" };

        // Add column headers
        worksheet.addRow([]);
        const columnRow = worksheet.addRow(columns);
        columnRow.eachCell((cell) => {
            cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FF808080" },
            };
            cell.alignment = { vertical: "middle", horizontal: "center" };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
        });

        // Add data rows
        data.forEach((item) => {
            const row = worksheet.addRow([
                capitalizeFirstLetter(item.branch),
                formatTopic(item.topic),
                item.item || "N/A",
                item.details || "N/A",
                item.effectiveDate || "N/A",
                item.personsWithCopies || "N/A",
            ]);

            row.eachCell((cell) => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
            });
        });

        // Adjust column widths
        worksheet.columns = [
            { width: 20 }, // Branch
            { width: 30 }, // Topic
            { width: 20 }, // Item
            { width: 30 }, // Details
            { width: 10 }, // Effective Date
            { width: 25 }, // Person(s) with copies
        ];

        // Generate the Excel file
        const buffer = await workbook.xlsx.writeBuffer();

        // Create a Blob from the ArrayBuffer
        const excelBlob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Trigger file download
        saveAs(excelBlob, `Health_Summary_Report_${date}.xlsx`);
    };

    const healthsTopics = useMemo(() => {
        const branch = mainBranches.find(
            (branch) => branch.path === "everyday"
        );
        const subBranch = branch?.subBranches.find(
            (subBranch) => subBranch.path === "health"
        );
        return subBranch?.topics || [];
    }, []);

    const modelHealthTopics = healthsTopics
        .filter(
            (item) =>
                item.path !== "dnaInfo" &&
                item.path !== "medicalHistory" &&
                item.path !== "familyMedicalHistory"
        )
        .map((item) => item.path)
        .concat();

    const getDataAssetSummary = () => {
        restGetSummary(
            "/health-summary",
            modelHealthTopics,
            (data) => {
                setData(data);
            },
            (error) => console.error(error)
        );
    };

    useEffect(() => {
        getDataAssetSummary();
    }, []);

    const topicReplacements = {
        AdvancedDirective: "advancedDirectives",
        RecentHealthExam: "recentHealthExams",
        Ailment: "ailments",
        Allergy: "allergies",
        Medication: "medications",
        CurrentSupplement: "currentSupplements",
        Vaccination: "vaccinations",
    };

    return (
        <>
            <PageTitle title="My Banyan" />

            <div className="mb-8">
                <div className="mx-auto flex max-w-7xl flex-col pt-4 md:pt-6 lg:pt-8">
                    <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-8 py-4 md:px-8">
                            <div className="flex flex-col md:flex-row items-center justify-between">
                                <div className="md:w-1/4 text-2xl uppercase text-primary-green font-bold">
                                    {name}
                                </div>

                                <div className="md:w-3/4 pt-2 md:pt-0 text-sm">
                                    <p>
                                        This report presents a crucial selection
                                        of information from your HEALTH Branch.
                                        We chose these topics because they can
                                        be of the greatest value in an
                                        emergency.
                                    </p>

                                    <p className="py-2">
                                        To add or update any data, click on a
                                        cell in the “TOPIC” or the “ITEM”
                                        column. You will be taken to that record
                                        where you can make any changes you like.
                                    </p>

                                    <p>
                                        To print a copy of your report, click on
                                        “DOWNLOAD” and you will receive your
                                        summary report as an Excel spreadsheet.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="px-8 py-4 md:px-8">
                            <hr className="border-t-2 border-lime-600" />
                        </div>

                        <div className="flex px-8 py-4 md:px-8 justify-center md:justify-end">
                            <button
                                className="rounded-full border border-transparent bg-secondary-green py-2 px-12 text-sm font-medium text-black hover:bg-emerald-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 transition duration-300"
                                onClick={handleExportExcel}
                            >
                                Download
                            </button>
                        </div>

                        <div className="px-8 py-4 md:px-8 overflow-y-auto">
                            <table className="w-full border-collapse border border-gray-300">
                                <thead className="px-8 py-4">
                                    <tr className="uppercase font-thin text-white bg-[#7A935A] text-base">
                                        <th className="w-1/5 border border-slate-600 px-4 py-2">
                                            Branch
                                        </th>
                                        <th className="w-1/5 border border-slate-600 px-4 py-2">
                                            Topic
                                        </th>
                                        <th className="w-1/5 border border-slate-600 px-4 py-2">
                                            Item
                                        </th>
                                        <th className="w-1/5 border border-slate-600 px-4 py-2">
                                            Details
                                        </th>
                                        <th className="w-1/5 border border-slate-600 px-4 py-2">
                                            Effective Date
                                        </th>
                                        <th className="w-1/5 border border-slate-600 px-4 py-2">
                                            Person(s) with copies
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {data?.map((item, index) => (
                                        <tr
                                            key={index}
                                            className="text-thin font-medium"
                                        >
                                            {/* BRANCH */}
                                            <td className="border border-slate-700 text-center px-4 py-2">
                                                {item.topic ===
                                                "PersonalInsurance"
                                                    ? "Insurance"
                                                    : capitalizeFirstLetter(
                                                          item.branch
                                                      )}
                                            </td>

                                            {/* TOPIC */}
                                            <td className="border border-slate-700 px-4 py-2">
                                                <a
                                                    href={`/everyday/health/${
                                                        topicReplacements[
                                                            item.topic
                                                        ] || item.topic
                                                    }`}
                                                    target="_blank"
                                                    className="text-blue-600 underline font-bold"
                                                    rel="noreferrer"
                                                >
                                                    {formatTopic(item.topic)}
                                                </a>
                                            </td>

                                            {/* ITEM */}
                                            <td className="italic border border-slate-700 px-4 py-2">
                                                {item.item ? (
                                                    <a
                                                        href={`/everyday/health/${
                                                            topicReplacements[
                                                                item.topic
                                                            ] || item.topic
                                                        }/view?id=${item.id}`}
                                                        target="_blank"
                                                        className="text-blue-600 underline"
                                                        rel="noreferrer"
                                                    >
                                                        {item.item}
                                                    </a>
                                                ) : (
                                                    <p>N/A</p>
                                                )}
                                            </td>

                                            {/* DETAILS */}
                                            <td className="italic border border-slate-700 px-4 py-2">
                                                {item.details ? (
                                                    <p>{item.details}</p>
                                                ) : (
                                                    <p>N/A</p>
                                                )}
                                            </td>

                                            {/* EFFECTIVE DATE */}
                                            <td
                                                className={`italic border border-slate-700 px-4 py-2 ${
                                                    [
                                                        "Allergy",
                                                        "RecentHealthExam",
                                                    ].includes(item.topic)
                                                        ? "text-black"
                                                        : ""
                                                }`}
                                            >
                                                {item.effectiveDate ? (
                                                    <p>{item.effectiveDate}</p>
                                                ) : (
                                                    <p>N/A</p>
                                                )}
                                            </td>

                                            <td
                                                className={`italic border border-slate-700 px-4 py-2 ${
                                                    [
                                                        "Ailment",
                                                        "Allergy",
                                                        "Medication",
                                                        "BloodType",
                                                        "RecentHealthExam",
                                                        "Vaccination",
                                                    ].includes(item.topic)
                                                        ? "text-black"
                                                        : ""
                                                }`}
                                            >
                                                {item.personsWithCopies ? (
                                                    <p>
                                                        {item.personsWithCopies}
                                                    </p>
                                                ) : (
                                                    <p>N/A</p>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

