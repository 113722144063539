export const graveMarkerInscriptionsData = {
  formTitle: "Add or edit my grave marker / inscription",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/graveMarkerInscriptions",
  formFields: [
    {
      component: "input",
      label: "Wording of inscriptions",
      name: "wording",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Marker provider",
      name: "marker",
      type: "text",
      colObject: true,
    },
    {
      component: "phoneNumber",
      label: "Marker phone number",
      name: "mPhone",
    },
    {
      component: "input",
      label: "Engraving service provider",
      name: "engraver",
      type: "text",
      colObject: true,
    },
    {
      component: "phoneNumber",
      label: "Engraving service phone number",
      name: "esPhone",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
