export const outstandingTaxesDueData = {
  formTitle: "Add or edit outstanding tax due",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/outstandingTaxesDue",
  formFields: [
    {
      component: "select",
      label: "Type of tax",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Capital gains", text: "Capital gains" },
        { value: "Corporate income", text: "Corporate income" },
        { value: "Estate", text: "Estate" },
        { value: "Excise", text: "Excise" },
        { value: "Gross receipts", text: "Gross receipts" },
        { value: "Individual income", text: "Individual income" },
        { value: "Payroll", text: "Payroll" },
        { value: "Property", text: "Property" },
        { value: "Sales", text: "Sales" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Government agency",
      name: "agency",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Year",
      name: "year",
      type: "number",
    },
    {
      component: "value",
      label: "Estimate amount owed",
      name: "estimateAmountOwed",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
