import WhiteBtn from "../../components/buttons/whiteButton";
import GreenBtn from "../../components/buttons/greenBtn";

import { useState, useEffect } from "react";
import {
    useNavigate,
    useSearchParams,
    useLocation,
    Link,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { restGet, restPost, restPut } from "../../store/api";
import { updateTopicByAll } from "../../store/actions";
import { ContactList } from "./contact-list";
import { SharedList } from "./shared-list";

export default function TopicShare({ path, callbackPath }) {
    const location = useLocation();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [params] = useSearchParams();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [sharedList, setSharedList] = useState({
        list: [],
        selected: null,
        topicId: "",
    });

    const user = useSelector((state) => state.auth.user);
    const data = useSelector((state) => state.topic.byAll);

    const failCallback = (err) => {
        console.error(err);
    };

    const addSelectedToSharedList = (contact) => {
        if (sharedList.list.length > 0) {
            const result = sharedList.list.find(
                (el) => el.email === contact.email
            );
            if (result) return undefined;
        }
        setSharedList((prevState) => ({
            ...prevState,
            list: [...prevState.list, contact],
            selected: contact,
        }));
    };

    const removeItemOfSharedList = (email) => {
        setSharedList((prevState) => ({
            ...prevState,
            list: prevState.list.filter((el) => el.email !== email),
        }));
    };

    const getDataToEdit = () => {
        restGet(
            "/myContacts/index",
            (data) => dispatch(updateTopicByAll(data)),
            failCallback
        );
        restGet(
            "/sharing/show/" + params.get("id"),
            (data) =>
                setSharedList((prevState) => ({
                    ...prevState,
                    list: [...prevState.list, ...data.sharedList],
                    topicId: params.get("id"),
                })),
            failCallback
        );
    };

    const onSubmit = async (values) => {
        let reqBody = {
            topic: path.split("/")[1],
            id: params.get("id"),
        };

        setIsSubmitting(true);

        if (values && values.length > 0) {
            reqBody.sharedList = values;
        } else {
            reqBody.sharedList = [];
        }

        if (sharedList.topicId) {
            await restPut(
                "/sharing/update/" + params.get("id"),
                reqBody,
                (data) => navigate(callbackPath),
                failCallback
            );
        } else {
            await restPost(
                "/sharing/new",
                reqBody,
                (data) => navigate(callbackPath),
                failCallback
            );
        }
    };

    useEffect(() => {
        getDataToEdit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="flex flex-col sm:flex-row w-full sm:justify-between sm:mt-4 mb-4">
            {data && data.length > 0 ? (
                <div className="mt-6 sm:mt-1 sm:w-1/2 sm:mr-4 md:mr-8 lg:mr-12">
                    <ContactList
                        selected={data[0]}
                        setSelected={addSelectedToSharedList}
                        list={data}
                    />
                </div>
            ) : (
                <div className="mt-6 sm:mt-1 sm:w-1/2 sm:mr-4 md:mr-8 lg:mr-12 text-sm">
                    There are no Inner Circle contacts to be shared. Please
                    create one{" "}
                    <Link
                        to={"/inner-circle"}
                        alt=""
                        className="text-primary-green hover:text-custom-dark-green hover:underline"
                    >
                        here
                    </Link>
                </div>
            )}

            <div className="space-y-3 divide-y divide-gray-300 sm:w-1/2 sm:ml-4">
                <div className="mt-6 sm:mt-0">
                    {sharedList.list.length > 0 ? (
                        <SharedList
                            people={sharedList.list}
                            removeItem={removeItemOfSharedList}
                        />
                    ) : (
                        <span className="text-sm text-gray-600">
                            Shared list is empty
                        </span>
                    )}
                </div>
                <div className="pt-5">
                    <div className="flex justify-end">
                        <WhiteBtn
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(location.pathname.split("/share")[0]);
                            }}
                        >
                            Cancel
                        </WhiteBtn>

                        <GreenBtn
                            type="button"
                            disabled={
                                user === null ||
                                user.type === "readonly" ||
                                isSubmitting ||
                                !data ||
                                data.length === 0 ||
                                sharedList.list.length < 1
                            }
                            onClick={(e) => {
                                e.preventDefault();
                                onSubmit(sharedList.list);
                            }}
                        >
                            {isSubmitting ? (
                                <>
                                    <svg
                                        className="animate-spin h-5 w-5 mr-3 text-white"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        />

                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        />
                                    </svg>
                                    Processing...
                                </>
                            ) : (
                                "Share"
                            )}
                        </GreenBtn>
                    </div>
                </div>
            </div>
        </div>
    );
}

