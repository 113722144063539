export const getSearchFromLocation = (splitString, url) => {
  const arr = url.split(splitString);

  if (arr && arr.length > 0) {
    return splitString + arr[arr.length - 1];
  } else {
    return splitString;
  }
};

export const getListPath = (pathname) => {
  const pathArray = pathname.split('/');
  pathArray.pop();

  return pathArray.join('/');
};
