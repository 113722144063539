export const homeHealthEquipmentData = {
  formTitle: "Add or edit home health equipment",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/homeHealthEquipment",
  formFields: [
    {
      component: "select",
      label: "Item",
      name: "item",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Blood pressure monitor", text: "Blood pressure monitor" },
        { value: "Cane", text: "Cane" },
        { value: "Elliptical trainer", text: "Elliptical trainer" },
        {
          value: "Eye glasses / contact lenses",
          text: "Eye glasses / contact lenses",
        },
        { value: "Hearing aids", text: "Hearing aids" },
        { value: "Hoyer lift", text: "Hoyer lift" },
        { value: "Pacemaker", text: "Pacemaker" },
        { value: "Sleep apnea machine", text: "Sleep apnea machine" },
        { value: "Stationary bike", text: "Stationary bike" },
        { value: "Treadmill", text: "Treadmill" },
        { value: "Walker", text: "Walker" },
        { value: "Weights", text: "Weights" },
        { value: "Wheel chair", text: "Wheel chair" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Manufacturer",
      name: "manufacturer",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Date of next exam, if needed",
      name: "date",
      type: "date",
    },
    {
      component: "input",
      label: "Name of medical contact",
      name: "name",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Phone number",
      name: "phone",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};

