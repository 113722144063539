export const loanAgreementsData = {
  formTitle: "Add or edit loan agreement",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/loanAgreements",
  formFields: [
    {
      component: "input",
      label: "Company",
      name: "company",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Lender name",
      name: "lender",
      type: "text",
      colObject: true,
    },
    {
      component: "phoneNumber",
      label: "Lender phone",
      name: "lenderPhone",
    },
    {
      component: "input",
      label: "Lender email",
      name: "lenderEmail",
      type: "email",
    },
    {
      component: "input",
      label: "Debtor name",
      name: "debtor",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Debtor phone",
      name: "debtorPhone",
    },
    {
      component: "input",
      label: "Debtor email",
      name: "debtorEmail",
      type: "email",
    },
    {
      component: "input",
      label: "Value",
      name: "value",
      type: "text",
    },
    {
      component: "input",
      label: "Date of origination",
      name: "originationDate",
      type: "date",
    },
    {
      component: "input",
      label: "Terms",
      name: "terms",
      type: "text",
    },
    {
      component: "input",
      label: "End date",
      name: "endDate",
      type: "date",
    },
    {
      component: "input",
      label: "Lastest revision date",
      name: "lastestRevisionDate",
      type: "date",
    },
    {
      component: "input",
      label: "Attorney name",
      name: "attorney",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
