export const liabilitiesLeasesData = {
  formTitle: "Add or edit liability: lease",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/liabilitiesLeases",
  formFields: [
    {
      component: "input",
      label: "Asset under lease",
      name: "asset",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "select",
      label: "Lease type",
      name: "leaseType",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Financial", text: "Financial" },
        { value: "Fixed lease: long term", text: "Fixed lease: long term" },
        { value: "Fixed lease: short term", text: "Fixed lease: short term" },
        { value: "Gross lease", text: "Gross lease" },
        { value: "Indirect lease", text: "Indirect lease" },
        { value: "Modified gross lease", text: "Modified gross lease" },
        { value: "Month-by-month lease", text: "Month-by-month lease" },
        { value: "Net Lease", text: "Net Lease" },
        { value: "Operating lease", text: "Operating lease" },
        { value: "Probationary lease", text: "Probationary lease" },
        { value: "Sale and lease back", text: "Sale and lease back" },
        { value: "Sales lease", text: "Sales lease" },
        { value: "Triple net lease", text: "Triple net lease" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Lease Issuer / Owner (Lessor) Name",
      name: "lessor",
      type: "text",
    },
    {
      component: "input",
      label: "Lease holder (lessee)",
      name: "lessee",
      type: "text",
    },
    {
      component: "input",
      label: "Date of origination",
      name: "originationDate",
      type: "date",
    },
    {
      component: "input",
      label: "Duration",
      name: "duration",
      type: "text",
    },
    {
      component: "input",
      label: "End date",
      name: "endDate",
      type: "date",
    },
    {
      component: "value",
      label: "Payment amount",
      name: "paymentAmount",
      type: "number",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Payment frequency",
      name: "paymentFreq",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
