import { SelectFavorites } from "../../components/dashboard/select-favorites";
import { classNames } from "../../lib/classnames";


export default function ChoosePriorities({ setShowReset, setShowHome }) {
  const isClosed = false

  return (
    <div className={classNames(isClosed ? "hidden" : "", "mb-8")}>
      <div className="mx-auto flex max-w-7xl flex-col pb-16 md:pb-0 pt-10 md:pt-6 lg:pt-8">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-4 md:px-6">
            <div className="flex items-center justify-between">
              <div className="text-3xl uppercase sm:text-center text-primary-green w-full">
                SELECT SIX BRANCHES
              </div>
            </div>
          </div>

          <div className="px-4 pt-6 pb-8 md:px-6 border-t border-gray-300 bg-alternative-green sm:rounded-b-lg">
            <SelectFavorites
              setShowReset={setShowReset}
              setShowHome={setShowHome}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

