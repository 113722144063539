import AWS from "aws-sdk";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { restPost, restGet, restDelete, restPut } from "../../store/api";
import { updateFileUploadByAll, updateTopicById } from "../../store/actions";
import { HorizontalSlide } from "./horizontal-slide";

const S3_BUCKET = "mybanyan-bucket-staging";
const REGION = "us-west-1";

AWS.config.update({
    accessKeyId: "AKIAQGLUQCEBYE4LU65D",
    secretAccessKey: "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",
});

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
});

const getKey = (userId, topicName, topicId, fileName) => {
    return userId + topicName + "/" + topicId + "/" + fileName;
};

export const FileUpload = ({ pathname, data }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.currentUser);
    const images = useSelector((state) => state.fileUpload.byAll);
    const [progress, setProgress] = useState(0);
    const [toggle, setToggle] = useState(false);
    const [dragActive, setDragActive] = useState(false);

    function getContentType(fileName) {
        const mimeTypes = {
            pdf: "application/pdf",
            png: "image/png",
            jpg: "image/jpeg",
            jpeg: "image/jpeg",
            gif: "image/gif",
        };

        const extension = fileName.split(".").pop().toLowerCase();
        return mimeTypes[extension] || "application/octet-stream";
    }

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.items) {
            for (let i = 0; i < e.dataTransfer.items.length; i++) {
                if (e.dataTransfer.items[i].kind === "file") {
                    const file = e.dataTransfer.items[i].getAsFile();
                    uploadFile(file);
                }
            }
        } else {
            for (let i = 0; i < e.dataTransfer.files.length; i++) {
                const file = e.dataTransfer.files[i];
                uploadFile(file);
            }
        }
    };

    const handleFileInput = (e) => {
        uploadFile(e.target.files[0]);
    };

    const failCallback = (err) => {
        console.error(err);
    };

    const setDefault = (imgUrl) => {
        restPut(
            pathname + "/update/" + data._id,
            { defaultFile: imgUrl },
            (data) => dispatch(updateTopicById(data)),
            failCallback
        );
    };

    const loadFilesFromDatabase = () => {
        restGet(
            "/fileUploads/topic?id=" + data._id,
            (data) => dispatch(updateFileUploadByAll(data)),
            failCallback
        );
    };

    const addToDatabase = async (file) => {
        const reqBody = {};
        reqBody.topic = pathname.split("/")[1];
        reqBody.id = data._id;
        reqBody.fileName = file.name;
        reqBody.type = "cabinet";
        reqBody.url =
            "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/" +
            getKey(user.attributes.sub, pathname, data._id, file.name);

        await restPost(
            "/fileUploads/new",
            reqBody,
            (data) => setToggle(!toggle),
            failCallback
        );
    };

    const uploadFile = (file) => {
        const params = {
            ACL: "public-read",
            Body: file,
            Bucket: S3_BUCKET,
            ContentType: getContentType(file.name),
            ContentDisposition: `attachment; filename="${file.name}"`,
            Key: getKey(user.attributes.sub, pathname, data._id, file.name),
        };

        myBucket
            .putObject(params)
            .on("httpUploadProgress", (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100));
            })
            .send((err, data) => {
                if (err) console.error(err);
                if (data) addToDatabase(file);
            });
    };

    const deleteFile = (fileName, fileId) => {
        const params = {
            Bucket: S3_BUCKET,
            Key: getKey(user.attributes.sub, pathname, data._id, fileName),
        };

        myBucket.deleteObject(params).send((err, data) => {
            if (err) console.error(err);
            if (data)
                restDelete(
                    "/fileUploads/delete/" + fileId,
                    { data: { topicId: data._id } },
                    (data) => setToggle(!toggle),
                    failCallback
                );
        });
    };

    useEffect(() => {
        loadFilesFromDatabase();
        // eslint-disable-next-line react-hooks/exhaustive-deps

        const dropZone = document.getElementById("drop-zone");
        dropZone.addEventListener("dragenter", handleDrag);
        dropZone.addEventListener("dragover", handleDrag);
        dropZone.addEventListener("dragleave", handleDrag);
        dropZone.addEventListener("drop", handleDrop);

        return () => {
            dropZone.removeEventListener("dragenter", handleDrag);
            dropZone.removeEventListener("dragover", handleDrag);
            dropZone.removeEventListener("dragleave", handleDrag);
            dropZone.removeEventListener("drop", handleDrop);
        };
    }, [toggle]);

    return (
        <div className="w-full">
            <div
                id="drop-zone"
                className={`flex w-full justify-center rounded-md shadow-sm border-2 border-dashed bg-white border-gray-300 px-6 pt-5 pb-6 ${
                    dragActive ? "border-indigo-600" : ""
                }`}
                onDragEnter={handleDrag}
                onDragOver={handleDrag}
                onDragLeave={handleDrag}
                onDrop={handleDrop}
            >
                <div className="space-y-1 text-center">
                    <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                    >
                        <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>

                    <div className="flex text-sm text-gray-600">
                        <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                            <span>Upload a file</span>
                            <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                className="sr-only"
                                onChange={handleFileInput}
                                multiple={true}
                            />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">
                        PNG, JPG, GIF, PDF up to 50MB
                    </p>
                </div>
            </div>

            <p className="mt-2 text-sm text-gray-500">
                File upload progress is{" "}
                <span
                    className={`${
                        progress === 100 ? "text-green-300" : "text-red-300"
                    }`}
                >
                    {" "}
                    {progress}%
                </span>
            </p>

            {images && images.length > 0 && (
                <HorizontalSlide
                    images={images.filter((image) => image.id === data._id)}
                    deleteFile={deleteFile}
                    data={data}
                    setDefault={setDefault}
                />
            )}
            {images && images.length === 0 && (
                <p className="mt-2 text-sm text-red-500">
                    If you don't have a file to upload, simply click cancel
                </p>
            )}
        </div>
    );
};

