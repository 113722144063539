export const rewardsMembershipAccountsData = {
  formTitle: "Add or edit rewards / membership account",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/rewardsMembershipAccounts",
  formFields: [
    {
      component: "input",
      label: "Company issuing card",
      name: "card",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "select",
      label: "Reward type",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Cash back", text: "Cash back" },
        { value: "Discounted price", text: "Discounted price" },
        { value: "Merchandise", text: "Merchandise" },
        { value: "Points", text: "Points" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Bank name",
      name: "bank",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Customer service phone number",
      name: "phone",
    },
    {
      component: "input",
      label: "Website",
      name: "website",
      type: "text",
    },
    {
      component: "input",
      label: "Account name",
      name: "name",
      type: "text",
    },
    {
      component: "input",
      label: "Account number",
      name: "accountNumber",
      type: "text",
    },
    {
      component: "input",
      label: "Name(s) on account",
      name: "names",
      type: "text",
    },
    {
      component: "input",
      label: "Login username (or its location)",
      name: "username",
      type: "text",
    },
    {
      component: "password",
      label: "Account password (or its location)",
      name: "password",
      type: "password",
    },
    {
      component: "input",
      label: "PIN number (or its location)",
      name: "pin",
      type: "text",
    },
    {
      component: "input",
      label: "Annual fee",
      name: "fee",
      type: "text",
    },
    {
      component: "input",
      label: "Renewal date",
      name: "date",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
