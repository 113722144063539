import { useRef, useState } from "react";

import { Tab } from "./tabs";
import PageTitle from "../common/page-title";

export default function TopicsOverview() {
    const [tab, setTab] = useState(1);
    const formRef = useRef(null);

    const handlePrev = () => {
        setTab(tab - 1);
        const position = formRef.current;
        window.scrollTo({
            top: position,
            behavior: "smooth",
        });
    };

    const handleNext = () => {
        setTab(tab + 1);
        const position = formRef.current;
        window.scrollTo({
            top: position,
            behavior: "smooth",
        });
    };

    return (
        <>
            <PageTitle title="My Banyan" />

            <div ref={formRef}>
                <div className="xl:max-w-3xl mx-auto md:px-10 xl:px-0 pt-5">
                    {tab === 1 && <Tab idx={0} />}
                    {tab === 2 && <Tab idx={1} />}
                    {tab === 3 && <Tab idx={2} />}
                </div>
                <div
                    className={`flex items-center ${
                        tab === 1 && "justify-end"
                    } ${tab === 2 && "justify-between"} ${
                        tab === 3 && "justify-start"
                    }`}
                >
                    {tab === 1 ? null : (
                        <div
                            onClick={handlePrev}
                            className="cursor-pointer group py-2 px-4 border border-blue-600 rounded-md hover:bg-blue-600"
                        >
                            <p className="text-blue-600 group-hover:text-white">
                                {"< Previous"}
                            </p>
                        </div>
                    )}
                    {tab === 3 ? null : (
                        <div
                            onClick={handleNext}
                            className="cursor-pointer group py-2 px-4 border border-blue-600 rounded-md hover:bg-blue-600"
                        >
                            <p className="text-blue-600 group-hover:text-white">
                                {"Next >"}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

