export const utilitiesData = {
  formTitle: "Add or edit my utility",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/utilities",
  formFields: [
    {
      component: "input",
      label: "Property location (street, city, state, zip code, country)",
      name: "location",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "select",
      label: "Utility type",
      name: "utilityType",
      required: true,
      colObject: true,
      options: [
        { value: "Cable / satellite TV", text: "Cable / satellite TV"},
        { value: "Cell phone", text: "Cell phone"},
        { value: "Electric", text: "Electric"},
        { value: "Fax service", text: "Fax service"},
        { value: "Gas", text: "Gas"},
        { value: "Internet", text: "Internet"},
        { value: "Phone land line", text: "Phone land line"},
        { value: "Sewer / waste water", text: "Sewer / waste water"},
        { value: "Security Service", text: "Security Service"},
        { value: "Trash", text: "Trash"},
        { value: "Water", text: "Water"},
        { value: "Other", text: "Other"},
      ]
    },
    {
      component: "input",
      label: "Name of utility",
      name: "utility",
      type: "text",
    },
    {
      component: "input",
      label: "Website",
      name: "website",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Customer service phone number",
      name: "phone",
    },
    {
      component: "input",
      label: "Account number",
      name: "accountNumber",
      type: "text",
    },
    {
      component: "input",
      label: "Name(s) on account",
      name: "names",
      type: "text",
    },
    {
      component: "input",
      label: "Login username (or its location)",
      name: "username",
      type: "text",
    },
    {
      component: "password",
      label: "Password (or its location)",
      name: "password",
      type: "password",
    },
    {
      component: "input",
      label: "PIN number (or its location)",
      name: "pin",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
