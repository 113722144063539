export const dnaInfoData = {
  formTitle: 'Add or edit DNA info',
  formSubtitle:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  pathname: '/dnaInfo',
  formFields: [
    {
      component: 'select',
      label: 'Company',
      name: 'company',
      type: 'text',
      required: true,
      colObject: true,
      options: [
        { value: '23andMe', text: '23andMe' },
        { value: 'Ancestry DNA', text: 'Ancestry DNA' },
        { value: 'Family Tree DNA', text: 'Family Tree DNA' },
        { value: 'Living DNA', text: 'Living DNA' },
        { value: 'My Heritage', text: 'My Heritage' },
        { value: 'Other', text: 'Other' },
      ],
    },
    {
      component: 'input',
      label: 'Annual fee',
      name: 'fee',
      type: 'text',
    },
    {
      component: 'input',
      label: 'Website',
      name: 'website',
      type: 'text',
    },
    {
      component: 'input',
      label: 'Login username (or its location)',
      name: 'username',
      type: 'text',
    },
    {
      component: 'password',
      label: 'Password (or its location)',
      name: 'password',
      type: 'password',
    },
    {
      component: 'input',
      label: 'Findings',
      name: 'findings',
      type: 'text',
    },
    {
      component: 'input',
      label: 'Dates',
      name: 'dates',
      type: 'date',
    },
    {
      component: 'textarea',
      label: 'Notes',
      name: 'notes',
    },
    {
      component: 'input',
      label: 'Location of files / documents',
      name: 'fileLocation',
      type: 'text',
    },
  ],
};
