import { TYPES } from '../actions/types';

const INITIAL_STATE = {
  currentUser: null,
  user: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.UPDATE_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case TYPES.GET_USER_DETAILS:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};
