export const genealogySoftwareData = {
  formTitle: "Add or edit my genealogy software",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/genealogySoftware",
  formFields: [
    {
      component: "select",
      label: "Name of software",
      name: "software",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Ancestry", text: "Ancestry" },
        { value: "Family Historian", text: "Family Historian" },
        { value: "Family Tree Builder", text: "Family Tree Builder" },
        { value: "Family tree Maker", text: "Family tree Maker" },
        { value: "Legacy Family Tree", text: "Legacy Family Tree" },
        { value: "Reunion", text: "Reunion" },
        { value: "Roots Magic", text: "Roots Magic" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Website",
      name: "website",
      type: "text",
    },
    {
      component: "input",
      label: "Annual fee",
      name: "fee",
      type: "text",
    },
    {
      component: "input",
      label: "Login username (or its location)",
      name: "username",
      type: "text",
    },
    {
      component: "password",
      label: "Password (or its location)",
      name: "password",
      type: "password",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
