import moment from "moment";

import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";

import { updateCurrentUser } from "../../store/actions";
import { ActionPanel } from "../common/action-panel";
import { ChangePassword } from "./change-password";
import { ChangeName } from "./change-name";
import { UploadPhoto } from "./upload-photo";
import { restGet } from "../../store/api";
import { getUserDetails } from "../../store/actions";
import GreenBtn from "../buttons/greenBtn";
import RedBtn from "../buttons/redBtn";

const MyAccount = () => {
    const user = useSelector((state) => state.auth.currentUser);
    const userCheck = useSelector((state) => state.auth.user);
    const [open, setOpen] = useState(false);
    const [component, setComponent] = useState("");
    const dispatch = useDispatch();

    async function updateUser() {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
            profile: "",
        });

        const updatedUser = await Auth.currentAuthenticatedUser();
        dispatch(updateCurrentUser(updatedUser));
    }

    const dateString = userCheck && userCheck.createdAt;
    const formattedDate = moment(dateString).format("YYYY-MM-DD");

    useEffect(() => {
        if (user) {
            restGet(
                "/users/show",
                (data) => dispatch(getUserDetails(data)),
                (err) => console.error(err)
            );
        }
    }, [user]);

    return (
        <Fragment>
            <ActionPanel open={open} setOpen={setOpen}>
                {component === "ChangePassword" && (
                    <ChangePassword setOpen={setOpen} />
                )}
                {component === "ChangeName" && <ChangeName setOpen={setOpen} />}
                {component === "UploadPhoto" && (
                    <UploadPhoto setOpen={setOpen} />
                )}
            </ActionPanel>
            <div className="mt-4 divide-y divide-gray-300">
                <div className="mt-6 -mb-4">
                    <dl className="divide-y divide-gray-300">
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                                Name
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <span className="flex-grow">
                                    {user.attributes?.name}
                                </span>
                                <span className="ml-4 flex-shrink-0">
                                    <GreenBtn
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setOpen(true);
                                            setComponent("ChangeName");
                                        }}
                                    >
                                        Update
                                    </GreenBtn>
                                </span>
                            </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                                Email
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <span className="flex-grow">
                                    {user.attributes?.email}
                                </span>
                            </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                                Subscription type
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <span className="flex-grow">
                                    {userCheck?.type === "readonly"
                                        ? "Inner Circle member"
                                        : userCheck?.type === "normal"
                                        ? "Normal"
                                        : "Trial"}
                                </span>
                            </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                                Registered on
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <span className="flex-grow">
                                    {userCheck && formattedDate}
                                </span>
                            </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                            <dt className="text-sm font-medium text-gray-500">
                                Photo
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <span className="flex-grow">
                                    <img
                                        className="h-8 w-8 rounded-full"
                                        src={
                                            user.attributes?.profile
                                                ? user.attributes.profile
                                                : "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                                        }
                                        alt=""
                                    />
                                </span>
                                <span className="ml-4 flex flex-shrink-0 items-start space-x-2">
                                    <GreenBtn
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setOpen(true);
                                            setComponent("UploadPhoto");
                                        }}
                                    >
                                        Upload
                                    </GreenBtn>
                                    <span
                                        className="text-gray-300"
                                        aria-hidden="true"
                                    >
                                        |
                                    </span>
                                    <RedBtn
                                        onClick={(e) => {
                                            e.preventDefault();
                                            updateUser();
                                            setOpen(false);
                                        }}
                                    >
                                        Remove
                                    </RedBtn>
                                </span>
                            </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                            <dt className="text-sm font-medium text-gray-500">
                                Password
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <span className="flex-grow">
                                    ***************
                                </span>
                                <span className="ml-4 flex-shrink-0">
                                    <GreenBtn
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setOpen(true);
                                            setComponent("ChangePassword");
                                        }}
                                    >
                                        Change Password
                                    </GreenBtn>
                                </span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </Fragment>
    );
};

export default MyAccount;

