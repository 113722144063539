import { TYPES } from "../actions/types";

const INITIAL_STATE = {
  byAll: null,
};


// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.UPDATE_TYPE_BY_ALL:
      return {
        ...state,
        byAll: action.payload,
      };
    default:
      return state;
  }
};
