export const allergiesData = {
  formTitle: "Add or edit allery",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/allergies",
  formFields: [
    {
      component: "select",
      label: "Type of allergy",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Animal dander", text: "Animal dander" },
        { value: "Dust mites", text: "Dust mites" },
        { value: "Foods", text: "Foods" },
        { value: "Grasses / pollens", text: "Grasses / pollens" },
        { value: "Household chemicals", text: "Household chemicals" },
        { value: "Latex", text: "Latex" },
        { value: "Medicines", text: "Medicines" },
        { value: "Moulds", text: "Moulds" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Name of allergy",
      name: "allergy",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Reactions to allergy",
      name: "reactions",
      type: "text",
    },
    {
      component: "input",
      label: "Physician name",
      name: "physician",
      type: "text",
    },
    {
      component: "input",
      label: "Treatment",
      name: "treatment",
      type: "text",
    },
    {
      component: "input",
      label: "Date of last exam",
      name: "lastExamDate",
      type: "date",
    },
    {
      component: "input",
      label: "Person who has copies",
      name: "personWhoHasCopies",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
