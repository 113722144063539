export const loansData = {
  formTitle: "Add or edit loan",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/loans",
  formFields: [
    {
      component: "select",
      label: "Type of loan",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Collateral", text: "Collateral" },
        { value: "Mortgage", text: "Mortgage" },
        { value: "Personal guarantee", text: "Personal guarantee" },
        { value: "Security backed loan", text: "Security backed loan" },
        { value: "Signature / cash loan", text: "Signature / cash loan" },
        { value: "Student loan", text: "Student loan" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Names on contract",
      name: "names",
      type: "text",
    },
    {
      component: "value",
      label: "Loan amount",
      name: "loanAmount",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Creditor name",
      name: "creditor",
      type: "text",
      description:
        "A creditor is a person or institution to whom you owe money.",
    },
    {
      component: "input",
      label: "Property name if any",
      name: "property",
      type: "text",
    },
    {
      component: "textarea",
      label: "Street, City, State, Zip Code",
      name: "address",
      type: "text",
    },
    {
      component: "input",
      label: "Country",
      name: "country",
      type: "text",
    },
    {
      component: "input",
      label: "Loan account number",
      name: "loanAccountNumber",
      type: "text",
    },
    {
      component: "input",
      label: "Collateral",
      name: "collateral",
      type: "text",
      description:
        "A pledge of a specific property or asset to a lender to secure your repayment of a loan.",
    },
    {
      component: "input",
      label: "Interest rate",
      name: "rate",
      type: "text",
    },
    {
      component: "input",
      label: "Amount owed",
      name: "amountOwed",
      type: "text",
    },
    {
      component: "input",
      label: "Payment period",
      name: "paymentPeriod",
      type: "text",
    },
    {
      component: "input",
      label: "End date",
      name: "date",
      type: "date",
    },
    {
      component: "input",
      label: "Login username (or its location)",
      name: "username",
      type: "text",
    },
    {
      component: "password",
      label: "Password (or its location)",
      name: "password",
      type: "password",
    },
    {
      component: "input",
      label: "Automatic payment",
      name: "autoPayment",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
