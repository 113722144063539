import { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { restGet, restPut } from "../../store/api";
import { useDispatch } from "react-redux";
import { getUserDetails } from "../../store/actions";

import FileBeSharedList from "./file-be-shared-list";

export default function FilesSharedWithMe(setTabThree) {
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);

    const successCallback = (data) => {
        setIsLoading(false);
        setTabThree();
    };

    const failCallback = (err) => {
        console.error(err);
    };

    const saveDocToBeShared = async () => {
        const reqBody = {
            email: params.get("email"),
            fileIds: params.get("id"),
        };
        await restPut(
            "/users/file-be-shared",
            reqBody,
            successCallback,
            failCallback
        );
    };

    useEffect(() => {
        if (params.get("id") && params.get("email")) {
            saveDocToBeShared();
        } else {
            setIsLoading(false);
        }

        const getData = () => {
            restGet(
                "/users/show",
                (data) => {
                    dispatch(getUserDetails(data));
                },
                (err) => console.error(err)
            );
        };

        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>{isLoading ? <div></div> : <FileBeSharedList />}</Fragment>
    );
}

