export const ailmentsData = {
  formTitle: "Add or edit ailment",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/ailments",
  formFields: [
    {
      component: "select",
      label: "Ailment type",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Alcoholism", text: "Alcoholism" },
        { value: "ALS", text: "ALS" },
        { value: "Alzheimer's disease", text: "Alzheimer's disease" },
        { value: "Annual physical exam", text: "Annual physical exam" },
        { value: "Arthritis", text: "Arthritis" },
        { value: "Asthma", text: "Asthma" },
        { value: "Attention deficit disorder", text: "Attention deficit disorder" },
        { value: "Balance / dizziness", text: "Balance / dizziness" },
        { value: "Blood disorder", text: "Blood disorder" },
        { value: "Blood pressure issues", text: "Blood pressure issues" },
        { value: "Cancer", text: "Cancer" },
        { value: "Chronic pain", text: "Chronic pain" },
        { value: "Cognition issues", text: "Cognition issues" },
        { value: "Coronary / heart issues", text: "Coronary / heart issues" },
        { value: "Cystic fibrosis", text: "Cystic fibrosis" },
        { value: "Dementia", text: "Dementia" },
        { value: "Diabetes", text: "Diabetes" },
        { value: "Ear / nose/ throat issues", text: "Ear / nose/ throat issues" },
        { value: "Emotional / mood swings", text: "Emotional / mood swings" },
        { value: "Endocrinology issues", text: "Endocrinology issues" },
        { value: "Fibromyalgia", text: "Fibromyalgia" },
        { value: "Gastrointestinal issues", text: "Gastrointestinal issues" },
        { value: "General physical exam", text: "General physical exam" },
        { value: "Hearing loss", text: "Hearing loss" },
        { value: "Heart disease", text: "Heart disease" },
        { value: "Hemophilia", text: "Hemophilia" },
        { value: "Lungs issues", text: "Lungs issues" },
        { value: "Macular degeneration", text: "Macular degeneration" },
        { value: "Menses / menopause issues", text: "Menses / menopause issues" },
        { value: "Multiple sclerosis", text: "Multiple sclerosis" },
        { value: "Muscular dystrophy", text: "Muscular dystrophy" },
        { value: "Neurological issues", text: "Neurological issues" },
        { value: "Parkinson's disease", text: "Parkinson's disease" },
        { value: "Sex issues", text: "Sex issues" },
        { value: "Sickle cell disease", text: "Sickle cell disease" },
        { value: "Skin issues", text: "Skin issues" },
        { value: "Sleep issues", text: "Sleep issues" },
        { value: "Tay-Sachs", text: "Tay-Sachs" },
        { value: "Thyroid disorders", text: "Thyroid disorders" },
        { value: "Vision issues", text: "Vision issues" },
        { value: "Weakness", text: "Weakness" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Ailment",
      name: "ailment",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Description / prognosis",
      name: "description",
      type: "text",
    },
    {
      component: "input",
      label: "Physician name",
      name: "physician",
      type: "text",
    },
    {
      component: "input",
      label: "Treatment",
      name: "treatment",
      type: "text",
    },
    {
      component: "input",
      label: "Date of last exam",
      name: "lastExamDate",
      type: "date",
    },
    {
      component: "input",
      label: "Frequency of check-ups",
      name: "frequency",
      type: "text",
    },
    {
      component: "input",
      label: "Next exam date",
      name: "nextExamDate",
      type: "date",
    },
    {
      component: "input",
      label: "Person who has copies",
      name: "personWhoHasCopies",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
