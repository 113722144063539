export const familyMedicalHistoryData = {
  formTitle: 'Add or edit family medical history',
  formSubtitle:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  pathname: '/familyMedicalHistory',
  formFields: [
    {
      component: 'select',
      label: 'Family member',
      name: 'family',
      type: 'text',
      required: true,
      colObject: true,
      options: [
        { value: 'Brother', text: 'Brother' },
        { value: 'Father', text: 'Father' },
        { value: 'Grandfather', text: 'Grandfather' },
        { value: 'Grandmother', text: 'Grandmother' },
        { value: 'Mother', text: 'Mother' },
        { value: 'Sister', text: 'Sister' },
        { value: 'Other', text: 'Other' },
      ],
    },
    {
      component: 'mc',
      label: 'Inheritable disease / condition',
      name: 'inheritable',
      required: true,
      colObject: true,
      values: [
        'Alcoholism',
        'ALS',
        "Alzheimer's disease",
        'Arthritis',
        'Asthma',
        'Attention deficit disorder',
        'Cancer',
        'Cystic fibrosis',
        'Dementia',
        'Diabetes',
        'Fibromyalgia',
        'Hearing loss',
        'Heart disease',
        'Hemophilia',
        'High blood pressure',
        'Huntington’s disease',
        'Macular degeneration',
        'Multiple sclerosis',
        'Muscular dystrophy',
        "Parkinson's disease",
        'Sickle cell disease',
        'Tay-Sachs',
        'Thyroid disorders',
        'Other',
      ],
    },
    {
      component: 'textarea',
      label: 'Notes',
      name: 'notes',
    },
    {
      component: 'input',
      label: 'Location of files / documents',
      name: 'fileLocation',
      type: 'text',
    },
  ],
};
