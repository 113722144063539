import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { XCircleIcon } from "@heroicons/react/outline";
import { Field, Form } from "react-final-form";
import { mainBranches } from "../private-layout/data";
import { restPut } from "../../store/api";
import { getUserDetails } from "../../store/actions";
import { hierarchyIntros } from "./hierarchy-intros";

import HierarchyIntros from "./hierarchy-intros";

const getReqBody = (values) => {
    var array = [];

    if (values.aboutMe) {
        values.aboutMe.forEach((el) => {
            var s = el.split("/")[2];
            array.push({
                path: el,
                favoriteIcon: mainBranches[0].subBranches.find((sub) => sub.path === s).favoriteIcon,
                name: mainBranches[0].subBranches.find((sub) => sub.path === s).name,
            });
        });
    }
    if (values.myEveryday) {
        values.myEveryday.forEach((el) => {
            var s = el.split("/")[2];
            array.push({
                path: el,
                favoriteIcon: mainBranches[1].subBranches.find((sub) => sub.path === s).favoriteIcon,
                name: mainBranches[1].subBranches.find((sub) => sub.path === s).name,
            });
        });
    }
    if (values.myEventual) {
        values.myEventual.forEach((el) => {
            var s = el.split("/")[2];
            array.push({
                path: el,
                favoriteIcon: mainBranches[2].subBranches.find((sub) => sub.path === s).favoriteIcon,
                name: mainBranches[2].subBranches.find((sub) => sub.path === s).name,
            });
        });
    }
    if (values.myUnexpected) {
        values.myUnexpected.forEach((el) => {
            var s = el.split("/")[2];
            array.push({
                path: el,
                favoriteIcon: mainBranches[3].subBranches.find((sub) => sub.path === s).favoriteIcon,
                name: mainBranches[3].subBranches.find((sub) => sub.path === s).name,
            });
        });
    }

    return array;
};

const getValues = (data) => {
    const values = {
        aboutMe: [],
        myEveryday: [],
        myEventual: [],
        myUnexpected: [],
    };

    data.forEach((el) => {
        var arr = el.path.split("/");
        if (arr[1] === "about-me") values.aboutMe.push(el.path);
        if (arr[1] === "everyday") values.myEveryday.push(el.path);
        if (arr[1] === "eventual") values.myEventual.push(el.path);
    });

    return values;
};

const favValidator = (values) => {
    let count = 0;
    if (values.aboutMe) {
        count = count + values.aboutMe.length;
    }
    if (values.myEveryday) {
        count = count + values.myEveryday.length;
    }
    if (values.myEventual) {
        count = count + values.myEventual.length;
    }
    if (values.myUnexpected) {
        count = count + values.myUnexpected.length;
    }
    return count;
};

export function SelectFavorites({ setShowReset, setShowHome }) {
    const [error, setError] = useState("");
    const [open, setOpen] = useState(false);
    const [subBranchName, setSubBranchName] = useState("");

    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    const onSubmit = (values) => {
        let reqBody = {};

        if (favValidator(values) > 6 || favValidator(values) < 6) {
            setError("Please choose 6 priorities.");
        } else {
            reqBody.favorites = getReqBody(values);
            reqBody.quickstart = false;
            restPut(
                "/users/update",
                reqBody,
                (data) => {
                    dispatch(getUserDetails(data));
                },
                (err) => console.error(err)
            );

            setShowReset(false);
            setShowHome(true);
        }
    };

    const openHierarchyIntro = (subName) => {
        setSubBranchName(subName);
        setOpen(true);
    };

    const aboutMeBranch = mainBranches[0].subBranches;
    const everydayBranch = mainBranches[1].subBranches.slice(1, 9);
    const eventualBranch = mainBranches[2].subBranches;

    return (
        <>
            <Form
                onSubmit={onSubmit}
                initialValues={user.favorites && getValues(user.favorites)}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-col sm:items-center max-w-3xl mx-auto px-4 sm:px-8 lg:px-6">
                            <p className="pb-5">
                                Click on a branch to review its scope and topics. Choose no more than 6 branches. Check the box next to a branch to select it. You can move from branch to branch and
                                enter important information or upload files in any order you like. You can always change your selection of branches through the “RESET” feature on your Dashboard.
                            </p>

                            <div className="w-full grid lg:grid-cols-2 text-sm md:text-sm 2xl:text-base lg:gap-8 xl:gap-12 2xl:gap-16">
                                <table className="w-full">
                                    <thead className="bg-primary-green text-white border border-black">
                                        <tr>
                                            <th colSpan="2" className="py-2 px-5 uppercase text-center">
                                                about me
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {aboutMeBranch.map((sub) => (
                                            <tr key={sub.name} className="border-b border-r border-black">
                                                <td className="py-2 px-5">
                                                    <Field name="aboutMe" type="checkbox" component={"input"} value={`/about-me/${sub.path}`} />
                                                </td>

                                                <td className="border-l border-black py-2 px-5">
                                                    <label htmlFor="aboutMe" onClick={() => openHierarchyIntro(sub.name)} className="group flex items-center justify-between gap-2 cursor-pointer">
                                                        <p className="uppercase ">{sub.name}</p>
                                                        <QuestionMarkCircleIcon className="shrink-0 h-5 w-5 text-gray-900 group-hover:text-primary-green transition duration-300" />
                                                    </label>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                    <thead className="bg-primary-green text-white border border-black">
                                        <tr>
                                            <th colSpan="2" className="py-2 px-5 uppercase text-center">
                                                everyday
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {everydayBranch.slice(0, 4).map((sub, idx) => (
                                            <tr key={sub.name} className={`${idx < 3 && "border-b"} lg:border-b border-r border-black`}>
                                                <td className="py-2 px-5">
                                                    <Field name="myEveryday" type="checkbox" component={"input"} value={`/everyday/${sub.path}`} />
                                                </td>

                                                <td className="border-l border-black py-2 px-5">
                                                    <label htmlFor="myEveryday" onClick={() => openHierarchyIntro(sub.name)} className="group flex items-center justify-between gap-2 cursor-pointer">
                                                        <p className="uppercase ">{sub.name}</p>
                                                        <QuestionMarkCircleIcon className="shrink-0 h-5 w-5 text-gray-900 group-hover:text-primary-green transition duration-300" />
                                                    </label>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <table className="w-full">
                                    <thead />

                                    <tbody>
                                        {everydayBranch.slice(4).map((sub) => (
                                            <tr key={sub.name} className="border-t lg:border-l border-r lg:border-r-0 border-black">
                                                <td className="py-2 px-5">
                                                    <Field name="myEveryday" type="checkbox" component={"input"} value={`/everyday/${sub.path}`} />
                                                </td>

                                                <td className="border-l border-black py-2 px-5">
                                                    <label htmlFor="myEveryday" onClick={() => openHierarchyIntro(sub.name)} className="group flex items-center justify-between gap-2 cursor-pointer">
                                                        <p className="uppercase ">{sub.name}</p>
                                                        <QuestionMarkCircleIcon className="shrink-0 h-5 w-5 text-gray-900 group-hover:text-primary-green transition duration-300" />
                                                    </label>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                    <thead className="bg-primary-green text-white border border-black">
                                        <tr>
                                            <th colSpan="2" className="py-2 px-5 uppercase text-center">
                                                eventual
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {eventualBranch.map((sub) => (
                                            <tr key={sub.name} className="lg:border-t-0 lg:border-l border-r lg:border-r-0 border-b border-black">
                                                <td className="py-2 px-5">
                                                    <Field name="myEventual" type="checkbox" component={"input"} value={`/eventual/${sub.path}`} />
                                                </td>

                                                <td className="border-l border-black py-2 px-5">
                                                    <label htmlFor="myEventual" onClick={() => openHierarchyIntro(sub.name)} className="group flex items-center justify-between gap-2 cursor-pointer">
                                                        <p className="uppercase ">{sub.name}</p>
                                                        <QuestionMarkCircleIcon className="shrink-0 h-5 w-5 text-gray-900 group-hover:text-primary-green transition duration-300" />
                                                    </label>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="w-full flex flex-col">
                                <div className="flex flex-col sm:flex-row items-baseline justify-between gap-4 sm:gap-0 my-8 sm:px-8">
                                    <p>TARGET TOTAL BRANCHES: 6</p>
                                    <p>YOU HAVE CHOSEN: {Object.values(values).flat().length}</p>
                                </div>

                                <button
                                    type="submit"
                                    disabled={submitting || Object.values(values).flat().length < 6 || Object.values(values).flat().length > 6}
                                    className="w-full sm:w-max mt-1 inline-flex justify-center rounded-full py-3 px-16 font-medium shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-primary-green focus:ring-offset-2 self-end bg-primary-green disabled:bg-white text-white hover:text-primary-green disabled:text-primary-green border-2 border-primary-green disabled:cursor-not-allowed transition duration-300"
                                >
                                    Save Branches
                                </button>

                                {error && <p className="text-red-500 text-sm mt-4 self-center">{error}</p>}
                            </div>
                        </div>
                    </form>
                )}
            />

            <HierarchyIntros open={open} setOpen={setOpen}>
                <div className="text-right mr-2">
                    <div className="absolute right-2 top-0 pr-1 pt-1">
                        <XCircleIcon onClick={() => setOpen(false)} className="h-6 w-6 text-gray-400 hover:text-gray-500 cursor-pointer transition duration-300" aria-hidden="true" />
                    </div>
                </div>

                <div className="p-8 space-y-4">
                    <h2 className="font-bold uppercase">{subBranchName}</h2>

                    {subBranchName && (
                        <main className="space-y-8">
                            <div className="space-y-4">
                                <h3>{hierarchyIntros[subBranchName.toUpperCase()][0]}</h3>
                            </div>

                            <hr />

                            <div className="space-y-4">
                                <h3 className="font-bold uppercase">Topics</h3>

                                {hierarchyIntros[subBranchName.toUpperCase()][1].map((topic) => (
                                    <p key={topic.name}>{topic.name}</p>
                                ))}
                            </div>
                        </main>
                    )}
                </div>
            </HierarchyIntros>
        </>
    );
}

