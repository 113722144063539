export const buySellAgreementsData = {
  formTitle: "Add or edit buy / sell agreement",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/buySellAgreements",
  formFields: [
    {
      component: "input",
      label: "Company",
      name: "company",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Buyer name",
      name: "buyer",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Buyer phone",
      name: "bPhone",
    },
    {
      component: "input",
      label: "Buyer email",
      name: "bEmail",
      type: "email",
    },
    {
      component: "input",
      label: "Seller name",
      name: "seller",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Seller phone",
      name: "sPhone",
    },
    {
      component: "input",
      label: "Seller email",
      name: "sEmail",
      type: "email",
    },
    {
      component: "input",
      label: "Value",
      name: "value",
      type: "text",
    },
    {
      component: "input",
      label: "Date of origination",
      name: "originationDate",
      type: "date",
    },
    {
      component: "input",
      label: "Terms",
      name: "terms",
      type: "text",
    },
    {
      component: "input",
      label: "End date",
      name: "endDate",
      type: "date",
    },
    {
      component: "input",
      label: "Lastest revision date",
      name: "lastestDate",
      type: "date",
    },
    {
      component: "input",
      label: "Attorney name",
      name: "attorney",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
