export const professionalCareProvidersData = {
  formTitle: "Add or edit professional care provider",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/professionalCareProviders",
  formFields: [
    {
      component: "select",
      label: "Type of care provider",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        {
          value: "Independent care providers",
          text: "Independent care providers",
        },
        { value: "Registered care provider", text: "Registered care provider" },
        { value: "Skilled nurse", text: "Skilled nurse" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Name of agency",
      name: "agency",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Website",
      name: "website",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Phone number",
      name: "phone",
    },
    {
      component: "input",
      label: "Contact person",
      name: "contact",
      type: "text",
    },
    {
      component: "input",
      label: "Billing rates",
      name: "billing",
      type: "text",
    },
    {
      component: "input",
      label: "Referrals",
      name: "referrals",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
