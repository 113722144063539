import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import RegJourneyModal from "./reg-journey-modal";
import { restPut } from "../../../store/api";
import { getUserDetails } from "../../../store/actions";

export default function Code({ setDialogNum }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);
  const [codeAnswer, setCodeAnswer] = useState(false);

  const handleCodeAnswerChange = (e) => {
    if (e.target.value.toLowerCase() === "yes") setCodeAnswer(true);
    else setCodeAnswer(false);
  };

  // End the registration journey. If yes was chosen, navigate to the subscription screen. If no was chosen, navigate to the dashboard screen.
  const submitCodeChoice = () => {
    let reqBody = {};
    reqBody.quickstart = false;

    restPut(
      "/users/update",
      reqBody,
      (data) => {
        dispatch(getUserDetails(data));
      },
      (err) => console.error(err)
    );

    setDialogNum(-1);

    if (codeAnswer === true) {
      navigate("/my-profile");
    }
  };

  return (
    <RegJourneyModal open={open} setOpen={setOpen}>
      <div className="w-full h-[7vh] flex justify-center items-center text-2xl md:text-3xl text-primary-green text-center font-medium">
        <h2>WELCOME</h2>
      </div>

      <article className="h-max lg:h-[65vh] p-8 flex justify-center items-center bg-alternative-green">
        <div className="max-w-4xl flex flex-col gap-y-8 lg:gap-y-12 items-center text-xl md:text-2xl text-center font-custom2">
          <p className="text-2xl md:text-3xl text-center font-bold">Have You Received a Special License Code?</p>

          <p className="text-xl md:text-2xl text-justify font-medium">
            Check the "NO" box below If you don't have a special license code. You can get started on your free trial right away on your Dashboard. You can become a paid subscriber at any during your trial.
          </p>

          <p className="text-xl md:text-2xl text-justify font-medium">
            Check the "YES" box below, if you have a license code. You will be taken directly to the subscription screens. Here, you can select the subscription that matches your license code. You
            will NOT be charged for the duration of your subscription.
          </p>

          <section className="w-full flex flex-col items-center sm:items-start gap-y-4 font-semibold">
            <div>
              <input
                type="radio"
                id="noCode"
                name="regJourneyCode"
                value="no"
                checked={codeAnswer === false}
                onChange={handleCodeAnswerChange}
                className="text-primary-green border-black focus:ring-primary-green focus:ring-2"
              />

              <label htmlFor="noCode" className="ml-2">
                NO. I don't have a special license code.
              </label>
            </div>

            <div>
              <input
                type="radio"
                id="yesCode"
                name="regJourneyCode"
                value="yes"
                checked={codeAnswer === true}
                onChange={handleCodeAnswerChange}
                className="text-primary-green border-black focus:ring-primary-green focus:ring-2"
              />

              <label htmlFor="yesCode" className="ml-2">
                YES. I have a special license code.
              </label>
            </div>
          </section>
        </div>
      </article>

      <section className="h-[15vh] sm:h-[10vh] px-10 flex justify-between items-center bg-alternative-green">
        <div className="hidden sm:block h-20 w-20" />

        <div className="flex gap-x-2">
          <div className="h-4 sm:h-6 w-4 sm:w-6 border-2 border-primary-green rounded-full" />
          <div className="h-4 sm:h-6 w-4 sm:w-6 border-2 border-primary-green rounded-full" />
          <div className="h-4 sm:h-6 w-4 sm:w-6 border-2 border-primary-green rounded-full" />
          <div className="h-4 sm:h-6 w-4 sm:w-6 border-2 border-primary-green rounded-full" />
          <div className="h-4 sm:h-6 w-4 sm:w-6 bg-primary-green rounded-full" />
        </div>

        <button onClick={submitCodeChoice}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fregistration-journey%2Fright%20arrow.svg?alt=media&token=ef3d9e22-73f8-41a8-b7d9-802f60348068"
            alt="Green right arrow"
            className="h-14 sm:h-20 w-14 sm:w-20"
          />
        </button>
      </section>
    </RegJourneyModal>
  );
}
