export const passportsData = {
  formTitle: "Add or edit passport",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/passports",
  formFields: [
    {
      component: "input",
      label: "Name on passport",
      name: "name",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Country",
      name: "country",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Passport number",
      name: "passportNo",
      type: "text",
    },
    {
      component: "input",
      label: "Expiration date",
      name: "date",
      type: "date",
    },
    {
      component: "input",
      label: "Location of passport",
      name: "location",
      type: "text",
    },
    {
      component: "input",
      label: "Person(s) who have copies of this document",
      name: "persons",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
    {
      component: "file",
      name: "passportFiles",
    },
  ],
};
