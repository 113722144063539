export const reflectionsOnMyCareerData = {
    formTitle: "Add or edit influential people in my life",
    formSubtitle:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    pathname: "/reflectionsOnMyCareer",
    formFields: [
        {
            component: "select",
            label: "Areas of reflection",
            name: "reflection",
            type: "text",
            required: true,
            colObject: true,
            options: [
                {
                    value: "Alternate careers that I could have taken",
                    text: "Alternate careers that I could have taken",
                },
                {
                    value: "How I feel about my career",
                    text: "How I feel about my career",
                },
                {
                    value: "How my career path evolved",
                    text: "How my career path evolved",
                },
                {
                    value: "Major career decisions I had to make",
                    text: "Major career decisions I had to make",
                },
                {
                    value: "My greatest risks during my career",
                    text: "My greatest risks during my career",
                },
                {
                    value: "My  most unpleasant job/employer",
                    text: "My  most unpleasant job/employer",
                },
                {
                    value: "My most challenging job",
                    text: "My most challenging job",
                },
                {
                    value: "My most interesting job / employer",
                    text: "My most interesting job / employer",
                },
                {
                    value: "Persons who helped me in my career decisions",
                    text: "Person(s) who helped me in my career decisions",
                },
                {
                    value: "The benefits of work in my life",
                    text: "The benefits of work in my life",
                },
                {
                    value: "The different careers I have had",
                    text: "The different careers I have had",
                },
                {
                    value: "What are my best professional talents",
                    text: "What are my best professional talents",
                },
                {
                    value: "What life skills I learned while at work",
                    text: "What life skills I learned while at work",
                },
                {
                    value: "Where my career started",
                    text: "Where my career started",
                },
                { value: "Other", text: "Other" },
            ],
        },
        {
            component: "input",
            label: "Description",
            name: "description",
            type: "text",
        },
        {
            component: "textarea",
            label: "Notes",
            name: "notes",
        },
    ],
};

