import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { restPut } from "../../../store/api";
import { getUserDetails } from "../../../store/actions";

import GreenBtn from "../../../components/buttons/greenBtn";

export default function RegJourneyModal({ open, setOpen, children }) {
    const dispatch = useDispatch();

    const currentUser = useSelector((state) => state.auth.user);

    const [countdown, setCountdown] = useState(null);
    const [showPopupExipre, setShowPopupExpire] = useState(true);

    const setShowPopup = () => {
        const reqBody = { showingPopup: false };
        restPut("/users/update", reqBody, (data) =>
            dispatch(getUserDetails(data), (err) => console.error(err))
        );
    };

    const typeInterval = currentUser?.currentPaymentAmount?.typeInterval;

    const handleClosePopup = () => {
        setShowPopup();
        setShowPopupExpire(false);
    };

    useEffect(() => {
        if (
            currentUser &&
            (currentUser.status === true ||
                new Date(currentUser.expiredSubscriptionDate) > new Date())
        ) {
            const intervalId = setInterval(() => {
                const remainingTime =
                    new Date(currentUser.expiredSubscriptionDate).getTime() -
                    new Date().getTime();
                if (remainingTime <= 0) {
                    clearInterval(intervalId);
                    setCountdown(null);
                } else {
                    const days = Math.floor(
                        remainingTime / (24 * 60 * 60 * 1000)
                    );
                    setCountdown(`${days} days`);
                }
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [currentUser]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => setOpen(true)}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                {currentUser &&
                    currentUser.status === true &&
                    new Date(currentUser.expiredSubscriptionDate) >
                        new Date() &&
                    countdown &&
                    showPopupExipre &&
                    typeInterval !== "lifetime" &&
                    currentUser.showingPopup &&
                    window.location.pathname === "/" && (
                        <div className="fixed z-50 inset-0 overflow-y-auto">
                            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                <div className="fixed inset-0 transition-opacity">
                                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                </div>
                                <div
                                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-32 sm:align-middle sm:max-w-lg sm:w-full"
                                    role="dialog"
                                    aria-modal="true"
                                    aria-labelledby="modal-headline"
                                >
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <p
                                            className="leading-5 font-medium text-gray-900"
                                            id="modal-headline"
                                        >
                                            Your account will expire within{" "}
                                            {countdown}
                                        </p>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                        <GreenBtn
                                            type="button"
                                            onClick={() => handleClosePopup()}
                                        >
                                            Close
                                        </GreenBtn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                {currentUser &&
                    (currentUser.status === false ||
                        new Date(currentUser.expiredSubscriptionDate) >
                            new Date()) &&
                    countdown &&
                    showPopupExipre &&
                    typeInterval !== "lifetime" &&
                    currentUser.showingPopup &&
                    window.location.pathname === "/" && (
                        <div className="fixed z-50 inset-0 overflow-y-auto">
                            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                <div className="fixed inset-0 transition-opacity">
                                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                </div>
                                <div
                                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-32 sm:align-middle sm:max-w-lg sm:w-full"
                                    role="dialog"
                                    aria-modal="true"
                                    aria-labelledby="modal-headline"
                                >
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <p
                                            className="leading-5 font-medium text-gray-900"
                                            id="modal-headline"
                                        >
                                            Your account will expire within{" "}
                                            {countdown}
                                        </p>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                        <GreenBtn
                                            type="button"
                                            onClick={() => handleClosePopup()}
                                        >
                                            Close
                                        </GreenBtn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0 lg:ml-64 mb-8 mt-20 lg:mt-8">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full sm:w-[90vw] lg:w-[70vw] xl:w-[75vw] top-0">
                                {children}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

