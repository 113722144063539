export const socialSecurityDisabilityData = {
  formTitle: "Add or edit social security / disability",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/socialSecurityDisability",
  formFields: [
    {
      component: "mc",
      label: "Disability type",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      values: [
        "Cancer malignant neoplastic diseases",
        "Cardiovascular system",
        "Congenital disorders that affect multiple body systems",
        "Digestive system",
        "Endocrine disorders",
        "Genutourinary disorders",
        "Hematological disorders",
        "Immune system",
        "Mental disorders",
        "Musculoskeletal disorder",
        "Neurological disorders",
        "Respiratory disorders",
        "Skin disorders",
        "Special senses and speech",
      ],
    },
    {
      component: "input",
      label: "SSDI website",
      name: "website",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Local phone number",
      name: "phone",
    },
    {
      component: "input",
      label: "Email",
      name: "email",
      type: "email",
    },
    {
      component: "input",
      label: "My Medicare number (or its location)",
      name: "medicareNumber",
      type: "text",
    },
    {
      component: "input",
      label: "Monthly SSDI income",
      name: "monthlyIncome",
      type: "text",
    },
    {
      component: "input",
      label: "Coverage limits",
      name: "coverageLimits",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
