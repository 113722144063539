export const familyData = {
  formTitle: "Add or edit family member",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/family",
  formFields: [
    {
      component: "select",
      label: "Member category",
      name: "category",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Spouse / partner", text: "Spouse / partner" },
        { value: "Child: biological", text: "Child: biological" },
        { value: "Child: adopted", text: "Child: adopted" },
        { value: "Child: step", text: "Child: step" },
        { value: "Child: foster", text: "Child: foster" },
        { value: "Brother", text: "Brother" },
        { value: "Sister", text: "Sister" },
        { value: "Grandson", text: "Grandson" },
        { value: "Granddaughter", text: "Granddaughter" },
        { value: "Son-in-law", text: "Son-in-law" },
        { value: "Daughter-in-law", text: "Daughter-in-law" },
        { value: "Grandmother", text: "Grandmother" },
        { value: "Grandfather", text: "Grandfather" },
        { value: "Mother", text: "Mother" },
        { value: "Father", text: "Father" },
        { value: "Other", text: "Other" },
      ],
      description: "Use the drop-down menu to indicate if the family member is a spouse, child, grandchild, sibling, stepchild, or parent."
    },
    {
      component: "input",
      label: "First",
      name: "firstName",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Middle",
      name: "middleName",
      type: "text",
    },
    {
      component: "input",
      label: "Last",
      name: "lastName",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "phoneNumber",
      label: "Cell phone",
      name: "phone",
    },
    {
      component: "phoneNumber",
      label: "Land Line",
      name: "landLine",
    },
    {
      component: "input",
      label: "Email",
      name: "email",
      type: "email",
    },
    {
      component: "textarea",
      label: "Street, City, State, Zip Code",
      name: "address",
      type: "text",
    },
    {
      component: "input",
      label: "Country",
      name: "country",
      type: "text",
    },
    {
      component: "input",
      label: "Birth date",
      name: "birthDate",
      type: "date",
    },
    {
      component: "input",
      label: "Birth place",
      name: "birthPlace",
      type: "text",
    },
    {
      component: "input",
      label: "Marriage date",
      name: "marriageDate",
      type: "date",
    },
    {
      component: "input",
      label: "Children names",
      name: "childrenNames",
      type: "text",
    },
    {
      component: "showHiddenCheckbox",
      title: "Show fields: Divorce date, Death date, Burial location.",
    },
    {
      component: "input",
      label: "Divorce date",
      name: "divorceDate",
      type: "date",
      isHidden: true,
    },
    {
      component: "input",
      label: "Death date",
      name: "deathDate",
      type: "date",
      isHidden: true,
    },
    {
      component: "input",
      label: "Burial location",
      name: "burialLocation",
      type: "text",
      isHidden: true,
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
