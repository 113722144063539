export const backUpOfDigitalFilesData = {
  formTitle: "Add or edit back-up of digital file",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/backUpOfDigitalFiles",
  formFields: [
    {
      component: "input",
      label: "Device with digital files",
      name: "device",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Location of device",
      name: "deviceLocation",
      type: "text",
    },
    {
      component: "select",
      label: "Backup options",
      name: "backup",
      options: [
        { value: "Cloud", text: "Cloud" },
        { value: "CD / DVD", text: "CD / DVD" },
        { value: "External hard drive", text: "External hard drive" },
        {
          value: "Network attached storage (NAS)",
          text: "Network attached storage (NAS)",
        },
        { value: "USB flash drive", text: "USB flash drive" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Name of backup service / program",
      name: "backupService",
      type: "text",
    },
    {
      component: "input",
      label: "Location of back-up files",
      name: "backupLocation",
      type: "text",
    },
    {
      component: "input",
      label: "Name(s) on account",
      name: "name",
      type: "text",
    },
    {
      component: "input",
      label: "Login username (or its location)",
      name: "username",
      type: "text",
    },
    {
      component: "password",
      label: "Password (or its location)",
      name: "password",
      type: "password",
    },
    {
      component: "input",
      label: "Unlock code (or its location)",
      name: "passcode",
      type: "text",
    },
    {
      component: "input",
      label: "Renewal date",
      name: "date",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
