export const creditCardDebtData = {
  formTitle: "Add or edit credit card debt",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/creditCardDebt",
  formFields: [
    {
      component: "input",
      label: "Company",
      name: "company",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Name on card",
      name: "name",
      type: "text",
      colObject: true,
    },
    {
      component: "value",
      label: "Current amount owed",
      name: "amountOwed",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Financing terms",
      name: "financing",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
