import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import PageTitle from "../common/page-title";

export function ChecklistPageLayout({ name, data }) {
    const { description, boldDescription, pdfs } = data;

    const user = useSelector((state) => state.auth.user);
    const userType = user ? user.type : null;

    return (
        <>
            <PageTitle title="My Banyan" />

            <div className="mb-8 mt-6 md:mt-0">
                <div className="mx-auto flex max-w-7xl flex-col pt-4 md:pt-6 lg:pt-8">
                    <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-4 md:px-6">
                            <div className="flex items-center justify-between">
                                <div className="text-3xl uppercase sm:text-center text-primary-green w-full">
                                    {name}
                                </div>
                            </div>
                        </div>

                        <div className="px-4 pt-4 pb-8 md:px-6 border-t border-gray-300 bg-alternative-green sm:rounded-b-lg">
                            <div className="space-y-2 sm:space-y-4">
                                {description &&
                                    Array.isArray(description) &&
                                    description.length > 0 &&
                                    description.map((el, idx) => (
                                        <p
                                            key={`description_${idx}`}
                                            className={`text-gray-500 text-sm`}
                                        >
                                            {el}
                                        </p>
                                    ))}

                                {description &&
                                    typeof description === "string" && (
                                        <p className="text-gray-500 text-sm">
                                            {description}
                                        </p>
                                    )}

                                {boldDescription && (
                                    <p className="text-black font-semibold text-sm text-center">
                                        {boldDescription}
                                    </p>
                                )}
                            </div>

                            <div
                                className={`py-10 px-5 md:px-10 ${
                                    pdfs.length === 1
                                        ? "flex"
                                        : `grid gap-x-6 gap-y-6 ${
                                              pdfs.length === 3 &&
                                              "lg:grid-cols-3"
                                          } ${
                                              pdfs.length === 5 &&
                                              "sm:grid-cols-2 lg:grid-cols-3"
                                          }`
                                } justify-center`}
                            >
                                {pdfs.map((item, index) => {
                                    if (
                                        userType === "readonly" &&
                                        !item.link.includes("legal-docs")
                                    ) {
                                        return (
                                            <div className="flex justify-center">
                                                <img
                                                    src={item.img}
                                                    alt={item.imgAlt}
                                                    className="w-60 cursor-not-allowed"
                                                />
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <a
                                                key={index}
                                                href={item.link}
                                                target={
                                                    !item.link.includes(
                                                        "legal-docs"
                                                    ) && "_blank"
                                                }
                                                rel="noopener noreferrer"
                                                className="flex justify-center"
                                            >
                                                <img
                                                    src={item.img}
                                                    alt={item.imgAlt}
                                                    className="transition ease-in-out duration-300 hover:scale-105 w-60"
                                                />
                                            </a>
                                        );
                                    }
                                })}
                            </div>

                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
