export const independentLivingData = {
  formTitle: "Add or edit independent living",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/independentLiving",
  formFields: [
    {
      component: "input",
      label: "Name of community",
      name: "community",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "textarea",
      label: "Street, City, State, Zip Code",
      name: "address",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Website",
      name: "website",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Phone number",
      name: "phone",
    },
    {
      component: "input",
      label: "Contact person",
      name: "input",
      type: "text",
    },
    {
      component: "input",
      label: "Preferred unit type",
      name: "unit",
      type: "text",
      description:
        "Do you want to live in a home or condo/hotel building with living room, bedroom and bath? We suggest you look at the floor plans provided by each venue.",
    },
    {
      component: "input",
      label: "Available medical care",
      name: "mediCare",
      type: "text",
      description:
        "Do you want to anticipate your need for possible medical/nursing care? If so, you should look for venues with that option.",
    },
    {
      component: "input",
      label: "Fees / costs",
      name: "fees",
      type: "number",
    },
    {
      component: "input",
      label: "Dates of visits",
      name: "dates",
      type: "date",
    },
    {
      component: "input",
      label: "Referrals",
      name: "referrals",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
