import AWS from "aws-sdk";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { updateCurrentUser } from "../../store/actions";

const S3_BUCKET = "mybanyan-bucket-staging";
const REGION = "us-west-1";

AWS.config.update({
    accessKeyId: "AKIAQGLUQCEBYE4LU65D",
    secretAccessKey: "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",
});

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
});

export const UploadPhoto = ({ setOpen }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.currentUser);
    const [progress, setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [dragActive, setDragActive] = useState(false);
    const [toggle, setToggle] = useState(false);

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.items) {
            for (let i = 0; i < e.dataTransfer.items.length; i++) {
                if (e.dataTransfer.items[i].kind === "file") {
                    const file = e.dataTransfer.items[i].getAsFile();
                    setSelectedFile(file);
                    uploadFile(file);
                }
            }
        } else {
            for (let i = 0; i < e.dataTransfer.files.length; i++) {
                const file = e.dataTransfer.files[i];
                setSelectedFile(file);
                uploadFile(file);
            }
        }
    };

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    async function updateUser(photo) {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
            profile:
                "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/" +
                user.attributes.sub +
                "/" +
                photo,
        });

        const updatedUser = await Auth.currentAuthenticatedUser();
        dispatch(updateCurrentUser(updatedUser));
        setToggle(!toggle);
        setOpen(false);
    }

    const uploadFile = (file) => {
        const params = {
            ACL: "public-read",
            Body: file,
            Bucket: S3_BUCKET,
            Key: user.attributes.sub + "/" + file.name,
        };

        myBucket
            .putObject(params)
            .on("httpUploadProgress", (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100));
            })
            .send((err, data) => {
                if (err) console.error(err);
                if (data) {
                    updateUser(selectedFile && selectedFile.name);
                }
            });
    };

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps

        const dropZone = document.getElementById("drop-zone");
        dropZone.addEventListener("dragenter", handleDrag);
        dropZone.addEventListener("dragover", handleDrag);
        dropZone.addEventListener("dragleave", handleDrag);
        dropZone.addEventListener("drop", handleDrop);

        return () => {
            dropZone.removeEventListener("dragenter", handleDrag);
            dropZone.removeEventListener("dragover", handleDrag);
            dropZone.removeEventListener("dragleave", handleDrag);
            dropZone.removeEventListener("drop", handleDrop);
        };
    }, [toggle]);

    return (
        <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
                Upload your avatar
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>Your photo will be shown as an avatar</p>
            </div>
            <div className="mt-8 space-y-6">
                <div
                    id="drop-zone"
                    className={`flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 $${
                        dragActive ? "border-indigo-600" : ""
                    }`}
                    onDragEnter={handleDrag}
                    onDragOver={handleDrag}
                    onDragLeave={handleDrag}
                    onDrop={handleDrop}
                >
                    <div className="space-y-1 text-center">
                        {selectedFile ? (
                            <img
                                src={URL.createObjectURL(selectedFile)}
                                alt={"selected file"}
                                className="pb-4"
                            />
                        ) : (
                            <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                            >
                                <path
                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        )}

                        {selectedFile ? (
                            <a
                                href="/"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedFile(null);
                                }}
                                className="text-sm text-indigo-600"
                            >
                                Remove the photo
                            </a>
                        ) : (
                            <>
                                <div className="flex text-sm text-gray-600">
                                    <label
                                        htmlFor="file-upload"
                                        className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                    >
                                        <span>Upload a file</span>
                                        <input
                                            id="file-upload"
                                            name="file-upload"
                                            type="file"
                                            className="sr-only"
                                            onChange={handleFileInput}
                                            multiple={true}
                                        />
                                    </label>
                                    <p className="pl-1">or drag and drop</p>
                                </div>
                                <p className="text-xs text-gray-500">
                                    PNG, JPG, GIF up to 10MB
                                </p>
                            </>
                        )}
                    </div>
                </div>

                <div className="text-sm text-gray-500">
                    File upload progress is {progress}%
                </div>

                <button
                    type="file"
                    onClick={() => uploadFile(selectedFile)}
                    className="w-full mx-1 px-4 py-2 text-sm font-medium bg-primary-green hover:bg-white disabled:bg-white text-white hover:text-primary-green disabled:text-primary-green border-2 border-primary-green focus:outline-none rounded-md disabled:cursor-not-allowed transition duration-300"
                >
                    Upload photo
                </button>
            </div>
        </div>
    );
};

