export const operatorLicensesData = {
  formTitle: "Add or edit operator license",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/operatorLicenses",
  formFields: [
    {
      component: "select",
      label: "Type of license",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Auto / car", text: "Auto / car" },
        { value: "Bus", text: "Bus" },
        { value: "Chauffeur", text: "Chauffeur" },
        { value: "Commercial A", text: "Commercial A" },
        { value: "Commercial B", text: "Commercial B" },
        { value: "Commercial C", text: "Commercial C" },
        { value: "Motorcycle", text: "Motorcycle" },
        { value: "Pilot: Airline transport", text: "Pilot: Airline transport" },
        { value: "Pilot: Commercial", text: "Pilot: Commercial" },
        { value: "Pilot: Private", text: "Pilot: Private" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Name on license",
      name: "name",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "License number",
      name: "licenseNumber",
      type: "text",
    },
    {
      component: "input",
      label: "State / country",
      name: "region",
      type: "text",
    },
    {
      component: "input",
      label: "Renewal date",
      name: "date",
      type: "date",
    },
    {
      component: "input",
      label: "Location of license",
      name: "location",
      type: "text",
    },
    {
      component: "input",
      label: "Person(s) who have copies of this document",
      name: "persons",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
