export const medicationsData = {
  formTitle: "Add or edit medication",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/medications",
  formFields: [
    {
      component: "input",
      label: "Prescription",
      name: "prescription",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Dosage",
      name: "dosage",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Purpose",
      name: "purpose",
      type: "text",
    },
    {
      component: "input",
      label: "Physician name",
      name: "physician",
      type: "text",
    },
    {
      component: "input",
      label: "Preferred pharmacy",
      name: "pharmacy",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Pharmacy phone",
      name: "phone",
    },
    {
      component: "input",
      label: "Person who has copies",
      name: "personWhoHasCopies",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
