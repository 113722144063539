export const realEstateValueData = {
  formTitle: "Add or edit real estate holding",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/realEstateValue",
  formFields: [
    {
      component: "select",
      label: "Property type",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "My primary residence", text: "My primary residence" },
        { value: "My secondary residence", text: "My secondary residence" },
        { value: "My vacation residence", text: "My vacation residence" },
        { value: "Agriculture", text: "Agriculture" },
        { value: "Commercial", text: "Commercial" },
        {
          value: "Cooperative apartment building",
          text: "Cooperative apartment building",
        },
        { value: "Industrial", text: "Industrial" },
        { value: "Mixed use", text: "Mixed use" },
        {
          value: "Residential rental: short-term",
          text: "Residential rental: short-term",
        },
        {
          value: "Residential rental: long-term",
          text: "Residential rental: long-term",
        },
        { value: "Time share", text: "Time share" },
        { value: "Vacant land", text: "Vacant land" },
        { value: "Water (lakes, streams)", text: "Water (lakes, streams)" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Property name",
      name: "property",
      type: "text",
      required: false,
      colObject: true,
    },
    {
      component: "textarea",
      label: "Street, City, State, Zip Code",
      name: "address",
      type: "text",
    },
    {
      component: "value",
      label: "Most recent estimated value",
      name: "estimatedValue",
      type: "number",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Date of most recent estimated value",
      name: "recentDate",
      type: "date",
    },
    {
      component: "input",
      label: "Date of next estimated value",
      name: "nextDate",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
