import React from 'react';

export const Button = ({ text, ...props }) => {
	return (
		<button
			{...props}
			type='button'
			className='px-2.5 py-0.5 rounded-md bg-secondary-green font-medium text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2'
		>
			{text}
		</button>
	);
};

export const FormButton = ({ text, ...props }) => {
	return (
		<div>
			<button
				{...props}
				className='group relative flex w-full justify-center rounded-md border border-transparent bg-secondary-green py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
			>
				{text}
			</button>
		</div>
	);
};

export const Text = ({ label, ...props }) => {
	return (
		<div>
			<label
				htmlFor='search'
				className='block text-sm font-medium text-gray-700'
			>
				{label}
			</label>
			<div className='relative mt-1 flex items-center'>
				<input
					{...props}
					className='block w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
				/>
				<div className='absolute inset-y-0 right-0 flex py-1.5 pr-1.5'>
					<kbd className='inline-flex items-center rounded border border-gray-200 px-2 font-sans text-sm font-medium text-gray-400'>
						⌘K
					</kbd>
				</div>
			</div>
		</div>
	);
};

export const Fieldset = ({ title, subtitle, children }) => {
	return (
		<div className='px-4 py-5 sm:p-6'>
			<h3 className='text-lg font-medium leading-6 text-gray-900'>{title}</h3>
			<div className='mt-2 max-w-xl text-sm text-gray-500'>
				<p>{subtitle}</p>
			</div>
			<form className='mt-8 space-y-6'>{children}</form>
		</div>
	);
};
