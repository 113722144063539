export const communityAgenciesData = {
  formTitle: "Add or edit community agency",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/communityAgencies",
  formFields: [
    {
      component: "input",
      label: "Name of agency",
      name: "agency",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Contact person",
      name: "contact",
      type: "text",
    },
    {
      component: "textarea",
      label: "Street, City, State, Zip Code",
      name: "address",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Phone number",
      name: "phone",
    },
    {
      component: "input",
      label: "Service provided",
      name: "serviceProvided",
      type: "text",
    },
    {
      component: "input",
      label: "Hours / days of service",
      name: "serviceTime",
      type: "text",
    },
    {
      component: "input",
      label: "Service contract",
      name: "serviceContract",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
