export const podAccountsData = {
  formTitle: "Add or edit PoD account",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/podAccounts",
  formFields: [
    {
      component: "input",
      label: "Bank",
      name: "bank",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Account name",
      name: "name",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Person(s) who have copies of this document",
      name: "persons",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Assets",
      name: "assets",
      type: "text",
    },
    {
      component: "input",
      label: "Beneficiaries",
      name: "beneficiaries",
      type: "text",
    },
    {
      component: "input",
      label: "Date notarized",
      name: "date",
      type: "date",
    },
    {
      component: "input",
      label: "Witnesses",
      name: "witnesses",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
