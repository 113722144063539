export const ceremonialPreferencesData = {
  formTitle: "Add or edit disposition of my body",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/ceremonialPreferences",
  formFields: [
    {
      component: 'mc',
      label: 'My preferences',
      name: 'preferences',
      required: true,
      colObject: true,
      values: [
        'No ceremony',
        'Celebration of life',
        'Funeral: military',
        'Funeral: nonsectarian',
        'Funeral: private / family only',
        'Funeral: religious',
        'Grave site ceremony',
        'Memorial',
        'Spread my ashes',
        'Wake',
        'Other',
      ],
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
  ],
};
