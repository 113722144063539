import { Link } from "react-router-dom";

import { summaryReports } from "./data";

export default function SummaryReports() {
  return (
    <div className="max-w-5xl mx-auto pt-4">
      <p className="text-sm text-gray-800">
        Introducing My Banyan's new "Reports Summary" page. With reports
        covering Assets, Estate Plan, Health, Passwords and more, you can easily
        access a consolidated view of all your important information. The
        Reports Summary page pulls selected data from all filled-out records in
        select topics, automatically calculates values and other financial
        figures when available. Plus, with the ability to download a .csv file,
        managing your finances and plans has never been more seamless.
      </p>

      <div className="pt-8 grid grid-cols-2 sm:grid-cols-3 gap-5">
        {summaryReports.map((item, index) => (
          <Link
            key={index}
            to={`/summary-reports/${item.link}`}
            className="group block bg-white hover:scale-105 transition duration-300 shadow-lg"
          >
            <img src={item.img} alt={item.imgAlt} className="w-full" />

            <p className="p-2 text-center text-sm sm:text-base uppercase text-primary-green group-hover:underline">
              My {item.title} Summary
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
}
