export const errorSignInMessages = {
    USER_NOT_COMFIRM: "User is not confirmed",
    PASSWORD_ATTEMPT_EXCEED: "Password attempts exceeded",
}

export const errorSignUpMessages = {
    USER_ALREADY_EXISTS: "An account with the given email already exists.",
}

export const errorFeedbackMessages = {
    MESSAGE_COULD_NOT_BE_SENT: "Message could not be sent",
}
