export const jointOwnershipsData = {
  formTitle: "Add or edit irrevocable trust",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/jointOwnerships",
  formFields: [
    {
      component: "select",
      label: "Type of jointly-owned asset",
      name: "property",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Bank accounts", text: "Bank accounts" },
        { value: "Investment accounts", text: "Investment accounts" },
        { value: "Real estate", text: "Real estate" },
        { value: "Vehicles", text: "Vehicles" },
        {
          value: "Other assets that have deeds of ownership",
          text: "Other assets that have deeds of ownership",
        },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Description of asset",
      name: "description",
      type: "text",
    },
    {
      component: "input",
      label: "Name(s) of joint owners",
      name: "jointOwners",
      type: "text",
    },
    {
      component: "input",
      label: "Date of formation",
      name: "date",
      type: "date",
    },
    {
      component: "input",
      label: "Person who has copies",
      name: "personWhoHasCopies",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};

