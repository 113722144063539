export const storageRentalUnitData = {
  formTitle: "Add or edit lease agreement",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/storageRentalUnit",
  formFields: [
    {
      component: "input",
      label: "Company",
      name: "company",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Storage unit number",
      name: "number",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Account number",
      name: "accountNumber",
      type: "text",
    },
    {
      component: "input",
      label: "Name(s) on account",
      name: "names",
      type: "text",
    },
    {
      component: "input",
      label: "Access code to facility",
      name: "facilityCode",
      type: "text",
    },
    {
      component: "input",
      label: "Unit lock code or location of key",
      name: "code",
      type: "text",
    },
    {
      component: "input",
      label: "Storage contents",
      name: "contents",
      type: "text",
    },
    {
      component: "input",
      label: "Rental terms",
      name: "terms",
      type: "text",
    },
    {
      component: "input",
      label: "Rental fee",
      name: "fee",
      type: "number",
    },
    {
      component: "input",
      label: "Expiration / renewal date",
      name: "date",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
