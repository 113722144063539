function BlackBtn({ type, disabled, onClick, children }) {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className="w-full mx-1 px-4 py-2 text-sm font-medium bg-black hover:bg-white disabled:bg-white text-white hover:text-black disabled:text-black border-2 border-black focus:outline-none rounded-md disabled:cursor-not-allowed transition duration-300"
    >
      {children}
    </button>
  );
}
  
export default BlackBtn;
  