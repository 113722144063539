
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import Root from "./store";

import { Amplify } from "aws-amplify";
import { ToastContainer } from "react-toastify";

import React from "react";
import ReactDOM from "react-dom/client";
import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Root>
    <App />
    <ToastContainer />
  </Root>
);
