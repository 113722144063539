import { TYPES } from '../actions/types';

const INITIAL_STATE = {
  byAll: null,
  byId: null,
  toggle: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.UPDATE_TOPIC_BY_ALL:
      return {
        ...state,
        byAll: action.payload,
      };
    case TYPES.UPDATE_TOPIC_BY_ID:
      return {
        ...state,
        byId: action.payload,
      };
    case TYPES.SET_TOPIC_TOGGLE:
      return {
        ...state,
        toggle: !state.toggle,
      };
    default:
      return state;
  }
};
