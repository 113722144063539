export const oralHistoriesData = {
  formTitle: "Add or edit oral history",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/oralHistories",
  formFields: [
    {
      component: "input",
      label: "Description",
      name: "description",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Highlights",
      name: "highlights",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
