export const extraPropertyKeysData = {
    formTitle: "Add or edit extra property key",
    formSubtitle:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    pathname: "/extraPropertyKeys",
    formFields: [
        {
            component: "input",
            label: "Property location (street, city, state, zip code, country)",
            name: "address",
            type: "text",
            colObject: true,
        },
        {
            component: "input",
            label: "Location of keys",
            name: "location",
            type: "text",
        },
        {
            component: "input",
            label: "Person(s) who have keys",
            name: "personsHave",
            type: "text",
        },
        {
            component: "input",
            label: "Person(s) who know where these keys are",
            name: "personsKnow",
            type: "text",
        },
        {
            component: "textarea",
            label: "Notes",
            name: "notes",
        },
    ],
};

