export const recordedMemoriesData = {
    formTitle: "Add or edit my recorded memory",
    formSubtitle:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    pathname: "/recordedMemories",
    formFields: [
        {
            component: "input",
            label: "Name of collection",
            name: "name",
            type: "text",
            colObject: true,
        },
        {
            component: "input",
            label: "Description of content",
            name: "description",
            type: "text",
        },
        {
            component: "input",
            label: "Years",
            name: "years",
            type: "number",
        },
        {
            component: "mc",
            label: "Media",
            name: "media",
            values: [
                "Multimedia: digital",
                "Audio: digital files",
                "Audio: analog tapes",
                "Photos: digital files",
                "Photos: negatives",
                "Photos: prints",
                "Photos: slides",
                "Videos: digital files",
                "Videos: film / tapes",
                "Other",
            ],
        },
        {
            component: "mc",
            label: "Storage",
            name: "storage",
            values: [
                "Boxes, envelopes",
                "Cloud",
                "Local drives",
                "Scrapbooks",
                "Other",
            ],
        },
        {
            component: "input",
            label: "Person(s) who know where these collection are located",
            name: "location",
            type: "text",
        },
        {
            component: "textarea",
            label: "Notes",
            name: "notes",
        },
        {
            component: "input",
            label: "Location of original files",
            name: "originalFiles",
            type: "text",
        },
        {
            component: "input",
            label: "Location of back up files",
            name: "backUpFiles",
            type: "text",
        },
    ],
};

