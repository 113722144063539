export const influentialPeopleInMyLifeData = {
  formTitle: "Add or edit influential people in my life",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/influentialPeopleInMyLife",
  formFields: [
    {
      component: "select",
      label: "Type of person",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Co-worker", text: "Co-worker" },
        { value: "Family member", text: "Family member" },
        { value: "Friend", text: "Friend" },
        { value: "Mentor", text: "Mentor" },
        { value: "Public figure I never met", text: "Public figure I never met" },
        {
          value: "Religious figure",
          text: "Religious figure",
        },
        { value: "Supervisor / manager", text: "Supervisor / manager" },
        { value: "Teacher", text: "Teacher" },
        { value: "Team mate", text: "Team mate" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Name",
      name: "name",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "My age",
      name: "age",
      type: "number",
    },
    {
      component: "input",
      label: "Their impact on me",
      name: "impact",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
  ],
};
