function WhiteBtn({ type, disabled, onClick, children }) {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className="mx-1 px-4 py-2 text-sm font-medium bg-white hover:bg-gray-400 disabled:bg-gray-400 text-black hover:text-white disabled:text-white border-2 border-gray-400 focus:outline-none rounded-md disabled:cursor-not-allowed transition duration-300"
    >
      {children}
    </button>
  );
}

export default WhiteBtn;
