export const vaccinationsData = {
  formTitle: "Add or edit vaccination",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/vaccinations",
  formFields: [
    {
      component: "select",
      label: "Type of vaccination/immunization",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Chickenpox", text: "Chickenpox" },
        { value: "COVID Coronavirus", text: "COVID Coronavirus" },
        { value: "Diptheria", text: "Diptheria" },
        { value: "Pertussis", text: "Pertussis" },
        { value: "Hepatitis A", text: "Hepatitis A" },
        { value: "Hepatitis B", text: "Hepatitis B" },
        { value: "Hepatitis C", text: "Hepatitis C" },
        { value: "Influenza", text: "Influenza" },
        {
          value: "Measles / mumps / rubella",
          text: "Measles / mumps / rubella",
        },
        { value: "Meningitis A", text: "Meningitis A" },
        { value: "Meningitis B", text: "Meningitis B" },
        { value: "Pneumonia", text: "Pneumonia" },
        { value: "Poliovirus", text: "Poliovirus" },
        { value: "Rotavirus", text: "Rotavirus" },
        { value: "Shingles", text: "Shingles" },
        { value: "Tetanus", text: "Tetanus" },
        { value: "Varicella", text: "Varicella" },
        { value: "RSV", text: "RSV" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Where I got the last dose",
      name: "location",
      type: "text",
    },
    {
      component: "input",
      label: "Date of last dose",
      name: "lastDoseDate",
      type: "date",
    },
    {
      component: "input",
      label: "Date of next dose",
      name: "nextDoseDate",
      type: "date",
    },
    {
      component: "input",
      label: "Person who has copies",
      name: "personWhoHasCopies",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};

