export const outstandingLawsuitsData = {
  formTitle: "Add or edit outstanding lawsuit",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/outstandingLawsuits",
  formFields: [
    {
      component: "input",
      label: "Name of litigant",
      name: "litigant",
      type: "text",
      description:
        "A litigant is a person who is involved in a civil legal case – someone you may be making a formal complaint about, or vice versa.",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Basis of suit",
      name: "suitBasis",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Attorney name",
      name: "attorney",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Possible next date",
      name: "date",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
