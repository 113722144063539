export const investmentsData = {
  formTitle: "Add or edit investment",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/investments",
  formFields: [
    {
      component: "select",
      label: "Account type",
      name: "accountType",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "401(k)", text: "401(k)" },
        { value: "529 Savings Plan", text: "529 Savings Plan" },
        {
          value: "Coverdell Education Savings Plan",
          text: "Coverdell Education Savings Plan",
        },
        {
          value: "Health Savings Account (HSA)",
          text: "Health Savings Account (HSA)",
        },
        { value: "Individual TOD Account", text: "Individual TOD Account" },
        { value: "IRA: Custodial", text: "IRA: Custodial" },
        { value: "IRA: Roth IRA", text: "IRA: Roth IRA" },
        { value: "IRA: SEP IRA", text: "IRA: SEP IRA" },
        { value: "IRA: Simple", text: "IRA: Simple" },
        { value: "IRA: Spousal", text: "IRA: Spousal" },
        { value: "IRA: Traditional", text: "IRA: Traditional" },
        { value: "Margin Account", text: "Margin Account" },
        {
          value: "Medical Savings Account (MSA)",
          text: "Medical Savings Account (MSA)",
        },
        { value: "Options Account", text: "Options Account" },
        { value: "Type 1 Cash Account", text: "Type 1 Cash Account" },
        { value: "UGMA Custodial Account", text: "UGMA Custodial Account" },
        { value: "UTMA Custodial Account", text: "UTMA Custodial Account" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "select",
      label: "Ownership type",
      name: "ownershipType",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Trust", text: "Trust" },
        { value: "Joint", text: "Joint" },
        { value: "Individual", text: "Individual" },
        { value: "Custodial", text: "Custodial" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "select",
      label: "Holdings type",
      name: "holdingsType",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Annuities", text: "Annuities" },
        { value: "Bonds", text: "Bonds" },
        { value: "CD", text: "CD" },
        { value: "Commercial bank accounts", text: "Commercial bank accounts" },
        { value: "Commodities", text: "Commodities" },
        { value: "Cryptocurrency", text: "Cryptocurrency" },
        {
          value: "Exchange traded funds (ETF)",
          text: "Exchange traded funds (ETF)",
        },
        { value: "Metals", text: "Metals" },
        { value: "Mutual funds", text: "Mutual funds" },
        { value: "Money market", text: "Money market" },
        { value: "Options", text: "Options" },
        { value: "Retirement plans", text: "Retirement plans" },
        { value: "Stocks", text: "Stocks" },
        { value: "UITs", text: "UITs" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Account name",
      name: "name",
      type: "text",
      required: true,
    },
    {
      component: "input",
      label: "Name(s) on account",
      name: "names",
      type: "text",
    },
    {
      component: "input",
      label: "Name of advisor",
      name: "advisor",
      type: "text",
    },
    {
      component: "input",
      label: "Account number",
      name: "accountNumber",
      type: "text",
    },
    {
      component: "value",
      label: "Current value",
      name: "currentValue",
      type: "number",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Date of estimated value",
      name: "estimatedValueDate",
      type: "date",
    },
    {
      component: "input",
      label: "Beneficiaries",
      name: "beneficiaries",
      type: "text",
    },
    {
      component: "input",
      label: "Next date for estimated value",
      name: "nextEstimatedValueDate",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
