import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { XCircleIcon } from "@heroicons/react/outline";
import { confirmSignup } from "./confirm-signup";
import { Text, Button } from "./inputs";
import { AuthLayout } from "./auth-layout";
import { SubmissionAlert } from "../common/submission-alert";
import { useDispatch } from "react-redux";
import { updateTypeByAll } from "../../store/actions";
import { Toastr } from "../common/toastr";

import HierarchyIntros from "../dashboard/hierarchy-intros";

const successMessage =
    "Verification code has been sent to your email. Please type or paste the code to the box below and verify your code.";

export const VerifyConfirmationCode = ({ username, subtitle, info }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [code, setCode] = useState("");
    const [type, setType] = useState("");
    const [isPending, setIsPending] = useState(false);
    const [success, setSuccess] = useState(false);
    const [open, setOpen] = useState(false);

    const dispatchCallback = async (type) => {
        if (location.state && location.state.pathname !== undefined) {
            navigate(location.state.pathname, { replace: true });
        } else {
            navigate("/signin", { replace: true });
            dispatch(updateTypeByAll(type));
        }
    };

    async function resendConfirmationCode() {
        try {
            await Auth.resendSignUp(username);
            setIsPending(false);
            setSuccess(true);
        } catch (err) {
            console.error("error resending code: ", err);
        }
    }

    const onChangeHandler = (e) => {
        e.preventDefault();

        setCode(e.target.value);
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();

        if (type === "") {
            Toastr({
                type: "error",
                message: "Please choose your user type preferences.",
            });
        } else {
            setIsPending(true);
            confirmSignup(username, code, type, dispatchCallback, setIsPending);
        }
    };

    const getStatusUser = async () => {
        try {
            await Auth.currentAuthenticatedUser();
        } catch (error) {
            console.error("error: ", error);
        }
    };

    useEffect(() => {
        if (info === "inner") {
            setType("readonly");
        } else if (info === "free") {
            setType("free");
        } else if (type === "") {
            setType("free");
        }
        getStatusUser();
    }, [info, type]);

    return (
        <>
            <AuthLayout title={"Verify my code"} subtitle={subtitle}>
                {success && (
                    <SubmissionAlert
                        type={"success"}
                        message={successMessage}
                    />
                )}

                <form className="space-y-6" onSubmit={onSubmitHandler}>
                    <div className="text-sm text-white font-light">
                        <p className="font-semibold text-left">
                            To confirm your account, look in your email for a
                            6-digit verification code. The subject line of the
                            email is "Your Verification Code."
                        </p>
                        <p className="font-semibold text-left py-3">
                            You should receive this email within a couple of
                            minutes. If not, check your spam folder.
                        </p>
                    </div>
                    <div className="space-y-1">
                        <Text
                            name="code"
                            type="text"
                            value={code}
                            onChange={onChangeHandler}
                            autoComplete="new-password"
                            required
                            label="Your verification code *"
                        />
                    </div>
                    {info === null && (
                        <div className="text-sm text-white font-light text-center">
                            <div className="flex justify-center items-center h-full mt-2">
                                <div className="flex flex-col space-y-2">
                                    <label className="flex space-x-2">
                                        <input
                                            type="radio"
                                            name="free"
                                            value="free"
                                            checked={
                                                type === "free" ||
                                                info === "free" ||
                                                (info === null &&
                                                    type !== "readonly")
                                            }
                                            onChange={(e) => {
                                                setType(e.target.value);
                                            }}
                                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <span>I want a 21-day FREE trial</span>
                                    </label>

                                    {info !== "free" && (
                                        <label className="flex space-x-2">
                                            <input
                                                type="radio"
                                                name="readonly"
                                                value="readonly"
                                                checked={
                                                    type === "readonly" ||
                                                    info === "inner"
                                                }
                                                onChange={(e) => {
                                                    setType(e.target.value);
                                                }}
                                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            />
                                            <span>
                                                I want to register as an Inner
                                                Circle member
                                            </span>
                                            <span className="relative inline-block group">
                                                <QuestionMarkCircleIcon
                                                    onClick={() =>
                                                        setOpen(true)
                                                    }
                                                    className="shrink-0 h-5 w-5 text-gray-900 group-hover:text-white transition duration-300 cursor-pointer"
                                                />
                                            </span>
                                        </label>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div>
                        <Button type="submit" isPending={isPending}>
                            VERIFY MY CODE
                        </Button>
                    </div>
                </form>

                <div className="pt-5 text-sm text-white font-light">
                    <p className="font-semibold text-center">
                        Didn't receive a verification code?
                    </p>
                    <p className="font-semibold text-center">
                        <Link
                            to="/resend-verification-code"
                            className="underline hover:text-gray-700"
                            onClick={(e) => {
                                e.preventDefault();
                                setIsPending(true);
                                if (!isPending) resendConfirmationCode();
                            }}
                        >
                            Resend verification email
                        </Link>
                    </p>
                </div>
            </AuthLayout>

            <HierarchyIntros open={open} setOpen={setOpen}>
                <div className="text-right mr-2">
                    <div className="absolute right-2 top-0 pr-1 pt-1">
                        <XCircleIcon
                            onClick={() => setOpen(false)}
                            className="h-6 w-6 text-gray-400 hover:text-gray-500 cursor-pointer transition duration-300"
                            aria-hidden="true"
                        />
                    </div>
                </div>

                <div className="px-6 mt-12 space-y-6 sm:space-y-8 font-custom2">
                    <p>You have chosen our Inner Circle Membership.</p>
                    <p>
                        Please note that this will only allow you to access
                        reports and files that are shared with you.
                    </p>
                    <p>
                        To access all My Banyan features you need to choose the
                        21-day free trial, or upgrade in your accounts panel
                        once registered.
                    </p>

                    <div className="text-right">
                        <button
                            onClick={() => setOpen(false)}
                            className="px-4 py-2 bg-secondary-green hover:bg-primary-green font-bold transition duration-300"
                        >
                            CLOSE
                        </button>
                    </div>
                </div>
            </HierarchyIntros>
        </>
    );
};

