export const airTravelClearancesData = {
  formTitle: "Add or edit air travel clearance",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/airTravelClearances",
  formFields: [
    {
      component: "select",
      label: "Type of clearance",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "TSA PreCheck", text: "TSA PreCheck" },
        { value: "Clear", text: "Clear" },
        { value: "Global Entry", text: "Global Entry" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Name on travel identification",
      name: "name",
      type: "text",
      description:
        "Important note: Be sure you know the exact name used on any government-issued IDs, including travel documents. If the name on your clearance documents doesn’t match the name on your ticket, you may be denied travel.",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Number",
      name: "number",
      type: "text",
    },
    {
      component: "input",
      label: "Renewal date",
      name: "date",
      type: "date",
    },
    {
      component: "input",
      label: "Location of identification",
      name: "idLocation",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
