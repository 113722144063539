export const insuranceBenefitsData = {
  formTitle: "Add or edit insurance / benefit",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/insuranceBenefits",
  formFields: [
    {
      component: "input",
      label: "Insurance company",
      name: "company",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Policy name",
      name: "policy",
      type: "text",
    },
    {
      component: "input",
      label: "Payor name",
      name: "payor",
      type: "text",
    },
    {
      component: "input",
      label: "Coverage",
      name: "coverage",
      type: "text",
    },
    {
      component: "input",
      label: "Exclusions",
      name: "exclusions",
      type: "text",
    },
    {
      component: "input",
      label: "Value",
      name: "value",
      type: "text",
    },
    {
      component: "input",
      label: "Date of policy",
      name: "date",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
