export const loansReceivableData = {
  formTitle: "Add or edit loan receivable",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/loansReceivable",
  formFields: [
    {
      component: "select",
      label: "Type of loan",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Collateral", text: "Collateral" },
        { value: "Mortgage", text: "Mortgage" },
        { value: "Personal guarantee", text: "Personal guarantee" },
        { value: "Security backed loan", text: "Security backed loan" },
        { value: "Signature / cash loan", text: "Signature / cash loan" },
        { value: "Student loan", text: "Student loan" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Borrower name",
      name: "borrower",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Name(s) of lender",
      name: "lenders",
      type: "text",
      description:
        "Indicate the exact name(s) of the lender(s) that appears on the loan document. This could be you or your spouse, a trust, or your business.",
    },
    {
      component: "input",
      label: "Purpose of loan",
      name: "purpose",
      type: "text",
    },
    {
      component: "value",
      label: "Loan amount",
      name: "amount",
      type: "number",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Most recent outstanding amount",
      name: "outstandingAmount",
      type: "text",
    },
    {
      component: "input",
      label: "Payment amount",
      name: "paymentAmount",
      type: "number",
    },
    {
      component: "input",
      label: "Payment frequency",
      name: "paymentFreq",
      type: "text",
    },
    {
      component: "input",
      label: "Date of origination",
      name: "originationDate",
      type: "date",
    },
    {
      component: "input",
      label: "Date of final payment",
      name: "finalPaymentDate",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
