export const donationsData = {
  formTitle: "Add or edit donation",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/donations",
  formFields: [
    {
      component: "input",
      label: "Charitable organizations",
      name: "organizations",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Flowers",
      name: "flowers",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
