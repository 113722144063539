export const serviceInvitationListsData = {
  formTitle: "Add or edit service invitation list",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/serviceInvitationLists",
  formFields: [
    {
      component: "input",
      label: "Name of person who has list of my preferred invitees",
      name: "invitees",
      type: "text",
      colObject: true,
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
