export const assistedLivingNursingData = {
  formTitle: "Add or edit assisted living nursing",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/assistedLivingNursing",
  formFields: [
    {
      component: "input",
      label: "Name of agency",
      name: "agency",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "textarea",
      label: "Street, City, State, Zip Code",
      name: "address",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Website",
      name: "website",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Phone number",
      name: "phone",
    },
    {
      component: "input",
      label: "Contact person",
      name: "contact",
      type: "text",
    },
    {
      component: "input",
      label: "Preferred level of care",
      name: "care",
      type: "text",
      description:
        "Assisted living facilities provide several levels of care. We suggest you review levels and their prices as you assess each venue.",
    },
    {
      component: "input",
      label: "Preferred room accomodations",
      name: "room",
      type: "text",
      description:
        "Do you want to live in a home, or a condo/hotel building with living room, bedroom and bath?",
    },
    {
      component: "input",
      label: "Billing rates",
      name: "billing",
      type: "text",
    },
    {
      component: "input",
      label: "Referrals",
      name: "referrals",
      type: "text",
    },
    {
      component: "input",
      label: "Visit dates",
      name: "dates",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
