import { XCircleIcon, CheckCircleIcon } from "@heroicons/react/solid";

const TYPES = {
  success: {
    bgColor: "rounded-md bg-green-50 p-4 mb-8",
    iconColor: "text-green-400",
    textColor: "text-green-800",
    Icon: CheckCircleIcon,
  },
  error: {
    bgColor: "rounded-md bg-red-50 p-4 mb-8",
    iconColor: "text-red-400",
    textColor: "text-red-800",
    Icon: XCircleIcon,
  },
};

export const SubmissionAlert = ({ type, message }) => {
  const bgColor = TYPES[type].bgColor;
  const iconColor = TYPES[type].iconColor;
  const textColor = TYPES[type].textColor;
  const IconComponent = TYPES[type].Icon;

  return (
    <div className={`rounded-md ${bgColor} p-4`}>
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <IconComponent
            className={`h-5 w-5 ${iconColor}`}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className={`text-xs font-medium ${textColor}`}>{message}</h3>
        </div>
      </div>
    </div>
  );
};
