export const publicHealthInsuranceData = {
  formTitle: "Add or edit public health insurance",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/publicHealthInsurance",
  formFields: [
    {
      component: "select",
      label: "Public health plan",
      name: "publicHealthPlan",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Medicaid", text: "Medicaid" },
        {
          value: "Medicare A & B (in-patient & out-patient)",
          text: "Medicare A & B (in-patient & out-patient)",
        },
        { value: "Medicare C (Advantage)", text: "Medicare C (Advantage)" },
        {
          value: "Medicare D (Prescription)",
          text: "Medicare D (Prescription)",
        },
        {
          value: "Veterans Health Administration (VHA)",
          text: "Veterans Health Administration (VHA)",
        },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Website",
      name: "website",
      type: "text",
    },
    {
      component: "input",
      label: "Username",
      name: "username",
      type: "text",
    },
    {
      component: "password",
      label: "Password",
      name: "password",
      type: "password",
    },
    {
      component: "phoneNumber",
      label: "Office phone",
      name: "officePhone",
    },
    {
      component: "input",
      label: "Name of agent",
      name: "agent",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Agent phone number",
      name: "agentPhone",
    },
    {
      component: "input",
      label: "File number",
      name: "fileNumber",
      type: "text",
    },
    {
      component: "input",
      label: "My Medicare number (or its location)",
      name: "medicareNumber",
      type: "text",
    },
    {
      component: "input",
      label: "Application approved date",
      name: "applicationApprovedDate",
      type: "date",
    },
    {
      component: "input",
      label: "Prescription drug provider",
      name: "prescriptionDrugProvider",
      type: "text",
    },
    {
      component: "input",
      label: "Plan ID",
      name: "planId",
      type: "text",
    },
    {
      component: "input",
      label: "Medicare Supplement plan",
      name: "medicareSupplementPlan",
      type: "text",
    },
    {
      component: "input",
      label: "Membership ID",
      name: "membershipId",
      type: "text",
    },
    {
      component: "input",
      label: "Effective date",
      name: "effectiveDate",
      type: "date",
    },
    {
      component: "input",
      label: "Website",
      name: "medicareWebsite",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Supplement Provider phone",
      name: "supplementProviderPhone",
    },
    {
      component: "input",
      label: "Days covered",
      name: "daysCovered",
      type: "text",
    },
    {
      component: "input",
      label: "Coverage limits",
      name: "coverageLimits",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
