export const longTermCareInsuranceData = {
  formTitle: "Add or edit property insurance",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/longTermCareInsurance",
  formFields: [
    {
      component: "input",
      label: "Insurance company",
      name: "company",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "mc",
      label: "Expenses covered in your policy",
      name: "expenses",
      required: true,
      colObject: true,
      values: [
        "Alternative care",
        "Assisted living care",
        "Home / personal day care only",
        "Home health care",
        "Hospice",
        "Intermittent nursing and rehabilitative care",
        "Nursing home / residential care facility only",
        "Skilled continuous 24/7 care",
        "Other",
      ],
    },
    {
      component: "input",
      label: "Policy owner / insured",
      name: "insured",
      type: "text",
      description:
        "The insured person or a representative of the insured (such as a spouse or trustee) should the insured become disabled.",
    },
    {
      component: "input",
      label: "Person(s) who have copies of this document",
      name: "persons",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Phone number of company",
      name: "phone",
    },
    {
      component: "phoneNumber",
      label: "Phone number to file a claim",
      name: "filePhone",
    },
    {
      component: "input",
      label: "Policy number",
      name: "policyNumber",
      type: "text",
    },
    {
      component: "input",
      label: "Date of issue",
      name: "issueDate",
      type: "date",
    },
    {
      component: "input",
      label: "Inflation rider",
      name: "inflationRider",
      type: "text",
      description: "The person that the insurance policy covers.",
    },
    {
      component: "input",
      label: "Waiting / elimination period",
      name: "period",
      type: "text",
    },
    {
      component: "input",
      label: "Exclusions",
      name: "exclusions",
      type: "text",
    },
    {
      component: "input",
      label: "Daily / monthly benefit amount",
      name: "benefitAmount",
      type: "number",
    },
    {
      component: "input",
      label: "Current death benefit (if applicable)",
      name: "deathBenefit",
      type: "text",
    },
    {
      component: "input",
      label: "Current surrender value (if applicable)",
      name: "surrenderValue",
      type: "text",
    },
    {
      component: "input",
      label: "Monthly premium amount",
      name: "premiumAmount",
      type: "number",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
