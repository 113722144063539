import { useState } from "react";

import { tutorialsData } from "./data";
import TModal from "./modal";
import PageTitle from "../common/page-title";

export default function Tutorials() {
    const [showVideo, setShowVideo] = useState({
        idx: null,
        open: false,
    });

    const onClickHandler = (index) => {
        setShowVideo((prevState) => ({
            ...prevState,
            idx: index,
            open: showVideo.idx === index ? !prevState.open : true,
        }));
    };

    return (
        <>
            <PageTitle title="My Banyan" />

            <div className="max-w-4xl mx-auto py-5 sm:py-10 px-2 sm:px-10 2xl:px-5">
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-5 sm:gap-10">
                    {tutorialsData.videos.map((item, index) => (
                        <div
                            key={index}
                            className="group cursor-pointer"
                            onClick={() => {
                                onClickHandler(index);
                            }}
                        >
                            <img
                                src={item.img}
                                alt={item.imgAlt}
                                className="w-full rounded-3xl group-hover:opacity-90"
                            />

                            <p className="pt-2 pb-1 text-sm sm:text-base font-semibold uppercase group-hover:underline">
                                {item.title}
                            </p>

                            <p className="text-xs">{item.body}</p>

                            {showVideo.open && showVideo.idx === index && (
                                <TModal
                                    open={showVideo.open}
                                    setOpen={setShowVideo}
                                    data={item}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

