export const summaryReports = [
  {
    img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsummary-reports%2Fasset_summary.jpg?alt=media&token=b301daec-01e3-42e6-ba54-734b6ae87178",
    imgAlt: "assets summary image",
    title: "assets",
    link: "assets-summary",
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/mybanyan-9ce7c.appspot.com/o/Liabilities.jpg?alt=media&token=ebec7cb9-67f0-473d-8d2c-f835085e4f9c",
    imgAlt: "my liablities summary image",
    title: "liabilities",
    link: "liabilities-summary",
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/mybanyan-9ce7c.appspot.com/o/networth-summary.jpg?alt=media&token=c5db5ac6-3e7c-4738-97bb-547129491908",
    imgAlt: "my net worth summary image",
    title: "net worth",
    link: "net-worth-summary",
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsummary-reports%2Festate_summary.jpg?alt=media&token=b5cae85e-a162-4f80-ace5-d6f7923d93f2",
    imgAlt: "estate plan summary image",
    title: "estate plan",
    link: "estate-plan-summary",
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsummary-reports%2Fhealth_summary.jpg?alt=media&token=7ea0acf5-dbf8-4f43-b748-adfa12a3c100",
    imgAlt: "health summary image",
    title: "health",
    link: "health-summary",
  },
];
