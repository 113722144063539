import { useState } from "react";
import { useSelector } from "react-redux";

import { Form } from "react-final-form";

import { restPost } from "../../../store/api";
import RegJourneyModal from "./reg-journey-modal";
import { Select, Text } from "../../../lib/form/inputs";
import WhiteBtn from "../../../components/buttons/whiteButton";
import GreenBtn from "../../../components/buttons/greenBtn";

const contactTypeOptions = [
  { value: "immediate-family", text: "Immediate Family" },
  { value: "extended-family", text: "Extended Family" },
  { value: "Friends", text: "Friends" },
  { value: "Acquaintances", text: "Acquaintances" },
  { value: "Legal contacts", text: "Legal contacts" },
  { value: "Financial contacts", text: "Financial contacts" },
  { value: "Health contacts", text: "Health contacts" },
  { value: "Business contacts", text: "Business contacts" },
  {
    value: "Other contacts",
    text: "Other contacts",
  },
];

export default function SelectInnerCircle({ setDialogNum }) {
  const [open, setOpen] = useState(true);

  const user = useSelector((state) => state.auth.user);
  const currentUser = useSelector((state) => state.auth.currentUser);

  const submitInnerCircleForm = async (values) => {
    // Adds the sender's (i.e. current user) full name
    const nameSend = currentUser.attributes.name;
    values.nameSend = nameSend;

    // Adds the contact to the user's inner circle
    await restPost(
      "/myContacts/new",
      values,
      () => setDialogNum(3),
      (err) => console.error(err)
    );
  };

  return (
    <RegJourneyModal open={open} setOpen={setOpen}>
      <div className="w-full h-[7vh] flex justify-center items-center text-2xl md:text-3xl text-primary-green text-center font-medium">
        <h2>WELCOME</h2>
      </div>

      <article className="h-max lg:h-[65vh] p-8 flex justify-center items-center bg-alternative-green">
        <div className="max-w-5xl flex flex-col gap-y-8 sm:gap-y-14 items-center text-xl md:text-2xl text-center font-custom2">
          <p className="text-2xl md:text-3xl text-center font-bold">Your Inner Circle</p>

          <p className="text-xl md:text-2xl text-justify">
            You can enter your first Inner Circle member here. You can add more members at any time. Each member will receive an invitation to join My Banyan.
          </p>

          <Form
            onSubmit={submitInnerCircleForm}
            initialValues={{
              contactType: "",
              firstName: "",
              lastName: "",
              email: "",
              innerCircle: true,
              nameSend: "",
            }}
            render={({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit} className="w-full space-y-8 divide-y divide-gray-300">
                <div className="space-y-8 sm:space-y-5 divide-y divide-gray-300 ">
                  <Select label="Contact Type" name="contactType" type="text" required>
                    <option value={""}>Select...</option>

                    {contactTypeOptions.map((option) => (
                      <option key={option.text} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </Select>

                  <Text name="firstName" type="text" required>
                    First Name <span className="text-red-500">*</span>
                  </Text>

                  <Text name="lastName" type="text" required>
                    Last Name <span className="text-red-500">*</span>
                  </Text>

                  <Text name="email" type="email" required>
                    Email <span className="text-red-500">*</span>
                  </Text>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    <WhiteBtn type="button" disabled={submitting} onClick={() => setDialogNum(1)}>
                      Cancel
                    </WhiteBtn>

                    <GreenBtn type="submit" disabled={user === null || submitting || !values.contactType || !values.firstName || !values.lastName || !values.email}>
                      Save
                    </GreenBtn>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </article>

      <section className="h-[15vh] sm:h-[10vh] px-10 flex justify-center items-center bg-alternative-green">
        <div className="flex gap-x-2">
          <div className="h-4 sm:h-6 w-4 sm:w-6 border-2 border-primary-green rounded-full" />
          <div className="h-4 sm:h-6 w-4 sm:w-6 border-2 border-primary-green rounded-full" />
          <div className="h-4 sm:h-6 w-4 sm:w-6 bg-primary-green rounded-full" />
          <div className="h-4 sm:h-6 w-4 sm:w-6 border-2 border-primary-green rounded-full" />
          <div className="h-4 sm:h-6 w-4 sm:w-6 border-2 border-primary-green rounded-full" />
        </div>
      </section>
    </RegJourneyModal>
  );
}
