export const bankCardsData = {
  formTitle: "Add or edit bank card",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/bankCards",
  formFields: [
    {
      component: "select",
      label: "Card type",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        // { value: "American Express", text: "American Express" },
        // { value: "Discover", text: "Discover" },
        // { value: "MasterCard", text: "MasterCard" },
        // { value: "Store or Merchant", text: "Store or Merchant" },
        { value: "Credit card", text: "Credit card" },
        { value: "Debit card", text: "Debit card" },
        { value: "ATM card", text: "ATM card" },
        { value: "ATM/Debit card", text: "ATM/Debit card" },
        // { value: "Visa", text: "Visa" },
        // { value: "Other", text: "Other" },
      ],
    },
    {
      component: "select",
      label: "Card Name",
      name: "card",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "American Express", text: "American Express" },
        { value: "Discover", text: "Discover" },
        { value: "MasterCard", text: "MasterCard" },
        { value: "Store or Merchant", text: "Store or Merchant" },
        { value: "Visa", text: "Visa" },
        { value: "Other", text: "Other" },
      ]
    },
    {
      component: "input",
      label: "Issuing bank",
      name: "issuingBank",
      type: "text",
      description:
          "The bank that issued the credit or debit card to the customer. Also known simply as issuers, these banks are members of card networks such as MasterCard and Visa.",
    },
    {
      component: "input",
      label: "Person's name on card",
      name: "name",
      type: "text",
      description:
        "The card user(s’) name. Record the exact name(s) as shown on the card.",
    },
    {
      component: "input",
      label: "Other name on card",
      name: "otherName",
      type: "text",
    },
    {
      component: "input",
      label: "Card number",
      name: "cardNumber",
      type: "number",
    },
    {
      component: "input",
      label: "Expiration date",
      name: "date",
      type: "date",
    },
    {
      component: "input",
      label: "Security code (CVV/CVC)",
      name: "securityCode",
      type: "text",
      description:
        "The CVV/CVC, or Card Verification/Value Code, is a 3- or 4-digit number on a credit card for added security with online or over-the-phone purchases. This code is needed to contact the credit card company, and usually found on the back of the card.",
    },
    {
      component: "input",
      label: "Website",
      name: "website",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Customer service phone number",
      name: "supportPhone",
    },
    {
      component: "input",
      label: "Name(s) on account",
      name: "names",
      type: "text",
      description:
        "It is possible that the name on the card is not the same name as on the account. For example, an adult child’s name may be on the debit/credit card, but the bill is sent to the parent whose name is on the account.",
    },
    {
      component: "input",
      label: "Login username (or its location)",
      name: "username",
      type: "text",
    },
    {
      component: "password",
      label: "Account password (or its location)",
      name: "password",
      type: "password",
    },
    {
      component: "input",
      label: "PIN number (or its location)",
      name: "pin",
      type: "text",
    },
    {
      component: "input",
      label: "Annual fee",
      name: "fee",
      type: "number",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};

