export const currentResidenceData = {
  formTitle: "Add or edit my current residence",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/currentResidence",
  formFields: [
    {
      component: "input",
      label: "Property location (street, city, state, zip code, country)",
      name: "location",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Possible upgrades or renovations",
      name: "upgrades",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
