export const floristsData = {
  formTitle: "Add or edit my florist",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/florists",
  formFields: [
    {
      component: "input",
      label: "Name",
      name: "name",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "textarea",
      label: "Street, City, State, Zip Code",
      name: "address",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Phone number",
      name: "phone",
    },
    {
      component: "input",
      label: "Email",
      name: "email",
      type: "email",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
