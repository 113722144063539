export const TYPES = {
  UPDATE_CURRENT_USER: 'update-current-user',
  UPDATE_TOPIC_BY_ALL: 'update-topic-by-all',
  UPDATE_TOPIC_BY_ID: 'update-topic-by-id',
  UPDATE_CALENDAR_BY_ALL: 'update-calendar-by-all',
  UPDATE_CALENDAR_BY_ID: 'update-calendar-by-id',
  UPDATE_TODO_BY_ALL: 'update-todo-by-all',
  UPDATE_TODO_BY_ID: 'update-todo-by-id',
  CALENDAR_SET_TOGGLE: 'calendar_set_toggle',
  TODO_SET_TOGGLE: 'todo_set_toggle',
  UPDATE_FILE_UPLOAD_BY_ALL: 'update-file-upload-by-all',
  UPDATE_FILE_UPLOAD_BY_ID: 'update-file-upload-by-id',
  UPDATE_SHARING_BY_ALL: 'update-sharing-by-all',
  UPDATE_SHARING_BY_ID: 'update-sharing-by-id',
  GET_USER_DETAILS: 'get-user-details',
  UPDATE_INNER_CIRCLE_BY_ALL: 'update-inner-circle-by-all',
  UPDATE_INNER_CIRCLE_BY_ID: 'update-inner-circle-by-id',
  UPDATE_CONTACT_BY_ALL: 'update-contact-by-all',
  UPDATE_CONTACT_BY_ID: 'update-contact-by-id',
  SET_TOPIC_TOGGLE: 'set-topic-toggle',
  UPDATE_NOTIFICATION_BY_ALL: 'update-notification-by-all',
  UPDATE_NOTIFICATION_BY_ID: 'update-notification-by-id',
  UPDATE_MULTIPLE_CHECKBOX_BY_ALL: 'update-multiple-checkbox-by-all',
  UPDATE_MULTIPLE_CHECKBOX_BY_ID: 'update-multiple-checkbox-by-id',
  UPDATE_TYPE_BY_ALL: 'update-type-by-all',
};
