export const lastWillTestamentsData = {
  formTitle: "Add or edit last & will testament",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/lastWillTestaments",
  formFields: [
    {
      component: "mc",
      label: "Contents",
      name: "contents",
      required: true,
      colObject: true,
      values: [
        "Beneficaries",
        "Claims against estate",
        "Debts",
        "Guardianship",
        "List of assets",
        "List of trusts",
        "No contest provision",
        "Plans for disposition all assets",
        "Plans for donations to charity",
        "Plans for family business",
        "Plans for funeral / burial",
        "Plans to minimize Inheritance tax",
        "Other",
      ],
    },
    {
      component: "input",
      label: "Legal name of document",
      name: "legalNameOfDocument",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Person(s) who have copies of this document",
      name: "persons",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Executor",
      name: "executor",
      type: "text",
    },
    {
      component: "input",
      label: "Sucessor executor",
      name: "sucessor",
      type: "text",
    },
    {
      component: "input",
      label: "Executor's powers",
      name: "powers",
      type: "text",
    },
    {
      component: "input",
      label: "Attorney",
      name: "attorney",
      type: "text",
    },
    {
      component: "input",
      label: "Date of origination",
      name: "originationDate",
      type: "date",
    },
    {
      component: "input",
      label: "Witnesses",
      name: "witnesses",
      type: "text",
    },
    {
      component: "input",
      label: "Latest revision date",
      name: "latestRevisionDate",
      type: "date",
    },
    {
      component: "input",
      label: "Next review date",
      name: "nextReviewDate",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: 'input',
      label: 'Location of files / documents',
      name: 'fileLocation',
      type: 'text',
    },
  ],
};
