export const homeSafesData = {
    formTitle: "Add or edit home safe",
    formSubtitle:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    pathname: "/homeSafes",
    formFields: [
        {
            component: "input",
            label: "Property location (street, city, state, zip code, country)",
            name: "address",
            type: "text",
            colObject: true,
        },
        {
            component: "input",
            label: "Location of safe",
            name: "location",
            type: "text",
            colObject: true,
        },
        {
            component: "input",
            label: "Person(s) who know where this safe is",
            name: "persons",
            type: "text",
        },
        {
            component: "input",
            label: "Contents",
            name: "contents",
            type: "text",
        },
        {
            component: "input",
            label: "Code (or its location)",
            name: "code",
            type: "text",
        },
        {
            component: "textarea",
            label: "Notes",
            name: "notes",
        },
        {
            component: "input",
            label: "Location of files / documents",
            name: "fileLocation",
            type: "text",
        },
    ],
};

