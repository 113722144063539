import PDFLayout from "./PDFLayout";
import AWS from "aws-sdk";

import { pdf } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { restGet, restPost } from "../../../store/api";
import { useEffect, useState } from "react";
import { Toastr } from "../../common/toastr";

import GreenBtn from "../../buttons/greenBtn";

const S3_BUCKET = "mybanyan-bucket-staging";
const REGION = "us-west-1";

AWS.config.update({
    accessKeyId: "AKIAQGLUQCEBYE4LU65D",
    secretAccessKey: "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",
});

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
});

const getKey = (userId, topicName, topicId, fileName, currentTime) => {
    return (
        userId +
        "/" +
        topicName +
        "/" +
        topicId +
        "/" +
        fileName +
        "_" +
        currentTime +
        ".pdf"
    );
};

export default function AllRecordsPDFReport({
    fileName,
    records,
    title,
    name,
    email,
    selectedRows,
    trueKeys,
}) {
    const location = useLocation();
    const user = useSelector((state) => state.auth.currentUser);
    const [downloadRecords, setDownloadRecords] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const pathname = location.pathname.split("/");

    const failCallback = (err) => {
        console.error(err);
    };

    const sucessCallback = (data, record) => {
        if (data && data.length > 0) {
            const response = { ...record, url: data[0].url };
            setDownloadRecords(response);
        }
    };

    const addToDatabase = async (currentTime, records) => {
        const reqBody = {};
        reqBody.topic = pathname[pathname.length - 1];
        reqBody.id = records[0].id;
        reqBody.fileName = fileName + "_" + currentTime + "_" + records[0].id;
        reqBody.type = "report";
        reqBody.url =
            "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/" +
            getKey(
                user.attributes.sub,
                "report",
                records[0].id,
                fileName,
                currentTime
            );

        await restPost(
            "/fileUploads/new",
            reqBody,
            (data) => console.info("Add to database sucessfully"),
            failCallback
        );
        Toastr({
            type: "success",
            message: "Reports have been saved in REPORTS",
        });
    };

    const handleDownload = async () => {
        setIsLoading(true);

        try {
            const pdfLayout = (
                <PDFLayout
                    records={records}
                    title={title}
                    name={name}
                    email={email}
                />
            );

            const blob = await pdf(pdfLayout).toBlob();
            const currentTime = new Date().getTime();
            const params = {
                ACL: "public-read",
                Key: getKey(
                    user.attributes.sub,
                    "report",
                    records[0].id,
                    fileName,
                    currentTime
                ),
                Body: blob,
                Bucket: S3_BUCKET,
                ContentType: "application/pdf",
            };

            await myBucket.upload(params).promise();
            await addToDatabase(currentTime, records);
        } catch (error) {
            console.error("Error uploading PDF to S3: ", error);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (!Array.isArray(downloadRecords)) {
            console.error("downloadRecords is not an array.");
            return;
        }

        const fetchData = async () => {
            const updatedRecords = await Promise.all(
                downloadRecords.map(async (record) => {
                    try {
                        await restGet(
                            `/fileUploads/type?id=${record.id}&type=cabinet`,
                            (data) => {
                                sucessCallback(data, record);
                            },
                            failCallback
                        );
                    } catch (error) {
                        console.error("Error fetching data:", error);
                    }

                    return record;
                })
            );

            setDownloadRecords(updatedRecords);
        };

        fetchData();
    }, []);

    return (
        <GreenBtn
            disabled={trueKeys.length === 0 || isLoading}
            onClick={async () => {
                handleDownload();
            }}
        >
            Save reports
        </GreenBtn>
    );
}

