/* eslint-disable no-loop-func */
import moment from 'moment';

const weekObj = {
  Sunday: 1,
  Monday: 2,
  Tuesday: 3,
  Wednesday: 4,
  Thursday: 5,
  Friday: 6,
  Saturday: 7,
};

export const getMonthTable = (current, isToday, events, selected) => {
  let days = [];

  const firstDayIndex =
    weekObj[moment(current).startOf('month').format('dddd')];

  if (firstDayIndex > 1) {
    for (var i = 1; i < firstDayIndex; i++) {
      days.push({
        date: moment(current)
          .startOf('month')
          .subtract(firstDayIndex - i, 'days')
          .format('YYYY-MM-DD'),
        events: [],
      });
    }
  }

  for (
    var j = 0;
    j < parseInt(moment(current).endOf('month').format('D'));
    j++
  ) {
    days.push({
      date: moment(current)
        .startOf('month')
        .add(j, 'days')
        .format('YYYY-MM-DD'),
      isCurrentMonth: true,
      isToday:
        moment(current).startOf('month').add(j, 'days').format('YYYY-MM-DD') ===
        isToday,
      events: Array.isArray(events) ? events.filter(
        (el) =>
          moment(current)
            .startOf('month')
            .add(j, 'days')
            .format('YYYY-MM-DD') === moment(el.starts).format('YYYY-MM-DD')
      ) : [],
      isSelected:
        selected &&
        moment(selected.date).format('YYYY-MM-DD') ===
          moment(current).startOf('month').add(j, 'days').format('YYYY-MM-DD'),
    });
  }

  const lastDayIndex = weekObj[moment(current).endOf('month').format('dddd')];

  for (var k = 0; k < 7 - lastDayIndex; k++) {
    days.push({
      date: moment(current)
        .endOf('month')
        .add(k + 1, 'days')
        .format('YYYY-MM-DD'),
      events: [],
    });
  }

  if (days.length < 42) {
    let l = days.length;
    let d = days[days.length - 1].date;
    for (var s = 0; s < 42 - l; s++) {
      days.push({
        date: moment(d)
          .add(s + 1, 'days')
          .format('YYYY-MM-DD'),
        events: [],
      });
    }
  }

  return days;
};
