import { combineReducers } from 'redux';
import authReducer from './authReducer';
import calendarReducer from './calendarReducer';
import topicReducer from './topicReducer';
import todoReducer from './todoReducer';
import fielUploadReducer from './fielUploadReducer';
import sharingReducer from './sharingReducer';
import innerCircleReducer from './innerCircleReducer';
import contactReducer from './contactReducer';
import notificationReducer from './notificationReducer';
import typeReducer from './typeReducer';

export default combineReducers({
  auth: authReducer,
  topic: topicReducer,
  calendar: calendarReducer,
  todo: todoReducer,
  fileUpload: fielUploadReducer,
  sharing: sharingReducer,
  innerCircle: innerCircleReducer,
  contact: contactReducer,
  notification: notificationReducer,
  type: typeReducer,
});
