export const prePaymentsData = {
  formTitle: "Add or edit pre-payment",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/prePayments",
  formFields: [
    {
      component: "input",
      label: "Payee",
      name: "payee",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Payor",
      name: "payor",
      type: "text",
    },
    {
      component: "input",
      label: "Amount",
      name: "amount",
      type: "number",
    },
    {
      component: "input",
      label: "Date",
      name: "date",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
