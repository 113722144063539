export const personalVehiclesValueData = {
  formTitle: "Add or edit collectible valuable",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/personalVehiclesValue",
  formFields: [
    {
      component: "select",
      label: "Vehicle type",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Automobile", text: "Automobile" },
        { value: "Boat", text: "Boat" },
        { value: "Bus", text: "Bus" },
        { value: "Motorcycle", text: "Motorcycle" },
        { value: "Off-road vehicle", text: "Off-road vehicle" },
        { value: "Plane", text: "Plane" },
        { value: "Recreational vehicle (RV)", text: "Recreational vehicle (RV)" },
        { value: "Truck", text: "Truck" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Manufacturer",
      name: "manufacturer",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Model + year",
      name: "model",
      type: "text",
      colObject: true,
    },
    {
      component: "value",
      label: "Most recent value",
      name: "value",
      type: "number",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Registered owner name(s)",
      name: "names",
      type: "text",
    },
    {
      component: "input",
      label: "Item location",
      name: "location",
      type: "text",
    },
    {
      component: "input",
      label: "Governing agency",
      name: "agency",
      type: "text",
    },
    {
      component: "input",
      label: "License / identification number",
      name: "license",
      type: "text",
    },
    {
      component: "input",
      label: "Registration / VIN number",
      name: "registration",
      type: "text",
    },
    {
      component: "input",
      label: "Person(s) who have copies of this document",
      name: "persons",
      type: "text",
    },
    {
      component: "input",
      label: "Registration renewal date",
      name: "renewalDate",
      type: "date",
    },
    {
      component: "input",
      label: "Registration fee",
      name: "fee",
      type: "text",
    },
    {
      component: "input",
      label: "Purchase date",
      name: "purchaseDate",
      type: "date",
    },
    {
      component: "input",
      label: "Purchase price",
      name: "price",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
