export const devicesData = {
  formTitle: "Add or edit device",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/devices",
  formFields: [
    {
      component: "select",
      label: "Device type",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Alexa and similar", text: "Alexa and similar" },
        { value: "Desktop computer", text: "Desktop computer" },
        { value: "Laptop computer", text: "Laptop computer" },
        { value: "Home security system", text: "Home security system" },
        { value: "Ring and similar", text: "Ring and similar" },
        { value: "Security gate", text: "Security gate" },
        { value: "Smart phone", text: "Smart phone" },
        { value: "Smart television", text: "Smart television" },
        { value: "SmartThing", text: "SmartThing" },
        { value: "Tablet", text: "Tablet" },
        { value: "Workstation", text: "Workstation" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Location of device",
      name: "location",
      type: "text",
    },
    {
      component: "input",
      label: "Manufacturer model + year",
      name: "model",
      type: "text",
    },

    {
      component: "input",
      label: "Specifications",
      name: "specifications",
      type: "text",
    },
    {
      component: "input",
      label: "Unlock code (or its location)",
      name: "passcode",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
