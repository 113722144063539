import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateInnerCircleByAll } from "../../store/actions";
import { restPost, restPut, restGet } from "../../store/api";
import { useLocation } from "react-router-dom";
import { Toastr } from "../common/toastr";

import WhiteBtn from "../buttons/whiteButton";
import GreenBtn from "../buttons/greenBtn";

export default function Submit({
    pathname,
    reqBody,
    initialValues,
    innerCircle,
    children,
}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.innerCircle.byAll);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id") ? searchParams.get("id").toString() : "";
    const user = useSelector((state) => state.auth.user);
    const currentUser = useSelector((state) => state.auth.currentUser);

    const nameSend = currentUser.attributes.name;

    const getDataToShow = () => {
        restGet(
            "/myContacts/index",
            (data) => dispatch(updateInnerCircleByAll(data)),
            (error) => console.error(error)
        );
    };

    const successCallback = (message) => {
        navigate("/inner-circle");
        Toastr({
            type: "success",
            message,
        });
    };

    const failCallback = (err) => {
        console.error(err);
        Toastr({ type: "error", message: "Error with contact." });
    };

    const onSubmit = async (values) => {
        const result = reqBody(values);
        result.innerCircle = innerCircle;
        result.nameSend = nameSend;

        getDataToShow();

        const contactList = data;

        const isEmailDuplicate = contactList?.some(
            (contact) => contact.email === result.email && contact._id !== id
        );

        if (isEmailDuplicate) {
            Toastr({
                type: "warning",
                message: "This email have been already in your contact list.",
            });
            return;
        }

        if (initialValues) {
            await restPut(
                pathname,
                result,
                () => successCallback("Contact successfully edited."),
                failCallback
            );
        } else {
            await restPost(
                pathname,
                result,
                () => successCallback("Contact successfully created."),
                failCallback
            );
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form
                    onSubmit={handleSubmit}
                    className="space-y-8 divide-y divide-gray-300 mb-6 -mt-2"
                >
                    <div className="space-y-8 divide-y divide-gray-300 sm:space-y-5">
                        {children}
                    </div>

                    <div className="pt-5">
                        <div className="flex justify-end">
                            <WhiteBtn
                                type="button"
                                disabled={submitting}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/inner-circle");
                                }}
                            >
                                Cancel
                            </WhiteBtn>

                            <GreenBtn
                                type="submit"
                                disabled={
                                    user === null ||
                                    user.type === "readonly" ||
                                    submitting
                                }
                            >
                                Save
                            </GreenBtn>
                        </div>
                    </div>
                </form>
            )}
        />
    );
}

