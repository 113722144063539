import TopicList from "./topic-list";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { restPut } from "../../store/api";
import { getUserDetails } from "../../store/actions";

const getColObject = (formFields) => {
    let colObject = {};
    formFields.forEach((el) => {
        if (el.colObject) colObject[el.name] = el.label;
    });

    return colObject;
};

export function List({ formFields, pathname, name }) {
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    const path = pathname + "/index";

    const updateLastVisitedArray = (lastVisited, value) => {
        let arr = [...lastVisited];
        arr.push({ path: value, name: name });

        return arr;
    };

    useEffect(() => {
        if (
            user.lastVisited &&
            !user.lastVisited.find((el) => el.path === location.pathname)
        ) {
            const updatedLastVisited = updateLastVisitedArray(
                user.lastVisited,
                location.pathname
            );

            const storedLastVisted = JSON.parse(
                localStorage.getItem("lastVisited")
            );
            if (storedLastVisted) {
                const existingIndex = storedLastVisted.findIndex(
                    (el) => el.path === location.pathname
                );

                if (existingIndex !== -1) {
                    storedLastVisted[existingIndex].timestamp = Date.now();
                }
            }

            const lastApiCallTimestamp = localStorage.getItem(
                "lastApiCallTimestamp"
            );
            const currentTimestamp = Date.now();
            const timeSentRequest = 0.2 * 60 * 1000;

            if (
                !lastApiCallTimestamp ||
                currentTimestamp - lastApiCallTimestamp >= timeSentRequest
            ) {
                restPut(
                    "/users/update",
                    {
                        lastVisited: updatedLastVisited,
                    },
                    (data) => {
                        dispatch(getUserDetails(data));
                        localStorage.setItem(
                            "lastVisited",
                            JSON.stringify(updatedLastVisited)
                        );
                        localStorage.setItem(
                            "lastApiCallTimestamp",
                            currentTimestamp
                        );
                    },
                    (err) => console.error(err)
                );
            }
        }
    }, []);

    return <TopicList colObject={getColObject(formFields)} pathname={path} />;
}

