import { Link } from "react-router-dom";

import PageTitle from "../../components/common/page-title";

export default function Confirmation() {
    return (
        <>
            <PageTitle title="My Banyan" />

            <div className="pt-10 flex justify-center">
                <Link
                    to="/"
                    className="rounded-md border border-gray-300 bg-primary-green py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-white hover:text-gray-900 focus:outline-none focus:ring-0 focus:ring-primary-green focus:ring-offset-0"
                >
                    Return to Dashboard
                </Link>
            </div>
        </>
    );
}

