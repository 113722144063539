export const obituaryFuneralNoticesData = {
  formTitle: "Add or edit obituary funeral notice",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/obituaryFuneralNotices",
  formFields: [
    {
      component: "input",
      label: "Newspapers",
      name: "newspapers",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Social media",
      name: "socialMedia",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Newsletters",
      name: "newsletters",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Email",
      name: "email",
      type: "email",
    },
    {
      component: "input",
      label: "Other",
      name: "other",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
