export const reflectionsOnMyYouthData = {
  formTitle: "Add or edit influential people in my life",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/reflectionsOnMyYouth",
  formFields: [
    {
      component: "select",
      label: "Moments that I recall",
      name: "moments",
      type: "text",
      required: true,
      colObject: true,
      options: [
        {
          value: "My earliest childhood memories",
          text: "My earliest childhood memories",
        },
        { value: "My happiest moments", text: "My happiest moments" },
        { value: "My romantic crushes", text: "My romantic crushes" },
        {
          value: "Somethings that deeply affected me",
          text: "Somethings that deeply affected me",
        },
        {
          value: "Things that fascinated me",
          text: "Things that fascinated me",
        },
        {
          value: "What I thought it was like to be an adult",
          text: "What I thought it was like to be an adult",
        },
        { value: "What I was most proud of", text: "What I was most proud of" },
        {
          value: "What it was like living in my family",
          text: "What it was like living in my family",
        },
        {
          value: "What jobs I though I might have as an adult",
          text: "What jobs I though I might have as an adult",
        },
        { value: "What made me cry", text: "What made me cry" },
        { value: "What made me laugh", text: "What made me laugh" },
        { value: "When I felt really brave", text: "When I felt really brave" },
        {
          value: "When I had a painful injury",
          text: "When I had a painful injury",
        },
        { value: "When I helped someone", text: "When I helped someone" },
        { value: "When I was really scared", text: "When I was really scared" },
        { value: "When I was really sick", text: "When I was really sick" },
        { value: "Where I spent my summers", text: "Where I spent my summers" },
        {
          value: "Who comforted me when I was really sad",
          text: "Who comforted me when I was really sad",
        },
      ],
    },
    {
      component: "input",
      label: "Description",
      name: "description",
      type: "text",
    },
    {
      component: "input",
      label: "My age",
      name: "age",
      type: "number",
    },
    {
      component: "input",
      label: "Who was there",
      name: "who",
      type: "text",
    },
    {
      component: "input",
      label: "Where and when",
      name: "whereWhen",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
  ],
};
