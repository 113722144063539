export const businessFinancialStatementsData = {
  formTitle: "Add or edit business financial statement",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/businessFinancialStatements",
  formFields: [
    {
      component: "input",
      label: "Company",
      name: "company",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "select",
      label: "Type",
      name: "statementType",
      colObject: true,
      options: [
        {value: "Balance sheet", text: "Balance sheet"},
        {value: "Income", text: "Income"},
        {value: "Cash flow", text: "Cash flow"},
        {value: "Shareholders equity", text: "Shareholders equity"},
        {value: "Other", text: "Other"},
      ]
    },
    {
      component: "input",
      label: "Financial agent",
      name: "financialAgent",
      type: "text",
    },
    {
      component: "input",
      label: "Last report date",
      name: "firstDate",
      type: "date",
    },
    {
      component: "input",
      label: "Next report date",
      name: "secondDate",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
