export const collectibleValuablesData = {
    formTitle: "Add or edit collectible valuable",
    formSubtitle:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    pathname: "/collectibleValuables",
    formFields: [
        {
            component: "select",
            label: "Type of collectible",
            name: "typeOfCollectible",
            type: "text",
            required: true,
            colObject: true,
            options: [
                { value: "Antiques", text: "Antiques" },
                { value: "Books", text: "Books" },
                { value: "Chinese porcelain", text: "Chinese porcelain" },
                {
                    value: "Classic  / exotic cars",
                    text: "Classic  / exotic cars",
                },
                { value: "Coins", text: "Coins" },
                { value: "Comic books", text: "Comic books" },
                {
                    value: "Fine / original artwork",
                    text: "Fine / original artwork",
                },
                { value: "Guns", text: "Guns" },
                { value: "Horses", text: "Horses" },
                { value: "Jewelry / gemstones", text: "Jewelry / gemstones" },
                { value: "Sculpture", text: "Sculpture" },
                { value: "Show dogs", text: "Show dogs" },
                { value: "Stamps", text: "Stamps" },
                { value: "Toys / dolls", text: "Toys / dolls" },
                { value: "Timepieces", text: "Timepieces" },
                { value: "Vinyl records", text: "Vinyl records" },
                { value: "Wine", text: "Wine" },
                { value: "Other", text: "Other" },
            ],
        },
        {
            component: "input",
            label: "Item name / description",
            name: "name",
            type: "text",
        },
        {
            component: "input",
            label: "Item location",
            name: "location",
            type: "text",
            required: true,
            colObject: true,
        },
        {
            component: "value",
            label: "Estimated value",
            name: "value",
            type: "number",
            required: true,
            colObject: true,
        },
        {
            component: "input",
            label: "Seller, if known",
            name: "seller",
            type: "text",
        },
        {
            component: "input",
            label: "Purchase date, if known",
            name: "date",
            type: "date",
        },
        {
            component: "input",
            label: "Purchase price, if known",
            name: "price",
            type: "text",
        },
        {
            component: "textarea",
            label: "Notes",
            name: "notes",
        },
        {
            component: "input",
            label: "Location of files / documents",
            name: "fileLocation",
            type: "text",
        },
    ],
};

