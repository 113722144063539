import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { restGet } from "../../store/api";
import { getUserDetails } from "../../store/actions";

export default function FileBeSharedList() {
    const data = useSelector((state) => state.auth.user);

    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState(null);

    const failCallback = (err) => {
        console.error(err);
    };

    const getDataToShow = () => {
        restGet(
            "/users/show?moreInfoOfFileBeShare=true",
            ({ user, moreInfo }) => {
                dispatch(getUserDetails(user));
                setUserInfo(moreInfo);
            },
            failCallback
        );
    };

    useEffect(() => {
        getDataToShow();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {data && userInfo && (
                <div>
                    <div className="mt-6 mb-4 flex flex-col">
                        <div className="overflow-x-auto">
                            <div className="inline-block min-w-full py-2 align-middle md:px-0">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                >
                                                    Shared from
                                                </th>

                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Topic
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                                >
                                                    <span className="sr-only"></span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                            {data.fileBeShared &&
                                                data.fileBeShared.map(
                                                    (el, elIdx) => (
                                                        <tr
                                                            key={uuidv4()}
                                                            className={
                                                                elIdx % 2 === 0
                                                                    ? undefined
                                                                    : "bg-gray-50"
                                                            }
                                                        >
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                {el.email}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {
                                                                    userInfo[
                                                                        elIdx
                                                                    ]?.topic
                                                                }
                                                            </td>
                                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                                {userInfo[elIdx]
                                                                    ?.url ? (
                                                                    <div>
                                                                        <a
                                                                            href={
                                                                                userInfo[
                                                                                    elIdx
                                                                                ]
                                                                                    ?.url
                                                                            }
                                                                            className="text-primary-green hover:text-indigo-900 hover:underline cursor-pointer mr-2"
                                                                        >
                                                                            Download
                                                                            <span className="sr-only">
                                                                                ,{" "}
                                                                                {
                                                                                    el.url
                                                                                }
                                                                            </span>
                                                                        </a>
                                                                        |
                                                                        <a
                                                                            href={
                                                                                userInfo[
                                                                                    elIdx
                                                                                ]
                                                                                    ?.url
                                                                            }
                                                                            className="text-primary-green hover:text-indigo-900 hover:underline cursor-pointer ml-2"
                                                                        >
                                                                            View
                                                                            <span className="sr-only">
                                                                                ,{" "}
                                                                                {
                                                                                    el.url
                                                                                }
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                ) : (
                                                                    <span className="text-gray-500">
                                                                        {"   "}
                                                                    </span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

