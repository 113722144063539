import { Component, Fragment } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { ActionPanel } from '../../components/action-panel';

class HorizontalSlide extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, imgUrl: '', fileName: '', imgId: '' };
  }

  handleClick = (imgUrl, fileName, imgId) => {
    const isImage = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'pdf'].some((ext) =>
      imgUrl.toLowerCase().endsWith(ext)
    );

    this.setState((prevState) => ({
      ...prevState,
      open: true,
      imgUrl,
      fileName,
      imgId,
    }));

    if (!isImage) {
      const link = document.createElement('a');
      link.href = imgUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.setOpen(false);
    }
  };

  setOpen = (open) => {
    this.setState((prevState) => ({ ...prevState, open }));
  };

  slideLeft = () => {
    var slider = document.getElementById('slider');
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  slideRight = () => {
    var slider = document.getElementById('slider');
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  render() {
    let { images, deleteFile, data, setDefault } = this.props;

    return (
      <Fragment>
        <ActionPanel open={this.state.open} setOpen={this.setOpen}>
          <div className='w-full flex flex-col items-center justify-center'>
            <div>
              <img src={this.state.imgUrl} alt='' />
            </div>
            <p className='mt-2 text-sm'>{this.state.fileName}</p>
            <div className='mt-1'>
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  deleteFile(this.state.fileName, this.state.imgId);
                  this.setState((prevState) => ({ ...prevState, open: false }));
                }}
                className='pl-2 text-sm text-primary-green hover:text-custom-dark-green'
              >
                Remove
              </a>
            </div>
          </div>
        </ActionPanel>
        <div className='relative flex items-center w-full py-4 border border-gray-300 shadow-sm rounded-md mt-6'>
          <ChevronLeftIcon
            onClick={this.slideLeft}
            className='flex-shrink-0 h-12 w-12 opacity-40 cursor-pointer hover:opacity-60'
            aria-hidden='true'
          />
          <div
            id='slider'
            className='w-full h-full overflow-x-scroll scroll scroll-smooth scrollbar-hide flex flex-nowrap'
          >
            {images.map((image, index) => {
              let src;
              if (['png', 'jpg', 'jpeg', 'gif', 'bmp'].some((ext) =>
                image.url.toLowerCase().endsWith(ext))) {
                src = image.url;
              } else if (image.url.toLowerCase().endsWith('pdf')) {
                src = '/icon/pdf.png';
              } else {
                src = '/icon/other-file.png';
              }
              return (
                <div className='w-[220px] flex-none' key={index}>
                  <img
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleClick(image.url, image.fileName, image._id);
                    }}
                    className='w-[220px] h-[220px] inline-block object-cover p-2 cursor-pointer hover:scale-105 ease-in-out duration-300'
                    src={src}
                    alt=''
                  />
                  <div className='px-2 pt-1 flex justify-center text-gray-500 text-sm items-center w-full'>
                    {data &&
                      data.defaultFile &&
                      data.defaultFile === image.url && (
                        <CheckCircleIcon className='w-5 h-5 flex-none mr-1 text-green-400' />
                      )}
                    <p className='line-clamp-1 flex-shrink'>{image.fileName}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <ChevronRightIcon
            onClick={this.slideRight}
            className='flex-shrink-0 h-12 w-12 opacity-40 cursor-pointer hover:opacity-60'
            aria-hidden='true'
          />
        </div>
      </Fragment>
    );
  }
}

export { HorizontalSlide };
