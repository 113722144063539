export const deathCertificateOriginalsData = {
  formTitle: "Add or edit death certificate original",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/deathCertificateOriginals",
  formFields: [
    {
      component: "input",
      label: "Agency for certificate",
      name: "agency",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "phoneNumber",
      label: "Agency phone",
      name: "phone",
    },
    {
      component: "input",
      label: "The number-of original death certificates that will be needed by the executor",
      name: "numberPaper",
      type: "number"
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
