export const maritalAgreementsData = {
  formTitle: "Add or edit marital agreement",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/maritalAgreements",
  formFields: [
    {
      component: "select",
      label: "Type of agreement",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Divorce agreement", text: "Divorce agreement" },
        { value: "Marriage certificate", text: "Marriage certificate" },
        { value: "Pre-nuptial agreement", text: "Pre-nuptial agreement" },
        { value: "Post-nuptial agreement", text: "Post-nuptial agreement" },
        { value: "Separation agreement", text: "Separation agreement" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Spouse name",
      name: "spouse",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Date",
      name: "date",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
