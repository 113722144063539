import { useState } from "react";
import { useNavigate } from "react-router-dom";

import RegJourneyModal from "./registration-journey/reg-journey-modal";

export default function CheckoutToDashboard() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);

  const navigateToDashboard = () => {
    navigate("/");
  };

  return (
    <RegJourneyModal open={open} setOpen={setOpen}>
      <div className="w-full h-[7vh] lg:h-[10vh] xl:h-[7vh] lg:pb-5 2xl:pb-0 flex justify-center items-center lg:items-end 2xl:items-center text-2xl md:text-3xl text-primary-green text-center font-medium">
        <h2>WELCOME</h2>
      </div>

      <article className="h-max xl:h-[65vh] p-8 flex justify-center items-center bg-alternative-green">
        <div className="max-w-4xl flex flex-col gap-y-8 lg:gap-y-12 items-center text-xl md:text-2xl text-center font-custom2">
          <p className="text-2xl md:text-3xl text-center font-bold">Your Dashboard</p>

          <section className="flex flex-col xl:flex-row items-center gap-8">
            <div className="flex-1 space-y-8">
              <p className="text-xl md:text-2xl text-justify font-medium">
                Your personalized dashboard is on the next page. You will see your 6 preselected Banyan branches. You can select any one of them to enter information or documents.
              </p>

              <p className="text-xl md:text-2xl text-justify font-medium">
                We recommend that you work on your branches in small bites so you don’t feel overwhelmed. You might start by working with 1 or 2 branches at a time.
              </p>

              <p className="text-xl md:text-2xl text-justify font-medium">You can change your branch selections at any time from your dashboard with the “Reset” button.</p>
            </div>

            <div className="flex-1 h-full">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fdashboard%2Fcheckout-to-dashboard%2Fcorrect-branches.jpg?alt=media&token=14aa26a2-46e7-4501-9849-d7d13d56bc0b"
                alt="My Banyan Dashboard's Current Branches"
              />
            </div>
          </section>
        </div>
      </article>

      <section className="h-[15vh] sm:h-[10vh] px-10 flex justify-between items-center bg-alternative-green">
        <div className="hidden sm:block h-20 w-20" />

        <button onClick={navigateToDashboard}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fregistration-journey%2Fright%20arrow.svg?alt=media&token=ef3d9e22-73f8-41a8-b7d9-802f60348068"
            alt="Green right arrow"
            className="h-14 sm:h-20 w-14 sm:w-20"
          />
        </button>
      </section>
    </RegJourneyModal>
  );
}
