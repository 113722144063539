export const financialPlansData = {
  formTitle: "Add or edit my financial plan",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/financialPlans",
  formFields: [
    {
      component: "input",
      label: "Name of plan",
      name: "name",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "mc",
      label: "Scope of plan",
      name: "scope",
      values: [
        "Asset allocation",
        "Cash flow analysis",
        "Cash flow plans",
        "College funds planning",
        "Debt management plans",
        "Emergency funds management",
        "Estate plans",
        "Gifting plans",
        "Insurance plans",
        "Investment plans",
        "Legacy / philanthropic plans",
        "Long term plans",
        "Net worth statements",
        "Portfolio management",
        "Retirement plans",
        "Risk management",
        "Senior living plans",
        "Small business plans",
        "Tax plans",
        "Other",
      ],
    },
    {
      component: "input",
      label: "Name of advisor",
      name: "advisor",
      type: "text",
    },
    {
      component: "input",
      label: "Frequency of update (times)",
      name: "frequency",
      type: "text",
    },
    {
      component: "input",
      label: "Person(s) who have copies of this document",
      name: "persons",
      type: "text",
    },
    {
      component: "input",
      label: "Last revision date",
      name: "firstDate",
      type: "date",
    },
    {
      component: "input",
      label: "Next revision date",
      name: "secondDate",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
