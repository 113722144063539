/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import React from "react";

import { restGet, restDelete } from "../../store/api";
import { updateFileUploadByAll } from "../../store/actions";
import { Toastr } from "../common/toastr";
import { SharePage } from "./share";
import DeleteModal from "../topics-overview/delete-modal";
import GreenBtn from "../buttons/greenBtn";
import RedBtn from "../buttons/redBtn";

const apiPath = "/fileUploads";

const colObject = {
    fileName: "Title of Report",
    topic: "",
    action: "Actions you can take",
};

const Button = ({ handleShare, selectedItems }) => {
    return (
        <div className="text-left mt-4 cursor-pointer">
            {" "}
            <button
                onClick={(e) => {
                    e.preventDefault();
                    handleShare();
                }}
                disabled={selectedItems.size < 1}
                className="inline-flex justify-center rounded-md py-2 px-4 text-sm font-medium shadow-sm hover:bg-custom-dark-green focus:outline-none focus:ring-2 focus:ring-primary-green focus:ring-offset-2 bg-primary-green disabled:bg-white text-white disabled:text-primary-green border-2 border-primary-green disabled:cursor-not-allowed transition duration-300"
            >
                Share selected reports
            </button>
        </div>
    );
};

const Table = ({
    data,
    location,
    selectedItems,
    handleSelectItem,
    handleDeleteItem,
    handleShareSingleFile,
    setOpenDeletePopup,
    setFileId,
}) => {
    const changeSelectedFiles = (event, item) => {
        event.stopPropagation();
        handleSelectItem(item);
    };

    return (
        <div className="mt-6 mb-4 flex flex-col">
            <div className="overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle md:px-0">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                                    >
                                        <div className="text-primary-green hover:text-indigo-900 hover:underline cursor-pointer" />
                                    </th>
                                    <th
                                        scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                        {colObject[Object.keys(colObject)[0]]}
                                    </th>
                                    {Object.keys(colObject)
                                        .filter((el, index) => index > 0)
                                        .map((el) => (
                                            <th
                                                key={el}
                                                scope="col"
                                                className={`px-3 py-3.5 text-sm font-semibold text-gray-900 ${
                                                    el === "action"
                                                        ? "text-center"
                                                        : "text-left"
                                                }`}
                                            >
                                                {colObject[el]}
                                            </th>
                                        ))}
                                    <th
                                        scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    />
                                </tr>
                            </thead>

                            <tbody className="bg-white">
                                {data &&
                                    data.map((file, fileIdx) => (
                                        <tr
                                            key={file._id}
                                            className={
                                                fileIdx % 2 === 0
                                                    ? undefined
                                                    : "bg-gray-50"
                                            }
                                        >
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                <div className="flex h-5 items-center">
                                                    <input
                                                        id=""
                                                        aria-describedby=""
                                                        name=""
                                                        checked={selectedItems.has(
                                                            file._id
                                                        )}
                                                        type="checkbox"
                                                        onChange={(e) =>
                                                            changeSelectedFiles(
                                                                e,
                                                                file._id
                                                            )
                                                        }
                                                        className="h-4 w-4 rounded border-2 border-gray-700 text-indigo-600 focus:ring-0 focus:ring-offset-0 cursor-pointer"
                                                    />
                                                </div>
                                            </td>

                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {file[
                                                    Object.keys(colObject)[0]
                                                ] &&
                                                    (file[
                                                        Object.keys(
                                                            colObject
                                                        )[0]
                                                    ].length > 40
                                                        ? file[
                                                              Object.keys(
                                                                  colObject
                                                              )[0]
                                                          ]
                                                              .match(
                                                                  /[^_]+_[^_]+_[^_]+/
                                                              )[0]
                                                              .split("_")
                                                              .map(
                                                                  (word) =>
                                                                      word
                                                                          .charAt(
                                                                              0
                                                                          )
                                                                          .toUpperCase() +
                                                                      word.slice(
                                                                          1
                                                                      )
                                                              )
                                                              .join(" ")
                                                        : file[
                                                              Object.keys(
                                                                  colObject
                                                              )[0]
                                                          ]
                                                              .split("_")
                                                              .map(
                                                                  (word) =>
                                                                      word
                                                                          .charAt(
                                                                              0
                                                                          )
                                                                          .toUpperCase() +
                                                                      word.slice(
                                                                          1
                                                                      )
                                                              )
                                                              .join(" "))}
                                            </td>
                                            {Object.keys(colObject)
                                                .filter(
                                                    (el, index) =>
                                                        index > 0 && index < 2
                                                )
                                                .map((el) => (
                                                    <React.Fragment key={el}>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        </td>

                                                        <td className="float-right relative flex whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                            <a
                                                                target="_blank"
                                                                href={file.url}
                                                                className="text-primary-green hover:text-indigo-900 hover:underline"
                                                                download
                                                                rel="noreferrer"
                                                            >
                                                                View
                                                            </a>
                                                            <span className="mx-2">
                                                                |
                                                            </span>
                                                            <a
                                                                onClick={() => {
                                                                    setOpenDeletePopup(
                                                                        true
                                                                    );
                                                                    setFileId(
                                                                        file._id
                                                                    );
                                                                }}
                                                                className="text-primary-green hover:text-indigo-900 hover:underline cursor-pointer"
                                                            >
                                                                Delete
                                                            </a>
                                                        </td>
                                                    </React.Fragment>
                                                ))}
                                            <td />
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const ReportList = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.fileUpload.byAll);
    const [selectedItems, setSelectedItems] = useState(new Set());
    const [showSharePage, setShowSharePage] = useState(false);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [fileId, setFileId] = useState("");

    const getDataToShow = () => {
        restGet(
            apiPath + "/report",
            (data) => dispatch(updateFileUploadByAll(data)),
            (error) => console.error(error)
        );
    };

    const handleSelectItem = (id) => {
        const newSelectedItems = new Set(selectedItems);
        if (newSelectedItems.has(id)) {
            newSelectedItems.delete(id);
        } else {
            newSelectedItems.add(id);
        }
        setSelectedItems(newSelectedItems);
    };

    const handleShare = () => {
        setShowSharePage(true);
    };

    const handleShareSingleFile = (e, fId) => {
        const newSelectedItems = new Set();
        newSelectedItems.add(fId);
        setSelectedItems(newSelectedItems);
        setShowSharePage(true);
    };

    const successCallback = (id) => {
        const newData = data.filter((file) => file._id !== id);
        dispatch(updateFileUploadByAll(newData));
        setSelectedItems(
            (prev) => new Set([...prev].filter((itemId) => itemId !== id))
        );

        Toastr({
            type: "success",
            message: "Report successfully deleted.",
        });
    };

    const failCallback = (err) => {
        Toastr({ type: "error", message: "Error deleting report." });
    };

    const handleDeleteItem = (id) => {
        restDelete(
            apiPath + "/delete/" + id,
            {},
            () => successCallback(id),
            failCallback
        );
    };

    useEffect(() => {
        getDataToShow();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getDataToShow();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSharePage]);

    const goBack = () => {
        setShowSharePage(false);
        setSelectedItems(new Set());
    };

    if (showSharePage) {
        return (
            <SharePage
                selectedItems={Array.from(selectedItems)}
                goBack={goBack}
            />
        );
    }

    return (
        <>
            <div>
                <div className="space-y-2 sm:space-y-4">
                    <p className="text-gray-500 text-sm">
                        When you generate a report encompassing one or more
                        records or topics, the report will be saved at this
                        location. You have the option to share one or multiple
                        reports with members of your Inner Circle. Additionally,
                        you can download or delete any report from the list of
                        reports in My Banyan Reports
                    </p>
                </div>
                <Button
                    handleShare={handleShare}
                    selectedItems={selectedItems}
                />
                <Table
                    data={data}
                    location={location}
                    selectedItems={selectedItems}
                    handleSelectItem={handleSelectItem}
                    handleDeleteItem={handleDeleteItem}
                    handleShareSingleFile={handleShareSingleFile}
                    setOpenDeletePopup={setOpenDeletePopup}
                    setFileId={setFileId}
                />
            </div>

            {/* POPUP TO DELETE REPORT */}
            <DeleteModal open={openDeletePopup} setOpen={setOpenDeletePopup}>
                <div className="text-lg space-y-6">
                    <h2>Do you really want to delete this report?</h2>

                    <div className="flex justify-evenly">
                        <GreenBtn
                            type="button"
                            onClick={() => {
                                handleDeleteItem(fileId);
                                setOpenDeletePopup(false);
                            }}
                        >
                            Yes
                        </GreenBtn>

                        <RedBtn
                            type="button"
                            onClick={() => setOpenDeletePopup(false)}
                        >
                            No
                        </RedBtn>
                    </div>
                </div>
            </DeleteModal>
        </>
    );
};

