export const giftingToOrganizationsData = {
    formTitle: "Add or edit gifting to organization",
    formSubtitle:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    pathname: "/giftingToOrganizations",
    formFields: [
        {
            component: "select",
            label: "Gifting structure",
            name: "giftingStructure",
            type: "text",
            required: true,
            colObject: true,
            options: [
                { value: "Charitable Trust", text: "Charitable Trust" },
                { value: "Crummey Trust", text: "Crummey Trust" },
                { value: "Gift In Trust", text: "Gift In Trust" },
                { value: "Outright direct gift", text: "Outright direct gift" },
                { value: "Other", text: "Other" },
            ],
        },
        {
            component: "input",
            label: "Organization name",
            name: "organization",
            type: "text",
            required: true,
            colObject: true,
        },
        {
            component: "phoneNumber",
            label: "Organization phone number",
            name: "phone",
        },
        {
            component: "input",
            label: "Person(s) who have copies of this document",
            name: "personsCopy",
            type: "text",
            required: true,
            colObject: true,
        },
        {
            component: "mc",
            label: "Assets",
            name: "asset",
            values: ["Gifting to individuals", "Gifting to organizations"],
        },
        {
            component: "select",
            label: "Frequency",
            name: "frequency",
            type: "text",
            options: [
                { value: "Variable", text: "Variable" },
                { value: "Annnual", text: "Annnual" },
                { value: "One-time", text: "One-time" },
                { value: "Other", text: "Other" },
            ],
        },
        {
            component: "input",
            label: "Gift value estimate",
            name: "giftValueEstimate",
            type: "text",
        },
        {
            component: "input",
            label: "Attorney",
            name: "attorney",
            type: "text",
        },
        {
            component: "input",
            label: "Financial advisor",
            name: "financialAdvisor",
            type: "text",
        },
        {
            component: "input",
            label: "Date of last gifting",
            name: "firstDate",
            type: "date",
        },
        {
            component: "input",
            label: "Date of next gifting",
            name: "secondDate",
            type: "date",
        },
        {
            component: "textarea",
            label: "Notes",
            name: "notes",
        },
        {
            component: "input",
            label: "Location of files / documents",
            name: "fileLocation",
            type: "text",
        },
        {
            component: "input",
            label: "Person(s) who know about this plan",
            name: "persons",
            type: "text",
        },
    ],
};

