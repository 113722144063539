export const ceremonyLeadersData = {
  formTitle: "Add or edit my ceremony leader",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/ceremonyLeaders",
  formFields: [
    {
      component: "input",
      label: "Officiant",
      name: "officiant",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Eulogists",
      name: "eulogists",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Readers",
      name: "readers",
      type: "text",
    },
  ],
};
