import { Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";

import { mainBranches } from "../private-layout/data";

const aboutMeBranch = mainBranches[0].subBranches;
const myEverydayBranch = mainBranches[1].subBranches;
const myEventualBranch = mainBranches[2].subBranches;

// ! If you get a 0 doesn't exist error, change the keys to match in client/src/components/dashboard/select-favorites.js & client/src/pages/dashboard/selectBranches.js
export const hierarchyIntros = {
    // ABOUT ME
    "MY ESSENTIALS BRANCH": ["Basic personal information (such birth date) about you and your official identification documents (such as passports and licenses).", aboutMeBranch[0].topics],
    "MY FAMILY BRANCH": ["All the important information about your nuclear and extended family members", aboutMeBranch[1].topics],
    "MY LIFE EXPERIENCES BRANCH": ["Valuable facts and memories about your youth and adult life.", aboutMeBranch[2].topics],

    // EVERYDAY
    "ACCOUNTS BRANCH": [
        "All the companies that you transact with on a regular basis. They include your banks, credit cards, merchants, and investment accounts. The only exceptions are your internet, email, and software accounts which are in the Digital World Branch.",
        myEverydayBranch[1].topics,
    ],
    "BUSINESS BRANCH": ["Key information about your business(es) that you own wholly or in part.", myEverydayBranch[2].topics],
    "DIGITAL WORLD BRANCH": ["All the ways that you manage and protect your life using smart devices, computers, the internet, emails, and data storage.", myEverydayBranch[3].topics],
    "FINANCES BRANCH": ["Your financial plans, tax returns, and a listing of your financial assets and liabilities.", myEverydayBranch[4].topics],
    "HEALTH BRANCH": [
        "Your preparations for medical emergencies, your current medical conditions, and significant historical facts about your health. My Banyan is HIPAA certified to guarantee you total security of information.",
        myEverydayBranch[5].topics,
    ],
    "INSURANCE BRANCH": ["Personal, property, health, disability, and long-term care coverages.", myEverydayBranch[6].topics],
    "LEGAL ISSUES BRANCH": ["Outstanding lawsuits and bankruptcies.", myEverydayBranch[7].topics],
    "REAL ESTATE BRANCH": ["All real properties that you own either directly or through an entity, such as a trust, partnership, or company.", myEverydayBranch[8].topics],

    // EVENTUAL
    "ESTATE PLANS BRANCH": [
        "The core documents that you need to protect and manage your preferred distribution of your assets. Includes wills, trusts, powers of attorney, gifting, and more.",
        myEventualBranch[0].topics,
    ],
    "SENIOR LIFE PLANS BRANCH": [
        "Financing your senior life, your residential options, and possible care providers, should you need them. This branch provides helpful  relocation checklists should you need to move from your current residence.",
        myEventualBranch[1].topics,
    ],
    "LEGACY WISHES BRANCH": ["Legacy statements for your family members, your supported charities, and recorded memories in the form of photos and videos to pass on.", myEventualBranch[2].topics],
    "FAREWELL PLANS BRANCH": ["Plans for the disposition of your body, financing any ceremony, and specific end-of-lifedetails for a ceremony that are important to you.", myEventualBranch[3].topics],
};

function HierarchyIntrosModal({ open, setOpen, children }) {
    return (
        <Transition appear show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center lg:ml-64 xl:ml-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="h-[400px] w-full max-w-md transform overflow-y-auto bg-white p-2 text-left align-middle shadow-xl transition-all">{children}</Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

export default HierarchyIntrosModal;

