import { useLocation } from "react-router-dom";

import PageTitle from "../../components/common/page-title";
import ContactView from "../../components/contact/view";
import { ContactList } from "../../components/contact/list";
import { ContactForm } from "../../components/contact/form";
import { Share } from "../../lib/share";

export default function Contact({ page }) {
    const location = useLocation();
    const pathnames = location.pathname.split("/");

    const pathname = "/" + pathnames[2];
    return (
        <>
            <PageTitle title="My Banyan" />

            {page === "list" && <ContactList />}
            {page === "new" && <ContactForm />}
            {page === "share" && <Share pathname={pathname} />}
            {page === "edit" && <ContactForm />}
            {page === "view" && <ContactView />}
        </>
    );
}

