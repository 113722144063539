export const valuesThatIHopeToPassOnData = {
  formTitle: 'Add or edit legacy statement',
  formSubtitle:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  pathname: '/valuesThatIHopeToPassOn',
  formFields: [
    {
      component: 'input',
      label: 'My statement of values',
      name: 'statementValues',
      type: 'text',
      colObject: true,
    },
    {
      component: 'textarea',
      label: 'Notes',
      name: 'notes',
    },
    {
      component: 'input',
      label: 'Location of files / documents',
      name: 'fileLocation',
      type: 'text',
    },
  ],
};
