import React, { useState } from "react";

import { Auth } from "aws-amplify";

import PageTitle from "../../components/common/page-title";
import VerifyResetPassword from "../../components/auth/verify-reset-password";
import { Text, Button } from "../../components/auth/inputs";
import { AuthLayout } from "../../components/auth/auth-layout";
import { SubmissionAlert } from "../../components/common/submission-alert";

const subtitle = {
    text: "back to sign in",
    href: "/signin",
};

export default function ResetPassword() {
    const [formValues, setFormValues] = useState({
        resetUsername: "",
    });
    const [req, setReq] = useState({
        confirmed: false,
        error: null,
        isPending: false,
    });

    async function forgotPassword(formValues) {
        try {
            await Auth.forgotPassword(formValues.resetUsername);
            setReq((prevState) => ({
                ...prevState,
                isPending: false,
                confirmed: true,
            }));
        } catch (error) {
            setReq((prevState) => ({
                ...prevState,
                error: error.message,
                isPending: false,
            }));
        }
    }

    const onChangeHandler = (e) => {
        e.preventDefault();

        setFormValues((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const onSubmitHandler = () => {
        forgotPassword(formValues);
    };

    return (
        <>
            <PageTitle title="My Banyan" />

            <AuthLayout
                title={"Reset password"}
                subtitle={subtitle}
                bgImg={"forgotpassword"}
            >
                {req.confirmed ? (
                    <VerifyResetPassword username={formValues.resetUsername} />
                ) : (
                    <>
                        {req.error && (
                            <SubmissionAlert
                                type={"error"}
                                message={req.error}
                            />
                        )}
                        <form
                            className="space-y-6"
                            onSubmit={(e) => {
                                e.preventDefault();
                                setReq((prevState) => ({
                                    ...prevState,
                                    isPending: true,
                                }));
                                onSubmitHandler();
                            }}
                        >
                            <div className="text-sm text-white font-light">
                                <p className="text-center">
                                    Enter your email and we will send you a code
                                    to verify your email before resetting your
                                    password.
                                </p>
                            </div>
                            <div className="space-y-1">
                                <Text
                                    name="resetUsername"
                                    type="email"
                                    value={formValues.resetUsername}
                                    onChange={onChangeHandler}
                                    required
                                    autoComplete="new-password"
                                    label="Email *"
                                />
                            </div>
                            <div>
                                <Button type="submit" isPending={req.isPending}>
                                    SUBMIT
                                </Button>
                            </div>
                        </form>
                    </>
                )}
            </AuthLayout>
        </>
    );
}

