import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

// page description
import { pageDescriptions } from "../components/page-layout/data";
import { checklistPageData } from "../components/checklist-page-layout/data";

// components
import { mainBranches } from "../components/private-layout/data";
import { TopicPage } from "../lib";
import { PrivateLayout } from "../components/private-layout";
import { PageLayout } from "../components/page-layout";
import { ChecklistPageLayout } from "../components/checklist-page-layout";

// summary
import SummaryReports from "../components/summary-reports";
import { AssetsSummary } from "../components/summary-reports/assets";
import { HealthSummary } from "../components/summary-reports/health";
import { EstatePlansSummary } from "../components/summary-reports/estate";

// pages
import Dashboard from "../pages/dashboard/index";
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import NotFound from "../pages/NotFound";
import Sharing from "../pages/sharing";
import Cabinet from "../pages/cabinet";
import Branch from "../pages/branch";
import Todo from "../components/todo";
import InnerCircle from "../pages/inner-circle";
import MyProfile from "../pages/my-profile";
import Contact from "../pages/contact";
import FeedbackAndSupport from "../pages/feedback-and-support";
import TopicsOverview from "../components/topics-overview";
import FAQ from "../components/tutorials-&-faq/faq";
import Tutorials from "../components/tutorials-&-faq/tutorials";
import Confirmation from "../pages/feedback-and-support/confirmation";
import ScrollToTop from "../components/scroll-to-top";
import Report from "../pages/report";
import ReadOnlyWarningDialog from "../components/popup/index";
import PrivacyPolicyAndTermsOfService from "../pages/PrivacyPolicyAndTermsOfService";
import { LiabilitySummary } from "../components/summary-reports/liability";
import { NetWorthSummary } from "../components/summary-reports/net-worth";

const Router = () => {
  const auth = useSelector((state) => state.auth);
  const open = false;

  return (
    <BrowserRouter>
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route
          path="/"
          element={auth.currentUser ? <PrivateLayout /> : <SignIn />}
        >
          <Route index element={<Dashboard />} />
          <Route path="my-profile" element={<PageLayout name={"My Profile"} />}>
            <Route index element={<MyProfile />} />
          </Route>
          <Route
            path="sharing"
            element={
              <PageLayout
                name={"My Sharing History"}
                description={pageDescriptions.sharing}
              />
            }
          >
            <Route index element={<Sharing />} />
          </Route>

          <Route
            path="reports"
            element={
              <PageLayout
                name={"MY REPORTS"}
                description={pageDescriptions.cabinet}
              />
            }
          >
            <Route index element={<Report />} />
          </Route>

          <Route
            path="uploaded-files"
            element={
              <PageLayout
                name="My Uploaded Files"
                description={pageDescriptions.cabinet}
              />
            }
          >
            <Route index element={<Cabinet />} />
          </Route>

          <Route
            path="summary-reports"
            element={<PageLayout name="Summary Reports" />}
          >
            <Route index element={<SummaryReports />} />

            <Route
              path="assets-summary"
              element={<AssetsSummary name="Assets Summary" title="ASSET" />}
            />

            <Route
              path="liabilities-summary"
              element={
                <LiabilitySummary
                  name="Liabilities Summary"
                  title="LIABILITY"
                />
              }
            >
            </Route>

            <Route
              path="net-worth-summary"
              element={<NetWorthSummary name="Net Worth Summary" title="NET WORTH" />}
            />

            <Route
              path="estate-plan-summary"
              element={
                <EstatePlansSummary
                  name="Estate Plans Summary"
                  title="ESTATE PLANS"
                />
              }
            />

            <Route
              path="health-summary"
              element={<HealthSummary name="Health Summary" title="HEALTH" />}
            />
          </Route>

          {mainBranches.map((mainBranch) => (
            <Route key={uuidv4()} path={mainBranch.path}>
              <Route index element={<Dashboard />} />
              {mainBranch.subBranches.map((subBranch) => (
                <Route key={uuidv4()} path={subBranch.path}>
                  <Route
                    index
                    element={
                      <Branch
                        data={subBranch}
                        parentPath={"/" + mainBranch.path + "/"}
                      />
                    }
                  />
                  {subBranch.topics
                    .filter((el) => "data" in el)
                    .map((topic) => (
                      <Route
                        key={topic.path}
                        path={topic.path}
                        element={
                          <PageLayout
                            name={topic.name}
                            description={
                              topic.description ? topic.description : null
                            }
                            type="topics"
                          />
                        }
                      >
                        <Route
                          index
                          element={
                            <TopicPage
                              page={"list"}
                              data={topic.data}
                              name={topic.name}
                            />
                          }
                        />
                        <Route
                          path={"new"}
                          element={<TopicPage page={"new"} data={topic.data} />}
                        />
                        <Route
                          path={"view"}
                          element={
                            <TopicPage page={"view"} data={topic.data} />
                          }
                        />
                        <Route
                          path={"edit"}
                          element={
                            <TopicPage page={"edit"} data={topic.data} />
                          }
                        />
                        <Route
                          path={"share"}
                          element={
                            <TopicPage page={"share"} data={topic.data} />
                          }
                        />
                      </Route>
                    ))}
                </Route>
              ))}
            </Route>
          ))}

          <Route
            path="unexpected"
            element={
              <ChecklistPageLayout
                name="My Unexpected"
                data={checklistPageData.myUnexpected}
              />
            }
          />
          <Route
            path="everyday/identity-theft"
            element={
              <ChecklistPageLayout
                name="identity theft"
                data={checklistPageData.identityTheft}
              />
            }
          />
          <Route
            path="everyday/planning-for-my-medical-emergency"
            element={
              <ChecklistPageLayout
                name="Planning for my medical emergency"
                data={checklistPageData.medicalEmergency}
              />
            }
          />
          <Route
            path="eventual/estate-planning-101"
            element={
              <ChecklistPageLayout
                name="estate planning 101"
                data={checklistPageData.estatePlanning101}
              />
            }
          />
          <Route
            path="eventual/relocation-checklists"
            element={
              <ChecklistPageLayout
                name="Relocation checklists"
                data={checklistPageData.relocation}
              />
            }
          />
          <Route
            path="eventual/retirement-checklist"
            element={
              <ChecklistPageLayout
                name="Retirement checklist"
                data={checklistPageData.retirement}
              />
            }
          />

          <Route
            path="inner-circle"
            element={
              <PageLayout
                name="My Inner Circle"
                description={pageDescriptions.innerCircle}
                type="innerCircle"
              />
            }
          >
            <Route index element={<InnerCircle page={"list"} />} />
            <Route path={"new"} element={<InnerCircle page={"new"} />} />
            <Route path={"share"} element={<InnerCircle page={"share"} />} />
            <Route path={"edit"} element={<InnerCircle page={"edit"} />} />
            <Route path={"view"} element={<InnerCircle page={"view"} />} />
          </Route>
          <Route
            path="contacts"
            element={
              <PageLayout
                name="My Contacts"
                description={pageDescriptions.contacts}
              />
            }
          >
            <Route index element={<Contact page={"list"} />} />
            <Route path={"new"} element={<Contact page={"new"} />} />
            <Route path={"edit"} element={<Contact page={"edit"} />} />
            <Route path={"share"} element={<Contact page={"share"} />} />
            <Route path={"view"} element={<Contact page={"view"} />} />
          </Route>
          <Route path="to-do-list" element={<PageLayout name={"To Do List"} />}>
            <Route index element={<Todo />} />
          </Route>
          <Route
            path="the-tracker"
            element={<PageLayout name={"The tracker"} />}
          >
            <Route index element={<TopicsOverview />} />
          </Route>
          <Route path="tutorials" element={<PageLayout name={"Tutorials"} />}>
            <Route index element={<Tutorials />} />
          </Route>

          <Route path="faq" element={<PageLayout name={"FAQ"} />}>
            <Route index element={<FAQ />} />
          </Route>

          <Route
            path="feedback-to-support"
            element={
              <PageLayout
                name={"Feedback To Support"}
                description={pageDescriptions.feedbackAndSupport}
                type="feedbackAndSupport"
              />
            }
          >
            <Route index element={<FeedbackAndSupport />} />
          </Route>

          <Route
            path="feedback-to-support/confirmation"
            element={
              <PageLayout
                name={"Thank You"}
                description={pageDescriptions.feedbackAndSupportConfirmation}
                type="feedbackAndSupport"
              />
            }
          >
            <Route index element={<Confirmation />} />
          </Route>

          <Route
            path="legal-docs"
            element={
              <ChecklistPageLayout
                name="My Legal Documents"
                data={checklistPageData.legalDocs}
              />
            }
          />

          <Route
            path="legal-docs/privacy"
            element={
              <PageLayout
                name="My Banyan, Inc. - Privacy Policy"
                description={pageDescriptions.privacyPolicy}
              />
            }
          />

          <Route
            path="legal-docs/terms-of-service"
            element={
              <PageLayout
                name="Terms of Service"
                description={pageDescriptions.termsOfService}
                type="termsOfService"
              />
            }
          />

          <Route
            path="legal-docs/end-user-agreement"
            element={
              <PageLayout
                name="My Banyan - End User License Agreement"
                description={pageDescriptions.endUserAgreement}
              />
            }
          />
        </Route>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        <Route
          path="/privacy-policy-and-terms-of-service"
          element={<PrivacyPolicyAndTermsOfService />}
        />

        <Route path="*" element={<NotFound />} />
      </Routes>

      <ReadOnlyWarningDialog isOpen={open} />
    </BrowserRouter>
  );
};

export default Router;
