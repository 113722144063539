export const dispositionOfMyBodiesData = {
  formTitle: "Add or edit disposition of my body",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/dispositionOfMyBodies",
  formFields: [
    {
      component: 'mc',
      label: 'Disposition of my body',
      name: 'disposition',
      required: true,
      colObject: true,
      values: [
        'Burial',
        'Cremation',
        'Donation to science',
        'Internment in a mausoleum',
        'Scatter my ashes',
        'Other',
      ],
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
  ],
};
