export const personalLetterOfIntentsData = {
  formTitle: "Add or edit personal letter of intent",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/personalLetterOfIntents",
  formFields: [
    {
      component: "mc",
      label: "Contents of letter",
      name: "contents",
      values: [
        "Care of pets",
        "Hopes for family legacy",
        "Items that may be of sentimental value",
        "Information about liquid assets",
        "List of all tangible assets",
        "List of all digital assets",
        "Messages to loved ones",
        "Plans for my funeral and disposition of my body",
        "Rationale for distribution of all property",
        "Wishes regarding family values",
        "Other",
      ],
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Person(s) who have copies of this document",
      name: "persons",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Attorney",
      name: "attorney",
      type: "text",
    },
    {
      component: "input",
      label: "Date signed",
      name: "date",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
