import { passportsData } from "../../pages/topics/passports/data";
import { phoneNumbersData } from "../../pages/topics/phoneNumbers/data";
import { rewardsMembershipAccountsData } from "../../pages/topics/rewardsMembershipAccounts/data";
import { socialSecurityCardsData } from "../../pages/topics/socialSecurityCards/data";
import { familyData } from "../../pages/topics/family/data";
import { familyTreeDocumentsData } from "../../pages/topics/familyTreeDocuments/data";
import { oralHistoriesData } from "../../pages/topics/oralHistories/data";
import { importantStoriesTraditionsData } from "../../pages/topics/importantStoriesTraditions/data";
import { genealogyWebsitesData } from "../../pages/topics/genealogyWebsites/data";
import { genealogySoftwareData } from "../../pages/topics/genealogySoftware/data";
import { businessInsuranceData } from "../../pages/topics/businessInsurance/data";
import { businessAssetsData } from "../../pages/topics/businessAssets/data";
import { businessHoldingCompaniesData } from "../../pages/topics/businessHoldingCompanies/data";
import { businessFormationAgreementData } from "../../pages/topics/businessFormationAgreement/data";
import { businessFinancialStatementsData } from "../../pages/topics/businessFinancialStatements/data";
import { stockLedgersData } from "../../pages/topics/stockLedgers/data";
import { stockCertificatesData } from "../../pages/topics/stockCertificates/data";
import { intellectualPropertyData } from "../../pages/topics/intellectualProperty/data";
import { recordOfCompanyMinutesData } from "../../pages/topics/recordOfCompanyMinutes/data";
import { employeeHandbookData } from "../../pages/topics/employeeHandbook/data";
import { taxReturnStatementsData } from "../../pages/topics/taxReturnStatements/data";
import { permitsData } from "../../pages/topics/permits/data";
import { loanAgreementsData } from "../../pages/topics/loanAgreements/data";
import { devicesData } from "../../pages/topics/devices/data";
import { backUpOfDigitalFilesData } from "../../pages/topics/backUpOfDigitalFiles/data";
import { organizationOfDigitalFilesData } from "../../pages/topics/organizationOfDigitalFiles/data";
import { internetAccessData } from "../../pages/topics/internetAccess/data";
import { internetServicesData } from "../../pages/topics/internetServices/data";
import { dataSecurityMethodsData } from "../../pages/topics/dataSecurityMethods/data";
import { financialPlansData } from "../../pages/topics/financialPlans/data";
import { incomeSourcesData } from "../../pages/topics/incomeSources/data";
import { cashOnHandData } from "../../pages/topics/cashOnHand/data";
import { safeDepositBoxesData } from "../../pages/topics/safeDepositBoxes/data";
import { homeSafesData } from "../../pages/topics/homeSafes/data";
import { collectibleValuablesData } from "../../pages/topics/collectibleValuables/data";
import { loansReceivableData } from "../../pages/topics/loansReceivable/data";
import { loansData } from "../../pages/topics/loans/data";
import { leasesData } from "../../pages/topics/leases/data";
import { accountsPayableData } from "../../pages/topics/accountsPayable/data";
import { creditCardDebtData } from "../../pages/topics/creditCardDebt/data";
import { outstandingTaxesDueData } from "../../pages/topics/outstandingTaxesDue/data";
import { recentHealthExamsData } from "../../pages/topics/recentHealthExams/data";
import { ailmentsData } from "../../pages/topics/ailments/data";
import { allergiesData } from "../../pages/topics/allergies/data";
import { medicationsData } from "../../pages/topics/medications/data";
import { currentSupplementsData } from "../../pages/topics/currentSupplements/data";
import { vaccinationsData } from "../../pages/topics/vaccinations/data";
import { homeHealthEquipmentData } from "../../pages/topics/homeHealthEquipment/data";
import { bloodTypeData } from "../../pages/topics/bloodType/data";
import { dnaInfoData } from "../../pages/topics/dnaInfo/data";
import { advancedDirectivesData } from "../../pages/topics/advancedDirectives/data";
import { medicalHistoryData } from "../../pages/topics/medicalHistory/data";
import { familyMedicalHistoryData } from "../../pages/topics/familyMedicalHistory/data";
import { namesData } from "../../pages/topics/names/data";
import { addressesData } from "../../pages/topics/addresses/data";
import { emailAddressesData } from "../../pages/topics/emailAddresses/data";
import { maritalStatusData } from "../../pages/topics/maritalStatus/data";
import { maritalAgreementsData } from "../../pages/topics/maritalAgreements/data";
import { birthInfoData } from "../../pages/topics/birthInfo/data";
import { militaryStatusData } from "../../pages/topics/militaryStatus/data";
import { citizenshipsData } from "../../pages/topics/citizenships/data";
import { naturalizationCertificatesData } from "../../pages/topics/naturalizationCertificates/data";
import { birthCertificatesData } from "../../pages/topics/birthCertificates/data";
import { medicareCardsData } from "../../pages/topics/medicareCards/data";
import { airTravelClearancesData } from "../../pages/topics/airTravelClearances/data";
import { operatorLicensesData } from "../../pages/topics/operatorLicenses/data";
import { emailAccountsData } from "../../pages/topics/emailAccounts/data";
import { merchantAccountsData } from "../../pages/topics/merchantAccounts/data";
import { softwareData } from "../../pages/topics/software/data";
import { bankAccountsData } from "../../pages/topics/bankAccounts/data";
import { bankCardsData } from "../../pages/topics/bankCards/data";
import { myContactsData } from "../../pages/topics/my-contacts/data";
import { personalInsuranceData } from "../../pages/topics/personalInsurance/data";
import { publicHealthInsuranceData } from "../../pages/topics/publicHealthInsurance/data";
import { privateHealthInsuranceData } from "../../pages/topics/privateHealthInsurance/data";
import { socialSecurityDisabilityData } from "../../pages/topics/socialSecurityDisability/data";
import { propertyInsuranceData } from "../../pages/topics/propertyInsurance/data";
import { investmentsData } from "../../pages/topics/investments/data";
import { investmentAccountData } from "../../pages/topics/investmentAccount/data";
import { realEstateHoldingsData } from "../../pages/topics/realEstateHoldings/data";
import { outstandingLawsuitsData } from "../../pages/topics/outstandingLawsuits/data";
import { deedsOnMyPropertiesData } from "../../pages/topics/deedsOnMyProperties/data";
import { leaseAgreementsData } from "../../pages/topics/leaseAgreements/data";
import { utilitiesData } from "../../pages/topics/utilities/data";
import { propertySecurityServiceData } from "../../pages/topics/propertySecurityService/data";
import { extraPropertyKeysData } from "../../pages/topics/extraPropertyKeys/data";
import { lastWillTestamentsData } from "../../pages/topics/lastWillTestaments/data";
import { personalLetterOfIntentsData } from "../../pages/topics/personalLetterOfIntents/data";
import { giftingToIndividualsData } from "../../pages/topics/giftingToIndividuals/data";
import { giftingToOrganizationsData } from "../../pages/topics/giftingToOrganizations/data";
import { podAccountsData } from "../../pages/topics/podAccounts/data";
import { todAccountsData } from "../../pages/topics/todAccounts/data";
import { currentResidenceData } from "../../pages/topics/currentResidence/data";
import { homeOfAnotherPersonData } from "../../pages/topics/homeOfAnotherPerson/data";
import { independentLivingData } from "../../pages/topics/independentLiving/data";
import { assistedLivingNursingData } from "../../pages/topics/assistedLivingNursing/data";
import { professionalCareProvidersData } from "../../pages/topics/professionalCareProviders/data";
import { communityAgenciesData } from "../../pages/topics/communityAgencies/data";
import { selfFundingHealthCareData } from "../../pages/topics/selfFundingHealthCare/data";
import { additionalSecurityIncomeData } from "../../pages/topics/additionalSecurityIncome/data";
import { otherFinanceSourcesData } from "../../pages/topics/otherFinanceSources/data";
import { legacyStatementsData } from "../../pages/topics/legacyStatements/data";
import { supportedGroupData } from "../../pages/topics/supportedGroup/data";
import { deathCertificateOriginalsData } from "../../pages/topics/deathCertificateOriginals/data";
import { burialPlotLocationsData } from "../../pages/topics/burialPlotLocations/data";
import { cremationAuthorizationsData } from "../../pages/topics/cremationAuthorizations/data";
import { insuranceBenefitsData } from "../../pages/topics/insuranceBenefits/data";
import { prePaymentsData } from "../../pages/topics/prePayments/data";
import { estimatedExpensesData } from "../../pages/topics/estimatedExpenses/data";
import { dispositionOfMyBodiesData } from "../../pages/topics/dispositionOfMyBodies/data";
import { donationsData } from "../../pages/topics/donations/data";
import { ceremonyLeadersData } from "../../pages/topics/ceremonyLeaders/data";
import { ceremonyLocationsData } from "../../pages/topics/ceremonyLocations/data";
import { floristsData } from "../../pages/topics/florists/data";
import { funeralHomeDirectorsData } from "../../pages/topics/funeralHomeDirectors/data";
import { graveMarkerInscriptionsData } from "../../pages/topics/graveMarkerInscriptions/data";
import { musicData } from "../../pages/topics/music/data";
import { transportationServicesData } from "../../pages/topics/transportationServices/data";
import { serviceInvitationListsData } from "../../pages/topics/serviceInvitationLists/data";
import { obituaryContentsData } from "../../pages/topics/obituaryContents/data";
import { obituaryFuneralNoticesData } from "../../pages/topics/obituaryFuneralNotices/data";
import { bankruptcyFilingsData } from "../../pages/topics/bankruptcyFilings/data";
import { influentialPeopleInMyLifeData } from "../../pages/topics/influentialPeopleInMyLife/data";
import { accomplishmentsData } from "../../pages/topics/accomplishments/data";
import { communityServiceData } from "../../pages/topics/communityService/data";
import { durablePoaData } from "../../pages/topics/durablePoa/data";
import { generalPoaData } from "../../pages/topics/generalPoa/data";
import { howISeeMySeniorLifeData } from "../../pages/topics/howISeeMySeniorLife/data";
import { irrevocableTrustsData } from "../../pages/topics/irrevocableTrusts/data";
import { recordedMemoriesData } from "../../pages/topics/recordedMemories/data";
import { revocableLivingTrustData } from "../../pages/topics/revocableLivingTrust/data";
import { buySellAgreementsData } from "../../pages/topics/buySellAgreements/data";
import { promissoryNotesData } from "../../pages/topics/promissoryNotes/data";
import { careProvidersFriendsFamilyData } from "../../pages/topics/careProvidersFriendsFamily/data";
import { liabilitiesLeasesData } from "../../pages/topics/liabilitiesLeases/data";
import { passwordManagementSoftwareData } from "../../pages/topics/passwordManagementSoftware/data";
import { employmentStatusData } from "../../pages/topics/employmentStatus/data";
import { iAmMostGratefulForData } from "../../pages/topics/iAmMostGratefulFor/data";
import { whatIHaveLearnedData } from "../../pages/topics/whatIHaveLearned/data";
import { reflectionsOnMyYouthData } from "../../pages/topics/reflectionsOnMyYouth/data";
import { myFavoritesInMyYouthData } from "../../pages/topics/myFavoritesInMyYouth/data";
import { reflectionsOnMyAdultLifeData } from "../../pages/topics/reflectionsOnMyAdultLife/data";
import { favoritesInMyAdultLifeData } from "../../pages/topics/favoritesInMyAdultLife/data";
import { reflectionsOnMyCareerData } from "../../pages/topics/reflectionsOnMyCareer/data";
import { taxReturnsData } from "../../pages/topics/taxReturns/data";
import { bankAccountsBalancesData } from "../../pages/topics/bankAccountsBalances/data";
import { personalVehiclesValueData } from "../../pages/topics/personalVehiclesValue/data";
import { longTermCareInsuranceData } from "../../pages/topics/longTermCareInsurance/data";
import { realEstateValueData } from "../../pages/topics/realEstateValue/data";
import { storageRentalUnitData } from "../../pages/topics/storageRentalUnit/data";
import { jointOwnershipsData } from "../../pages/topics/jointOwnerships/data";
import { todDeedsData } from "../../pages/topics/todDeeds/data";
import { valuesThatIHopeToPassOnData } from "../../pages/topics/valuesThatIHopeToPassOn/data";
import { ceremonialPreferencesData } from "../../pages/topics/ceremonialPreferences/data";
import { petsData } from "../../pages/topics/pets/data";

export const mainBranches = [
    {
        name: "ABOUT ME",
        path: "about-me",
        subBranches: [
            {
                name: "my essentials branch",
                path: "my-essentials",
                icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/about-me/my-essentials/01-personal-info-icon-white.svg",
                gIcon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/about-me/my-essentials/01-personal-info-icon-green.svg",
                favoriteIcon:
                    "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/about-me/my-essentials/download.svg",
                image: {
                    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/about-me/my-essentials/A-01-Personal-Info2.png",
                    imgAlt: "my essentials pic",
                },
                description:
                    "The My Essentials branch allows you to digitally capture both information and upload files (photos, documents, spreadsheets, etc.) of your everyday and lifetime information. Capture your birth and marriage certificates to your driver’s license, passports, email addresses, and more, all digitally secure.  And you can safely share the information of your choice with your inner circle members.",
                topics: [
                    {
                        name: "PERSONAL: Names",
                        path: "names",
                        data: namesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-essentials%2FPersonal-names.jpg?alt=media&token=bcecc393-64dd-4271-83ff-11c0406d7f0d",
                        imgAlt: "Names image",
                        summary:
                            "Here you can list all your names, including your professional names, nicknames, AKAs, and more.",
                        description:
                            "List all your names, including your professional names, nicknames, AKAs, and more.",
                    },
                    {
                        name: "PERSONAL: Phone Numbers",
                        path: "phoneNumbers",
                        data: phoneNumbersData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-essentials%2FPersonal-phone-numbers.jpg?alt=media&token=ee1616f3-34bf-45d0-8a79-7d7d699d29eb",
                        imgAlt: "Phone Numbers image",
                        summary:
                            "A great place to list all your phone numbers, including fax numbers, for personal and business.",
                        description:
                            "List all your phone numbers, including fax numbers, both personal and business.",
                    },
                    {
                        name: "PERSONAL: Addresses",
                        path: "addresses",
                        data: addressesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-essentials%2FPersonal-addresses.jpg?alt=media&token=a258bd31-477e-4249-8894-c501d1d85eb9",
                        imgAlt: "Addresses image",
                        summary:
                            "Use this to list all the property addresses where you have a residence.",
                        description:
                            "List all the property addresses where you have a residence.",
                    },
                    {
                        name: "PERSONAL: Email Addresses",
                        path: "emailAddresses",
                        data: emailAddressesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-essentials%2FPersonal-email.jpg?alt=media&token=97da4ec3-d81c-4f4b-ac61-5ade0192088f",
                        imgAlt: "Email Addresses image",
                        summary:
                            "Got a lot of email addresses? List them all here, both personal and business.",
                        description:
                            "List all your email addresses, both personal and business.",
                    },
                    {
                        name: "PERSONAL: Birth Info",
                        path: "birthInfo",
                        data: birthInfoData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-essentials%2FPersonal-birth-info.jpg?alt=media&token=190533b9-d635-44fb-a90a-7a4cd852dc14",
                        imgAlt: "Birth Info image",
                        summary:
                            "From recording the specific hospital or location where you were born, state and country, maintain a precise record of your birth history.",
                        description: `This topic offers a comprehensive platform to document and secure crucial details related to your birth. From recording the specific hospital or location where you were born to noting the city, state, and country, this tool allows you to maintain a precise record of your birth history. Additionally, you have the convenience of attaching notes, providing context or additional information as needed. The option to upload a copy of your birth certificate ensures that this vital document is readily accessible and safely stored, offering both convenience and peace of mind for important life events and administrative purposes.`,
                    },
                    {
                        name: "PERSONAL: Citizenships",
                        path: "citizenships",
                        data: citizenshipsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-essentials%2Fcitizenships.jpg?alt=media&token=e3f28268-f1cd-44a0-b210-728b88fb91c3",
                        imgAlt: "Citizenships image",
                        summary:
                            "Do you have citizenship in more than one country? Record it here.",
                        description:
                            "Do you have citizenship in more than one country? This may be necessary to know when resolving legal or tax issues.",
                    },
                    {
                        name: "PERSONAL: Marital Status",
                        path: "maritalStatus",
                        data: maritalStatusData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-essentials%2FMarital-status.jpg?alt=media&token=878c3dd1-1971-4b6a-b72b-3f97bd45de68",
                        imgAlt: "Marital Status image",
                        summary: "List your current marital status.",
                        description: "List your current marital status.",
                    },
                    {
                        name: "PERSONAL: Employment Status",
                        path: "employmentStatus",
                        data: employmentStatusData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-essentials%2FPersonal-employment-status.jpg?alt=media&token=7c38d4c5-8f50-40d4-9a9f-4b786cd3310f",
                        imgAlt: "Employment Status image",
                        summary: "List your current employment situation.",
                        description: "List your current employment situation.",
                    },
                    {
                        name: "PERSONAL: Military Status",
                        path: "militaryStatus",
                        data: militaryStatusData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-essentials%2Fmilitary-status.jpg?alt=media&token=a73e0625-dce9-4131-ad08-fc8e7009c94f",
                        imgAlt: "Military Status image",
                        summary:
                            "Include both full- and part-time service and consider uploading your military records and discharge papers.",
                        description:
                            "Include both full- and part-time service and consider uploading your military records and discharge papers (DD Form 214) while you’re at it.",
                    },
                    {
                        name: "DOCUMENTS: Birth Certificate",
                        path: "birthCertificates",
                        data: birthCertificatesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-essentials%2FBirth-certificate.jpg?alt=media&token=172c00e0-c7f2-4c3d-ba0c-f4a143deabd5",
                        imgAlt: "Birth Certificate image",
                        summary:
                            "Adding your birth certificate is a breeze. Simply input your name, city, and birth date, specify the location of the original certificate.",
                        description:
                            "Adding your birth certificate is a breeze. Simply input your name, city, and birth date, specify the location of the original certificate, list individuals with copies, and jot down any relevant notes. Plus, you have the option to upload a picture of your birth certificate for easy access and organization. It's a secure and convenient way to keep your essential documents in one place.",
                    },
                    {
                        name: "DOCUMENTS: Passports",
                        path: "passports",
                        data: passportsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-essentials%2FPassport.jpg?alt=media&token=3da089ae-50a3-41a1-90bb-58ce0d0bec64",
                        imgAlt: "Passports image",
                        summary:
                            "Got more than one passport? Record them all here.",
                        description: "List all your current passports.",
                    },
                    {
                        name: "DOCUMENTS: Naturalization Certificate",
                        path: "naturalizationCertificates",
                        data: naturalizationCertificatesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-essentials%2FDOCUMENTS--Naturalization-Certificate.jpg?alt=media&token=c24c5a84-387f-4e15-ab17-d302d13c5bb6",
                        imgAlt: "Naturalization Certificate image",
                        summary:
                            "If you have received a Naturalization Certificate, congratulations! You can record it here to provide verification of citizenship.",
                        description:
                            "If you have received a Naturalization Certificate, record it here to provide verification of citizenship.",
                    },
                    {
                        name: "DOCUMENTS: Operator Licenses",
                        path: "operatorLicenses",
                        data: operatorLicensesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-essentials%2FOperator-License.jpg?alt=media&token=e8127856-fd93-41f1-9396-7029dbfd3b44",
                        imgAlt: "Operator Licenses image",
                        summary:
                            "List your licenses or certificates authorizing you to operate or service specific types of equipment.",
                        description:
                            "Operator licenses or certificates are government-issued documents authorizing you to operate or service specific types of equipment. Check the drop-down menu for the most common operator licenses.",
                    },
                    {
                        name: "DOCUMENTS: Social Security Card",
                        path: "socialSecurityCard",
                        data: socialSecurityCardsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-essentials%2Fsocial-security.jpg?alt=media&token=60a8fa06-6be0-4e0a-9c1e-b9a80c19e605",
                        imgAlt: "Social Security Card image",
                        summary:
                            "You can easily upload your Social Security Card here for easy access if needed.",
                        description:
                            "A Social Security number is required for contributing to and receiving retirement and survivor benefits and disability income, as well as many other financial and identification purposes.",
                    },
                    {
                        name: "DOCUMENTS: Medicare Card",
                        path: "medicareCard",
                        data: medicareCardsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-essentials%2FDocuments-medicare-card.jpg?alt=media&token=48b47aba-59e7-43cf-bcff-77be1052d223",
                        imgAlt: "Medicare Card image",
                        summary:
                            "Your Medicare card has a unique number, and your username and password are required to access your Medicare records online.",
                        description:
                            "Medicare is the federal health insurance program for people 65 or older or with certain disabilities. Your Medicare card has a unique number, and your username and password are required to access your Medicare records online.",
                    },
                    {
                        name: "DOCUMENTS: Air Travel Clearances",
                        path: "airTravelClearances",
                        data: airTravelClearancesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-essentials%2FDocument-air-travel.jpg?alt=media&token=7a45fcea-b529-474d-91ae-0a5a285af0af",
                        imgAlt: "Air Travel Clearances image",
                        summary:
                            "List your Homeland Security’s TSA and Global Entry travel clearance ID programs here.",
                        description:
                            "Adults must show valid identification at airport checkpoints to travel in the U.S., and you may use the Dept. of Homeland Security’s TSA and Global Entry travel clearance ID programs for quicker check-in.",
                    },
                    {
                        name: "DOCUMENTS: Marital Agreements",
                        path: "maritalAgreements",
                        data: maritalAgreementsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-essentials%2Fdocument-marital-agreements.jpg?alt=media&token=e0b563f6-193d-4ba6-bd7e-582256a48f85",
                        imgAlt: "Marital Agreements image",
                        summary:
                            "These include marriage certificates, divorce agreements, separation, pre- and post-nuptial agreements.",
                        description:
                            "These include marriage certificates, divorce agreements, separation, pre- and post-nuptial agreements. Check the drop-down menu for common marital documents.",
                    },
                ],
            },
            {
                name: "my family branch",
                path: "my-family",
                icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/about-me/my-family/03-family-icon-white.svg",
                gIcon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/about-me/my-family/03-family-icon-green.svg",
                favoriteIcon:
                    "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/about-me/my-family/download-1.svg",
                image: {
                    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/about-me/my-family/A-08-MyFamily2.png",
                    imgAlt: "my family pic",
                },
                description:
                    "The My Family branch will help you create a virtual family tree by recording the names of your immediate and extended family members and digitally capture important information including their birthdays, wedding days, and more about each of them.  Additional you can upload both electronic video and audio memories and your narrations as you build your tree.",
                topics: [
                    {
                        name: "Family Members",
                        path: "family",
                        data: familyData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-family%2FFamily-members.jpg?alt=media&token=4b397f92-a9aa-4ceb-92e2-0ef97bf97130",
                        imgAlt: "Family Members image",
                        summary:
                            "Here you can list your nuclear family – your parents, siblings, spouse, children, and stepchildren.",
                        description:
                            "Your nuclear family – your parents, siblings, spouse, children, and stepchildren.",
                    },
                    {
                        name: "My Pets",
                        path: "pets",
                        data: petsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-family%2Fpets.jpg?alt=media&token=8dc08266-3e40-4c11-b66f-bd8386b25670",
                        imgAlt: "Pets image",
                        summary:
                            "Add detailed information such as the type of pet, name, microchip number, veterinarian and much more.",
                        description:
                            "If you have a dog or a cat, you can use the basic first “Standard Information” section below. For more detailed information or If you have any other animal or type of pet, you can add information to the “Additional Details” section.",
                    },
                    {
                        name: "Family Tree Documents",
                        path: "familyTreeDocuments",
                        data: familyTreeDocumentsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-family%2FFamily-tee-documents.jpg?alt=media&token=8f312bea-c3d4-4b2c-b873-1c25ce85d88e",
                        imgAlt: "Family Tree Documents image",
                        summary:
                            "If your family tree published, recorded on paper or stored in a software program, you can record it here.",
                        description:
                            "Is your family tree published, recorded on paper or stored in a software program?",
                    },
                    {
                        name: "Oral Histories",
                        path: "oralHistories",
                        data: oralHistoriesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-family%2Foral-histories.jpg?alt=media&token=3e249107-edc3-4de2-b14e-35f8c286c400",
                        imgAlt: "Oral Histories image",
                        summary:
                            "Are there recorded histories or stories from older members of your family? If so, upload them here.",
                        description:
                            "Are there recorded histories or stories from older members of your family? If so, have they been organized into a document or publication?",
                    },
                    {
                        name: "Important Stories / Traditions",
                        path: "importantStoriesTraditions",
                        data: importantStoriesTraditionsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-family%2FImportant-stories-traditions.jpg?alt=media&token=25b2bc3e-9601-4a7c-8ec3-ae4ae662fcff",
                        imgAlt: "Important Stories / Traditions image",
                        summary:
                            "Record stories and traditions that are an important part of your family’s culture.",
                        description:
                            "What stories and traditions are an important part of your family’s culture?",
                    },
                    {
                        name: "Genealogy Websites",
                        path: "genealogyWebsites",
                        data: genealogyWebsitesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-family%2Fgeneology-websites.jpg?alt=media&token=f15c4655-7453-41f9-8519-8a4ea2d4114b",
                        imgAlt: "Genealogy Websites image",
                        summary:
                            "If you or your family use genealogy websites to research your family tree or historical health data, list them here.",
                        description:
                            "If you or your family use genealogy websites to research your family tree or historical health data, list them here. Check the drop-down menu for popular genealogy websites.",
                    },
                    {
                        name: "Genealogy Software",
                        path: "genealogySoftware",
                        data: genealogySoftwareData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-family%2Fgeneology-software.jpg?alt=media&token=90547b95-01a0-4a66-b442-8b2af11e6446",
                        imgAlt: "Genealogy Software image",
                        summary:
                            "If you or your family use a software program vs a website to record your family tree or historical health data, list it here.",
                        description:
                            "If you or your family use a software program vs. a website to record your family tree or historical health data, list it here. Check the drop-down menu for popular genealogy software programs.",
                    },
                ],
            },
            {
                name: "my life experiences branch",
                path: "my-life-experiences",
                icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/about-me/my-life-experiences/03-life-experiences-icon-white.svg",
                gIcon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/about-me/my-life-experiences/03-life-experiences-icon-green.svg",
                favoriteIcon:
                    "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/about-me/my-life-experiences/download-2.svg",
                image: {
                    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/about-me/my-life-experiences/A-03-Life-Experiences2.png",
                    imgAlt: "life experiences pic",
                },
                description:
                    "This branch is your online scrapbook. It provides you with a space to capture the most important parts of your personal life that you would share with others. Here you can describe your most significant experiences, memories, and people. You can upload photos, videos, and key documents.",
                topics: [
                    {
                        name: "I Am Most Grateful For",
                        path: "iAmMostGratefulFor",
                        data: iAmMostGratefulForData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-life-experiences%2FI-am-most-grateful-for.jpg?alt=media&token=da40cd63-de78-4799-9a47-62afba742765",
                        imgAlt: "I Am Most Grateful For image",
                        summary:
                            "What experiences and people in your life are the most remarkable? Whom do you need to thank?",
                        description:
                            "What experiences and people in your life are the most remarkable? Whom do you need to thank?",
                    },
                    {
                        name: "What I Have Learned",
                        path: "whatIHaveLearned",
                        data: whatIHaveLearnedData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-life-experiences%2FWhat-I-have-learned.jpg?alt=media&token=b8b86e20-673c-40b8-9b63-9f5afbb83095",
                        imgAlt: "What I Have Learned image",
                        summary:
                            "What life experiences have taught you something that turned out to be very important? This is a valuable space to reflect on your life's lessons and wisdom.",
                        description:
                            "What life experiences have taught you something that turned out to be very important? This is a valuable space to reflect on your life's lessons and wisdom. Here, you can record profound statements and insightful notes that encapsulate your experiences and the knowledge you've gained along the way. Additionally, you can conveniently attach relevant documents and provide a location for each one. This feature empowers you to document and share your accumulated wisdom, creating a meaningful resource for yourself and future generations.",
                    },
                    {
                        name: "Person(s) Who Influenced My Life",
                        path: "influentialPeopleInMyLife",
                        data: influentialPeopleInMyLifeData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-life-experiences%2FPersons-who-influenced-my-life.jpg?alt=media&token=ba16be84-32fa-4ccd-876f-1545ff39ad50",
                        imgAlt: "Persons Who Influenced My Life image",
                        summary:
                            "What people have had the greatest impact on your life? Why were they so influential?",
                        description:
                            "What people have had the greatest impact on your life? Why were they so influential?",
                    },
                    {
                        name: "Reflections on My Youth",
                        path: "reflectionsOnMyYouth",
                        data: reflectionsOnMyYouthData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-life-experiences%2FReflections-on-my-youth.jpg?alt=media&token=0b9dbc34-fab1-4d7e-8d66-eebefa1718b3",
                        imgAlt: "Reflections on My Youth image",
                        summary:
                            "Here you can record significant or memorable parts of your childhood.",
                        description:
                            "What are some significant or memorable parts of your childhood?",
                    },
                    {
                        name: "My Favorites in My Youth",
                        path: "myFavoritesInMyYouth",
                        data: myFavoritesInMyYouthData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-life-experiences%2FMy-favorites-in-my-youth.jpg?alt=media&token=81e67034-5cff-4efb-b30d-c5373dc26c54",
                        imgAlt: "My Favorites in My Youth image",
                        summary:
                            "Cherish and catalog your cherished memories, whether they are favorite activities, books, games, movies, pets or anything else.",
                        description:
                            "Cherish and catalog your cherished memories, whether they are favorite activities, books, games, movies, schools, ice creams, pets, friends, or hangout spots. Document your age at the time, who joined you, the location, and the date. Plus, there's room for personal notes to add that extra touch of nostalgia to your digital time capsule. It's a fantastic way to relive and preserve the best moments of your youth.",
                    },
                    {
                        name: "Reflections on My Adult Life",
                        path: "reflectionsOnMyAdultLife",
                        data: reflectionsOnMyAdultLifeData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-life-experiences%2FReflections-on-My-Adult-Life.jpg?alt=media&token=2b04bc76-e9bc-4b59-b4a3-ae82e2b6e6ed",
                        imgAlt: "Reflections on My Adult Life image",
                        summary:
                            "What are some of the most memorable parts and times of your adult life?",
                        description:
                            "What are some of the most memorable parts and times of your adult life?",
                    },
                    {
                        name: "Favorites in My Adult Life",
                        path: "favoritesInMyAdultLife",
                        data: favoritesInMyAdultLifeData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-life-experiences%2FFavorites-in-My-Adult-Life.jpg?alt=media&token=5169978d-dcb6-4daf-a373-4ae3337a6a16",
                        imgAlt: "Favorites in My Adult Life image",
                        summary:
                            "Celebrate your adult life's most treasured moments, whether they involve camping, boating, dancing, fishing, hobbies, singing, traveling, or any other passion.",
                        description:
                            "Celebrate your adult life's most treasured moments, whether they involve camping, boating, dancing, fishing, hobbies, singing, traveling, or any other passion. Record your age during these experiences, note who shared these moments with you, specify the locations and dates, and add personal notes to capture the essence of these memories. It's the perfect way to reflect on and document the highlights of your journey through adulthood.",
                    },
                    {
                        name: "Reflections on My Career",
                        path: "reflectionsOnMyCareer",
                        data: reflectionsOnMyCareerData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-life-experiences%2FReflections-on-my-career.jpg?alt=media&token=11ffcfb0-8459-4a4c-a789-bb3b289e97e3",
                        imgAlt: "Reflections on My Career image",
                        summary:
                            "List your most significant jobs or careers – we bet they’ll be interesting to your loved ones, too.",
                        description:
                            "List your most significant jobs or careers – we bet they’ll be interesting to your loved ones, too.  Jot down some of your most important career moments and lessons and the accomplishments you’re proudest of.",
                    },
                    {
                        name: "My Accomplishments",
                        path: "accomplishments",
                        data: accomplishmentsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-life-experiences%2FMy-Accomplishments.jpg?alt=media&token=75fe8b41-8adc-452c-a609-2c02f89ecace",
                        imgAlt: "My Accomplishments image",
                        summary:
                            "What public or private accomplishments are you most proud of? How have these accomplishments affected your life?",
                        description:
                            "What public or private accomplishments are you most proud of? How have these accomplishments affected your life and perhaps those of others? Record your thoughts here.",
                    },
                    {
                        name: "My Community Service",
                        path: "communityService",
                        data: communityServiceData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fmy-life-experiences%2Fcommunity-service.jpg?alt=media&token=8ef6d7de-fc29-4d4b-b243-08a0c369a820",
                        imgAlt: "My Community Service image",
                        summary:
                            "What community service activities have you been involved in? This can include your support of schools, churches, or other organizations.",
                        description:
                            "What community service activities have you been involved in? This can include your support of schools, churches, temples, or other philanthropic organizations. Which have been most rewarding for you? Record these experiences here.",
                    },
                ],
            },
        ],
    },
    {
        name: "EVERYDAY",
        path: "everyday",
        subBranches: [
            {
                name: "my contacts",
                path: "my-contacts",
                icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/contacts/00-my-contacts-icon-white.svg",
                gIcon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/dashboard/icons/00-contacts-icon-green.svg",
                favoriteIcon:
                    "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/contacts/B+10+-+My+Contacts-01.svg",
                image: {
                    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/contacts/B-10-MyContacts2.jpg",
                    imgAlt: "my contacts pic",
                },
                description:
                    "Our platform is not intended to hold all your contacts. It is meant to include people who can provide timely information, records, and advice that you need to enter into My Banyan. They may include some friends, family, and professionals such as physicians, bankers, realtors, insurance brokers, veterinarians, clergy, et al.",
                topics: [
                    {
                        name: "Address Book",
                        path: "mycontacts",
                        data: myContactsData,
                        description:
                            "Our platform is not intended to hold all your contacts. It is meant to include people who can provide timely information, records, and advice that you need to enter into My Banyan. They may include some friends, family, and professionals such as physicians, bankers, realtors, insurance brokers, veterinarians, clergy, et al.",
                    },
                ],
            },
            {
                name: "accounts branch",
                path: "accounts",
                icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/accounts/01-my-accounts-icon-white.svg",
                gIcon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/accounts/01-accounts-icon-green.svg",
                favoriteIcon:
                    "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/accounts/download-3.svg",
                image: {
                    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/accounts/A-02-IDs2.png",
                    imgAlt: "accounts pic",
                },
                description: `The Accounts branch provides a complete approach to organizing and managing your digital and financial accounts. Important topics included in this branch are your bank accounts and bank cards, investment accounts, merchant accounts and safety deposit box information. Organizing and sharing this information with your loved ones and trusted advisors is key to maintaining financial security, convenience, and peace of mind in our digital era.`,
                topics: [
                    {
                        name: "Bank Accounts",
                        path: "bankAccounts",
                        data: bankAccountsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Faccounts%2FBanl-accounts.jpg?alt=media&token=40a26324-5cc8-498f-909a-9c247c74d2d9",
                        imgAlt: "Bank Accounts image",
                        summary:
                            "List your bank accounts both in the US and abroad.",
                        description:
                            "List your bank accounts both in the US and abroad.",
                    },
                    {
                        name: "Bank Cards",
                        path: "bankCards",
                        data: bankCardsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Faccounts%2Fbank-cards.jpg?alt=media&token=069fa586-952c-4c5b-9722-65bf346c42b3",
                        imgAlt: "Bank Cards image",
                        summary:
                            "Enter accurate and up-to-date bank card information, including the cardholder's name, card number, expiration date, and CVV/CVC code",
                        description:
                            "When creating a new record, enter accurate and up-to-date bank card information, including the cardholder's name, card number, expiration date, and CVV/CVC code.",
                    },
                    {
                        name: "Merchant Accounts",
                        path: "merchantAccounts",
                        data: merchantAccountsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Faccounts%2FMerchant-accounts.jpg?alt=media&token=2863af57-a66e-43d6-8605-49dafffe17fa",
                        imgAlt: "Merchant Accounts image",
                        summary:
                            "List all merchants with whom you have a credit account such as the merchant's name, contact information, and any relevant notes.",
                        description:
                            "List all merchants with whom you have a credit account. When adding a new merchant card record, input essential details such as the merchant's name, contact information, and any relevant notes for convenient access during future transactions. Keeping this information up-to-date can streamline payment processes and ensure seamless interactions with the merchant.",
                    },
                    {
                        name: "Investment Accounts",
                        path: "investmentsAccounts",
                        data: investmentAccountData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Faccounts%2FInvestment-accounts.jpg?alt=media&token=483bada0-7ff8-47c8-b643-90417d0b27c3",
                        imgAlt: "Investment Accounts image",
                        summary:
                            "Enter any critical information about your investment accounts here.",
                        description:
                            "Enter any critical information about your investment accounts here. Check the drop-down menu for examples of investment accounts you may have.",
                    },
                    {
                        name: "Utilities Accounts",
                        path: "utilities",
                        data: utilitiesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Faccounts%2Futilities-accounts.jpg?alt=media&token=08533f8d-d096-48f7-a81d-a27031363b3a",
                        imgAlt: "Utilities Accounts image",
                        summary:
                            "List the utility accounts for each property that you own such as electricity, water, gas, TV provider and more.",
                        description:
                            "List the utility accounts for each property that you own. When creating a new utilities record, include the type of utility (electricity, water, gas, TV provider), the property address associated with the utility, and the account login information to quickly access and manage utility services with ease. Storing this information securely will help streamline bill payments and ensure smooth management of essential services.",
                    },
                    {
                        name: "Rewards / Membership Programs",
                        path: "rewardsMembershipAccounts",
                        data: rewardsMembershipAccountsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Faccounts%2FRewards-membership.jpg?alt=media&token=3d3c6e80-e443-42db-a1f3-aeba979da516",
                        imgAlt: "Rewards / Membership Programs image",
                        summary:
                            "Record account information for your reward programs including frequent flyer, club membership and other loyalty programs.",
                        description:
                            "Record account information, credentials, and contact information for your reward programs including frequent flyer, club membership and other loyalty programs. Check the drop-down menu for various program types.",
                    },
                    {
                        name: "Safe Deposit Boxes",
                        path: "safeDepositBoxes",
                        data: safeDepositBoxesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Faccounts%2FSafe-Deposit-Boxes.jpg?alt=media&token=78c87f12-e39a-41a5-9a95-012e37d0ec19",
                        imgAlt: "Safe Deposit Boxes image",
                        summary:
                            "Record essential details such as the bank or institution's name, safety box number, the location of the key or access code.",
                        description:
                            "List the information for each safe deposit box that you rent. When setting up a new safety box record, record essential details such as the bank or institution's name, safety box number, the location of the key or access code, and any additional instructions or security measures for easy retrieval and secure management of valuable items.",
                    },
                ],
            },
            {
                name: "business branch",
                path: "business",
                icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/business/02-business-icon-white.svg",
                gIcon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/business/02-business-icon-green.svg",
                favoriteIcon:
                    "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/business/download-4.svg",
                image: {
                    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/business/B-02-Business2.png",
                    imgAlt: "business pic",
                },
                description: `The Business branch covers a wide range of topics that relate to any business entity you own fully or partially. Topics included are details of the formation documents for the business entity, shareholdings, assets and liabilities, intellectual properties, and tax returns. Having this information organized and easily accessible to loved ones and trusted advisors is very important to ensure the longevity, prosperity, and compliance of the business entity with all relevant governmental regulations. All important documents can be uploaded for sharing and easy access.`,
                topics: [
                    {
                        name: "Formation Agreements",
                        path: "businessFormationAgreement",
                        data: businessFormationAgreementData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fbusiness%2Fformation-Agreements.jpg?alt=media&token=4085723e-8613-45d9-95bc-57523a45c545",
                        imgAlt: "Formation Agreements image",
                        summary:
                            "Arbitration, Articles of incorporation, Indemnity, Operating, Non-disclosure,  Partnership, Shareholder...",
                        description:
                            "When adding a new record, specify the type of business you own, whether it's a C-corporation, LLC, or sole proprietorship, and provide details about the governing agreements that dictate how your business operates. For assistance, refer to the drop-down menu to find common types of business agreements.",
                    },
                    {
                        name: "Holding Companies",
                        path: "businessHoldingCompanies",
                        data: businessHoldingCompaniesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fbusiness%2FHolding-companies.jpg?alt=media&token=04915672-850a-47e5-82b2-33d8d058dbd1",
                        imgAlt: "Holding Companies image",
                        summary:
                            "A holding company’s primary business is holding a controlling interest in the securities of other companies",
                        description:
                            "A holding company’s primary business is holding a controlling interest in the securities of other companies; it typically doesn’t produce goods or services itself. For example, Alphabet is the holding company for Google.",
                    },
                    {
                        name: "Assets",
                        path: "businessAssets",
                        data: businessAssetsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fbusiness%2FAssets.jpg?alt=media&token=28994433-a6cb-4404-ad4b-c3e7b4551f4f",
                        imgAlt: "Assets image",
                        summary:
                            "AR, Cash, Equipment, Goodwill, Intellectual Property, Inventory,  Property, facilities & more",
                        description:
                            "If you own a business, include an estimate of its current value in your assets. These assets include capital assets, current assets, cash, accounts receivable and non-current assets. Recent financial statements are a good source of this information.",
                    },
                    {
                        name: "Financial Statements",
                        path: "businessFinancialStatements",
                        data: businessFinancialStatementsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fbusiness%2FFinancial-Statements.jpg?alt=media&token=acb8f1ee-e5b0-4cb4-b28f-dcc6b4a629ea",
                        imgAlt: "Financial Statements image",
                        summary:
                            "Balance Sheet, Income, Cash Flow, Shareholder Equity",
                        description:
                            "These are written records of your business activities and its financial performance. Such records are often audited by government agencies or accounting firms. Check the drop-down menu for common financial statement examples.",
                    },
                    {
                        name: "Business Insurance",
                        path: "businessInsurance",
                        data: businessInsuranceData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fbusiness%2FBusiness-insurance.jpg?alt=media&token=77a7a36e-5992-4150-9148-1edcc11e3761",
                        imgAlt: "Business Insurance image",
                        summary:
                            "Business Interruption, Commercial Property, Directors, General Liability, Health, Workers Comp & more",
                        description:
                            "When adding a new record under BUSINESS INSURANCE, ensure to include crucial details such as the insurance company's name, policy number, coverage limits, premiums, and any relevant account information for comprehensive and easily accessible insurance management.",
                    },
                    {
                        name: "Stock Ledgers",
                        path: "stockLedgers",
                        data: stockLedgersData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fbusiness%2FStock-ledgers.jpg?alt=media&token=4beb2f33-1802-4cdc-ae73-c964ee20c6a0",
                        imgAlt: "Stock Ledgers image",
                        summary:
                            "The official record of a corporation’s stock.",
                        description:
                            "The official record of a corporation’s stock. It lists the names and addresses of the stockholders, the number of shares owned, their stock certificate numbers, and the date the stock was issued. It’s maintained by the company’s financial manager or an outside entity such as a CPA.",
                    },
                    {
                        name: "Stock Certificates",
                        path: "stockCertificates",
                        data: stockCertificatesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fbusiness%2Fstock-certificates.jpg?alt=media&token=6c477ccc-1f26-43e9-a4f8-49dacc0cb0ce",
                        imgAlt: "Stock Certificates image",
                        summary:
                            "A paper that states a shareholder's ownership in a company.",
                        description:
                            "A paper that states a shareholder's ownership in a company. They include information such as number of shares owned, date of purchase, an identification number, and usually a corporate seal and signatures. Your loved ones and trusted advisors should know the location of any stock certificates you own.",
                    },
                    {
                        name: "Tax Return Statements",
                        path: "taxReturnStatements",
                        data: taxReturnStatementsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fbusiness%2FTax-return-statements.jpg?alt=media&token=3988e2ec-46c3-467c-aaf4-004eec37e7c7",
                        imgAlt: "Tax Return Statements image",
                        summary: "Completed tax return statements.",
                        description:
                            "The completed documentation that calculates an entity’s income earned and the amount of tax due to the government. Tax returns for business entities are usually prepared in house or by an outside CPA/tax advisor.",
                    },
                    {
                        name: "Permits",
                        path: "permits",
                        data: permitsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fbusiness%2FPermits.jpg?alt=media&token=5182d548-417d-4879-abd2-0cb982b738aa",
                        imgAlt: "Permits image",
                        summary:
                            "These are legal documents certifying a business entity’s compliance with federal, state, or city laws.",
                        description:
                            "These are legal documents certifying a business entity’s compliance with federal, state, or city laws regulating structural appearances and safety, as well as the sale of products and services. Many permits and licenses need yearly renewal.",
                    },
                    {
                        name: "Loan Agreements",
                        path: "loanAgreements",
                        data: loanAgreementsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fbusiness%2Floan-agreements.jpg?alt=media&token=8a82c2f4-921c-4b81-b8e6-e4bc593fa520",
                        imgAlt: "Loan Agreements image",
                        summary:
                            "Business loan agreements include facilities agreements, revolving loans,  term loans, and working capital loans.",
                        description:
                            "A contract between a borrower and a lender that regulates mutual promises made by each party. It details what is being loaned and when/how the borrower must pay it back. Business loan agreements include facilities agreements, revolving loans, term loans, and working capital loans.",
                    },
                    {
                        name: "Intellectual Property",
                        path: "intellectualProperty",
                        data: intellectualPropertyData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fbusiness%2FIntellectual-property.jpg?alt=media&token=20d75aeb-afa8-4066-905e-4898e10e7b8c",
                        imgAlt: "Intellectual Property image",
                        summary:
                            "Copyrights, Patents, Trademarks, Industrial Design, Trade Dress, Trade Secrets & more",
                        description:
                            "Any patents, copyrights, or trademarks your business owns can be valuable assets. These and other forms of intellectual property (IP) should be shared with loved ones and trusted advisors. Check the drop-down menu for common types of IP.",
                    },
                    {
                        name: "Record of Company Minutes",
                        path: "recordOfCompanyMinutes",
                        data: recordOfCompanyMinutesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fbusiness%2FRecord-of-company-minutes.jpg?alt=media&token=1e98d870-e468-477d-a84c-a9d14b4e09a2",
                        imgAlt: "Record of Company Minutes image",
                        summary:
                            "These are legal documents certifying a business entity’s compliance with federal, state, or city laws",
                        description:
                            "An official and legal record of major discussions, activities, and decisions made by a company during meetings. The company secretary typically compiles company minutes.",
                    },
                    {
                        name: "Buy / Sell Agreements",
                        path: "buysellAgreements",
                        data: buySellAgreementsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fbusiness%2Fbuy-sell-agreements.jpg?alt=media&token=ed5cfbc2-9a6a-4c97-98c1-4dc7489174bb",
                        imgAlt: "Buy / Sell Agreements image",
                        summary:
                            "Also known as a buyout agreement, it’s a legally binding contract between co-owners of a business",
                        description:
                            "Also known as a buyout agreement, it’s a legally binding contract between co-owners of a business that governs if one co-owner dies or is otherwise forced to leave the business or chooses to leave the business.",
                    },
                    {
                        name: "Promissory Notes",
                        path: "promissoryNotes",
                        data: promissoryNotesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fbusiness%2Fpromissory-notes.jpg?alt=media&token=9ba55f79-27c1-448b-a47f-58b4e23cfb52",
                        imgAlt: "Promissory Notes image",
                        summary:
                            "A promissory note is used to loan or borrow money.",
                        description:
                            "A promissory note is used to loan or borrow money. It’s a legal document in which one party promises in writing to pay a determinate sum of money to the other. The note includes interest rate and repayment terms. Any promissory notes for your business should be made known to your loved ones and trusted advisors.",
                    },
                    {
                        name: "Employee Handbook",
                        path: "employeeHandbook",
                        data: employeeHandbookData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fbusiness%2FEmployee-Handbook.jpg?alt=media&token=5fb6a721-30bc-4140-840e-facc8e4b38de",
                        imgAlt: "Employee Handbook image",
                        summary:
                            "Also known as an employee manual staff handbook, or company policy manual",
                        description:
                            "Also known as an employee manual, staff handbook, or company policy manual, it’s given by employers to employees to share job-related information and expectations.",
                    },
                ],
            },
            {
                name: "digital world branch",
                path: "digital-world",
                icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/digital-world/03-digital-world-icon-white.svg",
                gIcon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/digital-world/03-digital-world-icon-green.svg",
                favoriteIcon:
                    "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/digital-world/download-5.svg",
                image: {
                    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/digital-world/B-03-DigitalWorld2.png",
                    imgAlt: "digital world pic",
                },
                description: `The Digital World branch encompasses a diverse array of topics (Internet account access, email, electronic subscriptions, smart devices, and more).  Digitally capturing your electronic credentials will make for a less frustrating experience when you can’t remember or cannot find the piece of paper where you scribbled your account information.  Paper bills you receive may have account information you can scan, or take a picture, and upload the file into My Banyan for easy reference.`,
                topics: [
                    {
                        name: "Internet Access",
                        path: "internetAccess",
                        data: internetAccessData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fdigital-world%2FInternet-access.jpg?alt=media&token=8239176f-3a15-431e-8a80-4b50ba5871ba",
                        imgAlt: "Internet Access image",
                        summary:
                            "Enter the account information you use to access the internet here.",
                        description:
                            "Enter the account information you use to access and use the internet here.",
                    },
                    {
                        name: "Email Services",
                        path: "emailAccounts",
                        data: emailAccountsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fdigital-world%2FEmail-services.jpg?alt=media&token=afe27976-ed09-4c51-892b-22a40cc7a9d1",
                        imgAlt: "Email Services image",
                        summary:
                            "Information that enables your loved ones to access all your email accounts should be recorded and shared.",
                        description:
                            "Information that enables your loved ones to access all your email accounts should be recorded and shared.",
                    },
                    {
                        name: "Internet Subscriptions / Merchants",
                        path: "internetServices",
                        data: internetServicesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fdigital-world%2FInternet-Merchants.jpg?alt=media&token=14d6006a-fbfa-419a-885f-5afaf162df64",
                        imgAlt: "Internet Subscriptions / Merchants image",
                        summary:
                            "Record account information for retail merchants from whom you purchase goods and services.",
                        description:
                            "Personal and/or business website information including URLs and contact information for organizations that host your websites should be shared with loved ones.  Check the drop-down menu for popular website hosting services. Record account information for retail merchants (physical and online) from whom you purchase goods and services.",
                    },
                    {
                        name: "Smart Devices",
                        path: "devices",
                        data: devicesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fdigital-world%2FSmart-devices.jpg?alt=media&token=ad8b253b-a160-402e-b0df-0ad5d9033093",
                        imgAlt: "Smart Devices image",
                        summary:
                            'List all the "smart devices" that you have at home and elsewhere.',
                        description: `List all the "smart devices" that you have at home and elsewhere. When creating a new record for Smart Devices, include essential details such as the model, manufacturer, and the location of the device for easy reference and management. Storing this information will help you keep track of your devices, making it convenient to access crucial information when needed.`,
                    },
                    {
                        name: "Back-up of Digital Files",
                        path: "backUpOfDigitalFiles",
                        data: backUpOfDigitalFilesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fdigital-world%2FBAck-up-of-digital-files.jpg?alt=media&token=4b3490d1-f31b-45b7-af24-050f5062a40b",
                        imgAlt: "Back-up of Digital Files image",
                        summary:
                            "Enter the key information about how you currently back up your digital files.",
                        description:
                            "Enter the key information about how you currently back up your digital files. When adding a new record for the backup of digital files, make sure to include details about the backup location, whether it's a hard drive, thumb drive, cloud-service, or CD. Additionally, note any login information associated with the backup to ensure easy access and retrieval of your important files when required.",
                    },
                    {
                        name: "Organization of Digital Files",
                        path: "organizationOfDigitalFiles",
                        data: organizationOfDigitalFilesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fdigital-world%2FOrganization0of0digital-files.jpg?alt=media&token=4195c6d7-6097-451b-90d9-14e6b041f5a2",
                        imgAlt: "Organization of Digital Files image",
                        summary:
                            "Organize your computer files in a way that allows someone else to easily locate documents.",
                        description:
                            "Have you organized your computer files in a way that allows someone else to easily locate documents? If not, consider doing so before it becomes an issue for loved ones trying to find important information in your digital files.",
                    },
                    {
                        name: "Data Security",
                        path: "dataSecurityMethods",
                        data: dataSecurityMethodsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fdigital-world%2FData-security.jpg?alt=media&token=0d15d09c-f83a-421d-bdd4-2cf8dbc8d705",
                        imgAlt: "Data Security image",
                        summary:
                            "Record how you currently manage the security of your digital data.",
                        description:
                            "Examine how you currently manage the security of your digital data and how you can improve your methods in the future. When adding a DATA SECURITY record, note concerns about breaches or vulnerabilities, recent occurrences, and planned methods like spam filters, 2FA, backups, encrypted cloud services to safeguard data.",
                    },
                    {
                        name: "Password Managers",
                        path: "passwordManagementSoftware",
                        data: passwordManagementSoftwareData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fdigital-world%2Fpassword-managers.jpg?alt=media&token=d1b78d29-6c20-4a57-8b7e-71c4d5d9c314",
                        imgAlt: "Password Managers image",
                        summary:
                            "Record any software programs you use to manage usernames and passwords for sharing purposes.",
                        description:
                            "If you use 3rd-party software programs to manage names and passwords for apps on your electronic devices, it’s important to share this information with loved ones. Check the drop-down menu for popular password management programs.",
                    },
                    {
                        name: "Important Software",
                        path: "software",
                        data: softwareData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fdigital-world%2FSoftware%3Dprograms.jpg?alt=media&token=bc561984-00a3-44d0-ab59-af5522f207a8",
                        imgAlt: "Important Software image",
                        summary:
                            "Record account information for your most important software programs here.",
                        description:
                            "Record account information for your most important software programs here.",
                    },
                ],
            },
            {
                name: "finances branch",
                path: "finances",
                icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/finances/05-finances-icon-white.svg",
                gIcon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/finances/05-finances-icon-green.svg",
                favoriteIcon:
                    "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/finances/download-6.svg",
                image: {
                    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/finances/B-04-Finances2.png",
                    imgAlt: "finances pic",
                },
                description:
                    "The Finances branch lets you record and share essential information relating to your finances. This includes your financial plans, income, assets, and liabilities. Having this information organized enables your loved ones and trusted advisors to have easy access to it when it is needed. This avoids any uncertainties or anxiety concerning your financial situation and plans.",
                topics: [
                    {
                        name: "GENERAL: Financial Plans",
                        path: "financialPlans",
                        data: financialPlansData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffinances%2Ffinancial-plans.jpg?alt=media&token=2c601206-36b4-40ed-801a-bb9106fb472e",
                        imgAlt: "Financial Plans image",
                        summary:
                            "Summarizing your financial plans in a logical, convenient way is essential to removing complexity and confusion for your Inner Circle.",
                        description:
                            "Summarizing your financial plans in a logical, convenient way is essential to removing complexity and confusion for your Inner Circle.",
                    },
                    {
                        name: "GENERAL: Income Sources",
                        path: "incomeSources",
                        data: incomeSourcesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffinances%2Fincome-sources.jpg?alt=media&token=869c0d49-2a6c-4849-9a5f-02b924640529",
                        imgAlt: "Income Sources image",
                        summary:
                            "Record any “active” sources (wages, salary, bonuses); and “passive” sources (dividends, interest, rents, RMDs and more).",
                        description:
                            "Income generally comes from two sources: “active” sources (wages, salary, bonuses and fees generated from your work); and “passive” sources (returns on assets such as dividends, interest, rents, RMDs and Social Security).",
                    },
                    {
                        name: "GENERAL: Tax Returns",
                        path: "taxReturns",
                        data: taxReturnsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffinances%2Ftax-returns.jpg?alt=media&token=41752459-ba7a-4504-a369-d3913c0967aa",
                        imgAlt: "Tax Returns image",
                        summary:
                            "The completed documentation that calculates an entity’s income earned and the amount of tax due to the government.",
                        description:
                            "The completed documentation that calculates an entity’s income earned and the amount of tax due to the government. Tax returns for business entities are usually prepared in house or by an outside CPA/tax advisor.",
                    },
                    {
                        name: "ASSETS: Bank Accounts Balances",
                        path: "bankAccountsBalances",
                        data: bankAccountsBalancesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffinances%2FBank-account-balances.jpg?alt=media&token=6e762256-5129-44c5-b104-45255f5a48a3",
                        imgAlt: "Bank Accounts Balances image",
                        summary:
                            "Enter the estimated balances as of the current date for your various bank accounts.",
                        description:
                            "Enter the estimated balances as of the current date for your various bank accounts.",
                    },
                    {
                        name: "ASSETS: Cash on Hand",
                        path: "cashOnHand",
                        data: cashOnHandData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffinances%2FCash-on-hand.jpg?alt=media&token=5556b5ea-4a69-49cb-8dfd-b10e0f68b664",
                        imgAlt: "Cash on Hand image",
                        summary:
                            "Enter the value of your currency that may be in numerous locations.",
                        description:
                            "Enter the value of your currency that may be in numerous locations. You may include precious metals, such as gold or silver.",
                    },
                    {
                        name: "ASSETS: Real Estate Value",
                        path: "realEstateValue",
                        data: realEstateValueData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffinances%2Freal-estate-value.jpg?alt=media&token=37292547-c461-4763-97f8-b3dbc5633933",
                        imgAlt: "Real Estate Value image",
                        summary:
                            "Enter the most recent estimated value of each piece of real estate property that you own.",
                        description:
                            "Enter the most recent estimated value of each piece of real estate property that you own. You may want to make sure that your listed properties match those that are in your Real Estate Branch.",
                    },
                    {
                        name: "ASSETS: Investments Value",
                        path: "investments",
                        data: investmentsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffinances%2FInvestments-value.jpg?alt=media&token=730c8e30-cbad-4563-ae68-8d0302968562",
                        imgAlt: "Investments Value image",
                        summary:
                            "Write down your CDs,  IRAs and other portfolios requiring management by a financial advisor.",
                        description:
                            "Investments can be simple, such as CDs and IRAs. Portfolios requiring management by a financial advisor are more complex. Before starting this section, have recent statements from all your investment accounts handy.",
                    },
                    {
                        name: "ASSETS: Personal Vehicles Value",
                        path: "personalVehiclesValue",
                        data: personalVehiclesValueData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffinances%2FPersonal-vehicels-value.jpg?alt=media&token=150d5eb4-bb05-4886-8054-e959f98bfeb6",
                        imgAlt: "Personal Vehicles Value image",
                        summary:
                            "List all registered motor vehicles that you currently own or lease, along with the location of ownership deeds and more.",
                        description:
                            "List all registered motor vehicles that you currently own or lease, along with the location of ownership deeds and the date for registration renewal. Check the drop-down menu for examples of registered vehicles.",
                    },
                    {
                        name: "ASSETS: Collectibles Value",
                        path: "collectibleValuables",
                        data: collectibleValuablesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffinances%2Fcollectable-values.jpg?alt=media&token=0f750d99-c860-4cf7-9677-b9e486ad3462",
                        imgAlt: "Collectibles Value image",
                        summary:
                            "Record collectibles and valuable items you’ve acquired over your lifetime. The drop-menu will help you create a complete list of these items.",
                        description:
                            "Your will and trust documents should provide guidance regarding your wishes for distribution of collectible and valuable items you’ve acquired over your lifetime. The drop-menu will help you create a complete list of these items.",
                    },
                    {
                        name: "ASSETS: Loans Receivable",
                        path: "loansReceivable",
                        data: loansReceivableData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffinances%2Floans-receivable.jpg?alt=media&token=e64a95db-db15-4e5a-9445-22df3870b761",
                        imgAlt: "Loans Receivable image",
                        summary:
                            "Outstanding loans are a component of your total assets.",
                        description:
                            "Outstanding loans are a component of your total assets. These loans should be documented, and loan agreements or promissory notes signed. Be sure to record contact information on each loan holder and the location of loan agreements.",
                    },
                    {
                        name: "ASSETS: Leases Receivable",
                        path: "leases",
                        data: leasesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffinances%2FAssets-leases-receivable.jpg?alt=media&token=753bd66f-1c90-440c-b73e-bcc3199e06d2",
                        imgAlt: "Leases Receivable image",
                        summary:
                            "Recording updated information on existing leases may prevent violations of lease agreements.",
                        description:
                            "A lease is a contract calling for you to pay the owner for use of an asset. Property, buildings, industrial or business equipment and vehicles are commonly leased assets. Recording updated information on existing leases may prevent violations of lease agreements.",
                    },
                    {
                        name: "LIABILITIES: Accounts Payable",
                        path: "accountsPayable",
                        data: accountsPayableData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffinances%2Faccounts-payable.jpg?alt=media&token=f04a9ec3-1075-425c-90b0-f87dd4956fb6",
                        imgAlt: "Accounts Payable image",
                        summary:
                            "Amounts owed to vendors or suppliers for goods or services received and not yet paid for.",
                        description:
                            "Amounts owed to vendors or suppliers for goods or services received and not yet paid for. Common personal outstanding accounts payable are credit card balances, utility bills and rent.",
                    },
                    {
                        name: "LIABILITIES: Loans Payable",
                        path: "loans",
                        data: loansData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffinances%2FLoans-payable.jpg?alt=media&token=353f420f-54c4-42b4-aee5-08a9b05ef888",
                        imgAlt: "Loans Payable image",
                        summary:
                            "If you, your business, or other entity you are a party to has an outstanding loan, the debt and interest are a liability.",
                        description:
                            "If you, your business, or other entity you are a party to has an outstanding loan, the debt and interest are a liability. Check the drop-down menu for types of loans that may apply to you.",
                    },
                    {
                        name: "LIABILITIES: Leases Payable",
                        path: "liabilitiesLeases",
                        data: liabilitiesLeasesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffinances%2Fleases-payable.jpg?alt=media&token=c36778c8-40ef-4773-b65d-368a096ccbd4",
                        imgAlt: "Leases Payable image",
                        summary:
                            "Record key details such as the assets under lease, the type of lease, lease issuer, lease holder, date of origin, and more.",
                        description:
                            "record key details such as the assets under lease, the type of lease, lease issuer, lease holder, date of origin, duration, payment amount, and more. Additionally, you have the flexibility to add personal notes for context and upload relevant documents to maintain a well-organized and accessible record of your lease agreements. This ensures you stay on top of your financial responsibilities and make informed decisions regarding your assets.",
                    },
                    {
                        name: "LIABILITIES: Credit Card Debt",
                        path: "creditCardDebt",
                        data: creditCardDebtData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffinances%2Fcredit-card-debt.jpg?alt=media&token=2f653c6d-e9b7-4533-a507-49a5c624ed27",
                        imgAlt: "Credit Card Debt image",
                        summary:
                            "Keep track of the total amount outstanding on all your credit cards, including balances for the current month.",
                        description:
                            "The total amount outstanding on all your credit cards, including balances for the current month and any unpaid balances from previous months.",
                    },
                    {
                        name: "LIABILITIES: Outstanding Taxes Due",
                        path: "outstandingTaxesDue",
                        data: outstandingTaxesDueData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffinances%2Foutstanding-taxes-due.jpg?alt=media&token=af646361-2d59-47a0-b1cd-074a44324530",
                        imgAlt: "Outstanding Taxes Due image",
                        summary: "Record all taxes that have not been paid.",
                        description:
                            "Taxes that have not been paid. Common taxes are federal and state income taxes. You may also have unpaid property or business taxes. Record any outstanding taxes and contact information for your CPA.",
                    },
                ],
            },
            {
                name: "health branch",
                path: "health",
                icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/health/06-health-icon-white.svg",
                gIcon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/health/06-health-icon-green.svg",
                favoriteIcon:
                    "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/health/download-7.svg",
                image: {
                    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/health/AdobeStock_437404171.png",
                    imgAlt: "health pic",
                },
                description:
                    "This branch holds all your important health information that you may want others to know should you have a health crisis. In here will be facts in these three areas: 1) your current and past health, 2) your plans and medical resources required to manage your health, and 3) any familial diseases or predispositions that you have.",
                pdf: {
                    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/planning-medical-emergency.png",
                    imgAlt: "Planning for My Medical Emergency Article",
                    link: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/PLANNING+FOR+MY+MEDICAL+EMERGENCY.pdf",
                },
                topics: [
                    {
                        name: "Advanced Health Care Directives",
                        path: "advancedDirectives",
                        data: advancedDirectivesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fhealth%2FAdvanced-Health-Care-directives.jpg?alt=media&token=040958c9-a6ee-4866-b724-403db24b0706",
                        imgAlt: "Advanced Health Care Directives image",
                        summary:
                            "A legal document that includes a living will, powers of attorney, and a health care proxy.",
                        description:
                            "A legal document that includes a living will, powers of attorney, and a health care proxy. It explains how you want medical decisions about you to be made if you cannot, and who will make them in your place.",
                    },
                    {
                        name: "CURRENT: Blood Type",
                        path: "bloodType",
                        data: bloodTypeData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fhealth%2FBlood.jpg?alt=media&token=44bf5846-fc4a-4942-8dff-c6a058e08cc5",
                        imgAlt: "Blood Type image",
                        summary:
                            "You can input your blood type and specify which blood types you can receive and donate.",
                        description:
                            "Keep crucial health information at your fingertips. You can input your blood type and specify which blood types you can receive and donate. This feature is vital for emergencies and medical decisions. Additionally, you can add personal notes and upload any relevant documents, ensuring that your health records are organized, accessible, and up-to-date, contributing to your overall well-being and peace of mind.",
                    },
                    {
                        name: "CURRENT: Health Exams",
                        path: "recentHealthExams",
                        data: recentHealthExamsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fhealth%2FCurrent-Health-Exams.jpg?alt=media&token=adff0e81-5630-459c-80e8-84975a1a8429",
                        imgAlt: "Health Exams image",
                        summary:
                            "Check the drop-down menu for health exams you may have taken in the past 12-24 months.",
                        description:
                            "Check the drop-down menu for health exams you may have taken in the past 12-24 months.",
                    },
                    {
                        name: "CURRENT: Ailments",
                        path: "ailments",
                        data: ailmentsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fhealth%2FCurrent-ailments.jpg?alt=media&token=e025699b-3713-4a3d-9edc-a8d4dbac5db2",
                        imgAlt: "Ailments image",
                        summary:
                            "Record your current health issues and those you want to monitor.",
                        description:
                            "Check the drop-down menu for a list of common ailments and health concerns. Record your current health issues and those you want to monitor.",
                    },
                    {
                        name: "CURRENT: Allergies",
                        path: "allergies",
                        data: allergiesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fhealth%2FCurrent-allergies.jpg?alt=media&token=a714eb43-510a-4988-9ff5-0e8c7a74ade8",
                        imgAlt: "Allergies image",
                        summary:
                            "Identify your significant allergies that should be included on your medical emergency data sheet.",
                        description:
                            "Identify your significant allergies that should be included on your medical emergency data sheet.",
                    },
                    {
                        name: "CURRENT: Medications",
                        path: "medications",
                        data: medicationsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fhealth%2FCurrent-medications.jpg?alt=media&token=317eb04f-5c71-4a7a-9f34-3c6e45e1878b",
                        imgAlt: "Medications image",
                        summary:
                            "Record any prescription and non-prescription medications that you take on a regular or as-needed basis.",
                        description:
                            "Record any prescription and non-prescription medications that you take on a regular or as-needed basis.",
                    },
                    {
                        name: "CURRENT: Supplements",
                        path: "currentSupplements",
                        data: currentSupplementsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fhealth%2FCurrent-supplements.jpg?alt=media&token=64bb60b2-0376-4275-b0c6-4a393832b633",
                        imgAlt: "Supplements image",
                        summary:
                            "Record any over-the-counter vitamins, herbs and supplements  you take on a regular basis.",
                        description:
                            "Record any over-the-counter vitamins, herbs and supplements you take on a regular basis.",
                    },
                    {
                        name: "CURRENT: DNA Information",
                        path: "dnaInfo",
                        data: dnaInfoData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fhealth%2FCurrent-DNA-information.jpg?alt=media&token=2671a1e6-aa27-439d-85a8-8483c109626c",
                        imgAlt: "DNA Information image",
                        summary:
                            "Here’s where you can record significant health information based on any DNA research you’ve done.",
                        description:
                            "Here’s where you can record significant health information based on any DNA research you’ve done. Check the drop-down menu for popular DNA analysis companies.",
                    },
                    {
                        name: "CURRENT: Home Health Equipment",
                        path: "homeHealthEquipment",
                        data: homeHealthEquipmentData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fhealth%2FHome-health-equipment.jpg?alt=media&token=c5a123f0-6565-4125-82e0-4a8c7be0870b",
                        imgAlt: "Home Health Equipment image",
                        summary:
                            "Include equipment you routinely use to improve your daily life.",
                        description:
                            "Include equipment you routinely use to improve your daily life. Check the drop-down menu for common home health aides.",
                    },
                    {
                        name: "HISTORY: Vaccinations",
                        path: "vaccinations",
                        data: vaccinationsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fhealth%2FVaccinations.jpg?alt=media&token=a429a2c0-6090-4646-8c2d-d8bf87d27d37",
                        imgAlt: "Vaccinations image",
                        summary:
                            "Include flu and other shots you get on a regular basis and non-routine shots.",
                        description:
                            "Include flu and other shots you get on a regular basis and non-routine shots (ex: Covid19, tetanus, or those needed for international travel). Check the drop-down menu for more common vaccinations and immunizations.",
                    },
                    {
                        name: "HISTORY: Medical Events",
                        path: "medicalHistory",
                        data: medicalHistoryData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fhealth%2FHistory-medical-events.jpg?alt=media&token=9bae89a1-c10a-47d7-9d4f-f00c393fedd7",
                        imgAlt: "Medical Events image",
                        summary:
                            "Record major accidents, significant illnesses, major surgeries, and chronic health conditions requiring continual attention.",
                        description:
                            "Record major accidents, significant illnesses, major surgeries, and chronic health conditions requiring continual attention (cancer, heart disease, diabetes, depression, etc.).",
                    },
                    {
                        name: "HISTORY: Family Illnesses",
                        path: "familyMedicalHistory",
                        data: familyMedicalHistoryData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fhealth%2FHistory-family-illness.jpg?alt=media&token=dd10742a-d19a-48ca-8385-0cd585ddbd75",
                        imgAlt: "Family Illnesses image",
                        summary:
                            "Identify illnesses and predispositions that may be inherited from one generation to the next within your family.",
                        description:
                            "Identify illnesses and predispositions that may be inherited from one generation to the next within your family. Adoptees should enter whatever information they have about their birth parents. Check the drop-down menu for common inheritable diseases.",
                    },
                ],
            },
            {
                name: "insurance branch",
                path: "insurance",
                icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/insurance/07-insurance-icon-white.svg",
                gIcon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/insurance/07-insurance-icon-green.svg",
                favoriteIcon:
                    "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/insurance/download-8.svg",
                image: {
                    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/insurance/B-06-Insurance2.png",
                    imgAlt: "insurance pic",
                },
                description:
                    "The Insurance branch allows you to enter credentials and other pertinent information about your insurance policies, including personal, health, property, and any social security or disability policies. Your loved ones will likely need access to these policies if you pass away or become disabled. Copies of all insurance policies and related documents can be uploaded for easier access and sharing.",
                topics: [
                    {
                        name: "Personal Insurance",
                        path: "personalInsurance",
                        data: personalInsuranceData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Finsurance%2Fpersonal-insurance.jpg?alt=media&token=8807df39-a873-45bc-8e8e-c071c47678e4",
                        imgAlt: "Personal Insurance image",
                        summary:
                            "Enter credentials and other information about your insurance policies, including life, health, property, and more.",
                        description:
                            "Enter credentials and other pertinent information about your insurance policies, including life, health, property, and any business insurance policies. Your loved ones will likely need access to these policies if you pass away or become disabled.",
                    },
                    {
                        name: "Property Insurance",
                        path: "propertyInsurance",
                        data: propertyInsuranceData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Finsurance%2Fproperty-insurance.jpg?alt=media&token=dc9261e2-adcf-4490-af2d-e00cf7a2a2e9",
                        imgAlt: "Property Insurance image",
                        summary:
                            "Here you can include homeowner’s, renter’s, flood, and earthquake insurance.",
                        description:
                            "A broad term for a series of policies providing either property protection coverage or liability coverage for property owners. Property insurance can include homeowner’s, renter’s, flood, and earthquake insurance. Personal property is usually covered by homeowner’s or renter’s insurance.",
                    },
                    {
                        name: "Private Health Insurance",
                        path: "privateHealthInsurance",
                        data: privateHealthInsuranceData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Finsurance%2FProvate-health-insurance.jpg?alt=media&token=97fdd5d8-d4a8-4166-9641-45036ee53bf4",
                        imgAlt: "Private Health Insurance image",
                        summary:
                            "Record Health insurance that covers medical expenses that arise due to illness or injury.",
                        description:
                            "Health insurance covers medical expenses that arise due to illness or injury. Such expenses include hospitalization, medications, doctor exams and lab fees. Private insurance is insurance provided by private companies such as Blue Cross, Anthem, etc.",
                    },
                    {
                        name: "Public Health Insurance",
                        path: "publicHealthInsurance",
                        data: publicHealthInsuranceData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Finsurance%2Fpublic-insurance.jpg?alt=media&token=a5f3e2ac-9f55-4c0b-a2d0-39fc0c3f70ff",
                        imgAlt: "Public Health Insurance image",
                        summary:
                            "Health insurance provided by government organizations such as Medicaid, Medicare and the Veterans Health Administration.",
                        description:
                            "Health insurance provided by government organizations such as Medicaid, Medicare and the Veterans Health Administration.",
                    },
                    {
                        name: "Social Security Disability (SSDI)",
                        path: "socialSecurityDisability",
                        data: socialSecurityDisabilityData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Finsurance%2Faocil-security-disability.jpg?alt=media&token=7d4d29f5-6723-4ce9-a1b7-69671615d883",
                        imgAlt: "Social Security Disability image",
                        summary:
                            "SSDI provides health insurance services if you are disabled.",
                        description:
                            "SSDI provides health insurance services if you are disabled. To qualify for SSDI benefits you must have worked a specified amount of time in jobs covered by Social Security and have a medical condition that meets the Social Security Administration's disability criteria.",
                    },
                    {
                        name: "Long-term Care Insurance",
                        path: "longTermCareInsurance",
                        data: longTermCareInsuranceData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Finsurance%2FLong-term-care-insurance.jpg?alt=media&token=71afade4-6986-4b7b-a4ec-284156e5f14a",
                        imgAlt: "Long-term Care Insurance image",
                        summary:
                            "Enter the information that describes your coverage for medical care for later in life.",
                        description:
                            "Enter the information that describes your coverage for medical care for later in life.",
                    },
                ],
            },
            {
                name: "legal issues branch",
                path: "legal-issues",
                icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/legal-issues/09-legal-issues-icon-white.svg",
                gIcon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/legal-issues/09-legal-issues-icon-green.svg",
                favoriteIcon:
                    "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/legal-issues/download-9.svg",
                image: {
                    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/legal-issues/B-08-LegalIssues2.png",
                    imgAlt: "legal issues pic",
                },
                description:
                    "In this branch you can record and share important information about your legal affairs. These matters include current legal actions that involve you or a legal entity you are part of. Legal events such as bankruptcy filings and outstanding lawsuits can be recorded here.",
                topics: [
                    {
                        name: "Outstanding Law Suits",
                        path: "outstandingLawsuits",
                        data: outstandingLawsuitsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Flegal-issues%2FOutstanding-lawsuits.jpg?alt=media&token=f8e7030b-f420-4972-aea1-0efe3cbdacf7",
                        imgAlt: "Outstanding Law Suits image",
                        summary:
                            "Record any current / outstanding lawsuits filed by or against you.",
                        description:
                            "Record any current / outstanding lawsuits filed by or against you, including brief descriptions of each case, when and where the actions were filed, and contact information for the attorney representing you.",
                    },
                    {
                        name: "Bankruptcy Filings",
                        path: "bankruptcyFilings",
                        data: bankruptcyFilingsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Flegal-issues%2Fbankruptsy-filings.jpg?alt=media&token=6d6ca63e-5617-40f3-97a0-f441996d86f6",
                        imgAlt: "Bankruptcy Filings image",
                        summary:
                            "If you or your business have a current bankruptcy case, this information should be recorded.",
                        description:
                            "Bankruptcy is a legal process that helps people who can’t repay their debts to creditors by either liquidating assets to pay them or creating a repayment plan. If you or your business have a current bankruptcy case, this information should be recorded.",
                    },
                ],
            },
            {
                name: "real estate branch",
                path: "real-estate",
                icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/real-estate/10-real-estate-icon-white.svg",
                gIcon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/real-estate/10-real-estate-icon-green.svg",
                favoriteIcon:
                    "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/real-estate/download-10.svg",
                image: {
                    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/everyday/real-estate/B-09-RealEstate2.png",
                    imgAlt: "real estate pics",
                },
                description:
                    "Complete, accurate and current information on the properties you own such as utility providers and security systems/services should be shared with Inner Circle who may need it to address any property-related issues that arise. Check the drop-down menu for various types of real estate.",
                topics: [
                    {
                        name: "Real Estate Holdings",
                        path: "realEstateHoldings",
                        data: realEstateHoldingsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Freal-estate%2Freal-estate-holdings.jpg?alt=media&token=420bd574-4dcd-4e62-9ac0-2639f72ec673",
                        imgAlt: "Real Estate Holdings image",
                        summary:
                            "Record complete, accurate and current information on the properties you own to be shared with your inner circle of friend or family.",
                        description:
                            "Complete, accurate and current information on the properties you own such as utility providers and security systems/services should be shared with Inner Circle who may need it to address any property-related issues that arise. Check the drop-down menu for various types of real estate. ",
                    },
                    {
                        name: "Deeds",
                        path: "deedsOnMyProperties",
                        data: deedsOnMyPropertiesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Freal-estate%2Fdeeds.jpg?alt=media&token=8b5e7a49-317c-4adf-823e-e347c71da9d7",
                        imgAlt: "Deeds image",
                        summary:
                            "Upload copies filed in the county Registrar/Recorder Office by the owner or mortgage lender.",
                        description:
                            "Title deeds are legal documents that record your ownership of a property and any accompanying land. They’re typically filed in the county Registrar/Recorder Office by the owner or mortgage lender.",
                    },
                    {
                        name: "Lease Agreements",
                        path: "leaseAgreements",
                        data: leaseAgreementsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Freal-estate%2Flease-agreement.jpg?alt=media&token=457c0242-dc1c-44a0-81a6-3b366e631916",
                        imgAlt: "Lease Agreements image",
                        summary:
                            "Record any agreements for leasing commercial or residential real estate properties such as apartments.",
                        description:
                            "A lease agreement is an arrangement made between two parties allowing one of those parties to use an asset belonging to its owner. Common contracts include agreements for leasing commercial or residential real estate properties such as apartments.",
                    },
                    {
                        name: "Storage Rental Unit(s)",
                        path: "storageRentalUnit",
                        data: storageRentalUnitData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Freal-estate%2Fstorage-rental.jpg?alt=media&token=eedf2ae0-5d19-4933-adb0-fe39a1a65c2d",
                        imgAlt: "Storage Rental Unit(s) image",
                        summary:
                            "List all details about all current storage spaces that you are renting.",
                        description:
                            "List all details about all current storage spaces that you are renting.",
                    },
                    {
                        name: "Security Service",
                        path: "propertySecurityService",
                        data: propertySecurityServiceData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Freal-estate%2Fsecurity-service.jpg?alt=media&token=ae6f7d71-53f0-4dba-a72b-45e145c3b5cd",
                        imgAlt: "Security Service image",
                        summary:
                            "List any residential security alarm systems, those connected to an alarm monitoring service, or property patrols.",
                        description:
                            "This includes freestanding residential security alarm systems, those connected to an alarm monitoring service, or mobile property patrols.",
                    },
                    {
                        name: "Home Safes",
                        path: "homeSafes",
                        data: homeSafesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Freal-estate%2Fhome-safe.jpg?alt=media&token=9e713001-804d-480e-90b5-f47d2e4e80a0",
                        imgAlt: "Home Safes image",
                        summary:
                            "Enter details on safes that you have on your property.",
                        description:
                            "Enter details on safes that you have on your property.",
                    },
                    {
                        name: "Extra Keys",
                        path: "extraPropertyKeys",
                        data: extraPropertyKeysData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Freal-estate%2Fextra-keys.jpg?alt=media&token=6dc510ca-a2df-4060-b5f4-4386d650e80c",
                        imgAlt: "Extra Keys image",
                        summary:
                            "In an emergency, it is important for your loved ones to know the locations of your extra keys for your property.",
                        description:
                            "In an emergency, it is important for your loved ones to know the locations of your extra keys for your property. Enter details for them here.",
                    },
                ],
            },
        ],
    },
    {
        name: "EVENTUAL",
        path: "eventual",
        subBranches: [
            {
                name: "estate plans branch",
                path: "estate-plans",
                icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/eventual/estate-plans/01-estate-plans-icon-white.svg",
                gIcon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/eventual/estate-plans/01-estate-plans-icon-green.svg",
                favoriteIcon:
                    "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/eventual/estate-plans/download-11.svg",
                image: {
                    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/eventual/estate-plans/C-01-EstatePlans2.png",
                    imgAlt: "estate plans pic",
                },
                description:
                    "This branch holds your collection of documents that define how you want your affairs and assets managed when you pass away or can no longer manage these matters. It can be a simple will or a more complex set of documents. My Banyan provides tools to record and share this important information.",
                pdf: {
                    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/estate-planning-101.png",
                    imgAlt: "Estate Planning 101 Checklist",
                    link: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/Estate+Planning+101.pdf",
                },
                topics: [
                    {
                        name: "WILLS: Last Will & Testaments",
                        path: "lastWillTestaments",
                        data: lastWillTestamentsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Festate-plans%2FWills-Last-will-testaments.jpg?alt=media&token=5f9ef7af-3634-4cf4-be2c-2614657fde3a",
                        imgAlt: "Last Will & Testaments image",
                        summary:
                            "Your final wishes and instructions for the administration of your affairs to the probate court.",
                        description:
                            "This communicates your final wishes and instructions for the administration of your affairs to the probate court. Probate courts will use your last will and testament to finalize your estate. While you have the flexibility to edit or delete the existing record, there is no option available to add another entry.",
                    },
                    {
                        name: "WILLS: Personal Letter of Intent",
                        path: "personalLetterOfIntents",
                        data: personalLetterOfIntentsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Festate-plans%2FWills-personal-letter-of-intent.jpg?alt=media&token=1a67a663-5a4f-4bce-b7e8-eac9743c00c2",
                        imgAlt: "Personal Letter of Intent image",
                        summary:
                            "An informal letter you write to loved ones or the executor of your will.",
                        description:
                            "An informal letter you write to loved ones or the executor of your will. It can include hopes and wishes for loved ones, a list of all your assets, and your rationale for their distribution to beneficiaries.",
                    },
                    {
                        name: "TRUSTS: Revocable Living Trust",
                        path: "revocableLivingTrust",
                        data: revocableLivingTrustData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Festate-plans%2FTrusts-revocable-living-trust.jpg?alt=media&token=84937619-6e7e-4063-be88-1e060c8c4626",
                        imgAlt: "Revocable Living Trust image",
                        summary:
                            "A statement of how your assets are to be distributed on your passing.",
                        description:
                            "A statement of how your assets are to be distributed on your passing. You can modify it at any point during your lifetime. All your assets should be in the trust. The value of this trust is that it avoids probate court and can be implemented immediately.",
                    },
                    {
                        name: "TRUSTS: Irrevocable Trusts",
                        path: "irrevocableTrusts",
                        data: irrevocableTrustsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Festate-plans%2FTRUSTS-Irrevocable-Trusts.jpg?alt=media&token=33fba3ea-2d33-44f7-8f39-80d71301fe3b",
                        imgAlt: "Irrevocable Trusts image",
                        summary:
                            "This protects your assets, avoids probate, and reduces estate taxes. Generally, it cannot be modified while you are alive.",
                        description:
                            "This protects your assets, avoids probate, and reduces estate taxes. Generally, it cannot be modified while you are alive. These trusts are excellent shields from lawsuits, and each can have unique provisions to achieve a specific financial goal.",
                    },
                    {
                        name: "DESIGNATION: Joint Ownerships",
                        path: "jointOwnerships",
                        data: jointOwnershipsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Festate-plans%2FDesignation-joint-ownership.jpg?alt=media&token=b8727fb9-5bf0-46da-86ac-e59deee1ebcd",
                        imgAlt: "Joint Ownerships image",
                        summary:
                            "Jointly owned property or asset is held in the name of two or more persons. Joint ownership overrides a Will.",
                        description:
                            "Jointly owned property or asset is held in the name of two or more persons. Joint ownership overrides a Will. It also avoids probate.",
                    },
                    {
                        name: "TOD: Transfer on Death Accounts",
                        path: "todAccounts",
                        data: todAccountsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Festate-plans%2FTOD-Transfer-on-Death-accounts.jpg?alt=media&token=e1376cbf-4ff3-4574-8392-444ec85fa9c3",
                        imgAlt: "Transfer on Death Accounts image",
                        summary:
                            "This transfers certain financial assets such as brokerage accounts, stocks, and bonds upon your death.",
                        description:
                            "This transfers certain financial assets such as brokerage accounts, stocks, and bonds upon your death. The name of this deed may vary from state to state.",
                    },
                    {
                        name: "TOD: Transfer on Death Deed",
                        path: "todDeeds",
                        data: todDeedsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Festate-plans%2FTOD-Transfer-on-Death-Deed.jpg?alt=media&token=9e6bb70b-fb66-4996-84e9-a3744c8ac30e",
                        imgAlt: "Transfer on Death Deed image",
                        summary:
                            "Ensuring the seamless transfer of assets to named beneficiaries upon the property owner's passing, all without the complexities of probate.",
                        description: `This topic is a valuable resource for ensuring the seamless transfer of assets to named beneficiaries upon the property owner's passing, all without the complexities of probate. This tool offers a user-friendly form that simplifies the process, featuring essential fields such as property details, individuals holding copies of the document, beneficiary information, notarization dates, witness records, and space for important notes. Moreover, the capability to upload a scanned version of the Deed guarantees secure storage and easy access to this critical document, ultimately facilitating the executor's efficient distribution of assets in accordance with the property owner's wishes.`,
                    },
                    {
                        name: "POD: Pay on Death Account",
                        path: "podAccounts",
                        data: podAccountsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Festate-plans%2FPOD-Pay-on-Death-Account.jpg?alt=media&token=3c0cb63f-6382-4096-a5d7-f160a9a5b868",
                        imgAlt: "Pay on Death Account image",
                        summary:
                            "This conveys your bank assets (checking, savings, etc.) to designated beneficiaries upon your death.",
                        description:
                            "This conveys your bank assets (checking, savings, etc.) to designated beneficiaries upon your death. Check the latest laws in your state for updates on TOD accounts.",
                    },
                    {
                        name: "GIFTING: To Individuals",
                        path: "giftingToIndividuals",
                        data: giftingToIndividualsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Festate-plans%2FGifting-to-individuals.jpg?alt=media&token=be6a545e-7dbd-43a2-b5ab-08851ced63f6",
                        imgAlt: "GIFTING: To Individuals image",
                        summary:
                            "A common part of an estate plan is to gift (or “give away”) assets to people during your lifetime for tax advantages.",
                        description:
                            "A common part of an estate plan is to gift (or “give away”) assets to people during your lifetime. This provides tax advantages and the emotional satisfaction of supporting worthy causes. Gifts can be financial assets or real property. Strict government requirements must be met to ensure gifts qualify for tax benefits.",
                    },
                    {
                        name: "GIFTING: To Organizations",
                        path: "giftingToOrganizations",
                        data: giftingToOrganizationsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Festate-plans%2Fgifting-to-organizations.jpg?alt=media&token=144f784d-3e05-493a-9d8e-d9a5cb7e6dbc",
                        imgAlt: "GIFTING: To Organizations image",
                        summary:
                            "A common part of an estate plan is to gift (or “give away”) assets to organizations during your lifetime. This provides tax advantages.",
                        description:
                            "A common part of an estate plan is to gift (or “give away”) assets to organizations during your lifetime. This provides tax advantages and the emotional satisfaction of supporting worthy causes. Gifts can be financial assets or real property. Strict government requirements must be met to ensure gifts qualify for tax benefits.",
                    },
                    {
                        name: "POA: Durable Powers of Attorney",
                        path: "durablePoa",
                        data: durablePoaData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Festate-plans%2FPOA-durable-powers-of-attorney.jpg?alt=media&token=69100d73-8a7e-4774-a154-c3187b586c80",
                        imgAlt: "Durable Powers of Attorney image",
                        summary:
                            "This authorizes a person as your agent to manage your financial affairs when you become unable or unwilling to manage them yourself.",
                        description:
                            "This authorizes a person as your agent to manage your financial affairs when you become unable or unwilling to manage them yourself. POAs stay in effect until you die.",
                    },
                    {
                        name: "POA: General Powers of Attorney",
                        path: "generalPoa",
                        data: generalPoaData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Festate-plans%2FPOA-General-powers-of-attorney.jpg?alt=media&token=f1572ca2-aaa0-4f59-9210-8d72fb707320",
                        imgAlt: "General Powers of Attorney image",
                        summary:
                            "This allows your agent to manage your legal, business, and financial matters while you are alive and not incapacitated.",
                        description:
                            "This allows your agent to manage your legal, business, and financial matters while you are alive and not incapacitated. Your agent can buy/sell real estate, sign contracts, and access bank accounts on your behalf. You state these conditions in the POA document.",
                    },
                ],
            },
            {
                name: "senior life plans branch",
                path: "senior-life-plans",
                icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/eventual/senior-life-plans/02-senior-life-plans-icon-white.svg",
                gIcon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/eventual/senior-life-plans/02-senior-life-plans-icon-green.svg",
                favoriteIcon:
                    "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/eventual/senior-life-plans/download-12.svg",
                image: {
                    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/eventual/senior-life-plans/senior-life-plans-banner.jpg",
                    imgAlt: "senior life plans pic",
                },
                description:
                    "This branch provides you with a place to describe about how you want to spend your later years. My Banyan offers helpful checklists and other tools for thinking about your senior years. It covers activities you might like to pursue, residences for you to consider, and resources for planning and financing your future.",
                pdfs: [
                    {
                        img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/relocation-list-7-10.png",
                        imgAlt: "Relocation Checklist 7 to 10 Days",
                        link: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/RELOCATION+CHECKLIST-+7-10+DAYS.pdf",
                    },
                    {
                        img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/relocation-1-2.png",
                        imgAlt: "Relocation Checklist 1 to 2 Months",
                        link: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/RELOCATION+CHECKLIST-+1-2+MONTHS.pdf",
                    },
                    {
                        img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/relocation-list-9-12.png",
                        imgAlt: "Relocation Checklist 9 to 12 Months",
                        link: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/RELOCATION+CHECKLIST-+9-12+MONTHS.pdf",
                    },
                    {
                        img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/retirement-checklist.png",
                        imgAlt: "Retirement Checklist",
                        link: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/RETIREMENT+CHECKLIST-+3-5+YEARS+BEFORE.pdf",
                    },
                ],
                topics: [
                    {
                        name: "GENERAL: How I See My Senior Life",
                        path: "howISeeMySeniorLife",
                        data: howISeeMySeniorLifeData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fsenior-life-plans%2Fhow-I-see-my-senior-life.jpg?alt=media&token=70dd04b2-cf7c-4cb6-913e-db335714b6b5",
                        imgAlt: "How I See My Senior Life image",
                        summary:
                            "Take some time to think about how you feel about entering your senior life. What are your hopes and fears? Make notes in this topic.",
                        description:
                            "Take some time to think about how you feel about entering your senior life. What are your hopes and fears? Make notes in this topic. Share them with your loved ones.",
                    },
                    {
                        name: "FINANCE: Self-funding",
                        path: "selfFundingHealthCare",
                        data: selfFundingHealthCareData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fsenior-life-plans%2FFinance-self-funding.jpg?alt=media&token=7dbe021c-9bc9-429e-951b-ed974e114f48",
                        imgAlt: "Self-funding image",
                        summary:
                            "Self-funding your long-term care. Write down your plan here.",
                        description:
                            "Self-funding your long-term care can leave you financially vulnerable to potentially large, open-ended costs. Plan to have at least two years’ worth of care covered.",
                    },
                    {
                        name: "FINANCE: Supplemental Security (SSI)",
                        path: "supplementalSecurity(SSI)",
                        data: additionalSecurityIncomeData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fsenior-life-plans%2Ffinancial-supplemental-security.jpg?alt=media&token=0959eac0-1195-443e-ab46-d421127a2dcd",
                        imgAlt: "Supplemental Security image",
                        summary:
                            "Make notes of your application, local SSA phone numbers, coverage limits and more.",
                        description:
                            "A system of monthly payments made by the federal government if you are over 65 with low income, or if you are of any age and are blind or disabled. The Social Security Administration (SSA) administers the SSI program.",
                    },
                    {
                        name: "FINANCE: Other Finance Sources",
                        path: "otherFinanceSources",
                        data: otherFinanceSourcesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fsenior-life-plans%2FFinance-other-financial-sources.jpg?alt=media&token=0eeae783-4012-4326-babe-4b7251b12d2e",
                        imgAlt: "Other Finance Sources image",
                        summary:
                            "Meticulously record the source and value of these financial assets.",
                        description:
                            "Meticulously record the source and value of these financial assets. Additionally, you can add essential notes and specify the location of any related files or documents, ensuring all your financial records are organized and easily accessible. Plus, for added convenience, you have the option to upload pictures of relevant documents, making financial planning and management a breeze.",
                    },
                    {
                        name: "LIVING OPTION 1: Current Residence",
                        path: "currentResidence",
                        data: currentResidenceData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fsenior-life-plans%2Fliving-option-1-current-residence.jpg?alt=media&token=eb4a2dd5-0203-4ff9-8e01-8ef1b97fadf5",
                        imgAlt: "Current Residence image",
                        summary:
                            "It’s the choice of 75% of adults, so it makes sense if it’s yours, too. Make notes about your property here.",
                        description:
                            "It’s the choice of 75% of adults, so it makes sense if it’s yours, too. But you should also consider alternatives should life take you in a different direction.",
                    },
                    {
                        name: "LIVING OPTION 2: Home of Another Person",
                        path: "homeOfAnotherPerson",
                        data: homeOfAnotherPersonData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fsenior-life-plans%2Fliving-option-2-home-of-another-person.jpg?alt=media&token=215c874f-59ab-4efb-8b4e-4d496d5dc744",
                        imgAlt: "Home of Another Person image",
                        summary:
                            "Living with a family member is a possibility, especially in your later years. Make notes of the homeowner, possible equipment needed and more.",
                        description:
                            "Living with a family member is a possibility, especially in your later years. It usually requires that your relatives provide separate quarters such as a backhouse or separate bedroom and bath.",
                    },
                    {
                        name: "LIVING OPTION 3: Independent Living",
                        path: "independentLiving",
                        data: independentLivingData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fsenior-life-plans%2Fliving-option-3-independent-living.jpg?alt=media&token=fffa844c-c86a-4e34-bbcc-3e25b527e72d",
                        imgAlt: "Independent Living image",
                        summary:
                            "Such as a 55+ retirement community or residence with access to medical care. Make detailed note about it here.",
                        description:
                            "This commonly means a 55+ retirement community with a rich social environment. It can also refer to a residence where people live independently but have access to medical care or assistance with daily living.",
                    },
                    {
                        name: "LIVING OPTION 4: Assisted Living / Nursing",
                        path: "assistedLivingNursing",
                        data: assistedLivingNursingData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fsenior-life-plans%2Fliving-option-4-assisted-living.jpg?alt=media&token=e7e7ac35-1ad0-4c09-9dc8-a24e219a21ef",
                        imgAlt: "Assisted Living / Nursing image",
                        summary:
                            "Make notes about a facility with a nursing home component and preferred room accommodations and level of care.",
                        description:
                            "These residential facilities with a home-like setting that promote independence and social engagement. You can live in an individual room, apartment, or shared quarters. A facility with a nursing home component is ideal.",
                    },
                    {
                        name: "CARE PROVIDERS: Friends and Family",
                        path: "careProvidersFriendsFamily",
                        data: careProvidersFriendsFamilyData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fsenior-life-plans%2Fcare-provider-friends-and-family.jpg?alt=media&token=2c0867f7-ca70-49ea-8305-35dc1d74887d",
                        imgAlt: "Friends and Family image",
                        summary:
                            "Make a list of friends and family members who may be willing to provide you with either occasional or daily living care.",
                        description:
                            "Make a list of friends and family members who may be willing to provide you with either occasional or daily living care. Some states have programs that pay family members for providing care.",
                    },
                    {
                        name: "CARE PROVIDERS: Professional Services",
                        path: "professionalCareProviders",
                        data: professionalCareProvidersData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fsenior-life-plans%2Fcare-provider-professional-services.jpg?alt=media&token=9852f30e-1d4c-405f-bd63-99ad632da850",
                        imgAlt: "Professional Services image",
                        summary:
                            "Make notes about registered and independent care providers, skilled in-home nursing professionals and more.",
                        description:
                            "These include registered and independent care providers, skilled in-home nursing professionals and various community service and support agencies. Each offers different services, and fees vary significantly based on your choice.",
                    },
                    {
                        name: "CARE PROVIDERS: Community Agencies",
                        path: "communityAgencies",
                        data: communityAgenciesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Fsenior-life-plans%2Fcare-provider-community-agencies.jpg?alt=media&token=07beaeb6-9d0a-442c-9dbb-5f87ff80d79d",
                        imgAlt: "Community Agencies image",
                        summary:
                            "Make notes about meal delivery, ride sharing, senior center events, and short-term counseling if you chose to stay at home.",
                        description:
                            "Your local community can often provide meal delivery, ride sharing, senior center events, and short-term counseling if you chose to stay at home. They’re sponsored by the city, service clubs, religious groups, or private groups.",
                    },
                ],
            },
            {
                name: "legacy wishes branch",
                path: "legacy-wishes",
                icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/eventual/legacy-wishes/03-legacy-wishes-icon-white.svg",
                gIcon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/eventual/legacy-wishes/03-legacy-wishes-icon-green.svg",
                favoriteIcon:
                    "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/eventual/legacy-wishes/download-13.svg",
                image: {
                    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/eventual/legacy-wishes/C-03-LegacyWishes2.png",
                    imgAlt: "legacy wishes pic",
                },
                description:
                    "The Legacy Wishes branch enables you to communicate your legacy wishes to your loved ones in a clear and effective manner. You can identify charities that you have selected to receive your financial support. Important life memories that you want to pass on to your loved ones can be organized and uploaded for future generations of your family and loved ones to enjoy.",
                topics: [
                    {
                        name: "Legacy Statements",
                        path: "legacyStatements",
                        data: legacyStatementsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Flegacy-wishes%2Flegacy-statement.jpg?alt=media&token=69e8ea10-4137-412c-9d30-73d9c3ca81fa",
                        imgAlt: "Legacy Statements image",
                        summary:
                            "You can record your legacy wishes for specific individuals or groups.",
                        description:
                            "You can record your legacy wishes for specific individuals or groups.",
                    },
                    {
                        name: "Values That I Hope to Pass On",
                        path: "valuesThatIHopeToPassOn",
                        data: valuesThatIHopeToPassOnData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Flegacy-wishes%2Fvalues-i-hope-to-pass-on.jpg?alt=media&token=45551750-6f37-4473-a530-68a35ec5ad7c",
                        imgAlt: "Values That I Hope to Pass On image",
                        summary:
                            "Share the most important values you’ve lived your life by. Record them here.",
                        description:
                            "What are the most important values you’ve lived your life by and want to share with loved ones? Record them here.",
                    },
                    {
                        name: "Charities / Foundations I Supported",
                        path: "supportedGroup",
                        data: supportedGroupData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Flegacy-wishes%2Fcharities-i-support.jpg?alt=media&token=3e5da624-bb42-4a0f-add1-8ba94ef7f00e",
                        imgAlt: "Charities / Foundations I Supported image",
                        summary:
                            "Record any support for local or national philanthropic organizations or service groups.",
                        description:
                            "Do you regularly support local or national philanthropic organizations or service groups? These can be nonprofits or private foundations that provide charitable services. Record your intentions and aspirations for any donations that you may make or other support you want to provide.",
                    },
                    {
                        name: "My Collections of Memories",
                        path: "recordedMemories",
                        data: recordedMemoriesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Flegacy-wishes%2Fcollection-of-memories.jpg?alt=media&token=dbbd1777-4e2e-4bfc-9360-1f16f6770663",
                        imgAlt: "My Collections of Memories image",
                        summary:
                            "One of the most valuable gifts you can give your loved ones is a history of your life highlights.",
                        description:
                            "One of the most valuable gifts you can give your loved ones is a history of your life highlights. Here you can identify audio, photo, and video collections and any software programs you use to manage them.",
                    },
                ],
            },
            {
                name: "farewell plans branch",
                path: "farewell-plans",
                icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/eventual/farewell-plans/04-farewell-plans-icon-white.svg",
                gIcon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/eventual/farewell-plans/04-farewell-plans-icon-green.svg",
                favoriteIcon:
                    "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/eventual/farewell-plans/download-14.svg",
                image: {
                    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/branches/eventual/farewell-plans/C-04-Farewell-Plans2.png",
                    imgAlt: "farewell plans pic",
                },
                description:
                    "The Farewell Plans branch digitally captures your wishes and the professionals, companies, music, and people you want to handle your ceremony after your passing. Your explicit directions will relieve considerable stress on your loved ones by removing any guesswork about your wishes.",
                pdf: {
                    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/coordinating-farewell.png",
                    imgAlt: "Coordinating a Farewell Ceremony Checklist",
                    link: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/checklists/COORDINATING+A+FAREWELL+CEREMONY+.pdf",
                },
                topics: [
                    {
                        name: "GENERAL: Disposition of My Body",
                        path: "dispositionOfMyBodies",
                        data: dispositionOfMyBodiesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffarewell-plans%2FDisposition-of-my-body.jpg?alt=media&token=e3cb8696-fd70-4f9c-bee5-a135c5b917a8",
                        imgAlt: "Disposition of My Body image",
                        summary:
                            "Make known how you would like your remains to be handled after your passing.",
                        description:
                            "Make known how you would like your remains to be handled after your passing.",
                    },
                    {
                        name: "GENERAL: Death Certificate Originals",
                        path: "deathCertificateOriginals",
                        data: deathCertificateOriginalsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffarewell-plans%2Fdeath-certificate.jpg?alt=media&token=260814ac-7f01-4729-99e3-1f620ff51aac",
                        imgAlt: "Death Certificate Originals image",
                        summary:
                            "Record the location where original death certificates are kept.",
                        description:
                            "Record the location where original death certificates are kept. You need to acquire 15 original copies of the death certificate.",
                    },
                    {
                        name: "GENERAL: Ceremonial Preferences",
                        path: "ceremonialPreferences",
                        data: ceremonialPreferencesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffarewell-plans%2FCeremonial-preferences.jpg?alt=media&token=80211362-e6b6-4019-8fe4-5e61fa71e021",
                        imgAlt: "Ceremonial Preferences image",
                        summary:
                            "Select the details of your farewell ceremony that you would like, such as a Memorial, Celebration of Life, Military Funeral etc.",
                        description:
                            "Select the details of your farewell ceremony that you would like.",
                    },
                    {
                        name: "GENERAL: Burial Plot Location",
                        path: "burialPlotLocations",
                        data: burialPlotLocationsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffarewell-plans%2FBurial-Plot-Location.jpg?alt=media&token=fbd8df13-e935-4c07-ad1c-8d9489946ec3",
                        imgAlt: "Burial Plot Location image",
                        summary:
                            "Provide details of a pre-arranged or desired place of burial for your remains.",
                        description:
                            "Provide details of a pre-arranged or desired place of burial for your remains.",
                    },
                    {
                        name: "GENERAL: Grave Marker Inscription",
                        path: "graveMarkerInscriptions",
                        data: graveMarkerInscriptionsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffarewell-plans%2FGrave-marker-inscription.jpg?alt=media&token=c8c97b6a-9103-47af-98e3-f36a76832fac",
                        imgAlt: "Grave Marker Inscription image",
                        summary:
                            "You can provide the grave marker and inscription you’d like.",
                        description:
                            "You can provide the grave marker and inscription you’d like, and any related contact information.",
                    },
                    {
                        name: "GENERAL: Cremation Authorizations",
                        path: "cremationAuthorizations",
                        data: cremationAuthorizationsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffarewell-plans%2FCremation-authorizations.jpg?alt=media&token=09c90d71-fa2b-423b-90fc-bd26e59705da",
                        imgAlt: "Cremation Authorizations image",
                        summary:
                            "If you plan to be cremated, you’ll need an advance authorization for cremation.",
                        description:
                            "If you plan to be cremated, you’ll need an advance authorization for cremation. The form must be signed either by you prior to death or by your legal next of kin after your death.",
                    },
                    {
                        name: "GENERAL: Obituary Contents",
                        path: "obituaryContents",
                        data: obituaryContentsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffarewell-plans%2FOrbituary-contents.jpg?alt=media&token=184c5ccf-8359-48c4-9907-57ae37604f81",
                        imgAlt: "Obituary Contents image",
                        summary:
                            "Share stories and facts you would like included in your obituary.",
                        description:
                            "Share stories and facts you would like included in your obituary.",
                    },
                    {
                        name: "GENERAL: Funeral Home Director",
                        path: "funeralHomeDirectors",
                        data: funeralHomeDirectorsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffarewell-plans%2FFuneral-home-director.jpg?alt=media&token=998f5423-9a10-44cc-8f21-4f8f5e7f37b0",
                        imgAlt: "Funeral Home Director image",
                        summary:
                            "Record contact information for the funeral home you have chosen.",
                        description:
                            "Record contact information for the funeral home you have chosen.",
                    },
                    {
                        name: "GENERAL: Funeral Notices",
                        path: "obituaryFuneralNotices",
                        data: obituaryFuneralNoticesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffarewell-plans%2FFuneral-notices.jpg?alt=media&token=13167f2a-8e89-419c-8f3a-d0632f0cc1e9",
                        imgAlt: "Funeral Notices image",
                        summary:
                            "List any newspapers, social media, or email lists important to you.",
                        description:
                            "Where would you like your obituary/funeral notice to be noted? List any newspapers, social media, or email lists important to you.",
                    },
                    {
                        name: "FINANCE: Insurance Policy",
                        path: "insuranceBenefits",
                        data: insuranceBenefitsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffarewell-plans%2FInsurance-policy.jpg?alt=media&token=0fabecaa-b912-420a-81cf-11ad174c9445",
                        imgAlt: "Insurance Policy image",
                        summary:
                            "Provide an overview of your funeral insurance benefits, the insurance carrier, the coverage and benefits, and where the policy is located.",
                        description:
                            "If you have funeral insurance, provide an overview of your funeral insurance benefits, the insurance carrier, the coverage and benefits, and where the policy is located.",
                    },
                    {
                        name: "FINANCE: Pre-Payments",
                        path: "prePayments",
                        data: prePaymentsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffarewell-plans%2Fpre-payments.jpg?alt=media&token=a990ec23-64d2-4988-bdb6-b42114341910",
                        imgAlt: "Pre-Payments image",
                        summary:
                            "If you prepaid any of your anticipated funeral expenses, provide a record of who paid whom for what service.",
                        description:
                            "If you prepaid any of your anticipated funeral expenses, provide a record of who paid whom for what service. Recording and sharing this information can prevent double payment by the people you’ve entrusted with management of your funeral affairs.",
                    },
                    {
                        name: "FINANCE: Estimated Expenses",
                        path: "estimatedExpenses",
                        data: estimatedExpensesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffarewell-plans%2FEstimated-Expenses.jpg?alt=media&token=c576bc83-7c35-4158-9a97-73dba8fa1435",
                        imgAlt: "Estimated Expenses image",
                        summary:
                            "Here you can estimate the cost of your funeral services for budgeting purposes.",
                        description:
                            "Here you can estimate the cost of your funeral services for budgeting purposes.",
                    },
                    {
                        name: "CEREMONY: Location",
                        path: "ceremonyLocations",
                        data: ceremonyLocationsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffarewell-plans%2Fceremony-location.jpg?alt=media&token=785fbc94-a446-43a4-bee8-9126210a7f7d",
                        imgAlt: "CEREMONY: Location image",
                        summary:
                            "If you’ve chosen and arranged for a specific location, provide site contact information.",
                        description:
                            "If you’ve chosen and arranged for a specific location, provide site contact information.",
                    },
                    {
                        name: "CEREMONY: Leaders",
                        path: "ceremonyLeaders",
                        data: ceremonyLeadersData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffarewell-plans%2FCeremony-leader.jpg?alt=media&token=24a84473-a145-4bb3-a71a-debc66321704",
                        imgAlt: "CEREMONY: Leaders image",
                        summary:
                            "Share the name and contact information of the person or persons you’ve chosen to lead your memorial service.",
                        description:
                            "Share the name and contact information of the person or persons you’ve chosen to lead your memorial service.",
                    },
                    {
                        name: "CEREMONY: Service Invitation List",
                        path: "serviceInvitationLists",
                        data: serviceInvitationListsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffarewell-plans%2FService-invitation-list.jpg?alt=media&token=8ab1720d-0361-48c4-9eb1-d60adddaab02",
                        imgAlt: "CEREMONY: Service Invitation List image",
                        summary:
                            "Think who you want to be at your farewell ceremony. Create this list with a loved one.",
                        description:
                            "Think who you want to be at your farewell ceremony. Create this list with a loved one. Enter that person's name in this topic.",
                    },
                    {
                        name: "CEREMONY: Donations",
                        path: "donations",
                        data: donationsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffarewell-plans%2FCeremony-donations.jpg?alt=media&token=2c598751-7775-4537-8e9d-e38d1e1101bb",
                        imgAlt: "CEREMONY: Donations image",
                        summary:
                            "If you’d like friends and family to make charitable donations in your memory, provide that information.",
                        description:
                            "If you’d like friends and family to make charitable donations in your memory, provide that information.",
                    },
                    {
                        name: "CEREMONY: Florists",
                        path: "florists",
                        data: floristsData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffarewell-plans%2FCeremony-florist.jpg?alt=media&token=d0b5c85b-a9d6-4a0c-b15e-050b52b77d13",
                        imgAlt: "CEREMONY: Florists image",
                        summary:
                            "If you’ve chosen a florist, provide the contact information here.",
                        description:
                            "If you’ve chosen a florist, provide the contact information here.",
                    },
                    {
                        name: "CEREMONY: Music",
                        path: "music",
                        data: musicData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffarewell-plans%2FCeremony-music.jpg?alt=media&token=54d9c3a9-6783-4220-b6ec-90768b27df5a",
                        imgAlt: "CEREMONY: Music image",
                        summary:
                            "List the songs and artists of the music you’d like played at your service.",
                        description:
                            "List the songs and artists of the music you’d like played at your service.",
                    },
                    {
                        name: "CEREMONY: Transportation Services",
                        path: "transportationServices",
                        data: transportationServicesData,
                        img: "https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fsub-branches%2Ffarewell-plans%2FCeremony-transportation-services.jpg?alt=media&token=bb6b94ce-88dd-478d-83aa-2d4a2c3c23b3",
                        imgAlt: "CEREMONY: Transportation Services image",
                        summary:
                            "If you’ve arranged funeral transportation services, provide the contact information here.",
                        description:
                            "If you’ve arranged funeral transportation services, provide the contact information here.",
                    },
                ],
            },
        ],
    },
];

export const sidebarNavigation = [
    {
        name: "Dashboard",
        href: "/",
        icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/01-dashboard-icon-white.svg",
        current: true,
        hide: false,
    },
    {
        name: "About Me",
        href: "/about-me",
        icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/02-about-me-icon-white.svg",
        current: true,
        hide: false,
    },
    {
        name: "Everyday",
        href: "/everyday",
        icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/03-my-everyday-icon-white.svg",
        current: true,
        hide: false,
    },
    {
        name: "Eventual",
        href: "/eventual",
        icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/04-my-eventual-icon-white.svg",
        current: true,
        hide: false,
    },
    {
        name: "Unexpected",
        href: "/unexpected",
        icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/05-my-unexpected-icon-white.svg",
        current: true,
        hide: false,
    },
];

export const navigation = [
    {
        name: "Contacts",
        href: "/contacts",
        icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/00-my-contacts-icon-white.svg",
        current: true,
    },
    {
        name: "Inner Circle",
        href: "/inner-circle",
        icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/06-my-inner-circle-icon-white.svg",
        current: true,
    },
    {
        name: "Sharing History",
        href: "/sharing",
        icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/07-sharing-icon-white.svg",
        current: true,
    },
    {
        name: "Reports",
        href: "/reports",
        icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/Report-Icon-White-01.svg",
        current: true,
    },
    {
        name: "Uploaded Files",
        href: "/uploaded-files",
        icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/Z+-+File+Cabinet+icon+-+White-01-01.svg",
        current: true,
    },
    {
        name: "To Do List",
        href: "/to-do-list",
        icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/09-to-do-list-icon-white.svg",
        current: true,
    },
    {
        name: "Summary Reports",
        href: "/summary-reports",
        icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/09-to-do-list-icon-white.svg",
        current: true,
    },
];

export const secondaryNavigation = [
    {
        name: "Tutorials",
        href: "/tutorials",
        icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/12-tutorials-icon-white.svg",
        current: true,
        hide: false,
    },
    {
        name: "FAQ",
        href: "/faq",
        icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/Z+-+End+User+Agreement+icon+-+White-03-01.svg",
        current: true,
        hide: false,
    },
    {
        name: "Feedback To Support",
        href: "/feedback-to-support",
        icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/13-support-icon-white.svg",
        current: true,
        hide: false,
    },
    {
        name: "Legal Docs",
        href: "/legal-docs",
        icon: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/14-privacy-icon-white.svg",
        current: true,
        hide: false,
    },
];

export const userNavigation = [{ name: "My Account", href: "/my-profile" }];

