import { useLocation } from 'react-router-dom';
import { getListPath } from '../../components/helpers';

import TopicShare from './topic-share';

export function Share({ pathname }) {
  const location = useLocation();
  const callbackPath = getListPath(location.pathname);

  return <TopicShare path={pathname} callbackPath={callbackPath} />;
}
