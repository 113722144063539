export const safeDepositBoxesData = {
  formTitle: "Add or edit my safe deposit box",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/safeDepositBoxes",
  formFields: [
    {
      component: "input",
      label: "Bank",
      name: "bank",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "phoneNumber",
      label: "Bank phone number",
      name: "phone",
    },
    {
      component: "input",
      label: "Box number",
      name: "boxNumber",
      type: "text",
    },
    {
      component: "input",
      label: "Name(s) on account",
      name: "names",
      type: "text",
    },
    {
      component: "input",
      label: "Code / key (or its location)",
      name: "code",
      type: "text",
    },
    {
      component: "input",
      label: "Rental fee",
      name: "fee",
      type: "number",
    },
    {
      component: "input",
      label: "Renewal date",
      name: "date",
      type: "date",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
