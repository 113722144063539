export const currentSupplementsData = {
  formTitle: "Add or edit current supplement",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/currentSupplements",
  formFields: [
    {
      component: "input",
      label: "Name",
      name: "name",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Dosage",
      name: "dosage",
      type: "text",
    },
    {
      component: "input",
      label: "Purpose",
      name: "purpose",
      type: "text",
    },
    {
      component: "input",
      label: "Purchase location",
      name: "pharmacy",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
