import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "react-final-form";
import { restGet } from "../../store/api";
import { updateContactByAll } from "../../store/actions";

const apiPath = "/myContacts";

function getAutoCompleteQuerys(query, data) {
    const contacts = data;

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(
                contacts.filter((contact) =>
                    contact.firstName
                        .toLowerCase()
                        .includes(query.toLowerCase())
                )
            );
        }, Math.random() * 1000);
    });
}

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue;
}

export const AutoCompleteComponent = ({
    name,
    children,
    value,
    onChange,
    ...props
}) => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.contact.byAll);

    const [results, setResults] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const debouncedQuery = useDebounce(value, 100);
    const ref = useRef();

    const select = (option) => {
        onChange(option);
        setInputValue(option);
        setShowOptions(false);
    };

    const handleChange = (text) => {
        onChange(text);
        if (!showOptions) {
            setShowOptions(true);
        }
    };

    const getDataToShow = () => {
        restGet(
            apiPath + "/index",
            (data) => dispatch(updateContactByAll(data)),
            (error) => console.error(error)
        );
    };

    useEffect(() => {
        getDataToShow();
        setResults([]);
        if (debouncedQuery) {
            getAutoCompleteQuerys(debouncedQuery, data).then((results) => {
                setResults(results);
            });
        }
        const listener = (e) => {
            if (!ref.current.contains(e.target)) {
                setShowOptions(false);
            }
        };

        document.addEventListener("click", listener);
        document.addEventListener("focusin", listener);
        return () => {
            document.removeEventListener("click", listener);
            document.removeEventListener("focusin", listener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedQuery]);

    useEffect(() => {}, [inputValue]);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    return (
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-300 sm:pt-5">
            <label
                htmlFor={name}
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
                {children}
            </label>
            <div
                className="mt-1 sm:col-span-2 sm:mt-0 inline-flex flex-col justify-center"
                ref={ref}
            >
                <Field
                    name={name}
                    value={inputValue}
                    render={({ input }) => (
                        <input
                            {...props}
                            {...input}
                            type="text"
                            value={inputValue}
                            onChange={(e) => {
                                setInputValue(e.target.value);
                                handleChange(e.target.value);
                            }}
                            onFocus={() => setShowOptions(true)}
                            list="contacts"
                            className="block w-full max-w-lg rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    )}
                />

                {results.map((result, index) => (
                    <ul
                        key={index}
                        className="bg-white py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-200"
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.05 }}
                    >
                        {(() => {
                            const fullName = `${result.firstName} ${result.lastName}`;
                            const matchIndex = fullName.toLowerCase().indexOf(value.toLowerCase());

                            if (matchIndex !== -1) {
                                return (
                                    <li
                                        className="px-4 hover:bg-gray-200 py-2 rounded-lg"
                                        key={result}
                                        onClick={() => select(fullName)}
                                    >
                                        {fullName.slice(0, matchIndex)}
                                        <span className="text-blue-500">{fullName.slice(matchIndex, matchIndex + value.length)}</span>
                                        {fullName.slice(matchIndex + value.length)}
                                    </li>
                                );
                            } else {
                                return (
                                    <li className="px-4 py-2 text-gray-500">
                                        No results
                                    </li>
                                );
                            }
                        })()}
                    </ul>
                ))}
            </div>
        </div>
    );
};
