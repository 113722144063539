import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";

export const Text = ({ label, ...props }) => {
  return (
    <>
      <label className="block text-sm font-medium text-gray-50">{label}</label>
      <div>
        <input
          {...props}
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:ring-2 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        />
      </div>
    </>
  );
};

export const Checkbox = ({ children, ...props }) => {
  return (
    <div className="flex items-center">
      <div className="flex items-center">
        <input
          {...props}
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
        />
        <label
          htmlFor="remember-me"
          className="ml-2 block text-sm text-gray-50"
        >
          {children}
        </label>
      </div>
    </div>
  );
};

export const Button = ({ children, isPending, ...props }) => {
  return (
    <button
      {...props}
      disabled={isPending}
      className="w-full flex justify-center py-2 px-4 border-2 font-medium border-white hover:bg-indigo-500 rounded-md lg:shadow-md text-sm text-white bg-secondary-green disabled:bg-secondary-green focus:outline-none disabled:cursor-not-allowed transition duration-300"
    >
      {isPending ? (
        <>
          <div
            className="spinner-border animate-spin inline-block w-4 h-4 text-white rounded-full mr-1"
            role="status"
          />
          Processing...
        </>
      ) : (
        <p className="uppercase">{children}</p>
      )}
    </button>
  );
};

export const ShowPassword = ({ handleShowPassword, showPassword }) => {
  return (
    <div className="absolute inset-y-0 right-0 top-7">
      <div className="flex items-center pr-3" onClick={handleShowPassword}>
        {showPassword ? (
          <EyeIcon className="h-5 w-5 text-primary-green" aria-hidden="true" />
        ) : (
          <EyeOffIcon
            className="h-5 w-5 text-primary-green"
            aria-hidden="true"
          />
        )}
      </div>
    </div>
  );
};

