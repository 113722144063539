export const petsData = {
  formTitle:
    "Add detailed information such as the type of pet, name, microchip number, veterinarian and much more.",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/pets",
  formFields: [
    {
      component: "select",
      label: "Type of pet",
      name: "typeOfPet",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Dog", text: "Dog" },
        { value: "Cat", text: "Cat" },
        { value: "Fish", text: "Fish" },
        { value: "Bird", text: "Bird" },
        { value: "Rabbit", text: "Rabbit" },
        { value: "Hamster", text: "Hamster" },
        { value: "Reptile", text: "Reptile" },
        { value: "Guinea Pigs", text: "Guinea Pigs" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Name / nicknames",
      name: "name",
      type: "text",
      colObject: true,
    },
    {
      component: "input",
      label: "Breed",
      name: "breed",
      type: "text",
    },
    {
      component: "input",
      label: "Address (where is pet located)",
      name: "address",
      type: "text",
    },
    {
      component: "input",
      label: "Color",
      name: "color",
      type: "text",
    },
    {
      component: "input",
      label: "Sex",
      name: "sex",
      type: "text",
    },
    {
      component: "input",
      label: "Age / DoB",
      name: "age",
      type: "text",
      placeholder: "i.e. 04/11/2015",
    },
    {
      component: "select",
      label: "Neutered / Spayed",
      name: "neuteredOrSpayed",
      type: "text",
      options: [
        { value: "Neutered", text: "Neutered" },
        { value: "Spayed", text: "Spayed" },
      ],
    },
    {
      component: "textarea",
      label: "Type of food",
      name: "typeOfFood",
    },
    {
      component: "input",
      label: "How often do you feed your pet?",
      name: "howOftenDoYouFeedYourPet",
      type: "text",
    },
    {
      component: "input",
      label: "Any dietary restrictions?",
      name: "dietaryRestrictions",
      type: "text",
    },
    {
      component: "input",
      label: "Vaccinations (list all)",
      name: "vaccinations",
      type: "text",
    },
    {
      component: "input",
      label: "Allergies",
      name: "allergies",
      type: "text",
    },
    {
      component: "input",
      label: "Current medications (list all)",
      name: "medications",
      type: "text",
    },
    {
      component: "input",
      label: "Pet / animal hospital",
      name: "animalHospital",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Clinic / hospital phone",
      name: "hospitalPhone",
    },
    {
      component: "input",
      label: "Clinic / hospital email",
      name: "hospitalEmail",
      type: "email",
    },
    {
      component: "input",
      label: "Veterinarian name",
      name: "veterinarian",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Veterinarian phone",
      name: "veterinarianPhone",
    },
    {
      component: "input",
      label: "Microchip #",
      name: "microchip",
      type: "text",
    },
    {
      component: "select",
      label: "Collar with ID tags?",
      name: "collarWithIdTags",
      type: "text",
      options: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" },
      ],
    },
    {
      component: "input",
      label: "ID Tag#",
      name: "idTag",
      type: "text",
    },
    {
      component: "mc",
      label: "Good with…",
      name: "goodWith",
      values: ["Children", "Men", "Women", "Other pets"],
    },
    {
      component: "input",
      label: "Weight",
      name: "weight",
      type: "text",
    },
    {
      component: "input",
      label: "Personality",
      name: "personality",
      type: "text",
    },
    {
      component: "input",
      label: "Training commands",
      name: "trainingCommands",
      type: "text",
    },
    {
      component: "input",
      label: "Training language",
      name: "trainingLanguage",
      type: "text",
    },
    {
      component: "input",
      label: "Heritage",
      name: "heritage",
      type: "text",
    },
    {
      component: "input",
      label: "Social Media Page",
      name: "socialMediaPage",
      type: "text",
    },
    {
      component: "input",
      label: "Sleep location",
      name: "sleepLocation",
      type: "text",
    },
    {
      component: "input",
      label: "Favorite activities",
      name: "favoriteActivities",
      type: "text",
    },
    {
      component: "input",
      label: "Favorite toys",
      name: "favoriteToys",
      type: "text",
    },
    {
      component: "input",
      label: "Known aggression",
      name: "knownAggression",
      type: "text",
    },
    {
      component: "input",
      label: "Treats",
      name: "treats",
      type: "text",
    },
    {
      component: "input",
      label: "Food supplier",
      name: "foodSupplier",
      type: "text",
    },
    {
      component: "input",
      label: "Exercise locations",
      name: "exerciseLocations",
      type: "text",
    },
    {
      component: "input",
      label: "Groomer name",
      name: "groomer",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Groomer phone",
      name: "groomerPhone",
    },
    {
      component: "input",
      label: "Sitter name",
      name: "sitterName",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Sitter phone",
      name: "sitterPhone",
    },
    {
      component: "input",
      label: "Pet insurance type",
      name: "petInsuranceType",
      type: "text",
    },
    {
      component: "input",
      label: "Pet insurance name",
      name: "petInsuranceName",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Pet insurance phone",
      name: "petInsurancePhone",
    },
    {
      component: "input",
      label: "Pet insurance website",
      name: "petInsuranceWebsite",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
