/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";

import { Fragment, useEffect, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
import { DotsHorizontalIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import { restGet } from "../../store/api";
import { getMonthTable } from "../calendar/month-view/getMonthTable";
import { ActionPanel } from "../common/action-panel";
import { FormSubmit } from "../calendar/forms/form-submit";
import { XIcon } from "@heroicons/react/outline";
import { classNames } from "../../lib/classnames";


const apiPath = "calendarEvents";

export default function Calendar({ popupCalendar, setPopupCalendar }) {
    const user = useSelector((state) => state.auth.user);
    const events = useSelector((state) => state.calendar.byAll);
    const toggle = useSelector((state) => state.calendar.toggle);

    const [eventRCalendar, setEventRCalendar] = useState([]);
    const [currDate, setCurrDate] = useState(moment().format("YYYY-MM-DD"));
    const [selectedMonth, setSelectedMonth] = useState(
        moment(currDate).startOf("month").format("YYYY-MM-DD")
    );
    const [selectedDay, setSelectedDay] = useState(null);
    const [open, setOpen] = useState(false);
    const [event, setEvent] = useState(null);

    const currentMonth = moment(currDate).month() + 1;

    let whenList = [];

    if (eventRCalendar !== null && eventRCalendar.length > 0) {
        whenList = eventRCalendar.map((event) => event?.when);
    }

    const setInitialValues = (event) => {
        const e = { ...event };
        if (event.starts === event.ends) {
            e.allday = true;
        }

        return e;
    };

    const handleClose = () => {
        setPopupCalendar(!popupCalendar);
    };

    useEffect(() => {
        restGet(
            "/calendarEvents/day?day=" + currDate,
            (data) => {
                setSelectedDay((prevState) => ({
                    ...prevState,
                    date: currDate,
                    events: data,
                    isToday: true,
                    isSelected: true,
                }));
            },
            (err) => console.error(err)
        );
    }, [currDate, selectedMonth, toggle, events]);

    useEffect(() => {
        restGet(
            "/calendarEvents/month?month=" + currentMonth,
            (data) => {
                setEventRCalendar(data);
            },
            (err) => console.error(err)
        );
    }, [events, currentMonth]);

    return (
        <Fragment>
            {open && (
                <ActionPanel open={open} setOpen={setOpen}>
                    <FormSubmit
                        apiPath={apiPath}
                        initialValues={event ? event : null}
                        setOpen={setOpen}
                    />
                </ActionPanel>
            )}

            <div className="pb-8 px-6 pt-36 overflow-y-scroll max-h-screen">
                {/* MONTH AND ARROWS */}
                <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-green focus:ring-offset-2"
                    onClick={() => handleClose()}
                >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6 xl:hidden" aria-hidden="true" />
                </button>
                <div className="flex items-center">
                    <h2 className="flex-auto font-semibold text-gray-900">
                        {moment(selectedMonth).format("MMMM YYYY")}
                    </h2>

                    <button
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            setSelectedMonth(
                                moment(selectedMonth)
                                    .subtract(1, "months")
                                    .format("YYYY-MM-DD")
                            );
                        }}
                        className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                    >
                        <span className="sr-only">Previous month</span>

                        <ChevronLeftIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                        />
                    </button>

                    <button
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            setSelectedMonth(
                                moment(selectedMonth)
                                    .add(1, "months")
                                    .format("YYYY-MM-DD")
                            );
                        }}
                        className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                    >
                        <span className="sr-only">Next month</span>

                        <ChevronRightIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                        />
                    </button>
                </div>

                {/* DAYS OF THE WEEK */}
                <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
                    <div>S</div>
                    <div>M</div>
                    <div>T</div>
                    <div>W</div>
                    <div>T</div>
                    <div>F</div>
                    <div>S</div>
                </div>

                {/* CALENDAR */}
                <div className="mt-2 grid grid-cols-7 text-sm">
                    {getMonthTable(
                        selectedMonth,
                        moment().format("YYYY-MM-DD"),
                        events,
                        selectedDay
                    ).map((day, dayIdx) => {
                        const formattedDate = moment(day.date).format(
                            "YYYY-MM-DD"
                        );

                        return (
                            <div
                                key={day.date}
                                className={classNames(
                                    dayIdx > 6 && "border-t border-gray-200",
                                    "py-2",
                                    "text-center",
                                    "relative"
                                )}
                            >
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSelectedDay(day);
                                        setCurrDate(
                                            moment(day.date).format(
                                                "YYYY-MM-DD"
                                            )
                                        );
                                    }}
                                    className={classNames(
                                        day.isSelected && "text-white",
                                        !day.isSelected &&
                                            day.isToday &&
                                            "text-indigo-600",
                                        !day.isSelected &&
                                            !day.isToday &&
                                            day.isCurrentMonth &&
                                            "text-gray-900",
                                        !day.isSelected &&
                                            !day.isToday &&
                                            !day.isCurrentMonth &&
                                            "text-gray-400",
                                        day.isSelected &&
                                            day.isToday &&
                                            "bg-indigo-600",
                                        day.isSelected &&
                                            !day.isToday &&
                                            "bg-gray-900",
                                        !day.isSelected && "hover:bg-gray-200",
                                        (day.isSelected || day.isToday) &&
                                            "font-semibold",
                                        "mx-auto flex flex-col h-8 w-8 items-center justify-center rounded-full"
                                    )}
                                >
                                    <div className="relative">
                                        <time dateTime={day.date}>
                                            <span className="mb-1">
                                                {day.date
                                                    .split("-")
                                                    .pop()
                                                    .replace(/^0/, "")}
                                            </span>
                                        </time>
                                        {whenList.length > 0 &&
                                            whenList.includes(
                                                formattedDate
                                            ) && (
                                                <span
                                                    className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full"
                                                    style={{
                                                        fontSize: "30px",
                                                        lineHeight: 0,
                                                        color: "red",
                                                    }}
                                                >
                                                    .
                                                </span>
                                            )}
                                    </div>
                                </button>
                            </div>
                        );
                    })}
                </div>

                {/* SCHEDULE OF THE DAY */}
                <section className="mt-8 overflow-y-scroll max-h-86">
                    <h2 className="font-semibold text-gray-900">
                        Schedule for{" "}
                        <time dateTime="2022-01-21">
                            {selectedDay
                                ? moment(selectedDay.date).format(
                                      "MMMM DD, YYYY"
                                  )
                                : moment().format("MMMM DD, YYYY")}
                        </time>
                    </h2>
                    <ol className="mt-4 space-y-1 text-sm leading-6 text-gray-500">
                        {selectedDay &&
                            selectedDay.events.map((event) => (
                                <li
                                    key={event._id}
                                    className="group flex items-center space-x-4 rounded-xl py-2 px-4 focus-within:bg-gray-100 hover:bg-gray-100"
                                >
                                    <div className="flex-auto">
                                        <p className="text-gray-900">
                                            {event.title}
                                        </p>
                                        {event.starts ? (
                                            <>
                                                <p className="mt-0.5">
                                                    <time
                                                        dateTime={event.starts}
                                                    >
                                                        {moment(
                                                            event.starts
                                                        ).format(
                                                            "MMMM DD, YYYY"
                                                        )}
                                                    </time>{" "}
                                                    -{" "}
                                                    <time dateTime={event.ends}>
                                                        {moment(
                                                            event.ends
                                                        ).format(
                                                            "MMMM DD, YYYY"
                                                        )}
                                                    </time>
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                {event.when ? (
                                                    <p className="mt-0.5">
                                                        All-day
                                                    </p>
                                                ) : (
                                                    <p className="mt-0.5">
                                                        <time
                                                            dateTime={
                                                                event.starts
                                                            }
                                                        >
                                                            {moment(
                                                                event.starts
                                                            ).format("LT")}
                                                        </time>{" "}
                                                        -{" "}
                                                        <time
                                                            dateTime={
                                                                event.ends
                                                            }
                                                        >
                                                            {moment(
                                                                event.ends
                                                            ).format("LT")}
                                                        </time>
                                                    </p>
                                                )}
                                            </>
                                        )}
                                    </div>

                                    {user && user.type !== "readonly" && (
                                        <Menu
                                            as="div"
                                            className="relative opacity-0 focus-within:opacity-100 group-hover:opacity-100"
                                        >
                                            <div>
                                                <Menu.Button className="-m-2 flex items-center rounded-full p-1.5 text-gray-500 hover:text-gray-600">
                                                    <span className="sr-only">
                                                        Open options
                                                    </span>
                                                    <DotsHorizontalIcon
                                                        className="h-6 w-6"
                                                        aria-hidden="true"
                                                    />
                                                </Menu.Button>
                                            </div>

                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1">
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    href="/"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault();
                                                                        setOpen(
                                                                            true
                                                                        );
                                                                        setEvent(
                                                                            setInitialValues(
                                                                                event
                                                                            )
                                                                        );
                                                                    }}
                                                                    className={classNames(
                                                                        active
                                                                            ? "bg-gray-100 text-gray-900"
                                                                            : "text-gray-700",
                                                                        "block px-4 py-2 text-sm"
                                                                    )}
                                                                >
                                                                    Edit
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    href="#"
                                                                    className={classNames(
                                                                        active
                                                                            ? "bg-gray-100 text-gray-900"
                                                                            : "text-gray-700",
                                                                        "block px-4 py-2 text-sm"
                                                                    )}
                                                                >
                                                                    Cancel
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    )}
                                </li>
                            ))}
                    </ol>
                </section>
            </div>
        </Fragment>
    );
}

