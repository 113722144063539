import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { restGet } from "../../store/api";
import { updateInnerCircleByAll } from "../../store/actions";

const apiPath = "/myContacts";

const colObject = {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    contactType: "Contact Type",
};

const Button = ({ pathname }) => {
    const user = useSelector((state) => state.auth.user);

    const isSubscriptionDisabled = user && user.type === "readonly";

    const linkClasses = `
    ml-3 inline-flex justify-center rounded-md ${
        isSubscriptionDisabled
            ? "border-gray-500 bg-gray-500 text-white cursor-not-allowed"
            : "border-primary-green bg-primary-green text-white"
    } mx-1 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-white hover:text-gray-900 focus:outline-none sm:w-auto mb-4
  `;

    return (
        <div className="text-right mt-4">
            {isSubscriptionDisabled ? (
                <div className={linkClasses}>Add new contact</div>
            ) : (
                <Link to={pathname + "/new"} className={linkClasses}>
                    Add new contact
                </Link>
            )}
        </div>
    );
};

const Table = ({ data, location }) => {
    return (
        <div className="mt-6 mb-4 flex flex-col">
            <div className="overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle md:px-0">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                        {colObject[Object.keys(colObject)[0]]}
                                    </th>
                                    {Object.keys(colObject)
                                        .filter((el, index) => index > 0)
                                        .map((el) => (
                                            <th
                                                key={el}
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                {colObject[el]}
                                            </th>
                                        ))}
                                    <th
                                        scope="col"
                                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                    >
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {data &&
                                    data
                                        .filter((el) => el.innerCircle === true)
                                        .map((topic, topicIdx) => (
                                            <tr
                                                key={topic._id}
                                                className={
                                                    topicIdx % 2 === 0
                                                        ? undefined
                                                        : "bg-gray-50"
                                                }
                                            >
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {
                                                        topic[
                                                            Object.keys(
                                                                colObject
                                                            )[0]
                                                        ]
                                                    }
                                                </td>
                                                {Object.keys(colObject)
                                                    .filter(
                                                        (el, index) => index > 0
                                                    )
                                                    .map((el) => (
                                                        <td
                                                            key={el}
                                                            className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                                        >
                                                            {topic[el]}
                                                        </td>
                                                    ))}

                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <Link
                                                        to={
                                                            location.pathname +
                                                            "/view?id=" +
                                                            topic._id
                                                        }
                                                        className="text-primary-green hover:text-indigo-900 hover:underline"
                                                    >
                                                        View
                                                        <span className="sr-only">
                                                            ,{" "}
                                                            {
                                                                topic[
                                                                    Object.keys(
                                                                        colObject
                                                                    )[0]
                                                                ]
                                                            }
                                                        </span>
                                                    </Link>
                                                    <span className="mx-2">
                                                        |
                                                    </span>
                                                    <Link
                                                        to={
                                                            location.pathname +
                                                            "/edit?id=" +
                                                            topic._id
                                                        }
                                                        className="text-primary-green hover:text-indigo-900 hover:underline"
                                                    >
                                                        Edit
                                                        <span className="sr-only">
                                                            ,{" "}
                                                            {
                                                                topic[
                                                                    Object.keys(
                                                                        colObject
                                                                    )[0]
                                                                ]
                                                            }
                                                        </span>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const InnerCircleList = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.innerCircle.byAll);

    const getDataToShow = () => {
        restGet(
            apiPath + "/index",
            (data) => dispatch(updateInnerCircleByAll(data)),
            (error) => console.error(error)
        );
    };

    useEffect(() => {
        getDataToShow();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <Button pathname={location.pathname} />
            <Table data={data} location={location} />
        </Fragment>
    );
};

