import { useState } from "react";
import { useNavigate } from "react-router-dom";

import PageTitle from "../../components/common/page-title";
import { Toastr } from "../../components/common/toastr";
import WhiteBtn from "../../components/buttons/whiteButton";
import GreenBtn from "../../components/buttons/greenBtn";
import { errorFeedbackMessages } from "../../error/message";
import { restPost } from "../../store/api";

export default function FeedbackAndSupport() {
    const navigate = useNavigate();

    const [feedbackData, setFeedbackData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        message: "",
    });
    const [isLoading, setIsLoading] = useState(false);

    const formatPhoneNumber = (number) => {
        if (!number) return number;

        const currNum = number.replace(/[^\d]/g, "");
        const currNumLength = currNum.length;

        if (currNumLength < 4) return currNum;

        if (currNumLength < 7)
            return `(${currNum.slice(0, 3)}) ${currNum.slice(3)}`;

        return `(${currNum.slice(0, 3)}) ${currNum.slice(3, 6)}-${currNum.slice(
            6,
            10
        )}`;
    };

    const feedbackInputChange = (e) => {
        let { name, value } = e.target;

        if (name === "phoneNumber") value = formatPhoneNumber(value);

        setFeedbackData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const resetFeedback = () => {
        setFeedbackData({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            message: "",
        });
    };

    const successCallback = () => {
        Toastr({ type: "success", message: "Message sent" });

        setFeedbackData({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            message: "",
        });

        setIsLoading(false);

        navigate("/feedback-to-support/confirmation");
    };

    const failCallback = (err) => {
        console.error(err);
        Toastr({
            type: "error",
            message: errorFeedbackMessages.MESSAGE_COULD_NOT_BE_SENT,
        });
        setIsLoading(false);
    };

    const submitFeedbackForm = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        await restPost(
            "/users/feedback-form",
            feedbackData,
            successCallback,
            failCallback
        );
    };

    return (
        <>
            <PageTitle title="My Banyan" />

            <div className="mt-10 mb-4 px-4 2xl:px-20 flex flex-col">
                <form onSubmit={submitFeedbackForm}>
                    <div className="w-full flex flex-col gap-8">
                        <div className="grid grid-cols-1 sm:grid-cols-2 grid-rows-4 gap-x-16 2xl:gap-x-32 gap-y-10">
                            <div className="flex flex-col items-start gap-2">
                                <label
                                    htmlFor="firstName"
                                    className="md:text-lg font-medium text-gray-700"
                                >
                                    First Name
                                </label>

                                <input
                                    required
                                    id="firstName"
                                    type="text"
                                    name="firstName"
                                    value={feedbackData.firstName}
                                    autoComplete="firstName"
                                    onChange={feedbackInputChange}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-green focus:ring-primary-green text-sm"
                                />
                            </div>

                            <div className="flex flex-col items-start gap-2">
                                <label
                                    htmlFor="lastName"
                                    className="md:text-lg font-medium text-gray-700"
                                >
                                    Last Name
                                </label>

                                <input
                                    required
                                    id="lastName"
                                    type="text"
                                    name="lastName"
                                    value={feedbackData.lastName}
                                    autoComplete="lastName"
                                    onChange={feedbackInputChange}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-green focus:ring-primary-green text-sm"
                                />
                            </div>

                            <div className="flex flex-col items-start gap-2">
                                <label
                                    htmlFor="email"
                                    className="md:text-lg font-medium text-gray-700"
                                >
                                    Email Address
                                </label>

                                <input
                                    required
                                    id="email"
                                    type="email"
                                    name="email"
                                    value={feedbackData.email}
                                    autoComplete="email"
                                    onChange={feedbackInputChange}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-green focus:ring-primary-green text-sm"
                                />
                            </div>

                            <div className="flex flex-col items-start gap-2">
                                <label
                                    htmlFor="phoneNumber"
                                    className="md:text-lg font-medium text-gray-700"
                                >
                                    Phone Number
                                </label>

                                <input
                                    id="phoneNumber"
                                    type="tel"
                                    pattern="\([0-9]{3}\) [0-9]{3}-[0-9]{4}"
                                    name="phoneNumber"
                                    value={feedbackData.phoneNumber}
                                    autoComplete="phoneNumber"
                                    placeholder="(XXX) XXX-XXXX"
                                    onChange={feedbackInputChange}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-green focus:ring-primary-green text-sm"
                                />
                            </div>

                            <div className="sm:col-span-2 row-span-2 flex flex-col items-start gap-2">
                                <label
                                    htmlFor="message"
                                    className="md:text-lg font-medium text-gray-700"
                                >
                                    Message
                                </label>

                                <textarea
                                    id="message"
                                    name="message"
                                    value={feedbackData.message}
                                    onChange={feedbackInputChange}
                                    className="h-full w-full rounded-md border-gray-300 shadow-sm focus:border-primary-green focus:ring-primary-green sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="flex justify-between">
                            <WhiteBtn
                                type="button"
                                disabled={isLoading}
                                onClick={() => resetFeedback()}
                            >
                                Cancel
                            </WhiteBtn>

                            <GreenBtn type="submit" disabled={isLoading}>
                                Submit
                            </GreenBtn>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
