export const bankAccountsBalancesData = {
  formTitle: "Add or edit my income source",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/bankAccountsBalances",
  formFields: [
    {
      component: "select",
      label: "Account type",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Broker", text: "Broker" },
        { value: "Business checking", text: "Business checking" },
        { value: "Certificate of Deposit (CD)", text: "Certificate of Deposit (CD)" },
        { value: "Checking", text: "Checking" },
        { value: "Investment", text: "Investment" },
        { value: "Money market", text: "Money market" },
        { value: "Retirement", text: "Retirement" },
        { value: "Savings", text: "Savings" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Bank name",
      name: "bank",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Branch",
      name: "branch",
      type: "text",
    },
    {
      component: "value",
      label: "Balance estimate",
      name: "balance",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Date of estimate",
      name: "date",
      type: "date",
    },
    {
      component: "input",
      label: "Name(s) on account",
      name: "names",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
