import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import PageTitle from "../../components/common/page-title";

export default function Branch({ data, parentPath }) {
    const { path, topics, image, description, pdf, pdfs } = data;

    const user = useSelector((state) => state.auth.user);
    const userType = user ? user.type : null;

    return (
        <>
            <PageTitle title="My Banyan" />

            <div className="mb-8">
                <div className="mx-auto flex max-w-7xl flex-col pt-4 md:pt-6 lg:pt-8">
                    <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-4 md:px-6">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center justify-center text-3xl uppercase sm:text-center text-primary-green w-full">
                                    <img
                                        src={data.gIcon}
                                        alt={data.name + "logo"}
                                        className="w-12 mr-1"
                                    />
                                    {data.name}
                                </div>
                            </div>
                        </div>

                        <div className="border-t border-gray-300 bg-alternative-green sm:rounded-b-lg relative">
                            {pdf &&
                                (userType === "readonly" ? (
                                    <img
                                        src={`${pdf.img}`}
                                        alt={`${pdf.imgAlt}`}
                                        className="absolute top-20 sm:top-10 right-4 sm:right-10 w-20 sm:w-48 lg:w-44 2xl:w-52 rotate-15"
                                    />
                                ) : (
                                    <a
                                        href={pdf.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={`${pdf.img}`}
                                            alt={`${pdf.imgAlt}`}
                                            className="absolute top-20 sm:top-10 right-4 sm:right-10 w-20 sm:w-48 lg:w-44 2xl:w-52 rotate-15 hover:scale-105 transition duration-300"
                                        />
                                    </a>
                                ))}

                            {pdfs &&
                                (userType === "readonly" ? (
                                    <div className="hidden lg:grid grid-cols-4 gap-8 absolute top-[12vw] xl:top-[11vw] 2xl:top-32 right-10">
                                        {pdfs.map((pdf) => (
                                            <img
                                                src={`${pdf.img}`}
                                                alt={`${pdf.imgAlt}`}
                                                className="w-[9vw] xl:w-[7vw] 2xl:w-[8vw] hover:scale-105 transition duration-300"
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <div className="hidden lg:grid grid-cols-4 gap-8 absolute top-[12vw] xl:top-[11vw] 2xl:top-32 right-10">
                                        {pdfs.map((pdf) => (
                                            <a
                                                href={pdf.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img
                                                    src={`${pdf.img}`}
                                                    alt={`${pdf.imgAlt}`}
                                                    className="w-[9vw] xl:w-[7vw] 2xl:w-[8vw] hover:scale-105 transition duration-300"
                                                />
                                            </a>
                                        ))}
                                    </div>
                                ))}

                            <div
                                className={`${
                                    pdf &&
                                    "w-[70%] sm:w-[65%] md:w-[70%] 2xl:w-[75%]"
                                } px-4 md:px-6 py-8`}
                            >
                                <p className="text-gray-500 text-sm">
                                    {description && description}
                                </p>
                            </div>

                            {(pdf || pdfs) && (
                                <img
                                    src={image.img}
                                    alt={image.imgAlt}
                                    className={`w-full mb-10 shadow-lg ${
                                        image.imgAlt.toLowerCase() ===
                                            "senior life plans pic" &&
                                        "hidden lg:block"
                                    }`}
                                />
                            )}

                            {pdfs &&
                                (userType === "readonly" ? (
                                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-8 lg:hidden px-4 pb-10">
                                        {pdfs.map((pdf) => (
                                            <img
                                                src={`${pdf.img}`}
                                                alt={`${pdf.imgAlt}`}
                                                className="lg:w-24 xl:w-24 2xl:w-32 hover:scale-105 transition duration-300"
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-8 lg:hidden px-4 pb-10">
                                        {pdfs.map((pdf) => (
                                            <a
                                                href={pdf.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img
                                                    src={`${pdf.img}`}
                                                    alt={`${pdf.imgAlt}`}
                                                    className="lg:w-24 xl:w-24 2xl:w-32 hover:scale-105 transition duration-300"
                                                />
                                            </a>
                                        ))}
                                    </div>
                                ))}

                            {path !== "my-essentials" &&
                                path !== "finances" &&
                                path !== "health" &&
                                path !== "farewell-plans" && (
                                    <div className="mx-auto pb-10 px-4 md:px-8 grid sm:grid-cols-2 md:grid-cols-3 gap-5">
                                        {topics.map((topic, index) => (
                                            <Link
                                                key={index}
                                                to={
                                                    parentPath +
                                                    path +
                                                    "/" +
                                                    topic.path
                                                }
                                                className="group relative p-4 bg-white rounded-3xl shadow-xl hover:scale-105 transition duration-300"
                                            >
                                                <p
                                                    className={`absolute right-4 top-0 text-red-500 text-lg ${
                                                        user?.following
                                                            ?.length &&
                                                        user?.following.some(
                                                            (item) =>
                                                                item.topicName ===
                                                                topic.path
                                                        )
                                                            ? "visible"
                                                            : "invisible"
                                                    }`}
                                                >
                                                    ●
                                                </p>

                                                <div className="flex items-center gap-x-2">
                                                    <img
                                                        src={topic.img}
                                                        alt={topic.imgAlt}
                                                        loading="lazy"
                                                        className="w-16 rounded-xl"
                                                    />

                                                    <p className="text-sm font-medium text-gray-900 group-hover:underline">
                                                        {topic.name}
                                                    </p>
                                                </div>

                                                <p className="pt-2 text-xs line-clamp-3">
                                                    {topic.summary}
                                                </p>
                                            </Link>
                                        ))}
                                    </div>
                                )}

                            {(path === "my-essentials" ||
                                path === "health") && (
                                <div className="mx-auto pb-10 px-4 md:px-8 grid sm:grid-cols-2 md:grid-cols-3 gap-5">
                                    {topics.slice(0, 9).map((topic, index) => (
                                        <Link
                                            key={index}
                                            to={
                                                parentPath +
                                                path +
                                                "/" +
                                                topic.path
                                            }
                                            className="group relative p-4 bg-white rounded-3xl shadow-xl hover:scale-105 transition duration-300"
                                        >
                                            <p
                                                className={`absolute right-4 top-0 text-red-500 text-lg ${
                                                    user?.following?.length &&
                                                    user?.following.some(
                                                        (item) =>
                                                            item.topicName ===
                                                            topic.path
                                                    )
                                                        ? "visible"
                                                        : "invisible"
                                                }`}
                                            >
                                                ●
                                            </p>

                                            <div className="flex items-center gap-x-2">
                                                <img
                                                    src={topic.img}
                                                    alt={topic.imgAlt}
                                                    loading="lazy"
                                                    className="w-16 rounded-xl"
                                                />

                                                <p className="text-sm font-medium text-gray-900 group-hover:underline">
                                                    {topic.name}
                                                </p>
                                            </div>
                                            <p className="pt-2 text-xs line-clamp-3">
                                                {topic.summary}
                                            </p>
                                        </Link>
                                    ))}

                                    <div className="col-span-full my-3 w-full border-t border-[#B2C0A5]" />

                                    {topics.slice(9).map((topic, index) => (
                                        <Link
                                            key={index}
                                            to={
                                                parentPath +
                                                path +
                                                "/" +
                                                topic.path
                                            }
                                            className="group relative p-4 bg-white rounded-3xl shadow-xl hover:scale-105 transition duration-300"
                                        >
                                            <p
                                                className={`absolute right-4 top-0 text-red-500 text-lg ${
                                                    user?.following?.length &&
                                                    user?.following.some(
                                                        (item) =>
                                                            item.topicName ===
                                                            topic.path
                                                    )
                                                        ? "visible"
                                                        : "invisible"
                                                }`}
                                            >
                                                ●
                                            </p>

                                            <div className="flex items-center gap-x-2">
                                                <img
                                                    src={topic.img}
                                                    alt={topic.imgAlt}
                                                    loading="lazy"
                                                    className="w-16 rounded-xl"
                                                />

                                                <p className="text-sm font-medium text-gray-900 group-hover:underline">
                                                    {topic.name}
                                                </p>
                                            </div>
                                            <p className="pt-2 text-xs line-clamp-3">
                                                {topic.summary}
                                            </p>
                                        </Link>
                                    ))}
                                </div>
                            )}

                            {path === "finances" && (
                                <div className="mx-auto pb-10 px-4 md:px-8 grid sm:grid-cols-2 md:grid-cols-3 gap-5">
                                    {topics.slice(0, 3).map((topic, index) => (
                                        <Link
                                            key={index}
                                            to={
                                                parentPath +
                                                path +
                                                "/" +
                                                topic.path
                                            }
                                            className="group relative p-4 bg-white rounded-3xl shadow-xl hover:scale-105 transition duration-300"
                                        >
                                            <p
                                                className={`absolute right-4 top-0 text-red-500 text-lg ${
                                                    user?.following?.length &&
                                                    user?.following.some(
                                                        (item) =>
                                                            item.topicName ===
                                                            topic.path
                                                    )
                                                        ? "visible"
                                                        : "invisible"
                                                }`}
                                            >
                                                ●
                                            </p>

                                            <div className="flex items-center gap-x-2">
                                                <img
                                                    src={topic.img}
                                                    alt={topic.imgAlt}
                                                    loading="lazy"
                                                    className="w-16 rounded-xl"
                                                />

                                                <p className="text-sm font-medium text-gray-900 group-hover:underline">
                                                    {topic.name}
                                                </p>
                                            </div>
                                            <p className="pt-2 text-xs line-clamp-3">
                                                {topic.summary}
                                            </p>
                                        </Link>
                                    ))}

                                    <div className="col-span-full my-3 w-full border-t border-[#B2C0A5]" />

                                    {topics.slice(3, 11).map((topic, index) => (
                                        <Link
                                            key={index}
                                            to={
                                                parentPath +
                                                path +
                                                "/" +
                                                topic.path
                                            }
                                            className="group relative p-4 bg-white rounded-3xl shadow-xl hover:scale-105 transition duration-300"
                                        >
                                            <p
                                                className={`absolute right-4 top-0 text-red-500 text-lg ${
                                                    user?.following?.length &&
                                                    user?.following.some(
                                                        (item) =>
                                                            item.topicName ===
                                                            topic.path
                                                    )
                                                        ? "visible"
                                                        : "invisible"
                                                }`}
                                            >
                                                ●
                                            </p>

                                            <div className="flex items-center gap-x-2">
                                                <img
                                                    src={topic.img}
                                                    alt={topic.imgAlt}
                                                    loading="lazy"
                                                    className="w-16 rounded-xl"
                                                />

                                                <p className="text-sm font-medium text-gray-900 group-hover:underline">
                                                    {topic.name}
                                                </p>
                                            </div>
                                            <p className="pt-2 text-xs line-clamp-3">
                                                {topic.summary}
                                            </p>
                                        </Link>
                                    ))}

                                    <div className="col-span-full my-3 w-full border-t border-[#B2C0A5]" />

                                    {topics.slice(11).map((topic, index) => (
                                        <Link
                                            key={index}
                                            to={
                                                parentPath +
                                                path +
                                                "/" +
                                                topic.path
                                            }
                                            className="group relative p-4 bg-white rounded-3xl shadow-xl hover:scale-105 transition duration-300"
                                        >
                                            <p
                                                className={`absolute right-4 top-0 text-red-500 text-lg ${
                                                    user?.following?.length &&
                                                    user?.following.some(
                                                        (item) =>
                                                            item.topicName ===
                                                            topic.path
                                                    )
                                                        ? "visible"
                                                        : "invisible"
                                                }`}
                                            >
                                                ●
                                            </p>

                                            <div className="flex items-center gap-x-2">
                                                <img
                                                    src={topic.img}
                                                    alt={topic.imgAlt}
                                                    loading="lazy"
                                                    className="w-16 rounded-xl"
                                                />

                                                <p className="text-sm font-medium text-gray-900 group-hover:underline">
                                                    {topic.name}
                                                </p>
                                            </div>
                                            <p className="pt-2 text-xs line-clamp-3">
                                                {topic.summary}
                                            </p>
                                        </Link>
                                    ))}
                                </div>
                            )}

                            {path === "farewell-plans" && (
                                <div className="mx-auto pb-10 px-4 md:px-8 grid sm:grid-cols-2 md:grid-cols-3 gap-5">
                                    {topics.slice(0, 9).map((topic, index) => (
                                        <Link
                                            key={index}
                                            to={
                                                parentPath +
                                                path +
                                                "/" +
                                                topic.path
                                            }
                                            className="group relative p-4 bg-white rounded-3xl shadow-xl hover:scale-105 transition duration-300"
                                        >
                                            <p
                                                className={`absolute right-4 top-0 text-red-500 text-lg ${
                                                    user?.following?.length &&
                                                    user?.following.some(
                                                        (item) =>
                                                            item.topicName ===
                                                            topic.path
                                                    )
                                                        ? "visible"
                                                        : "invisible"
                                                }`}
                                            >
                                                ●
                                            </p>

                                            <div className="flex items-center gap-x-2">
                                                <img
                                                    src={topic.img}
                                                    alt={topic.imgAlt}
                                                    loading="lazy"
                                                    className="w-16 rounded-xl"
                                                />

                                                <p className="text-sm font-medium text-gray-900 group-hover:underline">
                                                    {topic.name}
                                                </p>
                                            </div>
                                            <p className="pt-2 text-xs line-clamp-3">
                                                {topic.summary}
                                            </p>
                                        </Link>
                                    ))}

                                    <div className="col-span-full my-3 w-full border-t border-[#B2C0A5]" />

                                    {topics.slice(9, 12).map((topic, index) => (
                                        <Link
                                            key={index}
                                            to={
                                                parentPath +
                                                path +
                                                "/" +
                                                topic.path
                                            }
                                            className="group relative p-4 bg-white rounded-3xl shadow-xl hover:scale-105 transition duration-300"
                                        >
                                            <p
                                                className={`absolute right-4 top-0 text-red-500 text-lg ${
                                                    user?.following?.length &&
                                                    user?.following.some(
                                                        (item) =>
                                                            item.topicName ===
                                                            topic.path
                                                    )
                                                        ? "visible"
                                                        : "invisible"
                                                }`}
                                            >
                                                ●
                                            </p>

                                            <div className="flex items-center gap-x-2">
                                                <img
                                                    src={topic.img}
                                                    alt={topic.imgAlt}
                                                    loading="lazy"
                                                    className="w-16 rounded-xl"
                                                />

                                                <p className="text-sm font-medium text-gray-900 group-hover:underline">
                                                    {topic.name}
                                                </p>
                                            </div>
                                            <p className="pt-2 text-xs line-clamp-3">
                                                {topic.summary}
                                            </p>
                                        </Link>
                                    ))}

                                    <div className="col-span-full my-3 w-full border-t border-[#B2C0A5]" />

                                    {topics.slice(12).map((topic, index) => (
                                        <Link
                                            key={index}
                                            to={
                                                parentPath +
                                                path +
                                                "/" +
                                                topic.path
                                            }
                                            className="group relative p-4 bg-white rounded-3xl shadow-xl hover:scale-105 transition duration-300"
                                        >
                                            <p
                                                className={`absolute right-4 top-0 text-red-500 text-lg ${
                                                    user?.following?.length &&
                                                    user?.following.some(
                                                        (item) =>
                                                            item.topicName ===
                                                            topic.path
                                                    )
                                                        ? "visible"
                                                        : "invisible"
                                                }`}
                                            >
                                                ●
                                            </p>

                                            <div className="flex items-center gap-x-2">
                                                <img
                                                    src={topic.img}
                                                    alt={topic.imgAlt}
                                                    loading="lazy"
                                                    className="w-16 rounded-xl"
                                                />

                                                <p className="text-sm font-medium text-gray-900 group-hover:underline">
                                                    {topic.name}
                                                </p>
                                            </div>
                                            <p className="pt-2 text-xs line-clamp-3">
                                                {topic.summary}
                                            </p>
                                        </Link>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

