export const favoritesInMyAdultLifeData = {
  formTitle: "Add or edit influential people in my life",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/favoritesInMyAdultLife",
  formFields: [
    {
      component: "select",
      label: "My favorite. . .",
      name: "favorite",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Amusement parks / zoos", text: "Amusement parks / zoos" },
        { value: "Arts & crafts", text: "Arts & crafts" },
        { value: "Beaches / lakes / pools", text: "Beaches / lakes / pools" },
        { value: "Being with friends", text: "Being with friends" },
        {
          value: "Biking / skating",
          text: "Biking / skating",
        },
        { value: "Boating", text: "Boating" },
        { value: "Building / fixing things", text: "Building / fixing things" },
        { value: "Camping", text: "Camping" },
        { value: "Community service", text: "Community service" },
        { value: "Cooking", text: "Cooking" },
        { value: "Day trips", text: "Day trips" },
        { value: "Dancing", text: "Dancing" },
        { value: "Dining out", text: "Dining out" },
        { value: "Family events / trips", text: "Family events / trips" },
        { value: "Fishing", text: "Fishing" },
        { value: "Games / puzzles", text: "Games / puzzles" },
        { value: "Gardening", text: "Gardening" },
        { value: "Hobbies", text: "Hobbies" },
        { value: "Holidays", text: "Holidays" },
        { value: "Listening to music", text: "Listening to music" },
        { value: "Performing", text: "Performing" },
        { value: "Pets", text: "Pets" },
        { value: "Playing sports", text: "Playing sports" },
        { value: "Reading", text: "Reading" },
        { value: "Skiing / snow boarding", text: "Skiing / snow boarding" },
        { value: "Singing", text: "Singing" },
        { value: "Social groups / events", text: "Social groups / events" },
        { value: "Solitude / meditation", text: "Solitude / meditation" },
        { value: "Sports teams", text: "Sports teams" },
        { value: "Stage shows / musicals", text: "Stage shows / musicals" },
        { value: "Traveling", text: "Traveling" },
        { value: "Walking / hiking", text: "Walking / hiking" },
        {
          value: "Watching movies / cartoons",
          text: "Watching movies / cartoons",
        },
        { value: "Watching television", text: "Watching television" },
        { value: "Vacations / road trips", text: "Vacations / road trips" },
        { value: "Volunteering", text: "Volunteering" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Description",
      name: "description",
      type: "text",
    },
    {
      component: "input",
      label: "My age",
      name: "age",
      type: "number",
    },
    {
      component: "input",
      label: "Who was there",
      name: "who",
      type: "text",
    },
    {
      component: "input",
      label: "Where and when",
      name: "whereWhen",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
  ],
};
