import { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ActionPanel } from "../common/action-panel";
import { restGet } from "../../store/api";
import { CreditCardIcon } from "@heroicons/react/outline";
import { ChangeCard } from "./update-payment-card";
import { getUserDetails } from "../../store/actions";

import GreenBtn from "../buttons/greenBtn";

const BillingPlan = () => {
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [component, setComponent] = useState("");
    const [paymentHistories, setPaymentHistories] = useState([]);
    const [dayLeft, setDayLeft] = useState(0);

    useEffect(() => {
        restGet(
            "/users/show",
            (data) => dispatch(getUserDetails(data)),
            (err) => console.error(err)
        );
        const now = new Date();
        const expiredDate = new Date(user?.expiredSubscriptionDate);
        const diffTime = expiredDate - now;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        setDayLeft(diffDays);
        async function fetchPaymentHistories() {
            await restGet(
                "/paymentHistories",
                (data) => setPaymentHistories(data),
                (err) => console.error(err)
            );
        }
        fetchPaymentHistories();
    }, []);

    return (
        <Fragment>
            <ActionPanel open={open} setOpen={setOpen}>
                {component === "ChangeCard" && <ChangeCard setOpen={setOpen} />}
            </ActionPanel>
            {user && user.currentPaymentPlan ? (
                <>
                    <div className="mt-4">
                        <div className="bg-white rounded-lg border border-gray-300 p-6">
                            <h2 className="text-lg font-medium text-gray-900">
                                Billing Information
                            </h2>
                            <div className="mt-4 divide-y divide-gray-300">
                                <div className="mt-6 -mb-4">
                                    <dl className="divide-y divide-gray-300">
                                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                                            <dt className="text-sm font-medium text-gray-500">
                                                Current Plan
                                            </dt>
                                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                <div className="flex flex-col">
                                                    <span className="font-medium">
                                                        {
                                                            user
                                                                .currentPaymentPlan
                                                                .planName
                                                        }
                                                    </span>
                                                    {user.currentPaymentPlan
                                                        .typeInterval !==
                                                        "lifetime" && (
                                                        <span className="mt-1 text-gray-500">
                                                            {dayLeft} days left
                                                            until next payment
                                                            ($
                                                            {
                                                                user
                                                                    .currentPaymentPlan
                                                                    .nextPaymentAmount
                                                            }{" "}
                                                            on{" "}
                                                            {user.expiredSubscriptionDate.substring(
                                                                0,
                                                                10
                                                            )}
                                                            )
                                                        </span>
                                                    )}
                                                </div>
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                                            <dt className="text-sm font-medium text-gray-500">
                                                Saved Payment Method
                                            </dt>
                                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                <div className="flex flex-col">
                                                    <div className="flex items-center">
                                                        <CreditCardIcon className="h-6 w-6 text-gray-500" />
                                                        <span className="ml-2 font-medium">{`**** **** **** ${user.defaultPayment.last4}`}</span>
                                                    </div>
                                                    <span className="mt-1 text-gray-500">{`${user.defaultPayment.brand} ${user.defaultPayment.expMonth}/${user.defaultPayment.expYear}`}</span>
                                                </div>
                                                <span className="ml-4 flex-shrink-0">
                                                    <GreenBtn
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setOpen(true);
                                                            setComponent(
                                                                "ChangeCard"
                                                            );
                                                        }}
                                                    >
                                                        Change card
                                                    </GreenBtn>
                                                </span>
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4">
                        <div className="bg-white rounded-lg border border-gray-300 p-6">
                            <h2 className="text-lg font-medium text-gray-900">
                                Payment Histories
                            </h2>
                            <div className="mt-4">
                                {paymentHistories.length > 0 ? (
                                    <table className="w-full table-fixed">
                                        <thead>
                                            <tr>
                                                <th className="w-2/5 text-left font-medium text-gray-500 py-2">
                                                    Plan Name
                                                </th>
                                                <th className="w-1/5 text-left font-medium text-gray-500 py-2">
                                                    Amount
                                                </th>
                                                <th className="w-2/5 text-left font-medium text-gray-500 py-2">
                                                    Card
                                                </th>
                                                <th className="w-1/5 text-left font-medium text-gray-500 py-2">
                                                    Timestamp
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paymentHistories.map(
                                                (history, index) => (
                                                    <tr key={index}>
                                                        <td className="text-sm text-gray-900 py-2">
                                                            {history.planName}
                                                        </td>
                                                        <td className="text-sm text-gray-900 py-2">
                                                            $
                                                            {(
                                                                history.amount /
                                                                100
                                                            ).toFixed(2)}
                                                        </td>
                                                        <td className="text-sm text-gray-900 py-2">
                                                            <div className="flex items-center">
                                                                <CreditCardIcon className="h-6 w-6 text-gray-500" />
                                                                <span className="ml-2 font-medium">{`**** **** **** ${history.last4}`}</span>
                                                            </div>
                                                        </td>
                                                        <td className="text-sm text-gray-900 py-2">
                                                            {new Date(
                                                                history.timestamp
                                                            ).toLocaleString()}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div>No payment histories found.</div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="mt-4 text-md">
                    <p className="mt-4 mb-4">
                        You are on a 21-day free trial. Click on the
                        “Subscription” tab above to upgrade on renew your
                        subscription.
                    </p>
                    <p>
                        <b className="text-primary-green">NOTE:</b> If you have
                        a <b>promotional code</b>, choose a subscription from
                        the Subscription page, and click the UPGRADE / RENEW
                        button. You can enter the promotional code during
                        checkout.
                    </p>
                </div>
            )}
        </Fragment>
    );
};

export default BillingPlan;

