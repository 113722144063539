/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { restDelete, restGet } from "../../store/api";
import { updateContactByAll } from "../../store/actions";
import { PDFDownloadLink } from "@react-pdf/renderer";

import PDFLayout from "../pdf-report/all/PDFLayout";
import DeleteModal from "../topics-overview/delete-modal";
import GreenBtn from "../buttons/greenBtn";
import RedBtn from "../buttons/redBtn";
import { Toastr } from "../common/toastr";

const apiPath = "/myContacts";

const colObject = {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    contactType: "Contact Type",
    innerCircle: "Inner Circle",
    actiton: "Actions you can take",
};

const Button = ({ pathname, data }) => {
    const user = useSelector((state) => state.auth.user);
    const currentUser = useSelector((state) => state.auth.currentUser);
    const email = currentUser.attributes.email;
    const name = currentUser.attributes.name;

    const isSubscriptionDisabled = user && user.type === "readonly";
    const linkClasses = `
    ml-3 inline-flex justify-center rounded-md ${
        isSubscriptionDisabled
            ? "border-gray-500 bg-gray-500 text-white cursor-not-allowed"
            : "border-primary-green bg-primary-green text-white"
    } mx-1 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-white hover:text-gray-900 focus:outline-none sm:w-auto mb-4
  `;

    return (
        <div>
            <div className="text-right mt-4">
                {isSubscriptionDisabled ? (
                    <div className={linkClasses}>Add new contact</div>
                ) : (
                    <Link to={pathname + "/new"} className={linkClasses}>
                        Add new contact
                    </Link>
                )}

                {data == null ? (
                    <div className={linkClasses}></div>
                ) : (
                    <PDFDownloadLink
                        document={
                            <PDFLayout
                                records={data}
                                title={"All contact data"}
                                name={name}
                                email={email}
                            />
                        }
                        fileName={"All contact data"}
                    >
                        <button className="inline-flex items-center justify-center rounded-md border border-primary-green bg-primary-green mx-1 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-white hover:text-gray-900 focus:outline-none sm:w-auto mb-4">
                            Save contacts
                        </button>
                    </PDFDownloadLink>
                )}
            </div>
        </div>
    );
};

const Table = ({ data, location, setOpenDeletePopup, setFileId }) => {
    const handleDeleteClick = (topicId) => {
        setFileId(topicId);
        setOpenDeletePopup(true);
    };

    return (
        <div className="mt-6 mb-4 flex flex-col">
            <div className="overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle md:px-0">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                        {colObject[Object.keys(colObject)[0]]}
                                    </th>
                                    {Object.keys(colObject)
                                        .filter((el, index) => index > 0)
                                        .map((el) => (
                                            <th
                                                key={el}
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                {colObject[el]}
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {data &&
                                    data.map((topic, topicIdx) => {
                                        const id = topic._id;
                                        return (
                                            <tr
                                                key={topic._id}
                                                className={
                                                    topicIdx % 2 === 0
                                                        ? undefined
                                                        : "bg-gray-50"
                                                }
                                            >
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {
                                                        topic[
                                                            Object.keys(
                                                                colObject
                                                            )[0]
                                                        ]
                                                    }
                                                </td>
                                                {Object.keys(colObject)
                                                    .filter(
                                                        (el, index) =>
                                                            index > 0 &&
                                                            index < 5
                                                    )
                                                    .map((el) => {
                                                        let cellValue;
                                                        if (
                                                            typeof topic[el] ===
                                                            "boolean"
                                                        ) {
                                                            cellValue = topic[
                                                                el
                                                            ]
                                                                ? "Yes"
                                                                : "No";
                                                        } else if (
                                                            typeof topic[el] ===
                                                                "string" ||
                                                            typeof topic[el] ===
                                                                "number"
                                                        ) {
                                                            cellValue =
                                                                topic[
                                                                    el
                                                                ].toString();
                                                        } else {
                                                            cellValue = "";
                                                        }

                                                        return (
                                                            <td
                                                                key={el}
                                                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                                            >
                                                                {cellValue}
                                                            </td>
                                                        );
                                                    })}

                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <Link
                                                        to={
                                                            location.pathname +
                                                            "/view?id=" +
                                                            topic._id
                                                        }
                                                        className="text-primary-green hover:text-indigo-900 hover:underline"
                                                    >
                                                        View
                                                        <span className="sr-only">
                                                            ,{" "}
                                                            {
                                                                topic[
                                                                    Object.keys(
                                                                        colObject
                                                                    )[0]
                                                                ]
                                                            }
                                                        </span>
                                                    </Link>
                                                    <span className="mx-2">
                                                        |
                                                    </span>
                                                    <Link
                                                        to={
                                                            location.pathname +
                                                            "/edit?id=" +
                                                            topic._id
                                                        }
                                                        className="text-primary-green hover:text-indigo-900 hover:underline"
                                                    >
                                                        Edit
                                                        <span className="sr-only">
                                                            ,{" "}
                                                            {
                                                                topic[
                                                                    Object.keys(
                                                                        colObject
                                                                    )[0]
                                                                ]
                                                            }
                                                        </span>
                                                    </Link>
                                                    <span className="mx-2">
                                                        |
                                                    </span>
                                                    <a
                                                        onClick={() =>
                                                            handleDeleteClick(
                                                                id
                                                            )
                                                        }
                                                        className="text-primary-green hover:text-indigo-900 hover:underline cursor-pointer"
                                                    >
                                                        Delete
                                                        <span className="sr-only">
                                                            ,{" "}
                                                            {
                                                                topic[
                                                                    Object.keys(
                                                                        colObject
                                                                    )[0]
                                                                ]
                                                            }
                                                        </span>
                                                    </a>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const ContactList = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.contact.byAll);

    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [fileId, setFileId] = useState("");

    const successCallback = (id) => {
        const newData = data.filter((contact) => contact._id !== id);
        dispatch(updateContactByAll(newData));
        Toastr({
            type: "success",
            message: "Contact successfully deleted.",
        });
    };

    const getDataToShow = () => {
        restGet(
            apiPath + "/index",
            (data) => dispatch(updateContactByAll(data)),
            (error) => console.error(error)
        );
    };

    const failCallback = (err) => {
        Toastr({ type: "error", message: "Error deleting contact." });
    };

    const handleDeleteItem = (id) => {
        restDelete(
            apiPath + "/delete/" + id,
            {},
            () => successCallback(id),
            failCallback
        );
    };

    useEffect(() => {
        getDataToShow();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <Button pathname={location.pathname} data={data} />
            <Table
                data={data}
                location={location}
                setOpenDeletePopup={setOpenDeletePopup}
                setFileId={setFileId}
            />

            <DeleteModal open={openDeletePopup} setOpen={setOpenDeletePopup}>
                <div className="text-lg space-y-6">
                    <h2>Do you really want to delete this contact</h2>

                    <div className="flex justify-evenly">
                        <GreenBtn
                            type="button"
                            onClick={() => {
                                handleDeleteItem(fileId);
                                setOpenDeletePopup(false);
                            }}
                        >
                            Yes
                        </GreenBtn>
                        <RedBtn
                            type="button"
                            onClick={() => setOpenDeletePopup(false)}
                        >
                            No
                        </RedBtn>
                    </div>
                </div>
            </DeleteModal>
        </Fragment>
    );
};

